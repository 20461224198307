import gql from 'graphql-tag';


export const photoRemovedSubsctiption = gql`
    subscription photoRemoved {
        photoRemoved {
            userId
            id
            imageThumb
            url
            approved
            label
        }
    }
`