import gql from 'graphql-tag';
import { courseFragment } from './course.fragment';

export const myCoursesQuery = gql`
    query myCourses($userId: ID) {
        myCourses(userId: $userId) {
            ...CourseFragment
        }
    }
  
    ${courseFragment}
`;
