import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../../assets/locales/localization';
import styled from 'styled-components';

export class ChatFriendsSearchBar extends Component {
  static propTypes = {
    onTextChange: PropTypes.func.isRequired,
    term: PropTypes.string.isRequired
  };

  render() {
    return (
      <div style={styles.container}>
        <TextInput
          type='text'
          placeholder={LangData.chatFriendsSearchBar.search}
          // style={styles.searchInput}
          onChange={e => this.props.onTextChange(e.target.value)}
          value={this.props.term}
        />
      </div>
    );
  }
}

const styles = {
  container: {
    marginTop: '10px',
    marginBottom: '10px',
    backgroundColor: 'white',
    borderRadius: '15px',
    padding: '7px',
    width: '80%'
  },
  searchInput: {
    height: '25px',
    padding: '0px',
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'white',
    background: 'none',
    border: 'none',
    width: '100%',
    fontFamily: 'inherit'
  }
};

const TextInput = styled.input`
  height: 25px;
  padding: 0px;
  font-size: 15px;
  font-weight: bold;
  color: black;
  background: none;
  border: none;
  width: 100%;
  font-family: inherit;

  &:focus {
    outline: none;
    border: none;
  }
`;
