import { connect } from 'react-redux';
import { graphql } from 'react-apollo-temp';
import { compose } from 'redux';
import NewsFeedCategoriesGridView from './categories-grid.rn';
import { setCategories, toggleCategory } from '../../news-feed.actions';
import { categoriesQuery } from '../../graphql/categories.query';
import { withErrorHandler } from '../../../common/hoc/error-handler.rn';
import { toggleComponent } from '../../../router/router.actions';

const mapStateToProps = ({ newsFeed, navigation }) => ({
  selectedCategories: newsFeed.selectedCategoriesOnView,
  currentComponent: navigation.navigationComponents.component
});

const mapDispatchToProps = dispatch => ({
  setCategories: (newState = null) => dispatch(setCategories(newState)),
  toggleCategory: categoryId => dispatch(toggleCategory(categoryId)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

export const withCategories = graphql(categoriesQuery, {
  props({ data: { loading, error, categories } }) {
    return {
      categories: loading || error ? [] : categories,
      categoriesLoading: loading
    };
  }
});

const withError = withErrorHandler({
  apollo: ['data']
});

const withState = connect(mapStateToProps, mapDispatchToProps);

export const NewsFeedCategoriesGrid = compose(
  withState,
  withCategories,
  withError
)(NewsFeedCategoriesGridView);
