import { connect } from 'react-redux';
import MenuView from './menu-list.rn';
import { toggleComponent } from '../router/router.actions';
import { compose } from 'redux';
import { setCategories } from '../news-feed/news-feed.actions';
import { setUser } from '../account/account.actions';

const mapStateToProps = ({ navigation, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  setCategories: (newState = null) => dispatch(setCategories(newState)),
  setUser: user => dispatch(setUser(user))
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const Menu = compose(withState)(MenuView);
