import React, { useState, useEffect } from 'react';
import Navigation from './router/navigation';
import { ApolloProvider } from 'react-apollo';

import { Provider } from 'react-redux';
import config from './core/config/config';
import { store } from './store';
import { CONTINUE_SESSION } from './login/login.reducer';
import {
  osVersion,
  fullBrowserVersion,
  isIOS,
  isAndroid
} from 'react-device-detect';
import { setAppVersion } from './main/main.actions';
import ENV from './env';
import { AppVersionMiddleware } from './core/network/app-version-middleware';
import { HIDE_NEW_BADGE } from './core/constants/storage';
import { setReadIndicationForItem } from './news-feed/news-feed.actions';
import { analytics } from './analytics/report';
import { refreshSession, getUser, updateUserLogin } from './account/account.util';
import { client } from './account/account.init';
import { apolloClient } from './apollo-subs'; 
import { loggingIn } from './account/account.actions';
import './App.css';
import ReactPWAInstallProvider from "react-pwa-install";
import { setUser } from './auth/auth.actions';

console.disableYellowBox = true;

if (config.cleanStorage) {
  localStorage.clear();
}

function App(props) {
  useEffect(() => {
    if (!isIOS) {
      Notification.requestPermission(function(status) {
        console.log('Notification permission status:', status);
      });
    }

    analytics.start(15 * 1000, client);
    store.dispatch(CONTINUE_SESSION);
    // I18nManager.forceRTL(true)

    async function refreshSessionAsyncFunction() {
      try {
        await refreshSession();
        let user = await getUser()

        // update user logged in
        await updateUserLogin()

        analytics.setUser(user);
        store.dispatch(setUser(user))
      } catch (e) {
        store.dispatch(loggingIn(false));
      }
    }
    refreshSessionAsyncFunction();

   
   

 

    let appVersionIdentifier = '';

    try {
      // const appVersion = osVersion;
      // const appBuildNumber = fullBrowserVersion;
      // appVersionIdentifier = `${appVersion}-${appBuildNumber}`;
      AppVersionMiddleware.setVersion('2.0-22');

      if (ENV === 'staging') {
        appVersionIdentifier = `staging (${appVersionIdentifier})`;
      }

      store.dispatch(setAppVersion('2.0-22'));

      async function existingIndicationsFunction() {
        const existingIndications = localStorage.getItem(HIDE_NEW_BADGE);
        store.dispatch(
          setReadIndicationForItem(
            existingIndications ? JSON.parse(existingIndications) : {}
          )
        );
      }
      existingIndicationsFunction();
    } catch (e) {
      // nothing to do
    }
  }, []);

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ReactPWAInstallProvider enableLogging>
          <Navigation />
        </ReactPWAInstallProvider>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
