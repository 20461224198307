import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { uploadToCloudinary } from '../../cloudinary/utils/upload';
import moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import { createUpdateNewsMutation } from '../../posts/graphql/create-update-news.mutation';
import { apolloClient } from '../../apollo-subs';
import ErrorMessage from '../../login/components/error-message.rn';

const width = window.innerWidth


class AddPost extends Component {

    state = {
        postTitle: '',
        postDescription: '',
        posterImage: null,
        posterImageUrl: null,
        duringUploadPicture: false,
        mainCategory: '5ee08f9514d8e012c0c05f31',
        categories: ['5ee08f9514d8e012c0c05f31'],
        limitStart: false,
        limitEnd: false,
        displayAuthor: null,
        menuVisible: false,
        imageType: '',
        currentNews: null,
        secondStageOpen: false,
        selectedCategoriesIds: [],
        selectedMainCategoryId: '',
        isFinished: false,
        startDate: null,
        startTime: null,
        startDateTime: null,
        endDate: null,
        endTime: null,
        endDateTime: null,
        targetGroups: ['all'],
        done: false,
        link: '',
        isUpdating: false,
        isLoading: false,
        imagePreview: null,
        error: null,
    }

    componentDidMount() {
        const { editEntity } = this.props;
        this.setEditEntity(editEntity);
    }

    setEditEntity(editEntity) {
        if (editEntity) {
            const startMoment = editEntity.startTime
                ? moment(editEntity.startTime)
                : null;
            const endMoment = editEntity.endTime ? moment(editEntity.endTime) : null;

            this.setState({
                categories: editEntity.categories.map(item => item.id),
                mainCategory: editEntity.mainCategory.id,
                title: editEntity.title,
                link: editEntity.link,
                description: editEntity.description,
                displayAuthor: editEntity.displayAuthor,
                posterImage: editEntity.posterImageUrl,
                startTime: editEntity.startTime ? startMoment.format('hh:mm A') : '',
                startDate: editEntity.startTime ? startMoment.toDate() : null,
                endTime: editEntity.endTime ? endMoment.format('hh:mm A') : '',
                endDate: editEntity.endTime ? endMoment.toDate() : null,
                limitStart: !!startMoment,
                limitEnd: !!endMoment
            });

        }
    }

    onTitleChangeHandler = e => {
        this.setState({
            postTitle: e.target.value
        })
    }

    onDescriptionChangeHandler = e => {
        this.setState({
            postDescription: e.target.value
        })
    }

    async handleImage(e) {
        this.setState({ duringUploadPicture: true, imagePreview: URL.createObjectURL(e.target.files[0])});
        let file = e.target.files[0];
        requestAnimationFrame(async () => {
            this.setState({ menuVisible: false });
            await this.createImage(file, 'news_image');
        });
    }

    async createImage(file, tag) {
        const fileData = await toBase64(file);
        const base64 = `data:image/jpeg;base64,${fileData}`;
        const uploadResult = await uploadToCloudinary(file, null, tag);

        const publicId = uploadResult.public_id;
        const filePath = uploadResult.secure_url;

        return this.props.uploadFile(
            file,
            tag,
            base64,
            filePath,
            publicId,
            (store, { data: { uploadToCloudinary } }) => {
                const imageUrl = uploadToCloudinary.filePath;
                this.setState({ posterImage: imageUrl });
                this.setState({ posterImageUrl: imageUrl }, () => {
                    this.setState({ duringUploadPicture: false });
                });
            }
        );
    }

    discardPost = () => {
        this.setState({ done: true, isUpdating: false, isLoading: false, imagePreview: null })
        this.props.updateAddPost()
    }

    saveNews() {
        // this.setState({ isUpdating: true });
        if(this.state.postDescription === "") {
            this.setState({ error: "Cannot post empty content"})
            return
        }



        this.setState({ isLoading: true })
        apolloClient.mutate({
            mutation: createUpdateNewsMutation,
            variables: {
                userId: this.props.currentUser.id,
                newsId: this.props.editEntityId,
                news: {
                    posterImage: this.state.posterImageUrl,
                    link: this.state.link,
                    title: this.props.currentUser.name,
                    description: this.state.postDescription,
                    categories: this.state.categories,
                    mainCategory: this.state.mainCategory,
                    displayAuthorId: this.state.displayAuthor
                        ? this.state.displayAuthor.id
                        : null,
                    startTime: this.state.startDateTime ? this.state.startDateTime : null,
                    endTime: this.state.endDateTime ? this.state.endDateTime : null
                }
            }
        })
        .then(({ data }) => {
            this.setState({ currentNews: data,  });
            this.setState({ done: true });
            this.setState({ isUpdating: false, isLoading: false });
            this.props.updateAddPost()
        })
        .catch(e => this.setState({ isUpdating: false, error: e }));
    }

    render() {
        return (
            <div style={styles.AddPostContainer}>
                {
                    this.state.imagePreview !== null &&
                    <div style={styles.previewContainer}>
                        <img src={this.state.imagePreview} alt="" style={styles.imagePreview}/>
                    </div>
                }
                <div style={styles.bottomContainer}>
                    <div style={styles.InputsContainer}>
                        {/* <input
                            value={this.state.postTitle}
                            onChange={this.onTitleChangeHandler}
                            placeholder="Post Title"
                            style={styles.TitleInputStyle} /> */}
                        <textarea
                            value={this.state.postDescription}
                            onChange={this.onDescriptionChangeHandler}
                            placeholder="Tell us about your experiences with cancer"
                            style={styles.DescriptionInputStyle} />
                    </div>
                    <div style={styles.OtherActionsContainer}>
                    <span style={styles.addImageContainer}>
                        <label style={styles.uploadImgLabel} htmlFor='upload_img'>
                            <input
                                type='file'
                                accept='image/*'
                                onChange={e => this.handleImage(e)}
                                style={styles.hideInputStyle}
                                name='upload_img'
                                id='upload_img'
                            />
                            <FontAwesomeIcon icon={faPlusCircle} style={styles.iconStyle} />
                            <span style={ width < 500 ? {fontSize: "4vw"}: {fontSize: '1rem'}}>Add image</span>
                        </label>
                        {this.state.duringUploadPicture && <LoadingSpinner />}
                    </span>      
                </div>
                </div>
                {
                    this.state.isLoading ? 
                    <Loader
                        type="TailSpin"
                        color="#4d4b62"
                        height={60}
                        width={60}
                        visible={this.state.isLoading} 
                    />
                    :
                    <div style={styles.buttonsContainer}>
                        <button onClick={() => this.saveNews()} style={styles.postBtn}>Post</button>
                        <button onClick={this.discardPost} style={styles.postBtn}>Discard</button>
                    </div>

                }

                {   this.state.error && (
                        <ErrorMessage
                        key='error message'
                        show={this.state.error}
                        message={this.state.error}
                        onPress={() => {
                            this.setState({ error: null});
                            this.props.dismissError();
                        }}
                        />
                    )}
            </div>
        )
    }
}

export default AddPost

const styles = {
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '90%'
    },
    previewContainer: {
        display: 'flex'
    },
    imagePreview: {
        width: '90%',
        height: '17vh',
        objectFit: 'contain'
    },
    bottomContainer: {
        width: '85%',
        margin: 'auto',
        backgroundColor: '#E1C2B0',
        display: 'flex',
        justifyContent: 'space-around',
        borderRadius: '20px',
        padding: '15px 5%',
        textAlign: 'center',
        position: 'relative',
        zIndex: 9999999999,
    },
    AddPostContainer: {
        width: width < 500 ? '100%' : "55%",
        margin: 'auto',
        backgroundColor: '#E1C2B0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        borderRadius: '20px',
        padding: '15px 5%',
        textAlign: 'center',
        position: 'relative',
        zIndex: 9999999999
    },
    InputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    OtherActionsContainer: {
        width: '20%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        alignItems: 'center'
    },
    TitleInputStyle: {
        textAlign: 'center',
        border: 'none',
        borderRadius: '20px',
        padding: '5px 0',
        marginBottom: '10px',
        outline: 'none'
    },
    DescriptionInputStyle: {
        textAlign: 'center',
        border: 'none',
        borderRadius: '20px',
        padding: '13px 1px',
        outline:'none',
        resize: "none",
        fontFamily:'inherit',
        fontSize: width < 500 ? '4vw': '2vw'
    },
    addImageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '12px',
        justifyContent: 'center',
        color: '#4D4B62',
        marginBottom: '10px'
    },
    postBtn: {
        color: '#E1C2B0',
        border: 'none',
        backgroundColor: '#4D4B62',
        borderRadius: '20px',
        padding: '10px 17px',
        outline:'none',
        marginRight: '2%',
        fontSize: width < 500 ? '4vw': '1rem'
    },
    iconStyle: {
        color: '#4D4B62',
        fontSize: '20px',
    },
    hideInputStyle: {
        width: '0.1px',
        height: '0.1px',
        opacity: '0',
        overflow: 'hidden',
        position: 'absolute',
        zIndex: '-1'
    },
    uploadImgLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

export const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });