import React, { useState } from 'react';
import PostsNavigationScrollableTabView from './my-posts-navigation-top-bar.rn';
import Fade from 'react-reveal/Fade';
import { LangData } from '../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SwipeableViews from 'react-swipeable-views';
import { Polls } from '../components/posts-types/polls-container';
import { CreateUpdateNewsForm } from '../components/posts-types/news-container';
import { CreateEventForm } from '../components/posts-types/events-container';
import { Redirect } from 'react-router';
import './my-posts-style.scss';
import { Theme } from '../../common/providers/colors';

const MyPostsView = props => {
  const [currIndex, setCurrIndex] = useState(0);
  const handleChangeIndex = index => {
    setCurrIndex(index);
  };

  function _setClosePoll() {
    props.toggleComponent('News_Feed');
    props.history.push('/');
  }
  if (!props.currentUser) {
    return <Redirect to='/login' />;
  }
  return (
    <div style={styles.pageContainer} className='my-posts-main-container'>
      {/* <Fade bottom> */}
      <div style={styles.pageContainer}>
        <div style={styles.optionsRow}>
          <div>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => {
                props.toggleComponent(props.prevComponent);
                props.history.push('/');
                // props.history.go(-1);
              }}
            />
          </div>
          <div>
            <span>{LangData.postsNavigator.center}</span>
          </div>
          <div></div>
        </div>
        <div style={{ width: '100%' }}>
          {/* <PostsNavigationScrollableTabView
            index={currIndex}
            changeIndex={handleChangeIndex}
          /> */}
          <SwipeableViews index={currIndex} onChangeIndex={handleChangeIndex}>
            <CreateUpdateNewsForm
              // close={() => props.toggleMyPosts(false)}
              tabLabel={LangData.postsNavigator.news}
              _setClosePoll={_setClosePoll}
            />
            {/* <Polls
              // close={() => props.toggleMyPosts(false)}
              tabLabel={LangData.postsNavigator.polls}
              _setClosePoll={_setClosePoll}
            />
            <CreateEventForm
              // close={() => props.toggleMyPosts(false)}
              tabLabel={LangData.postsNavigator.events}
              _setClosePoll={_setClosePoll}
            /> */}
          </SwipeableViews>
        </div>
      </div>
      {/* </Fade> */}
    </div>
  );
};

export default MyPostsView;

const styles = {
  pageContainer: {
    minHeight: window.innerHeight,
    backgroundColor: Theme.BACKGROUND_COLOR
  },

  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold'
  }
};
