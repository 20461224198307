import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notificationsStyles } from './common';
import ProfileImage from '../../../../common/components/profile-image.rn';

export class SocialNetworkNotificationItem extends Component {
  static propTypes = {
    item: PropTypes.any
  };

  render() {
    const { item } = this.props;

    if (!item) {
      return null;
    }

    const { otherUser } = item;

    if (!otherUser) {
      return null;
    }

    return (
      <div style={notificationsStyles.container}>
        <div style={notificationsStyles.imageContainer}>
          <ProfileImage
            imageUrl={otherUser.profilePictureThumbUrl}
            fullSizeUrl={otherUser.profilePictureUrl}
            showBorder={false}
            size={50}
            withFullSize={false}
          />
        </div>
        <div style={notificationsStyles.contentContainer}>
          <span style={notificationsStyles.text}>
            <span style={notificationsStyles.boldText}>
              {item.otherUser.name}{' '}
            </span>
            {item.content}
          </span>
          <span style={notificationsStyles.date}>{item.time}</span>
        </div>
      </div>
    );
  }
}
