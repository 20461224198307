import { connect } from 'react-redux';
import { graphql } from 'react-apollo-temp';
import CoursesView from './courses.rn';
import { changeActiveCourse, toggleDrawer } from '../courses.actions';
import { toggleComponent } from '../../router/router.actions';
import { myCoursesQuery } from '../graphql/my-courses.query';
import { compose } from 'redux';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';

const mapStateToProps = ({ courses, chat, navigation, auth }) => ({
  activeCourse: courses.activeCourse,
  isDrawerOpen: courses.isDrawerOpen,
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  currentUser: auth.currentUser

  // isChatScreenActive: !!chat.currentChat
});

const mapDispatchToProps = dispatch => ({
  changeActiveCourse: course => dispatch(changeActiveCourse(course)),
  toggleDrawer: newState => dispatch(toggleDrawer(newState)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

const POLL_INTERVAL = 30 * 1000;

const withError = withErrorHandler({
  apollo: ['courses']
});

export const withCoursesData = graphql(myCoursesQuery, {
  name: 'courses',
  options: (props) => {
      return ({
        pollInterval: POLL_INTERVAL,
        fetchPolicy: 'network-only',
        variables: {
          userId: props.currentUser ? props.currentUser.id : null,
        }
      })
  }
});

export const Courses = compose(
  withState,
  withCoursesData,
  withError
)(CoursesView);
