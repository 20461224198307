import gql from 'graphql-tag';

export const updateYogiChatSympthomsMutation = gql`
  mutation updateYogiChatSympthoms($yogiId: ID, $name: String, $severity: Int, $watchTime: String) {
    updateYogiChatSympthoms(
        yogiId: $yogiId, name:$name, severity:$severity, watchTime:$watchTime
    ){
      _id
    }
  }
`;
