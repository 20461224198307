import { combineReducers } from 'redux';
import { ELinkedInActions } from './linkedin.actions';
import { EAuthActions } from '../auth/auth.actions';

const isDuringAuthentication = (state = false, action) => {
  if (action.type === ELinkedInActions.START_AUTH ||
    (action.type === EAuthActions.RELOAD_USER && action.reason === 'linkedin')) {
    return true;
  }

  if (action.type === EAuthActions.USER_LOADED) {
    return false;
  }

  return state;
};

export const linkedin = combineReducers({
  isDuringAuthentication,
});
