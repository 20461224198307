const config = {
  server: {
    protocol: 'https',
    host: 'Be.YOCAS.life', //'31.133.102.222' // localhost
    port: 4000,
    path: '/graphql'
  },
  cleanStorage: false
};

export default config;
