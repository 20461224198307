import gql from 'graphql-tag';

export const classmateItemFragment = gql`
  fragment ClassmateItem on ClassmateType {
    id
    username
    name
    firstName
    profilePictureUrl
    profilePictureThumbUrl
    major
    classification
    linkedin
    linkedinLink
    facebook
    twitter
    instagram
    snapchat
    facebookName
    msjEmail
    friendsSince
    friendRequestStatus
    appSupportChat
    canRemoveConnection
    userYoutubeVideoId
  }
`;
