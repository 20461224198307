export const EFriendsListActiveState = {
  FRIENDS_LIST: 'FRIENDS_LIST',
  REQUESTS: 'REQUESTS',
  GLOBAL_SEARCH: 'GLOBAL_SEARCH',
};

export const EFriendsListFilterType = {
  PENDING: 'PENDING',
  SENT: 'SENT',
  IGNORED: 'IGNORED',
};

export const EFriendsListActions = {
  SET_SEARCH_TERM: '@FRIENDS/SET_SEARCH_TERM',
  TOGGLE_REQUESTS: '@FRIENDS/TOGGLE_REQUESTS',
  LOAD_HIDDEN_STUDENTS: '@FRIENDS/LOAD_HIDDEN_STUDENTS',
};

export const toggleRequests = newState => ({
  type: EFriendsListActions.TOGGLE_REQUESTS,
  newState,
});

export const setSearchTerm = search => ({
  type: EFriendsListActions.SET_SEARCH_TERM,
  search,
});

export const lookForMore = () => ({
  type: EFriendsListActions.LOAD_HIDDEN_STUDENTS,
});

