import gql from 'graphql-tag';

export const userTalkedWithYogi = gql`
  mutation userTalkedWithYogi($userId:String!) {
    userTalkedWithYogi(
        userId: $userId
    ){
        id
        talkedWithYogi
    }
  }
`;
