import { createNewChatMutation } from '../graphql/create-new-chat.mutation';
import { graphql } from 'react-apollo-temp';

export const withCreateChatMutation = graphql(createNewChatMutation, {
  props: ({ mutate }) => ({
    createNewChat: (userId, users, title, createIfNotExists) =>
      mutate({
        variables: {
          userId,
          title,
          users,
          createIfNotExists
        },
        // refetchQueries: ['myChats']
      })
  })
});
