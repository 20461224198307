import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { Theme } from '../../../common/providers/colors';

const width = window.innerWidth;

export class ChatNavBar extends Component {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    left: PropTypes.any,
    showSeparator: PropTypes.bool,
    right: PropTypes.any,
    children: PropTypes.node,
    onClose: PropTypes.func,
    onDone: PropTypes.func,
    openUsersList: PropTypes.func
  };

  getLeft() {
    if (this.props.left !== undefined) {
      return this.props.left;
    }

    return (
      <div onClick={() => this.props.onClose()}>
        <FontAwesomeIcon
          icon={faTimes}
          style={{ color: Theme.SECONDARY_TEXT_COLOR }}
        />
      </div>
    );
  }

  getRight() {
    if (this.props.right !== undefined) {
      return this.props.right;
    }

    return (
      <div
        onClick={() => this.props.onDone()}
        style={{
          right: 20,
          left: 20,
          top: 20,
          bottom: 20
        }}
      ></div>
    );
  }

  render() {
    return (
      <div
        style={
          this.props.type === 'findFriends'
            ? styles.containerFindFriends
            : styles.containerChatScreen
        }
        id='container'
      >
        <div style={styles.topBarContainer} id='topBarContainer'>
          <div style={styles.left}>{this.getLeft()}</div>
          <div
            disabled={!this.props.openUsersList}
            onClick={() =>
              this.props.openUsersList && this.props.openUsersList()
            }
            style={styles.center}
          >
            <span style={styles.titleText}>{this.props.title}</span>
            <br />
            {this.props.subTitle && this.props.subTitle !== '' ? (
              <span style={styles.subTitleText}>{this.props.subTitle}</span>
            ) : null}
          </div>
          <div style={styles.right}>{this.getRight()}</div>
        </div>
        {this.props.children && this.props.showSeparator
          ? [
              <div
                key={0}
                style={{ alignItems: 'center', marginHorizontal: 10 }}
              ></div>,
              <div style={styles.containerInput} key={1}>
                {this.props.children}
              </div>
            ]
          : null}
      </div>
    );
  }
}

const styles = {
  containerFindFriends: {
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    position: 'fixed',
    top: '0',
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0 4px 2px -2px ${Theme.BACKGROUND_COLOR}`
  },
  containerChatScreen: {
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    position: 'fixed',
    top: '0',
    width: '100%',
    height: 60,
    display: 'flex',

    boxShadow: `0 4px 2px -2px ${Theme.BACKGROUND_COLOR}`
  },
  topBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    color: 'white',
    margin: '8px 0'
  },
  left: {
    marginLeft: '10px'
  },
  right: {
    marginRight: '10px'
  },
  center: {
    textAlign: 'center'
  },
  titleText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
    color: Theme.TEXT_COLOR
  },
  subTitleText: {
    fontSize: 12,
    color: Theme.SECONDARY_TEXT_COLOR
  },
  icon: {
    marginLeft: 10
  },
  iconRight: {
    width: 25,
    height: 25,
    marginRight: 20
  },
  containerInput: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  }
};
