import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faBell as fasBell } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../../common/providers/colors';

const ReminderBell = props => {
  return (
    <div
      style={props.style}
      onClick={e => {
        e.stopPropagation();
        props.onPress();
      }}
    >
      <FontAwesomeIcon
        icon={props.colored ? fasBell : faBell}
        style={{ color: Theme.TEXT_COLOR }}
      />
    </div>
  );
};

export default ReminderBell;
