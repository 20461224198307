import React, { useState } from 'react';
import LoadingSpinner from '../../../../common/components/loading-spinner.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { uploadToCloudinary } from '../../../../cloudinary/utils/upload';
import { LangData } from '../../../../assets/locales/localization';
import { Theme } from '../../../../common/providers/colors';

const UploadEventFeedImageView = props => {
  const [loading, setLoading] = useState(false);

  async function handleImage(e) {
    let file = e.target.files[0];
    if (file.data && props.onStartUpload) {
      props.onStartUpload(`data:image/jpeg;base64,${file.data}`);
    }
    setLoading(true);

    const imageId = await uploadToCloudinary(
      file,
      progress => props.onUploadProgress(progress),
      'event_image'
    );

    props.onUploadDone();

    if (!imageId || imageId.error) {
      props.setAlert(LangData.uploadEventImage.wrong);
      return;
    }

    props
      .uploadFeedImage(imageId.public_id, props.event.id)
      .then(() => {
        setLoading(false);
        props.setAlert(LangData.uploadEventImage.uploaded);
      })
      .catch(() => {
        alert(
          LangData.uploadEventImage.wrong,
          LangData.uploadEventImage.again,
          () => handleImage(file)
        );
      });
  }
  return (
    <label htmlFor='upload_images_curr_evnet'>
      <div
        style={{ color: Theme.TEXT_COLOR, padding: '1%' }}
        onClick={e => e.stopPropagation()}
      >
        <input
          type='file'
          accept='image/*'
          onChange={e => {
            e.stopPropagation();
            handleImage(e);
          }}
          style={styles.hideInputStyle}
          name='upload_images_curr_evnet'
          id='upload_images_curr_evnet'
          disabled={loading}
        />
        {loading ? <LoadingSpinner /> : <FontAwesomeIcon icon={faCamera} />}
      </div>
    </label>
  );
};

export default UploadEventFeedImageView;

const styles = {
  loadingParent: {
    position: 'absolute',
    top: 18,
    right: 11
  },
  cameraIcon: {
    marginLeft: 40
  },
  container: {
    flex: 1
  },
  hideInputStyle: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1'
  }
};
