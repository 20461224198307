export const EChatActions = {
  SET_SEARCH_TERM: 'chat/SET_SEARCH_TERM',
  CHAT_NAVIGATE: 'chat/NAVIGATE_TO_CHAT',
  NAVIGATE_TO_FRIENDS_LIST: 'chat/NAVIGATE_TO_FRIENDS_LIST',
  NAVIGATE_TO_PROFILE_PAGE: 'chat/NAVIGATE_TO_PROFILE_PAGE',
};

export const setSearchTerm = search => ({
  type: EChatActions.SET_SEARCH_TERM,
  search,
});

export const chatNavigate = chat => ({
  type: EChatActions.CHAT_NAVIGATE,
  chat,
});

export const chatWithUser = user => ({
  type: EChatActions.CHAT_NAVIGATE,
  user,
});

export const navigateToFriendsList = toPendingRequests => ({
  type: EChatActions.NAVIGATE_TO_FRIENDS_LIST,
  toPendingRequests,
});


export const navigateToProfilePage = () => ({
  type: EChatActions.NAVIGATE_TO_PROFILE_PAGE,
});
