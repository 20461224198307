import gql from 'graphql-tag';

export const addPhotoMutation = gql`
  mutation addPhoto($userId: ID, $photoId: String!, $label: String) {
    addPhoto(userId: $userId, photoId: $photoId, label: $label) {
      id
      url
      imageThumb
      approved
      label
    }
  }
`;
