import { breadcrumb } from '../bugsnag';

export const ETwitterActions = {
  START_AUTH: 'twitter/START_AUTH'
};

export const startTwitterAuth = () => {
  breadcrumb('twitter', 'auth started');

  return {
    type: ETwitterActions.START_AUTH
  };
};
