import React, { Fragment, useState, useEffect } from 'react';
import { LangData } from '../../assets/locales/localization';

const NotificationsNavigationScrollableTabView = props => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setIndex(props.index);
  }, [props]);

  return (
    <div style={styles.toggleContainer}>
      <label style={styles.switch}>
        <span
          style={styles.notificationsToggle}
          id='notificationsToggle'
          onClick={() => props.changeIndex(0)}
        >
          {LangData.notificationsNavigator.notifications}
        </span>
        <span
          style={styles.reminderToggle}
          id='reminderToggle'
          onClick={() => props.changeIndex(1)}
        >
          {LangData.notificationsNavigator.reminder}
        </span>
        <span
          style={
            index === 0 ? styles.sliderNotifications : styles.sliderReminder
          }
          id='checked'
        ></span>
      </label>
    </div>
  );
};

export default NotificationsNavigationScrollableTabView;

const styles = {
  toggleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '5%',
    marginBottom: ' 3%'
  },
  notificationsToggle: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    zIndex: '1',
    left: window.innerWidth / 7.2 + 'px',
    fontSize: window.innerWidth / 30 + 'px',
    bottom: window.innerWidth / 45 + 'px'
  },
  reminderToggle: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    zIndex: '1',
    left: window.innerWidth / 1.78 + 'px',
    fontSize: window.innerWidth / 30 + 'px',
    bottom: window.innerWidth / 45 + 'px',
    width: 'fit-content'
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    color: 'white',
    fontWeight: 'bold'
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: window.innerWidth / 1.10299 + 'px',
    height: window.innerWidth / 12 + 'px',
    borderRadius: window.innerWidth / 18 + 'px',
    backgroundColor: 'none',
    border: '2px solid #1114A4'
  },
  checkboxInput: {
    opacity: '0',
    width: '0',
    height: '0'
  },
  sliderReminder: {
    position: 'absolute',
    content: '""',
    height: window.innerWidth / 12 + 1 + 'px',
    width: '50%',
    left: window.innerWidth / 2.61856 + 'px',
    backgroundColor: '#1114A4',
    transition: 'all 0.4s ease 0s',
    borderRadius: window.innerWidth / 7.5 + 'px',
    transform: 'translateX(26px)'
  },
  sliderNotifications: {
    position: 'absolute',
    content: '""',
    height: window.innerWidth / 12 + 1 + 'px',
    width: '50%',
    left: window.innerWidth / -13.67 + 'px',
    backgroundColor: '#1114A4',
    transition: 'all 0.4s ease 0s',
    borderRadius: window.innerWidth / 10.048 + 'px',
    transform: 'translateX(26px)'
  }
};
