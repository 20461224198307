import React, { useState, useEffect } from 'react';
import { LangData } from '../../../../assets/locales/localization';
import { Theme } from '../../../../common/providers/colors';

const AttendingCornerButton = props => {
  const [isAttending, setIsAttending] = useState(null);

  useEffect(() => {
    setIsAttending(props.event.userRSVP === 'GOING');
  }, [props]);

  function changeRSVP() {
    requestAnimationFrame(() => {
      if (props.event.userRSVP === 'GOING') {
        props.changeRSVP('MAYBE');
      } else {
        props.changeRSVP('GOING');
      }
    });
  }

  return (
    <div style={styles.container}>
      <button
        style={styles.cornerBtnText}
        onClick={e => {
          e.stopPropagation();
          changeRSVP();
        }}
      >
        {isAttending ? (
          <span>{LangData.attendingCornerButton.cancel}</span>
        ) : (
          <span>{LangData.attendingCornerButton.attending}</span>
        )}
      </button>
    </div>
  );
};
export default AttendingCornerButton;

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cornerBtnText: {
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    border: 'none',
    textAlign: 'center',
    fontFamily: 'inherit',
    padding: '3%',
    borderRadius: '10px',
    fontWeight: 'bold',
    color: 'white'
  }
};
