import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStudentsList } from './allStudents.container';
import { ProfileAvatar } from '../../common/components/profile-avatar.rn';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme } from '../../common/providers/colors';

export class UserPickerDropdownView extends Component {
  static propTypes = {
    updateFilter: PropTypes.func,
    search: PropTypes.string,
    skip: PropTypes.number,
    limit: PropTypes.number,
    students: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allStudents: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    }),
    selectedUser: PropTypes.any,
    onChange: PropTypes.func
  };

  constructor() {
    super();

    this._input = React.createRef();
  }

  handleChange(event) {
    console.log(event, event.target.value);
    this.props.onChange(event.target.value);
  }

  selectionRenderer(value) {
    if (value) {
      return (
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            color: 'white'
          }}
        >
          <ProfileAvatar src={value.profilePictureUrl} size={20} />
          {value.name}
        </div>
      );
    }

    return '';
  }

  get users() {
    const users =
      this.props.students &&
      this.props.students.allStudents &&
      this.props.students.allStudents.items
        ? this.props.students.allStudents.items
        : [];

    if (this.props.selectedUser) {
      const without = users.filter(u => u.id !== this.props.selectedUser.id);

      return [this.props.selectedUser, ...without];
    }

    return users;
  }

  opened() {
    this.focus();
  }

  focus() {
    if (this._input && this._input.current) {
      this._input.current.focus();
    }
  }

  handleKeyDown(e) {
    e.stopPropagation();
    this.focus();
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.focus();
    }, 0);
  }

  render() {
    const data = this.users.map(student => (
      <MenuItem key={student.id} value={student}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: Theme.SECONDARY_TEXT_COLOR
          }}
        >
          <ProfileAvatar src={student.profilePictureUrl} size={30} />
          {student.name}
        </div>
      </MenuItem>
    ));

    return (
      <div
        onClick={() => this.opened()}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <p
          style={{
            color: Theme.SECONDARY_TEXT_COLOR,
            fontSize: 17,
            fontWeight: 'bold'
          }}
        >
          Override Author name:
        </p>
        <Select
          renderValue={this.selectionRenderer}
          value={this.props.selectedUser ? this.props.selectedUser : ''}
          onChange={(event, index, value) => this.handleChange(event)}
          style={{ width: '90%' }}
        >
          <MenuItem disabled onKeyDown={this.moveFocusToInput}>
            <TextField
              inputRef={this._input}
              onKeyDown={e => e.stopPropagation()}
              label='Find Participants'
              value={this.props.search}
              fullWidth={true}
              InputProps={{
                style: {
                  color: Theme.TEXT_COLOR
                }
              }}
              onChange={e => {
                this.props.updateFilter({ search: e.target.value });
              }}
            />
          </MenuItem>

          {data}
        </Select>
      </div>
    );
  }
}

export const UserPickerDropdown = withStudentsList(UserPickerDropdownView);
