import FeedEventLiveModalView from './live-modal.rn';
import { compose } from 'react-apollo-temp';
import { withGoingList } from './attending-list.container';
import { withErrorHandler } from '../../../../common/hoc/error-handler.rn';
import { connect } from 'react-redux';
import { setLiveFeedModalActiveTab } from '../../../news-feed.actions';

const withError = withErrorHandler({
  apollo: ['goingList']
});

const withState = connect(
  ({ newsFeed }) => ({
    activeTab: newsFeed.liveModalActiveTab
  }),
  dispatch => ({
    setActiveTab: index => dispatch(setLiveFeedModalActiveTab(index))
  })
);

export const FeedEventLiveModal = compose(
  withState,
  withGoingList,
  withError
)(FeedEventLiveModalView);
