import gql from 'graphql-tag';

export const FeedEventItemFragment = gql`
  fragment FeedEventItem on NewsFeedItemType {
    id
    title
    allDay
    description
    posterImageUrl
    thumbUrl
    creationDate
    displayCreationDate
    cancelled
    startTime
    endTime
    exportStartTime
    exportEndTime
    locationName
    previousEventDate
    previousEventImages
    previousEventImagesThumbs
    userReminder
    categories {
      id
      name
      icon
    }
    mainCategory {
      id
      name
      icon
    }
    author {
      profilePictureUrl
      name
      admin
    }
    whoToContact
    userRSVP
    isNew
    likes
    comments {
      id
      parentId
      userId
      userName
      createdAt
      body
      email
      updatedAt
      reported
      likes
      postId
      userProfileImg
    }
  }
`;
