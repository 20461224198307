import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TargetPickerForm } from './target-picker-form.container';
import { PublicationPlatformPickerForm } from './publication-platform-picker-form.container';
import { Theme } from '../../common/providers/colors';

export default class SecondStageModal extends Component {
  static propTypes = {
    type: PropTypes.string,
    poll: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      closeModal: false,
      openPlatformPicker: false,
      data: {}
    };
  }

  _setClose = (value, stateKey) => {
    console.log('changed', value, stateKey);
    this.setState({ [stateKey]: value }, () => {
      if (this.state.closeModal) {
        this.props._setCloseSecond(true, 'isFinished');
      }
    });
  };

  render() {
    return (
      <div>
        <TargetPickerForm
          poll={this.props.poll}
          type={this.props.type}
          _setClose={this._setClose}
          setGroups={this.props.setGroups}
        />

        {/* <PublicationPlatformPickerForm
          poll={this.props.poll}
          type={this.props.type}
          _setClose={this._setClose}
        /> */}
      </div>
    );
  }
}

const styles = {
  mainContainer: {
    height: '100%',
    position: 'fixed',
    top: '0',
    backgroundColor: Theme.BACKGROUND_COLOR,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5%',
    alignItems: 'center'
  },
  userItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5
  },
  details: {
    marginLeft: 10
  },
  list: {
    flexGrow: 1
  },
  name: {
    color: Theme.TEXT_COLOR
  },
  major: {
    color: Theme.TEXT_COLOR,
    fontSize: 12
  }
};
