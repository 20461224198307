import gql from 'graphql-tag';

export const eventLiveFeedImagesQuery = gql`
  query eventLiveFeedImages($id: String!) {
    feedItem(id: $id) {
      ... on NewsFeedEventItem {
        id
        liveFeedImages {
          id
          pending
          approved
          rejectionReason
          photoUrl: photoUrl(
            params: { height: "768", width: "1024", crop: FIT }
          )
          thumbUrl: photoUrl(
            params: { height: "200", width: "200", crop: FILL }
          )
          uploadedBy {
            id
            name
            profilePictureUrl
          }
        }
      }
    }
  }
`;
