import { graphql } from 'react-apollo-temp';
import YourPhotosView from './your-photos.rn';
import { myPhotosQuery } from '../../graphql/my-photos.query';
import { withErrorHandler } from '../../../common/hoc/error-handler.rn';
import { compose } from 'redux';

const withData = graphql(myPhotosQuery, {
  name: 'photosData',
  options: ({currentUser}) => ({
    // pollInterval: 15 * 1000,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: currentUser ? currentUser.id : null
    }
  })
});

const withError = withErrorHandler({
  apollo: ['photosData']
});

export const YourPhotos = compose(withData, withError)(YourPhotosView);
