import gql from 'graphql-tag';
import { userFieldsFragment } from '../../login/graphql/me.fragment';

export const resetSocialLinkMutation = gql`
  mutation resetSocialLink($userId: ID, $type: SocialNetwork!) {
    resetSocialLink(userId: $userId, type: $type) {
      ...UserFields
    }
  }

  ${userFieldsFragment}
`;
