import React, { useState, Fragment } from 'react';
import ProfileImage from '../../../../common/components/profile-image.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faCommentAlt,
  faTrashAlt
} from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as fasThumbsUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Theme } from '../../../../common/providers/colors';

function Comment(props) {
  const [viewInputForComment, setViewInputForComment] = useState(false);
  const [replyInput, setReplyInput] = useState('');

  const renderChildComment = comment => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        key={comment.id}
      >
        <div style={styles.userCommentChildCotainer}>
          <div style={styles.userComment}>
            <ProfileImage
              showBorder={false}
              withFullSize={false}
              height={30}
              width={30}
              imageUrl={comment.userProfileImg || null}
            />
          </div>
          <div style={styles.commentContent}>
            <div style={styles.contentContainer}>
              <span style={styles.userName}>{comment.userName}</span>
              <span>{comment.body}</span>
            </div>
          </div>
        </div>
        <div style={styles.commentChildOptions}>
          <span style={styles.commentDate}>
            {props.comment.createdAt.substring(0, 10)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={styles.userCommentCotainer}>
        <div style={styles.userComment}>
          <ProfileImage
            showBorder={false}
            withFullSize={false}
            height={30}
            width={30}
            imageUrl={props.comment.userProfileImg || null}
          />
        </div>
        <div style={styles.commentContent}>
          <div style={styles.contentContainer}>
            <span style={styles.userName}>{props.comment.userName}</span>
            <span>{props.comment.body}</span>
          </div>
          <div style={styles.commentOptions}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{ display: 'flex', marginRight: '25%' }}
                onClick={() => props.likeAction(props.comment)}
              >
                <FontAwesomeIcon
                  icon={
                    props.likeExtractor(props.comment)
                      ? fasThumbsUp
                      : faThumbsUp
                  }
                  style={styles.icon}
                />
                <span>Like</span>
              </div>
              <div
                style={{ display: 'flex' }}
                onClick={() => setViewInputForComment(!viewInputForComment)}
              >
                <FontAwesomeIcon icon={faCommentAlt} style={styles.icon} />
                <span>Reply</span>
              </div>
            </div>
            {/* delete comment only for comment owner */}
            {props.comment.userName === props.viewingUserName.username && (
              <div
                style={{ display: 'flex' }}
                onClick={() => props.deleteAction(props.comment)}
              >
                <FontAwesomeIcon icon={faTrashAlt} style={styles.icon} />
                <span>Delete</span>
              </div>
            )}

            <span style={styles.commentDate}>
              {props.comment.createdAt.substring(0, 10)}
            </span>
          </div>
        </div>
      </div>
      <div>
        {props.childrenComments.map(item => {
          if (item.parentId === props.comment.id) {
            return renderChildComment(item);
          }
        })}
      </div>
      {viewInputForComment && (
        <div id='inputCommentContainer'>
          <div style={styles.inputRowChild} onClick={e => e.stopPropagation()}>
            <ProfileImage
              showBorder={false}
              withFullSize={false}
              height={20}
              width={20}
              imageUrl={props.viewingUserName.profilePictureUrl}
            />
            <TextInput
              type='text'
              placeholder='Add a comment...'
              value={replyInput}
              onChange={e => setReplyInput(e.target.value)}
              // style={styles.commentInput}
            />
          </div>
          {replyInput !== '' && (
            <button
              onClick={e => {
                e.stopPropagation();
                props.saveAction(replyInput, props.comment.id);
                setReplyInput('');
                setViewInputForComment(false);
              }}
              style={styles.postBtnChild}
            >
              Reply
            </button>
          )}
        </div>
      )}
    </div>
  );
}

const styles = {
  icon: {
    marginRight: '10%',
    color: Theme.TEXT_COLOR
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2%'
  },
  inputRowChild: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2%',
    width: '70%',
    marginLeft: '12%',
    marginBottom: '2%'
  },
  commentInput: { width: '90%', padding: '1%', fontFamily: 'inherit' },
  postBtn: {
    border: 'none',
    borderRadius: '15px',
    color: 'white',
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    padding: '2% 5%',
    display: 'block',
    marginTop: '2%',
    marginLeft: '7%',
    fontFamily: 'inherit'
  },
  postBtnChild: {
    border: 'none',
    borderRadius: '15px',
    color: 'white',
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    padding: '2% 5%',
    display: 'block',
    marginTop: '2%',
    marginLeft: '13%'
  },
  userCommentCotainer: {
    display: 'flex',
    margin: '2% 0% 2% 0%'
  },
  userComment: {
    flex: 0.1,
    display: 'flex',
    alignItems: 'center',
    marginRight: '2%'
  },
  commentContent: {
    flex: '0.8',
    display: 'flex',
    flexDirection: 'column'
  },
  commentDate: {
    fontSize: '10px',
    color: 'gray'
  },
  commentOptions: {
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  userName: {
    fontSize: '12px',
    fontWeight: 'bold'
  },
  contentContainer: {
    backgroundColor: 'aliceblue',
    borderRadius: '12px',
    padding: '1% 5%',
    paddingLeft: '5%',
    paddingTop: '1%',
    paddingBottom: '1%',
    paddingRight: '5%',
    display: 'flex',
    flexDirection: 'column'
  },
  userCommentChildCotainer: {
    display: 'flex',
    margin: '2% 0% 2% 0%',
    marginLeft: '13%'
  },
  commentChildOptions: {
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '91%',
    marginTop: '-2%'
  }
};

export default Comment;

const TextInput = styled.input`
  width: 90%;
  padding: 1%;
  font-family: inherit;
  font-size: 16px;

  &:focus {
    outline: none;
    border: none;
  }
`;
