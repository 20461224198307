import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChatScreen } from './chat-screen.container';
import { Redirect } from 'react-router';
import { Theme } from '../../../common/providers/colors';

export class ActiveChatScreenModalView extends Component {
  static propTypes = {
    currentChat: PropTypes.any,
    close: PropTypes.func
  };

  render() {
    if (!this.props.currentUser) {
      return <Redirect to='/login' />;
    }
    return (
      <Fragment>
        {this.props.currentChat !== null ? (
          <div
            // onRequestClose={this.props.close}
            style={styles.mainModal}
          >
            <ChatScreen {...this.props} />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const styles = {
  mainModal: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    height: window.innerHeight,
    display: 'flex',
    flexDirection: 'column',
    width: window.innerWidth
  }
};
