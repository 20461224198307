import React, { Component } from 'react';
import { FeedList } from './feed-list.container';

export class FeedRemindersList extends Component {
  static propTypes = {};

  render() {
    return (
      <div style={{ marginTop: '-8.5%' }}>
        <FeedList onlyReminders={true} history={this.props.history} />
      </div>
    );
  }
}
