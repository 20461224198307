import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../../assets/locales/localization';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Theme } from '../../../common/providers/colors';

export class FindChatsBarView extends Component {
  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    openNewChatModal: PropTypes.func.isRequired
  };

  render() {
    return (
      <div style={styles.container}>
        <FontAwesomeIcon icon={faSearch} style={styles.searchIcon} />
        <TextInput
          type='text'
          placeholder={LangData.findChatsBar.placeholder}
          onChange={e => this.props.setSearchTerm(e.target.value)}
          value={this.props.searchTerm}
        />

        {this.props.searchTerm !== '' && (
          <div
            onClick={() => this.props.setSearchTerm('')}
            style={styles.clearIcon}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}

        <div
          onClick={() => this.props.openNewChatModal()}
          style={styles.newChatBtn}
        >
          {LangData.findChatsBar.newChat.toUpperCase()}
        </div>
      </div>
    );
  }
}

const styles = {
  newChatBtn: {
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '15px',
    padding: '1% 5%',
    marginRight: '3%',
    fontSize: '15px',
    zIndex: 99999
  },
  buttonTextStyle: {
    color: 'white',
    fontWeight: 'bold',
    height: 25,
    alignSelf: 'center',
    fontSize: 12,
    paddingTop: 5
  },
  leftSideContainer: {
    flex: 0.7,
    marginRight: '10px',
    position: 'relative'
  },
  searchIcon: {
    marginTop: '1vh',
    fontSize: '12px',
    position: 'absolute',
    marginLeft: '5vw',
    zIndex: '99999'
  },
  clearIcon: {
    marginTop: '0.5vh',
    fontSize: '12px',
    position: 'absolute',
    marginLeft: '48vw'
  },
  container: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    width: '100%',
    borderBottomColor: Theme.BACKGROUND_COLOR,
    borderBottomWidth: 2,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  searchInput: {
    paddingRight: '30px',
    height: '25px',
    alignSelf: 'stretch',
    fontSize: '13px',
    fontFamily: 'inherit',
    color: Theme.TEXT_COLOR,
    outline: 'none',
    border: 'none',
    paddingLeft: '7%',
    fontWeight: 'bold',
    marginLeft: '3%',
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '15px',
    width: '50%'
  }
};

const TextInput = styled.input`
  padding-right: 30px;
  height: 25px;
  align-self: stretch;
  font-size: 15px;
  font-family: inherit;
  color: ${Theme.TEXT_COLOR};
  outline: none;
  border: none;
  padding-left: 7%;
  font-weight: bold;
  margin-left: 3%;
  position: relative;
  background-color: white;
  border-radius: 15px;
  width: 50%;

  &:focus {
    outline: none;
    border: none;
  }
`;
