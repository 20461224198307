import { breadcrumb, reportError } from '../bugsnag';
import { getClient } from '../apollo';
import { startSnapchatEnrollmentMutation } from './graphql/start-enrollment.mutation';
import { reloadUserProfile } from '../auth/auth.actions';
import { verifySnapchatEntrollmentCodeMutation } from './graphql/verify-code.mutation';
import { cancelSnapchatEnrollmentMutation } from './graphql/cancel-enrollment.mutation';

export const DURING_SNAPCHAT_ENROLLMENT = 'DURING_ENROLLMENT';

export const ESnapchatActions = {
  START_AUTH: 'snapchat/START_AUTH',
  DONE_AUTH: 'snapchat/DONE_AUTH',
  ENROLL_START: 'snapchat/ENROLL_START',
  ENROLL_START_DONE: 'snapchat/ENROLL_START_DONE',
  ENROLL_START_ERROR: 'snapchat/ENROLL_START_ERROR',
  VERIFICATION_START: 'snapchat/VERIFICATION_START',
  VERIFICATION_DONE: 'snapchat/VERIFICATION_DONE',
  VERIFICATION_ERROR: 'snapchat/VERIFICATION_ERROR',
  CANCEL_START: 'snapchat/CANCEL_START',
  CANCEL_DONE: 'snapchat/CANCEL_DONE',
  CANCEL_ERROR: 'snapchat/CANCEL_ERROR',
  SET_VERIFICATION_CODE: 'snapchat/SET_VERIFICATION_CODE'
};

export const setCode = codeArray => ({
  type: ESnapchatActions.SET_VERIFICATION_CODE,
  codeArray
});

export const startSnapchatEnrollment = (userId, start = true) => {
  breadcrumb('snapchat', 'snapchat enrollment started');

  if (start) {
    return {
      type: ESnapchatActions.START_AUTH
    };
  }

  return {
    type: ESnapchatActions.DONE_AUTH
  };
};

export const doVerify = (userId, code) => {
  breadcrumb('snapchat', `snapchat code verification ${code}`);

  return dispatch => {
    dispatch({
      type: ESnapchatActions.VERIFICATION_START,
      code
    });

    getClient()
      .mutate({
        mutation: verifySnapchatEntrollmentCodeMutation,
        variables: {
          userId,
          code
        }
      })
      .then(({ data }) => {
        breadcrumb('snapchat', 'done snapchat code verification');

        if (!data.verifySnapchatEntrollmentCode) {
          throw new Error('invalid code');
        }

        dispatch({
          type: ESnapchatActions.VERIFICATION_DONE,
          result: data.verifySnapchatEntrollmentCode
        });
        reloadUserProfile('snapchat')(dispatch);
      })
      .catch(error => {
        dispatch({
          type: ESnapchatActions.VERIFICATION_ERROR,
          error
        });
      });
  };
};

export const doEnroll = (userId, username) => {
  breadcrumb('snapchat', `snapchat enrollment requested with ${username}`);

  return dispatch => {
    dispatch({
      type: ESnapchatActions.ENROLL_START,
      username
    });

    getClient()
      .mutate({
        mutation: startSnapchatEnrollmentMutation,
        variables: {
          userId, 
          username
        }
      })
      .then(({ data }) => {
        breadcrumb(
          'snapchat',
          `done entrollment start request, result: ${data.startSnapchatEnrollment}`
        );

        dispatch({
          type: ESnapchatActions.ENROLL_START_DONE,
          result: data.startSnapchatEnrollment
        });
        reloadUserProfile('snapchat')(dispatch);
      })
      .catch(error => {
        dispatch({
          type: ESnapchatActions.ENROLL_START_ERROR,
          error
        });

        reportError(error);
      });
  };
};

export const cancelEnroll = () => {
  breadcrumb('snapchat', 'cancel enrollment requested');

  return dispatch => {
    dispatch({
      type: ESnapchatActions.CANCEL_START
    });

    getClient()
      .mutate({
        mutation: cancelSnapchatEnrollmentMutation
      })
      .then(({ data }) => {
        breadcrumb(
          'snapchat',
          `cancel enrollment start request, result: ${data.cancelSnapchatEnrollment}`
        );

        dispatch({
          type: ESnapchatActions.CANCEL_DONE,
          result: data.cancelSnapchatEnrollment
        });
        reloadUserProfile('snapchat')(dispatch);
      })
      .catch(error => {
        dispatch({
          type: ESnapchatActions.CANCEL_ERROR,
          error
        });

        reportError(error);
      });
  };
};
