import { combineReducers } from 'redux';
import { EChatActions } from './chat.actions';
import { EMainActions } from '../main/main.actions';

function searchTerm(state = '', action) {
  switch (action.type) {
    case EMainActions.NAVIGATE_CHILD:
    case EMainActions.NAVIGATE_PARENT:
    case EChatActions.CHAT_NAVIGATE:
      return '';
    case EChatActions.SET_SEARCH_TERM:
      return action.search;
    default:
      return state;
  }
}

function currentChat(state = null, action) {
  switch (action.type) {
    case EMainActions.CLOSE_ALL_MODALS:
      return null;
    case EMainActions.NAVIGATE_CHILD: {
      if (action.routeName === 'FriendsList') {
        return null;
      }

      return state;
    }
    case EChatActions.NAVIGATE_TO_PROFILE_PAGE:
    case EChatActions.NAVIGATE_TO_FRIENDS_LIST:
      return null;
    case EChatActions.CHAT_NAVIGATE: {
      if (action.chat) {
        return action.chat;
      } else if (action.user) {
        const userId = action.user.id;
        const currentChatUserId = state !== null && state.users.length === 1 ? state.users[0].id : null;

        if (userId === currentChatUserId) {
          return state;
        }

        return {
          id: null,
          users: [
            action.user,
          ],
        };
      }
      return null;
    }
    default:
      return state;
  }
}

export const chat = combineReducers({
  searchTerm,
  currentChat,
});
