import gql from 'graphql-tag';

export const allVideosQuery = gql`
  query allVideos(
    $search: String
    $skip: Int
    $limit: Int
    $perferedIds: [String]
    $yocasType: String
    $duration: String
  ) {
    allVideos(
      search: $search
      skip: $skip
      limit: $limit
      perferedIds: $perferedIds
      yocasType: $yocasType
      duration:$duration
    ) {
      total
      totalForFilter
      items {
        id
        order
        name
        label
        data
        duration
        previewImage
        pdfUrl
      }
    }
  }
`;
