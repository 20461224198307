import React from 'react';
import welcomeBackground from '../../assets/images/welcomeBackground.png'
import welcomeFullscreenBackground from '../../assets/images/welcomeFullscreenBackground.png'
import welcomeScreenBackgroundImage from '../../assets/images/welcomeScreenBackgroundImage.png'
const width = window.innerWidth

const WelcomePage = props => {
    return (
        <div style={styles.welcomePageContainer}>
        <img src={welcomeScreenBackgroundImage} style={styles.backgroundImage}/>
            <h1 style={styles.titleStyle}>WELCOME {width<500&&<br/>} TO YOCAS</h1>
            <p style={styles.textStyle}>
                YOCAS yoga empowers you to thrive,
                 not just survive, with cancer, by reducing your side effects from cancer
                 and its treatments and improving your quality of life
            </p>
            <span style={styles.buttonsContainer}>
                <button
                    onClick={() => { props.history.push('/login')}}
                    style={
                        styles.signInBtn
                    }
                >
                    Sign In
            </button>
                <button
                    onClick={() => { }}
                    style={
                        styles.signUpBtn
                    }
                >
                    Sign Up
            </button>
            </span>
        </div>
    )
}

export default WelcomePage

const styles = {
    welcomePageContainer: {
        backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.85), rgb(237,204,181,0.9))',
        width: window.innerWidth,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: '#4d4b62',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: "center",
        alignItems:'center'
    },
    titleStyle: {
        margin: 0,
        fontWeight: "bold",
        width:'60%',
        fontSize:width<500?'':'50px'
    },
    signInBtn: {
        backgroundColor: '#4d4b62',
        color: 'white',
        textAlign: 'center',
        padding: width<500?'3%':'2%',
        fontFamily: 'inherit',
        border: 'none',
        fontSize: width<500?'15px':'22px',
        width: width<500?'35%':'30%',
        marginTop: '5%',
        borderRadius: '30px',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)'
    },
    signUpBtn: {
        backgroundColor: 'transparent',
        color: '#6c6674',
        textAlign: 'center',
        padding: width<500?'3%':'2%',
        fontFamily: 'inherit',
        border: '3px solid #4d4b62',
        fontSize: width<500?'15px':'22px',
        width: width<500?'35%':'30%',
        marginTop: '5%',
        borderRadius: '30px',
    },
    buttonsContainer:{
        display:'flex',
        justifyContent: "space-around",
        flexDirection:width<500?'row':'column',
        alignItems:'center',
        width:'100%',
        paddingBottom:'5%'
    },
    textStyle:{
        width:width<500?'70%':'30%',
        color:'#4d4b62',
        fontWeight: "bold",
        lineHeight:'1.5'
    },
    welcomePageFooter:{
        width:'70%'
    },
    backgroundImage:{
        position:'fixed',
        zIndex:'-1',
        width:'100vh',
        height:'100vh',
        objectFit:'contain'
    }
}