import gql from 'graphql-tag';
import { userFieldsFragment } from '../login/graphql/me.fragment';


export const getUserQuery = gql`
query getUser($userId: ID) {
    getUser(userId: $userId) {
        ...UserFields
    }
}
${userFieldsFragment}

`