import MyPostsView from './my-posts.rn';
import { withApollo, compose, graphql } from 'react-apollo-temp';
import { connect } from 'react-redux';
import { toggleComponent } from '../../router/router.actions';

const mapStateToProps = ({ navigation, auth }) => ({
  prevComponent: navigation.navigationComponents.prevComponent,
  currentComponent: navigation.navigationComponents.component,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component))
});

export const withState = connect(mapStateToProps, mapDispatchToProps);

export const MyPosts = compose(withApollo, withState)(MyPostsView);
