import gql from 'graphql-tag';

export const courseFragment = gql`
    fragment CourseFragment on CourseType {
        fullName
        identifier
        courseInternalId
        sessionCode
        academicYear
        name
        nextSchedule {
            date
            startTime
            endTime
        }
        location
        teacher {
            fullName
            profilePictureUrl
        }
    }
`;
