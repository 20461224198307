import gql from 'graphql-tag';

export const uploadToAzureMutation = gql`
    mutation uploadToAzure ($file: String, $tag: String, $base64: String, $chatId: String ){
        uploadToAzure(file: $file, tag: $tag, base64: $base64, chatId: $chatId) {
            id
            filePath
        }
    }
`;
