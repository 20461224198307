import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Animated } from 'react-animated-css';
import { LangData } from '../../assets/locales/localization';
import { Theme } from '../../common/providers/colors';

export class ConfirmationModal extends Component {
  static propTypes = {
    question: PropTypes.string,
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        onPress: PropTypes.func,
        iconName: PropTypes.string
      })
    )
  };

  static defaultProps = {
    onClose: () => null
  };

  renderIcon(option) {
    if (!option.iconName || option.iconName === '') {
      return null;
    }

    return <p>{option.iconName}</p>;
  }

  renderOptions() {
    return this.props.options.map(option => (
      <div
        style={styles.optionButton}
        key={option.name}
        onClick={() => option.onClick()}
      >
        {this.renderIcon(option)}
        <p style={styles.optionText}>{option.name}</p>
      </div>
    ));
  }

  render() {
    return (
      //   <Modal
      //     onRequestClose={() => this.props.onClose()}
      //     animationType="fade"
      //     transparent={true}
      //     visible={this.props.open}>
      <div style={styles.backgroundWaitApprove} id='confirmation-modal'>
        <Animated
          animationIn='pulse'
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div style={styles.modal}>
            <p style={styles.questionText}>{this.props.title}</p>
            <p style={styles.questionText}>{this.props.question}</p>
            {this.props.loading ? (
              <p style={{ textAlign: 'center', color: 'white', fontSize: 12 }}>
                {LangData.confirmationModal.moment}
              </p>
            ) : (
              <div style={styles.optionsContainer}>{this.renderOptions()}</div>
            )}
          </div>
        </Animated>
      </div>
    );
  }
}

const styles = {
  backgroundWaitApprove: {
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'fixed',
    opacity: '0.97',
    zIndex: '9999999',
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    margin: '-1%'
  },
  modal: {
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: window.innerWidth / 15 + 'px',
    marginBottom: window.innerWidth / 18.75 + 'px',
    color: Theme.TEXT_COLOR,
    width: '60%',
    padding: '3%'
  },
  optionText: {
    textAlign: 'center',
    color: Theme.SECONDARY_TEXT_COLOR,
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    borderRadius: '20px',
    width: '50%'
  },
  optionButton: {
    width: '50%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px'
  },
  optionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  singleButton: {
    justifyContent: 'center'
  },

  modalParent: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(48,109,172,0.9)'
  },
  questionText: {
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 5,
    marginHorizontal: 10
  }
};
