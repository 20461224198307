import React, { Fragment, useState, useEffect } from 'react';
import UserInfo from './user-info';
import { UserPhotos } from '../gallery/user-photos.container';
import { LangData } from '../../../assets/locales/localization';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {
  faComments,
  faUserTimes,
  faTimes,
  faUserPlus,
  faUserClock,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router';
import { Theme } from '../../../common/providers/colors';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';

const urls = {
  instagram: username => `instagram://user?username=${username}`,
  snapchat: username => `snapchat://add/${username}`,
  twitter: username => `twitter://user?screen_name=${username}`
};

const UserProfileModalView = props => {
  const [renderStatus, setRenderStatus] = useState(false);


  useEffect(() => {
    console.log('props in profile =>', props)
    
  }, [props])

  const onSocialClick = (socialType, username) => {
    const link = urls[socialType](username);
  };

  const chat = () => {
    props.navigateToChat(props.user, () => props.toggleModal(false, null));
    props.toggleComponent('ChatScreen');
    props.history.push('chatScreen');
  };

  const renderFriendView = user => {
    console.log('user in render', user)
    if(!user) return (
      <LoadingSpinner/>
    )
    if (user.friendRequestStatus === 'APPROVED') {
      return (
        <div style={styles.buttonContainer}>
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '46%'
            }}
            onClick={() => chat()}
          >
            <CustomButton>Chat</CustomButton>
          </div>

          {user.canRemoveConnection ? (
            <div style={{ marginLeft: 15 }} onClick={() => removeConnection()}>
              <CustomButton>Remove Friend</CustomButton>
            </div>
          ) : null}
        </div>
      );
    }

    return null;
  };

  const renderPendingAndIgnored = user => {
    if(!user) return (
      <LoadingSpinner />
    )

    const ignored = user.friendRequestStatus === 'IGNORED';
    const pending = user.friendRequestStatus === 'PENDING';

    console.log('ignored =>', ignored)
    console.log('pending =>', pending)

    if (ignored || pending) {
      return (
        <div style={styles.buttonContainer}>
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              width: '100%'
            }}
          >
            <button style={styles.connectBtn} onClick={() => approve()}>
              <FontAwesomeIcon
                icon={faUserFriends}
                style={styles.iconPlusStyle}
              />

              {LangData.userProfileModal.approve}
            </button>

            {!ignored ? (
              <div
                style={{
                  padding: 4,
                  alignSelf: 'stretch',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  fontSize: 13,
                  fontWeight: 'bold'
                }}
                onClick={() => {
                  ignore();
                }}
              >
                {LangData.userProfileModal.ignore}
                <FontAwesomeIcon
                  icon={faUserTimes}
                  style={{ marginLeft: '2%' }}
                />
              </div>
            ) : (
              <p
                style={{
                  fontSize: 13,
                  color: Theme.TEXT_COLOR,
                  marginLeft: 5
                }}
              >
                {LangData.userProfileModal.ignored}
              </p>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderNonFriend = user => {
    if(!user) return
    console.log('user', user)
    console.log('user.friendRequestStatus', user.friendRequestStatus)
    if (user.friendRequestStatus === null) {
      return (
        <div style={styles.buttonContainer}>
          <button
            style={styles.connectBtn}
            onClick={() => props.connect(props.currentUser, props.user.id)}
          >
            <FontAwesomeIcon icon={faUserPlus} style={styles.iconPlusStyle} />
            {LangData.userProfileModal.connect}
          </button>
        </div>
      );
    }

    return null;
  };

  const removeConnection = () => {
    props.removeConnection(props.currentUser, props.user.id);
  };

  const renderRequestSent = user => {
    if(!user) return

    if (user.friendRequestStatus === 'SENT') {
      return (
        <div style={styles.buttonContainer}>
          <button
            disabled
            style={Object.assign({ opacity: 0.5 }, styles.connectBtn)}
          >
            <FontAwesomeIcon icon={faUserClock} style={styles.iconPlusStyle} />
            {LangData.userProfileModal.request}
          </button>
          <div
            style={{ marginLeft: 15 }}
            onClick={() => {
              removeConnection();
              setRenderStatus(!renderStatus);
            }}
          >
            <FontAwesomeIcon icon={faUserTimes} />
          </div>
        </div>
      );
    }

    return null;
  };

  const renderGalleryHeader = () => {
    return (
      <div style={styles.galleryHeader}>
        <div style={styles.hr} />
        <p style={styles.galleryHeaderTxt}>
          {props.user.firstName + LangData.userProfileModal.gallery}
        </p>
        <div style={styles.hr} />
      </div>
    );
  };

  const approve = () => {
    props.respond(props.currentUser.id, 'APPROVED', props.user.id).then(() => {
      props.setAlert('Connection request approved!');
      props.toggleComponent('Friends');
      props.history.push('/');
    });
  };

  const ignore = () => {
    props.respond(props.currentUser.id, 'IGNORE', props.user.id).then(() => {
      alert(LangData.userProfileModal.connection);
      props.setAlert(LangData.userProfileModal.connection);
    });
  };

  const shouldDisplayYouTube = () => {
    return !!props.user.userYoutubeVideoId;
  };

  const { user } = props;

  if (!props.currentUser) {
    return <Redirect to='/login' />;
  }

  if(!props.user){
    return  <div style={styles.pageContainer}><LoadingSpinner /></div>;
  }

  console.log('renderStatus', renderStatus)

  return (
    <div style={styles.pageContainer}>
      {props.isOpen ? (
        <Fade bottom>
          <Fragment>
            <div style={styles.optionsRow}>
              <div>
                <RowIcon
                  icon={faTimes}
                  onClick={() => {
                    console.log(props.prevComponent);
                    if (props.prevComponent !== 'ImageGalleryViewerModal') {
                      props.toggleComponent(props.prevComponent);
                    }

                    if(props.fromSug !== null && props.fromSug === true) {
                      props.toggleModal()
                      return
                    }
                    // props.history.go(-1);
                    if (
                      props.prevComponent === 'Chat' ||
                      props.prevComponent === 'News_Feed' ||
                      props.prevComponent === 'Notifications'
                    ) {
                      props.history.push('/');
                    }

                    if (props.prevComponent === 'ChatScreen') {
                      props.history.push('chatScreen');
                    }
                    if (props.prevComponent === 'Profile') {
                      props.history.push('profile');
                    }
                    if (props.prevComponent === 'Courses') {
                      props.history.push('courses');
                    }
                    if (props.prevComponent === 'Friends') {
                      props.history.push('/');
                    }
    
                    props.toggleModal(false);
                  }}
                />
              </div>
            </div>

            <UserInfo
              user={props.user}
              onSocialClick={(type, username) => onSocialClick(type, username)}
              history={props.history}
            />
            {renderStatus ? (
              <Fragment>
                {renderFriendView(props.user)}
                {renderPendingAndIgnored(props.user)}
                {renderNonFriend(props.user)}
                {renderRequestSent(props.user)}{' '}
              </Fragment>
            ) : (
              <Fragment>
                {renderFriendView(props.user)}
                {renderPendingAndIgnored(props.user)}
                {renderNonFriend(props.user)}
                {renderRequestSent(props.user)}
              </Fragment>
            )}

            {!shouldDisplayYouTube() ? renderGalleryHeader() : null}

            <div style={styles.section}>
              {shouldDisplayYouTube() ? (
                <div></div>
              ) : (
                // <YouTube
                //   videoId={props.user.userYoutubeVideoId}
                //   play={false}
                //   fullscreen={true}
                //   style={{ alignSelf: 'stretch', height: 300 }}
                // />
                <UserPhotos {...props} user={props.user} currentUser={props.currentUser} />
              )}
            </div>
          </Fragment>
        </Fade>
      ) : null}
    </div>
  );
};

export default UserProfileModalView;

const SPACE_SIZE = 25;
const ICON_SIZE = 20;

const styles = {
  pageContainer: {
    minHeight: window.innerHeight,
    backgroundColor: '#fff'
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: '0 5%',
    color: Theme.TEXT_COLOR
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  innerContainer: {},
  topBar: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  closeBtn: {
    flex: 0,
    width: ICON_SIZE,
    height: ICON_SIZE
  },
  hr: {
    flex: 1,
    height: 2,
    backgroundColor: Theme.TEXT_COLOR
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  section: {
    marginTop: SPACE_SIZE,
    flex: 1
  },
  textBtnStyle: {
    marginLeft: 5,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    textAlign: 'center',
    fontSize: 13
  },
  galleryHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    padding: '0 3%'
  },
  galleryHeaderTxt: {
    marginHorizontal: SPACE_SIZE,
    color: Theme.TEXT_COLOR,
    fontSize: 13,
    padding: '0 2%'
  },
  connectBtn: {
    flexGrow: '1',
    borderRadius: '20px',
    color: Theme.SECONDARY_TEXT_COLOR,
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    border: 'none',
    padding: '2%',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    WebkitBoxShadow: '0px 0px 25px -10px rgba(0,0,0,0.75)',
    MozBoxShadow: '0px 0px 25px -10px rgba(0,0,0,0.75)',
    boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.75)'
  },
  iconPlusStyle: {
    marginRight: '5px'
  }
};

const RowIcon = styled(FontAwesomeIcon)`
  color: ${Theme.TEXT_COLOR};
  font-size: ${window.innerWidth / 18}px;
  &:hover {
    color: #286cc0;
  }
  &:focus {
    color: #286cc0;
  }
`;

const CustomButton = styled.button`
  background-color: ${Theme.DARK_BLUE_COLOR};
  justify-content: center;
  align-items: center;
  border: none;
  color: ${Theme.WHITE_COLOR};
  font-weight: bold;
  border-radius: 15px;
  padding: 1% 4%;
  height: 30px;
  white-space: nowrap;
  text-align: center;

  &:focus {
    outline: none;
    border: none;
  }
`;