import { getClient } from '../apollo';
import { updatePrivacyMutation } from './graphql/update-privacy.mutation';
import { reloadUserProfile } from '../auth/auth.actions';
import { breadcrumb, reportError } from '../bugsnag';

export const EProfileActions = {
  UPDATE_PRIVACY: 'UPDATE_PRIVACY',
  UPDATE_PRIVACY_SUCCESS: 'UPDATE_PRIVACY_SUCCESS',
  UPDATE_PRIVACY_FAIL: 'UPDATE_PRIVACY_FAIL',
  CHANGE_PROFILE_PICTURE: 'CHANGE_PROFILE_PICTURE',
  PROFILE_PICTURE_REJECTED: 'PROFILE_PICTURE_REJECTED',
  TOGGLE_PROFILE_MODAL: 'TOGGLE_PROFILE_MODAL',
  TOGGLE_USER_PROFILE_MODAL: 'TOGGLE_USER_PROFILE_MODAL'
};

export const updatePrivacy = (privacy, userId, changes, remoteChange) => dispatch => {
  breadcrumb('profile', `start update privacy, is remote: ${remoteChange}`);

  dispatch({
    type: EProfileActions.UPDATE_PRIVACY,
    tempState: Object.assign({}, privacy, changes),
    changes
  });


  delete changes.__typename
  
  if (remoteChange) {
    getClient()
      .mutate({
        mutation: updatePrivacyMutation,
        variables: {
          userId,
          config: changes
        }
      })
      .then(({ data }) => {
        breadcrumb(
          'profile',
          `done update privacy, is remote: ${remoteChange}`
        );

        dispatch({
          type: EProfileActions.UPDATE_PRIVACY_SUCCESS,
          privacy: {
            ...data.updatePrivacy,
            ...changes
          },
          changes
        });
        reloadUserProfile('privacy')(dispatch);
      })
      .catch(error => {
        dispatch({
          type: EProfileActions.UPDATE_PRIVACY_FAIL,
          previousPrivacy: privacy,
          error,
          changes
        });
        reportError(error);
      });
  }
};

export const changeProfilePictureStarted = isCancel => ({
  type: EProfileActions.CHANGE_PROFILE_PICTURE,
  isCancel
});

export const toggleProfileModal = isOpen => ({
  type: EProfileActions.TOGGLE_PROFILE_MODAL,
  isOpen
});

export const toggleUserProfileModal = (isOpen, userId) => ({
  type: EProfileActions.TOGGLE_USER_PROFILE_MODAL,
  userId,
  isOpen
});

export const setRejectionReason = reason => ({
  type: EProfileActions.PROFILE_PICTURE_REJECTED,
  reason
});
