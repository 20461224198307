import gql from 'graphql-tag';

export const FeedSportsEventItemFragment = gql`
  fragment FeedSportsEventItem on NewsFeedItemType {
    id
    title
    description
    startTime
    exportStartTime
    locationName
    userReminder
    userRSVP
    mainCategory {
      id
      name
      icon
    }
    type
    gender
    cancelled
    score
    ended
    allDay
    posterImageUrl
    link
  }
`;
