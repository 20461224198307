import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class CourseIcon extends Component {
  static propTypes = {
    courseIdentifier: PropTypes.string.isRequired,
    style: PropTypes.any,
    active: PropTypes.bool
  };

  static courseToImgSrc = [
    {
      // Accounting
      courses: ['ACC'],
      icons: {
        black: '/course-icons/accounting@3x.png',
        white: '/course-icons/accountingCopy@3x.png'
      }
    },
    {
      // Business
      courses: ['BUS', 'CIS', 'FIN', 'ECO', 'MGT', 'MKT'],
      icons: {
        black: '/course-icons/briefcase@3x.png',
        white: '/course-icons/briefcaseCopy@3x.png'
      }
    },
    {
      // Career & Experiential Education
      courses: ['CED'],
      icons: {
        black: '/course-icons/career@3x.png',
        white: '/course-icons/careerCopy@3x.png'
      }
    },
    {
      // Liberal Arts , Education
      courses: [
        'HIS',
        'ENG',
        'ASL',
        'REL',
        'RPS',
        'SPA',
        'AYA',
        'EDU',
        'IEC',
        'MCE',
        'RDG',
        'SED',
        'ETH',
        'PHI'
      ],
      icons: {
        black: '/course-icons/182321@3x.png',
        white: '/course-icons/182321Copy@3x.png'
      }
    },
    {
      // biology
      courses: ['BIO', 'FOR', 'BRW'],
      icons: {
        black: '/course-icons/dnaStructure@3x.png',
        white: '/course-icons/dnaStructureCopy@3x.png'
      }
    },
    {
      // Interdisciplinary
      courses: ['COR', 'IDS'],
      icons: {
        black: '/course-icons/layers@3x.png',
        white: '/course-icons/layersCopy@3x.png'
      }
    },
    {
      // Communication And New Media
      courses: ['COM'],
      icons: {
        black: '/course-icons/chat@3x.png',
        white: '/course-icons/chatCopy@3x.png'
      }
    },
    {
      // Legal Studies
      courses: ['JCG', 'LGS', 'PRL', 'PSC'],
      icons: {
        black: '/course-icons/hammer@3x.png',
        white: '/course-icons/hammerCopy@3x.png'
      }
    },
    {
      // Math
      courses: ['MTH'],
      icons: {
        black: '/course-icons/math@3x.png',
        white: '/course-icons/mathCopy@3x.png'
      }
    },
    {
      // Project Excel
      courses: ['EXC'],
      icons: {
        black: '/course-icons/table@3x.png',
        white: '/course-icons/tableCopy@3x.png'
      }
    },
    {
      // Honors
      courses: ['HON'],
      icons: {
        black: '/course-icons/medal@3x.png',
        white: '/course-icons/medalCopy@3x.png'
      }
    },
    {
      // Art
      courses: ['ART', 'AED', 'GRD'],
      icons: {
        black: '/course-icons/158250@3x.png',
        white: '/course-icons/158250Copy@3x.png'
      }
    },
    {
      // music
      courses: ['MUS'],
      icons: {
        black: '/course-icons/headphones@3x.png',
        white: '/course-icons/headphonesCopy@3x.png'
      }
    },
    {
      // Chemistry
      courses: ['CHE', 'GEO', 'SST', 'PHY'],
      icons: {
        black: '/course-icons/flasks@3x.png',
        white: '/course-icons/flasksCopy@3x.png'
      }
    },
    {
      // Computing
      courses: ['INF'],
      icons: {
        black: '/course-icons/computerCopy@3x.png',
        white: '/course-icons/computer@3x.png'
      }
    },
    {
      // Criminology And Criminal
      courses: ['CRM'],
      icons: {
        black: '/course-icons/pairOfHandcuffs@3x.png',
        white: '/course-icons/pairOfHandcuffsCopy@3x.png'
      }
    },
    {
      // Psychology
      courses: ['PSY'],
      icons: {
        black: '/course-icons/brain@3x.png',
        white: '/course-icons/brainCopy@3x.png'
      }
    },
    {
      // Sociology
      courses: ['GST', 'SOC', 'SWK'],
      icons: {
        black: '/course-icons/group@3x.png',
        white: '/course-icons/groupCopy@3x.png'
      }
    },
    {
      //  Organizational Leadership
      courses: ['BOL'],
      icons: {
        black: '/course-icons/user@3x.png',
        white: '/course-icons/userCopy@3x.png'
      }
    },
    {
      // Sport Management
      courses: ['SPM'],
      icons: {
        black: '/course-icons/manager@3x.png',
        white: '/course-icons/managerCopy@3x.png'
      }
    },
    {
      // Athletic Training
      courses: ['ACT', 'ATR', 'ESF', 'HLT'],
      icons: {
        black: '/course-icons/footballShoe@3x.png',
        white: '/course-icons/footballShoeCopy@3x.png'
      }
    },
    {
      // Nursing
      courses: ['NUR'],
      icons: {
        black: '/course-icons/drugs@3x.png',
        white: '/course-icons/drugsCopy@3x.png'
      }
    }
  ];

  static DEFAULT_ICON = {
    black: '/course-icons/182321@3x.png',
    white: '/course-icons/182321Copy@3x.png'
  };

  getImgSrc() {
    const key = this.props.courseIdentifier.substr(0, 3);

    for (const courseImgInfo of CourseIcon.courseToImgSrc) {
      if (courseImgInfo.courses.includes(key.toUpperCase())) {
        return courseImgInfo.icons;
      }
    }

    return CourseIcon.DEFAULT_ICON;
  }

  render() {
    const imgSrcObj = this.getImgSrc();
    const imgSrc =
      process.env.PUBLIC_URL +
      (this.props.active ? imgSrcObj.white : imgSrcObj.black);

    return <img alt={imgSrc} src={imgSrc} style={this.props.style || {}} />;
  }
}
