import gql from 'graphql-tag';
import { FeedEventItemFragment } from './feed-event-item.fragment';
import { FeedSportsEventItemFragment } from './feed-sports-event-item.fragment';

export const rsvpMutation = gql`
  mutation rsvp($rsvp: RSVPAnswerInput!) {
    rsvp(rsvp: $rsvp) {
      ...FeedEventItem
      ...FeedSportsEventItem
    }
  }

  ${FeedEventItemFragment}
  ${FeedSportsEventItemFragment}
`;
