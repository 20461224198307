import React, { Component, Fragment } from 'react';
import ErrorMessage from './error-message.rn';
import { resetPassword } from '../../account/account.util';
import { LangData } from '../../assets/locales/localization';
import logoSrc from '../../assets/images/default_profile.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { Redirect } from 'react-router';
import { isAndroid, isIOS } from 'react-device-detect';
import { Theme } from '../../common/providers/colors';

export class ChangePasswordScreen extends Component {
  state = {
    password: '',
    error: null,
    loading: false
  };

  changePassword = async () => {
    this.setState({ loading: true });
    let url_string = window.location.href;
    let url = new URL(url_string);
    let token = url.searchParams.get('t');

    try {
      // await resetPassword(token, this.state.password);
      this.setState({ error: null, loading: false });
      if (
        !window.matchMedia('(display-mode: standalone)').matches &&
        // isAndroid &&
        this.props.showInstallPage
      ) {
        this.props.history.push('/installApp');
      } else {
        this.props.history.push('/');
      }
    } catch (err) {
      this.setState({
        error: LangData.changePasswordScreen.wrong,
        loading: false
      });
    }
  };

  render() {
    const { password, loading, error } = this.state;

    return (
      <Fragment>
        {error && (
          <ErrorMessage
            message={error}
            onPress={() => {
              this.setState({ error: null, password: '' });
            }}
          />
        )}

        {loading && <LoadingSpinner />}
        <div style={styles.mainContainer}>
          <div style={styles.formContainer}>
            <img src={logoSrc} alt='logo' style={styles.logoImg} />
            <p>{LangData.changePasswordScreen.description}</p>
            <div style={styles.inputDiv}>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '96%',
                  border: 'none'
                }}
              >
                <FontAwesomeIcon icon={faKey} />
                <input
                  type='text'
                  onChange={e => this.setState({ password: e.target.value })}
                  value={password}
                  placeholder={LangData.changePasswordScreen.password}
                  style={{
                    border: 'none',
                    width: '100%',
                    backgroundColor: 'transparent'
                  }}
                />
              </label>
            </div>
          </div>

          <button
            disabled={error || loading || !password}
            onClick={() => this.changePassword()}
            style={
              error || loading || !password
                ? styles.submitBtnDis
                : styles.submitBtn
            }
          >
            {LangData.changePasswordScreen.change}
          </button>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  mainContainer: {
    height: window.innerHeight,
    backgroundColor: 'rgb(226, 226, 226)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: Theme.TEXT_COLOR
  },
  logoImg: {
    height: '150px',
    width: '150px',
    margin: '10% 10% 3% 10%'
  },
  inputDiv: {
    backgroundColor: 'white',
    borderRadius: window.innerWidth / 12 + 'px',
    padding: '2.5%',
    width: window.innerWidth / 1.894 + 'px',
    marginBottom: '2%',
    input: {
      WebkitAppearance: 'none',
      outline: 'none',
      backgroundColor: 'white',
      border: 'none',
      height: window.innerWidth / 12 + 'px',
      width: window.innerWidth / 2.12 + 'px',
      fontSize: window.innerWidth / 24 + 'px',
      paddingLeft: window.innerWidth / 180 + 'px',
      fontFamily: 'inherit'
    }
  },
  trouble: {
    alignSelf: 'flex-end',
    marginRight: '22%'
  },
  aboutRow: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    margin: '1%'
  },
  submitBtn: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    color: Theme.TEXT_COLOR,
    textAlign: 'center',
    padding: '3%',
    fontWeight: 'bold',
    opacity: '0.8',
    fontFamily: 'inherit'
  },
  submitBtnDis: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    color: Theme.TEXT_COLOR,
    textAlign: 'center',
    padding: '3%',
    fontWeight: 'bold',
    opacity: '0.6',
    fontFamily: 'inherit'
  }
};
