import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../assets/locales/localization';
import { Theme } from '../common/providers/colors';

export class SocialEditableButton extends Component {
  static propTypes = {
    existingLink: PropTypes.string,
    onEditPress: PropTypes.func.isRequired,
    onAddPress: PropTypes.func.isRequired,
    color: PropTypes.string,
    name: PropTypes.string
  };

  render() {
    if (this.props.existingLink) {
      return (
        <div style={styles.existingLinkContainer}>
          <div style={styles.textItem}>
            <span style={styles.title}>{this.props.name}</span>
            <span style={styles.subtitle}>{this.props.existingLink}</span>
          </div>
          <div
            style={styles.editContainer}
            onClick={() => this.props.onEditPress()}
          >
            <span style={styles.editText}>
              {LangData.socialEditableButton.edit}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div onClick={() => this.props.onAddPress()} style={styles.button}>
        <span style={styles.emptyLinkText}>{this.props.name}</span>
      </div>
    );
  }
}

const styles = {
  editText: {
    color: Theme.TEXT_COLOR,
    fontSize: 13
  },
  editContainer: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'flex-end'
  },
  existingLinkContainer: {
    flexDirection: 'row'
  },
  textItem: {
    flexDirection: 'column'
  },
  title: {
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold'
  },
  subtitle: {
    color: 'white',
    fontSize: 12
  },
  button: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#FFC412',
    border: 'none',
    padding: window.innerWidth / 72 + 'px',
    width: window.innerWidth / 1.531 + 'px',
    fontFamily: 'inherit',
    borderRadius: window.innerWidth / 18 + 'px',
    fontSize: window.innerWidth / 27.692 + 'px',
    marginLeft: window.innerWidth / 24 + 'px',
    textAlign: 'center'
  },
  emptyLinkText: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold'
  }
};
