import gql from 'graphql-tag';
import { userFieldsFragment } from '../login/graphql/me.fragment';


export const updateUserLoginMutation = gql`
mutation($userId: ID) {
    updateUserLogin(userId: $userId) {
       _id
    }
}

`