import React, { useState, Fragment } from 'react';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import ProfileImage from '../../../common/components/profile-image.rn';
import { uploadToCloudinary } from '../../../cloudinary/utils/upload';
import { LangData } from '../../../assets/locales/localization';
import { Theme } from '../../../common/providers/colors';

let modalMargin = 130;
const width = window.innerWidth

const ProfilePictureMenuView = props => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openWaitingApproval, setOpenWaitingApprovel] = useState(false);

  const onImgUpload = e => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      handleImage(file);
    }
  };

  const handleImage = async file => {
    // calc modal margin
    let containerHeight = document.getElementById('profile_modal_container')
      .offsetHeight;
    if (containerHeight > 600) modalMargin = 16;
    if (containerHeight > 500) modalMargin = 73;
    if (containerHeight > 400) modalMargin = 130;
    if (containerHeight > 300) modalMargin = 187;
    if (containerHeight > 200) modalMargin = 244;

    setProgress(0);
    setLoading(true);
    const fileId = await uploadToCloudinary(
      file,
      progress => setProgress(progress),
      ['profile_picture']
    );
    if (!fileId || fileId.error) {
      alert(LangData.profilePictureMenu.wrong);

      return;
    }
    props
      .uploadImage(props.currentUser.id, fileId.public_id)
      .catch(() => null)
      .then(() => {
        setProgress(0);
        setLoading(false);
        setOpenWaitingApprovel(true);
        document.body.style.position = 'fixed';
      });
  };

  const cancel = () => {
    props.cancel(props.currentUser.id);
  };

  return (
    <Fragment>
      {props.rejectionReason ? (
        <div style={styles.alertRejections}>
          <p style={styles.approvalText}>
            {LangData.profilePictureMenu.reject}
          </p>
          <p style={styles.duringApprovalText}>{props.rejectionReason}</p>
          <button
            onClick={() => {
              props.setProfilePictureRejected(null);
            }}
          >
            {LangData.profilePictureMenu.dismiss}
          </button>
        </div>
      ) : null}

      {props.isLoading || loading ? (
        <Fragment>
          <div
            style={
            width < 500 ?   
            {
              display: 'flex',
              justifyContent: 'center',
              width: '30%'
            }
          :
            {
              display: 'flex',
              justifyContent: 'center',
              width: '6%'
            }}
          >
            {' '}
            <ProfileImage
              imageUrl={props.user.profilePictureUrl}
              size={70}
              withFullSize={false}
              showBorder={true}
              borderColor={'white'}
            />
          </div>

          <div style={styles.backgroundWaitApprove}>
            <div id='modal' style={styles.modal}>
              <p style={styles.loadingText}>
                {loading
                  ? LangData.profilePictureMenu.uploading
                  : LangData.profilePictureMenu.moment}
              </p>
              <LoadingSpinner bgcolor={'rgba(0, 0, 0, 0.2)'} />
            </div>
          </div>
        </Fragment>
      ) : props.currentUser.duringProfilePictureApproval &&
        !props.rejectionReason ? (
        <Fragment>
          <div
            style={ width < 500 ?   
              {
                display: 'flex',
                justifyContent: 'center',
                width: '30%'
              }
            :
              {
                display: 'flex',
                justifyContent: 'center',
                width: '6%'
              }}
            onClick={() => {
              setOpenWaitingApprovel(true);
              document.body.style.position = 'fixed';
            }}
          >
            <ProfileImage
              imageUrl={props.currentUser.duringProfilePictureApproval}
              size={70}
              withFullSize={false}
              showBorder={true}
              borderColor={'white'}
            />
          </div>
          {openWaitingApproval && (
            <div style={styles.backgroundWaitApprove}>
              <div id='modal' style={styles.modal}>
                <ProfileImage
                  imageUrl={props.currentUser.duringProfilePictureApproval}
                  size={70}
                  withFullSize={false}
                />
                <p style={styles.approvalText}>
                  {LangData.profilePictureMenu.staff}
                </p>
                <p style={styles.cancelText} onClick={() => cancel()}>
                  {LangData.profilePictureMenu.sure}
                </p>
              </div>
              <button
                onClick={() => {
                  setOpenWaitingApprovel(false);
                  document.body.style.position = '';
                }}
                style={styles.modalBtn}
              >
                {LangData.profilePictureMenu.done}
              </button>
            </div>
          )}
        </Fragment>
      ) : (
        !props.rejectionReason && (
          <Fragment>
            <input
              type='file'
              accept='image/*'
              onChange={e => onImgUpload(e)}
              style={styles.inputStyle}
              name='upload_profile_img'
              id='upload_profile_img'
            />
            <label
              htmlFor='upload_profile_img'
              style={ width < 500 ?   
                {
                  display: 'flex',
                  justifyContent: 'center',
                  width: '30%'
                }
              :
                {
                  display: 'flex',
                  justifyContent: 'center',
                  width: '6%'
                }}
            >
              <ProfileImage
                imageUrl={props.user.profilePictureUrl}
                size={70}
                withFullSize={false}
                showBorder={true}
                borderColor={'white'}
              />
            </label>
          </Fragment>
        )
      )}
    </Fragment>
  );
};

export default ProfilePictureMenuView;

const styles = {
  loadingText: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: Theme.TEXT_COLOR
  },
  approvalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: window.innerWidth / 37.5 + 'px'
  },
  cancelText: {
    fontSize: window.innerWidth / 31.25 + 'px',
    textAlign: 'center',
    marginTop: window.innerWidth / 18.75 + 'px',
    marginBottom: window.innerWidth / 12.5 + 'px'
  },
  duringApprovalText: {
    textAlign: 'center',
    marginTop: window.innerWidth / 37 + 'px'
  },
  inputStyle: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1'
  },
  backgroundWaitApprove: {
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'fixed',
    opacity: '0.97',
    zIndex: '9999999',
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0
  },
  modal: {
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: window.innerWidth / 15 + 'px',
    marginBottom: window.innerWidth / 18.75 + 'px',
    color: Theme.TEXT_COLOR,
    width: '85%',
    padding: '3%'
  },
  modalBtn: {
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: window.innerWidth / 15 + 'px',
    marginBottom: window.innerWidth / 37.5 + 'px',
    width: '90%',
    border: 'none',
    fontWeight: 'bold',
    fontSize: window.innerWidth / 25 + 'px',
    color: Theme.TEXT_COLOR,
    WebkitBoxShadow: '0px 1px 19px -7px rgba(0,0,0,0.69)',
    MozBoxShadow: '0px 1px 19px -7px rgba(0,0,0,0.69)',
    boxShadow: '0px 1px 19px -7px rgba(0,0,0,0.69)'
  }
};
