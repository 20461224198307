import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Theme } from '../../../common/providers/colors';

export class CoursesView extends Component {
  static propTypes = {
    editModalOpen: PropTypes.bool,
    editModeEntity: PropTypes.any,
    statisticsData: PropTypes.any,
    toggleEditMode: PropTypes.func,
    removeCourse: PropTypes.func,
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    search: PropTypes.string.isRequired,
    updateFilter: PropTypes.func.isRequired,
    courses: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allCourses: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      courseToRemove: null,
      courseToManage: null
    };
  }

  render() {
    console.log('props', this.props);
    console.log('props.students.statisticsData', this.props.statisticsData);
    let data = this.props.statisticsData || {};

    return (
      <div>
        {!data.users ? (
          <LoadingSpinner />
        ) : (
          <div>
            <span>{`כל הקורסים (סך הכל ${data.users} סטודנטים)`}</span>
            <StyledCheckbox
              checked={this.state.isAll}
              color='primary'
              onChange={() => {
                console.log('all courses');
                this.setState({ isAll: !this.state.isAll });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);
