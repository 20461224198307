import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { toggleComponent, closeImgModal } from '../../router/router.actions';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { isIOS } from 'react-device-detect';

const FullScreenImageModal = props => {
  function download(url, filename) {
    fetch(url).then(function(t) {
      return t.blob().then(b => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', filename);
        a.click();
      });
    });
  }

  if (!props.currentUser) {
    return <Redirect to='/login' />;
  }

  return (
    <Fragment>
      {props.isOpen || props.imgSrc ? (
        <div style={styles.mainContainer}>
          <div style={styles.topRow}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => {
                if (props.onCloseImgModal) {
                  props.onCloseImgModal();
                } else {
                  // props.history.go(-1);
                  if (
                    props.prevComponent === 'Chat' ||
                    props.prevComponent === 'News_Feed' ||
                    props.prevComponent === 'Videos' ||
                    props.prevComponent === 'Notifications' ||
                    props.prevComponent === 'Schedule'
                  ) {
                    props.history.push('/');
                  }
                  if (props.prevComponent === 'UserProfile') {
                    props.history.push('userProfile');
                  }
                  if (props.prevComponent === 'ChatScreen') {
                    props.history.push('chatScreen');
                  }
                  if (props.prevComponent === 'Profile') {
                    props.history.push('profile');
                  }
                  if (props.prevComponent === 'Friends') {
                    props.history.push('/');
                    props.toggleComponent('Friends')
                  }
                  if (props.prevComponent === 'Courses') {
                    props.history.push('courses');
                  }

                  props.toggleComponent(props.prevComponent);
                  props.closeModal();
                }
              }}
            />
            {(props.image || props.imgSrc) && !isIOS && (
              <span
                onClick={() => {
                  download(props.image || props.imgSrc, 'download.jpg');
                }}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={styles.iconDownload}
                />
              </span>
            )}
          </div>
          <img src={props.image || props.imgSrc} style={styles.img} />
          <div></div>
        </div>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = ({ navigation, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  imgSrc: navigation.imageModal.imgSrc,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  closeModal: () => dispatch(closeImgModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullScreenImageModal);

const styles = {
  mainContainer: {
    width: window.innerWidth,
    height: window.innerHeight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'black',
    position: 'fixed',
    top: '0',
    zIndex: '99'
  },
  topRow: {
    display: 'flex',
    color: 'white',
    padding: '10px',
    justifyContent: 'space-between'
  },
  img: {
    width: '100%',
    maxHeight: '50%'
  },
  iconDownload: {
    color: 'white',
    fontSize: '14px'
  }
};
