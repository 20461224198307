import { Theme } from '../../../../common/providers/colors';

export const notificationsStyles = {
  imageContainer: {
    width: 55,
    justifyContent: 'center',
    paddingLeft: '1%',
    paddingTop: '1%'
  },
  imageGalleryContainer: {
    width: 72,
    justifyContent: 'center',
    paddingLeft: '1%',
    paddingTop: '1%'
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  extraContainer: {
    flex: 0.3,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  extraContainerImageApproval: {
    flex: 0.3,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    position: 'relative'
  },
  boldText: {
    fontWeight: 'bold'
  },
  text: {
    fontSize: 15,
    color: Theme.TEXT_COLOR
  },
  container: {
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: 'white',
    alignItems: 'center',
    margin: '2%',
    borderRadius: '15px'
  },
  date: {
    fontSize: 14,
    color: Theme.SECONDARY_TEXT_COLOR,
    marginTop: 3
  }
};
