import gql from 'graphql-tag';

export const twitterDetailsQuery = gql`
  query {
    twitterAuth {
      token
      redirectUrl
      returnUrl
    }
  }
`;
