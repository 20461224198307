import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ProfileImage from '../../../common/components/profile-image.rn';
import { AutoSizeImage } from './chat-autosize-image.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import { isSafari, isIOS } from 'react-device-detect';
import { LangData } from '../../../assets/locales/localization';
import { Theme } from '../../../common/providers/colors';

export class MessagesListItem extends PureComponent {
  static propTypes = {
    duringUpload: PropTypes.bool,
    mine: PropTypes.bool,
    hideProfilePic: PropTypes.bool,
    createdAt: PropTypes.string,
    imageThumb: PropTypes.string,
    content: PropTypes.string,
    messageType: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    name: PropTypes.string,
    onUserPicked: PropTypes.func,
    navigateToProfile: PropTypes.func,
    onOpenImageViewer: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.state = {
      imageMenuUrl: null,
      viewImgModal: null
    };
  }

  getName(fullName) {
    if (!fullName) {
      return '';
    }

    const splitted = (fullName || '').split(' ');

    return splitted[0];
  }

  _openLink(link) {
    window.open(link);
  }

  handleUrlPress(url) {
    this._openLink(url);
  }

  handlePhonePress(phone) {
    this._openLink(`tel:${phone}`);
  }

  handleEmailPress(email) {
    this._openLink(`mailto:${email}`);
  }

  handleButtonPress() {
    this.buttonPressTimer = setTimeout(
      () => this.handleLongPress(this.props.messageType, this.props.content),
      1200
    );
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }

  handleLongPress(messageType, content) {
    // copy to clipboard or open img menu (save to device)
    if (messageType === 'TEXT') {
      const el = document.createElement('textarea');
      el.value = content;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.props.setAlert('Copied to clipboard');
    }
  }

  render() {
    return (
      <div
        style={
          this.props.mine
            ? styles.messageContainerMine
            : styles.messageContainer
        }
      >
        <div style={styles.pic}>
          {!this.props.hideProfilePic && (
            <div
              onClick={() =>
                this.props.mine
                  ? this.props.navigateToProfile()
                  : this.props.onUserPicked()
              }
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: isSafari || isIOS ? '50px' : '40px'
              }}
            >
              <ProfileImage
                height={35}
                width={35}
                withFullSize={false}
                style={{
                  alignSelf: 'center',
                  borderColor: this.props.mine ? 'white' : 'white',
                  borderWidth: 2
                }}
                imageUrl={this.props.profilePictureUrl}
              />
              <span style={styles.authorName}>
                {this.getName(this.props.name)}
              </span>
            </div>
          )}
        </div>
        <div style={styles.message}>
          <div
            onTouchStart={
              this.props.messageType === 'TEXT' ? this.handleButtonPress : null
            }
            onTouchEnd={
              this.props.messageType === 'TEXT'
                ? this.handleButtonRelease
                : null
            }
            onMouseDown={
              this.props.messageType === 'TEXT' ? this.handleButtonPress : null
            }
            onMouseUp={
              this.props.messageType === 'TEXT'
                ? this.handleButtonRelease
                : null
            }
            onMouseLeave={
              this.props.messageType === 'TEXT'
                ? this.handleButtonRelease
                : null
            }
            style={
              this.props.mine
                ? {
                    overflow: 'hidden',
                    padding: this.props.messageType === 'TEXT' ? '2px 10px' : 3,
                    borderRadius: 10,
                    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    maxWidth: '200px',
                    overflowWrap: 'break-word'
                  }
                : {
                    overflow: 'hidden',
                    padding: this.props.messageType === 'TEXT' ? '2px 10px' : 3,
                    borderRadius: 10,
                    backgroundColor: 'white',
                    color: Theme.TEXT_COLOR,
                    fontWeight: 'bold',
                    fontSize: '14px',
                    maxWidth: '200px',
                    overflowWrap: 'break-word'
                  }
            }
          >
            {this.props.messageType === 'TEXT' ? (
              // <ParsedText
              //   parse={[
              //     {
              //       type: 'url',
              //       style: styles.external,
              //       onPress: this.handleUrlPress.bind(this)
              //     },
              //     {
              //       type: 'phone',
              //       style: styles.external,
              //       onPress: this.handlePhonePress.bind(this)
              //     },
              //     {
              //       type: 'email',
              //       style: styles.external,
              //       onPress: this.handleEmailPress.bind(this)
              //     }
              //   ]}
              //   childrenProps={{ allowFontScaling: false }}
              //   style={[
              //     styles.messageText,
              //     this.props.mine ? { color: 'white' } : {}
              //   ]}
              // >
              <span> {this.props.content}</span>
            ) : (
              <div
                onClick={() => {
                  this.props.onOpenImageViewer(this.props.content);
                }}
                style={{ width: '100%', height: '100%' }}
              >
                <AutoSizeImage
                  borderRadius={10}
                  maxDimension={160}
                  style={{ marginBottom: 14, height: 160, width: 160 }}
                  source={{ uri: this.props.imageThumb }}
                />
                {this.props.duringUpload && (
                  <div style={styles.imageProgress}>
                    <LoadingSpinner />
                  </div>
                )}
              </div>
            )}
            <br />
            <span style={styles.messageTime}>{this.props.createdAt}</span>
          </div>
        </div>
        {this.state.imageMenuUrl !== null && (
          <div

          // onClose={() => this.setState({ imageMenuUrl: null })}
          >
            <button onClick={() => this.saveImage()}>
              <span>download icon</span>
              <span>{LangData.messagesListItem.device} </span>
            </button>

            <button onClick={() => this.setState({ imageMenuUrl: null })}>
              <span>close icon</span>
              <span>{LangData.messagesListItem.close}</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  pic: {
    width: 50,
    margin: '0 4px'
  },
  message: {
    flexShrink: 1,
    justifyContent: 'flex-start'
  },
  messageContainerMine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
    flex: 1,
    justifyContent: 'flex-start'
  },
  authorName: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 10,
    color: 'white'
  },
  messageTime: {
    bottom: 2,
    right: 0,
    alignSelf: 'flex-end',
    marginRight: 10,
    color: '#aaaaaa',
    fontSize: 10,
    fontWeight: 'bold',
    backgroundColor: 'transparent'
  },
  messageText: {
    minWidth: 50,
    fontSize: 15,
    fontWeight: 'bold',
    color: Theme.TEXT_COLOR
  },
  external: {
    color: 'white',
    textDecorationLine: 'underline'
  },
  imageProgress: {
    borderRadius: 7,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    left: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }
};
