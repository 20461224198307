import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FindFriendsForChat } from '../find-friends/find-friends.container';
import { ClassmateItem } from '../../../classmates/components/classmate-item.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import { LangData } from '../../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { Theme } from '../../../common/providers/colors';

export class NewChatView extends Component {
  static propTypes = {
    createNewChat: PropTypes.func.isRequired,
    navigateToChat: PropTypes.func.isRequired,
    navTitle: PropTypes.string,
    onClose: PropTypes.func,
    hideUsers: PropTypes.array,
    onUsersPicked: PropTypes.func,
    step: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      title: '',
      step: 0,
      stepsConfig: [],
      loading: false
    };
  }

  componentWillMount() {
    const state = {
      stepsConfig: this.getSteps()
    };

    if (this.props.step >= 0) {
      state.step = this.props.step;
    }

    this.setState(state);
  }

  usersPicked(users) {
    this.setState(
      {
        users
      },
      () => {
        this.setState({
          stepsConfig: this.getSteps()
        });
      }
    );
  }

  getLeft() {
    return (
      <div
        onClick={() => {
          // this.props.history.go(-1);
          this.props.history.push('/');
          this.props.toggleComponent(this.props.prevComponent);
        }}
        style={{
          right: 15,
          left: 15,
          top: 15,
          bottom: 15
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  }

  getRight() {
    if (this.state && this.state.users.length === 1) {
      return undefined;
    } else if (
      this.state &&
      ((!this.props.onClose && this.state.users.length > 1) ||
        (this.props.onClose && this.state.users.length > 0))
    ) {
      return (
        <div
          onClick={() => this.doneOrNext()}
          style={{
            right: 15,
            left: 15,
            top: 15,
            bottom: 15
          }}
        ></div>
      );
    }

    return null;
  }

  getRightStep2() {
    if (this.state && this.state.title === '' && this.state.step === 1) {
      return null;
    }

    return undefined;
  }

  updateTitleInput(text) {
    this.setState({ title: text }, () => {
      this.setState({
        stepsConfig: this.getSteps()
      });
    });
  }

  doneOrNext() {
    // Keyboard.dismiss();
    // LayoutAnimation.configureNext(LayoutAnimation.create(300, LayoutAnimation.Types.easeInEaseOut, LayoutAnimation.Properties.scaleXY));

    const { users } = this.state;

    if (this.props.onUsersPicked) {
      this.props.onUsersPicked(users);

      return;
    }

    if (users.length === 1 || this.state.step === 1) {
      this.setState({ loading: true }, () => {
        if (this.props.onClose) {
          this.props.onClose();
        }

        requestAnimationFrame(() => {
          this.props
            .createNewChat(
              this.props.currentUser.id,
              users.map(user => user.id),
              this.state.step === 1 ? this.state.title : null,
              true
            )
            .then(({ data: { createNewChat } }) => {
              console.log('createNewChat =>', createNewChat)
              // setTimeout(() => {
                this.props.navigateToChat(createNewChat);
                this.props.toggleComponent('ChatScreen');
                this.props.history.push('chatScreen');
              // }, 0);
            });
        });
      });
    } else {
      this.setState(
        {
          step: 1,
          users
        },
        () => {
          this.setState({
            stepsConfig: this.getSteps()
          });
        }
      );
    }
  }

  renderTitleStep() {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }

    const usersToDisplay = this.state.users.slice(0, 3);
    const more = this.state.users.length - 3;

    return (
      <div style={{ marginTop: '-20%' }}>
        <p style={styles.noticeTextFirst}>{LangData.newChat.setTitle}</p>
        <div style={styles.inputContainer}>
          <TextInput
            type='text'
            maxLength={30}
            autoFocus={true}
            placeholder={LangData.newChat.title}
            // style={styles.setGroupTitleInput}
            onChange={e => this.updateTitleInput(e.target.value)}
            value={this.state.title}
          />
        </div>

        <p style={styles.noticeText}>{LangData.newChat.group}</p>
        <div style={styles.profilesContainer}>
          {usersToDisplay.map((user, index) => (
            <ClassmateItem
              disabled={true}
              key={index}
              minimal={true}
              classmate={user}
              onItemClick={classmate => null}
              isRelative={true}
            />
          ))}
          {more > 0 ? (
            <div>
              <p style={styles.noticeText}>+{more}</p>
              <p style={styles.noticeText}>{LangData.newChat.more}</p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  getSteps() {
    return [
      {
        showNavBar: true,
        showSearch: true,
        showChips: true,
        usersGrid: true,
        right: this.getRight()
      },
      {
        showNavBar: true,
        showSearch: false,
        showChips: false,
        usersGrid: false,
        content: () => this.renderTitleStep(),
        left: this.getLeft(),
        right: this.getRightStep2()
      }
    ];
  }

  render() {
    if (!this.props.currentUser) {
      return <Redirect to='/login' />;
    }

    return (
      <FindFriendsForChat
        title={
          this.props.navTitle
            ? this.props.navTitle
            : this.state.step === 0
            ? LangData.chatScreen.newChat
            : LangData.newChat.mainTitle
        }
        step={this.state.step}
        onClose={() => this.props.onClose()}
        onDone={() => this.doneOrNext()}
        users={this.state.users}
        hideUsers={this.props.hideUsers || []}
        onUsersChanged={users => this.usersPicked(users)}
        stepsConfig={this.state.stepsConfig}
        {...this.props}
      />
    );
  }
}

const styles = {
  container: {
    flex: 1
  },
  icon: {
    color: Theme.TEXT_COLOR,
    marginLeft: 15
  },
  rightIcon: {
    color: Theme.TEXT_COLOR,
    marginRight: 15
  },
  profilesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    display: 'flex'
  },
  noticeTextFirst: {
    marginTop: 10,
    color: Theme.TEXT_COLOR,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  noticeText: {
    marginTop: 10,
    color: Theme.TEXT_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  setGroupTitleInput: {
    height: '25px',
    padding: '0px',
    fontSize: '15px',
    fontWeight: 'bold',
    color: Theme.TEXT_COLOR,
    background: 'none',
    border: 'none',
    width: '100%',
    fontFamily: 'inherit',
    textAlign: 'center'
  },
  inputContainer: {
    marginTop: '10px',
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '15px',
    padding: '7px',
    textAlign: 'center'
  }
};

const TextInput = styled.input`
  height: 25px;
  padding: 0px;
  font-size: 20px;
  font-weight: bold;
  color: ${Theme.TEXT_COLOR};
  background: none;
  border: none;
  width: 100%;
  font-family: inherit;
  text-align: center;

  &:focus {
    outline: none;
    border: none;
  }
`;
