import React, { Component } from 'react';
import PropTypes, { string } from 'prop-types';
import SecondStageModal from '../secondStage';
import { CategoriesPicker } from '../categories-picker.container';
import moment from 'moment';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import { UserPickerDropdown } from '../user-dropdown.component';
import DateFnsUtils from '@date-io/date-fns';
import { LangData } from '../../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './poll.scss';
import { uploadToCloudinary } from '../../../cloudinary/utils/upload';
import styled from 'styled-components';
import { Theme} from '../../../common/providers/colors';
import { apolloClient } from '../../../apollo-subs';
import { createFeatureLogMutation } from '../../../account/createFeatureLog';

export class CreateUpdateNewsFormView extends Component {
  static propTypes = {
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    loading: PropTypes.bool,
    createUpdateNews: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isUpdating: false,
      mainCategory: '5ee08f9514d8e012c0c05f31',
      categories: ['5ee08f9514d8e012c0c05f31'],
      title: '',
      description: '',
      posterImage: null,
      link: '',
      limitStart: false,
      limitEnd: false,
      displayAuthor: null,
      menuVisible: false,
      imageType: '',
      currentNews: null,
      secondStageOpen: false,
      selectedCategoriesIds: [],
      selectedMainCategoryId: '',
      isFinished: false,
      startDate: null,
      startTime: null,
      startDateTime: null,
      endDate: null,
      endTime: null,
      endDateTime: null,
      targetGroups: ['all'],
      done: false,
      isAdvancedOpen: false,
      duringUploadPicture: false
    };
  }

  openImagesMenu(gotFrom) {
    this.setState({ menuVisible: !this.state.menuVisible });
    this.setState({ imageType: 'poster' });
  }

  onMainCategoryChange = value => {
    this.setState({ mainCategory: value });
  };

  onCategoriesChange = categories => {
    this.setState({ categories });
  };

  handleStartTimeChange(e, type) {
    let currStartTime = this.state.startDateTime;
    if (type === 'Date') {
      currStartTime.setFullYear(e.getFullYear());
      currStartTime.setMonth(e.getMonth());
      currStartTime.setDate(e.getDate());
    } else if (type === 'Time') {
      currStartTime.setHours(e.getHours());
      currStartTime.setMinutes(e.getMinutes());
    }

    this.setState({
      startDateTime: moment(currStartTime, 'DD-MM-YYYY HH:mm:ss').toDate()
    });
  }

  handleEndTimeChange(e, type) {
    let currEndTime = this.state.endDateTime;
    if (type === 'Date') {
      currEndTime.setFullYear(e.getFullYear());
      currEndTime.setMonth(e.getMonth());
      currEndTime.setDate(e.getDate());
    } else if (type === 'Time') {
      currEndTime.setHours(e.getHours());
      currEndTime.setMinutes(e.getMinutes());
    }

    this.setState({
      endDateTime: moment(currEndTime, 'DD-MM-YYYY HH:mm:ss').toDate()
    });
  }

  async handleImage(e) {
    this.setState({ duringUploadPicture: true });
    let file = e.target.files[0];
    requestAnimationFrame(async () => {
      this.setState({ menuVisible: false });
      console.log('file=  ', file);
      await this.createImage(file, 'news_image');
    });
  }

  async createImage(file, tag) {
    const fileData = await toBase64(file);
    const base64 = `data:image/jpeg;base64,${fileData}`;
    const uploadResult = await uploadToCloudinary(file, null, tag);

    const publicId = uploadResult.public_id;
    const filePath = uploadResult.secure_url;

    return this.props.uploadFile(
      file,
      tag,
      base64,
      filePath,
      publicId,
      (store, { data: { uploadToCloudinary } }) => {
        const imageUrl = uploadToCloudinary.filePath;
        console.log(imageUrl, 'imageUrl');
        this.setState({ posterImage: imageUrl });
        this.setState({ posterImageUrl: imageUrl }, () => {
          this.setState({ duringUploadPicture: false });
        });
      }
    );
  }

  navigateSecondStage(news) {
    requestAnimationFrame(() => {
      this.setState({ secondStageOpen: true });
      this.props.navigateSecondStage(news);
    });
  }

  setTargetGroup = data => {
    this.setState({ targetGroups: data });
  };

  createFeatureLog = async (feature) => {
    apolloClient.mutate({
      mutation: createFeatureLogMutation,
      variables: {
        userId: localStorage.getItem("userId"),
        feature,
        clickedAt: new Date()
      }
    })
  }

  saveNews() {
    this.setState({ isUpdating: true });
    this.createFeatureLog("Add Post");
    console.log('posterImage', this.state.posterImage);
    console.log('link', this.state.link);
    console.log('title', this.state.title);
    console.log('description', this.state.description);
    console.log('categories', this.state.categories);
    console.log('mainCategory', this.state.mainCategory);
    console.log(
      'displayAuthorId',
      this.state.displayAuthor ? this.state.displayAuthor.id : null
    );
    console.log(
      'startTime',
      this.state.startDateTime ? this.state.startDateTime : null
    );
    console.log(
      'endTime',
      this.state.endDateTime ? this.state.endDateTime : null
    );

    this.props
      .createUpdateNews(this.props.editEntityId, {
        posterImage: this.state.posterImageUrl,
        link: this.state.link,
        title: this.state.title,
        description: this.state.description,
        categories: this.state.categories,
        mainCategory: this.state.mainCategory,
        displayAuthorId: this.state.displayAuthor
          ? this.state.displayAuthor.id
          : null,
        startTime: this.state.startDateTime ? this.state.startDateTime : null,
        endTime: this.state.endDateTime ? this.state.endDateTime : null
      })
      .then(({ data }) => {
        console.log('data before navigateSecondStage', data);
        this.setState({ currentNews: data });
        this.navigateSecondStage(data);

        this.props.updateTargets(
          data.createUpdateNews.id,
          this.state.targetGroups
        );
        this.props.publish(data.createUpdateNews.id, 'NEWS_FEED');

        this.setState({ done: true });
        this.setState({ isUpdating: false });
      })
      .catch(e => this.setState({ isUpdating: false, error: e }));
  }

  setEditEntity(editEntity) {
    if (editEntity) {
      const startMoment = editEntity.startTime
        ? moment(editEntity.startTime)
        : null;
      const endMoment = editEntity.endTime ? moment(editEntity.endTime) : null;

      this.setState({
        categories: editEntity.categories.map(item => item.id),
        mainCategory: editEntity.mainCategory.id,
        title: editEntity.title,
        link: editEntity.link,
        description: editEntity.description,
        displayAuthor: editEntity.displayAuthor,
        posterImage: editEntity.posterImageUrl,
        startTime: editEntity.startTime ? startMoment.format('hh:mm A') : '',
        startDate: editEntity.startTime ? startMoment.toDate() : null,
        endTime: editEntity.endTime ? endMoment.format('hh:mm A') : '',
        endDate: editEntity.endTime ? endMoment.toDate() : null,
        limitStart: !!startMoment,
        limitEnd: !!endMoment
      });
    }
  }

  componentDidMount() {
    const { editEntity } = this.props;
    this.setEditEntity(editEntity);
  }

  _setCloseSecond = (value, stateKey) => {
    console.log('changed2', value, stateKey);
    this.setState({ [stateKey]: value }, () => {
      console.log('this.state.isFinished', this.state.isFinished);
      if (this.state.isFinished) {
        this.props._setClosePoll(true, 'closePool');
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    const { editEntity } = nextProps;
    this.setEditEntity(editEntity);
  }

  isEmpty(field) {
    return !field || field === '';
  }

  isSubmitDisabled() {
    return (
      this.isEmpty(this.state.title) ||
      this.isEmpty(this.state.description) ||
      this.state.mainCategory === null ||
      this.state.categories.length === 0
    );
  }

  render() {
    if (this.props.loading || this.state.isUpdating) {
      return <LoadingSpinner />;
    }

    if (this.state.done) {
      setTimeout(() => this.props._setClosePoll(true, 'closePool'), 1900);
      return (
        <div style={styles.successContainer}>
          <span style={{ color: Theme.TEXT_COLOR }}>
            {LangData.publicationPlatformPickerForm.success}
          </span>

          <button
            onClick={() => this.props._setClosePoll(true, 'closePool')}
            style={styles.continueBtn}
          >
            {LangData.publicationPlatformPickerForm.return}
          </button>
        </div>
      );
    }

    return (
      <div style={styles.newsView}>
        <h2 style={{ textAlign: 'center', fontSize: 20 }}>
          {LangData.news.details}
        </h2>
        <div style={styles.newsDetailsContainer}>
          <div>
            <TextInput
              type='text'
              value={this.state.title}
              placeholder={LangData.news.link}
              onChange={e => this.setState({ title: e.target.value })}
              // style={styles.textInput}
            />

            <TextareaInput
              type='text'
              value={this.state.description}
              placeholder={LangData.news.description}
              onChange={e => this.setState({ description: e.target.value })}
              rows='6'
            />
          </div>
          <label htmlFor='upload_img'>
            <h2 style={{ textAlign: 'center', fontSize: 16, color: Theme.SECONDARY_TEXT_COLOR }}>
              Add post image
              <FontAwesomeIcon
                style={{ color: Theme.SECONDARY_TEXT_COLOR, marginLeft: '3%' }}
                icon={faImage}
              />
            </h2>
            <input
              type='file'
              accept='image/*'
              onChange={e => this.handleImage(e)}
              style={styles.hideInputStyle}
              name='upload_img'
              id='upload_img'
            />
          </label>
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 2,
              borderColor: 'grey',
              marginBottom: '3%'
            }}
          >
            {this.state.duringUploadPicture && <LoadingSpinner />}
            {this.state.posterImage && (
              <label htmlFor='upload_img'>
                <div style={styles.imgContainer}>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={e => this.handleImage(e)}
                    style={styles.hideInputStyle}
                    name='upload_img'
                    id='upload_img'
                  />

                  <img
                    style={{ width: '100%', height: '100%', borderRadius: 5 }}
                    src={this.state.posterImageUrl}
                  />
                </div>
              </label>
            )}
            {/* {!this.state.posterImage && (
              <label htmlFor='upload_img'>
                <div style={styles.imgContainer}>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={e => this.handleImage(e)}
                    style={styles.hideInputStyle}
                    name='upload_img'
                    id='upload_img'
                  />

                  <FontAwesomeIcon style={{ color: 'grey' }} icon={faImage} />
                </div>
              </label>
            )} */}
          </div>
          {/* <TextInput
            type='text'
            value={this.state.link}
            placeholder={LangData.news.url}
            onChange={e => this.setState({ link: e.target.value })}
            // style={styles.textInput}
          /> */}
        </div>

        {/* Advanced Settings */}
        <h2 style={{ textAlign: 'center', fontSize: 20, marginBottom: '-2%' }}>
          Advanced{' '}
          <FontAwesomeIcon
            icon={this.state.isAdvancedOpen ? faChevronUp : faChevronDown}
            onClick={() =>
              this.setState({ isAdvancedOpen: !this.state.isAdvancedOpen })
            }
          />
        </h2>
        <div
          style={
            this.state.isAdvancedOpen
              ? styles.advancedContainerOpen
              : styles.advancedContainerClose
          }
        >
          <h2
            style={{
              textAlign: 'center',
              fontSize: 16
            }}
          >
            Choose news categories
          </h2>

          <CategoriesPicker
            categories={this.state.categories}
            mainCategory={this.state.categories[this.state.mainCategory]}
            onMainCategoryChange={this.onMainCategoryChange}
            onCategoriesChange={this.onCategoriesChange}
          />

          <SecondStageModal
            poll={this.state.currentNews}
            type='news'
            onClose={() => this.setState({ secondStageOpen: false })}
            isOpen={this.state.secondStageOpen && !this.state.isFinish}
            _setCloseSecond={this._setCloseSecond}
            setGroups={this.setTargetGroup}
          />
        </div>

        {/* <UserPickerDropdown
          selectedUser={this.state.displayAuthor}
          onChange={user => this.setState({ displayAuthor: user })}
        /> */}

        {/* limitations view */}
        {/* <div className='pollsView' style={{ width: '85%' }}>
          <h2 style={{ textAlign: 'center', fontSize: 20 }}>
            {LangData.news.limit}
          </h2>
          <div style={styles.checkboxContainer}>
            <StyledCheckbox
              value='limitStart'
              checked={this.state.limitStart}
              color='primary'
              onChange={e => {
                this.setState({
                  limitStart: !this.state.limitStart,
                  ...(this.state.limitStart === false
                    ? {
                        startDateTime: new Date(Date.now())
                      }
                    : {})
                });
              }}
            />
            <span>{LangData.polls.limitStart}</span>
          </div>

          {this.state.limitStart && (
            <div style={styles.timePickerContainer}>
              <span style={{ color: 'white', textAlign: 'left', fontSize: 11 }}>
                {LangData.polls.time}
              </span>{' '}
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    label='Start Date'
                    value={this.state.startDate}
                    autoOk={true}
                    onChange={e => {
                      this.setState({ startDate: e });
                      this.handleStartTimeChange(e, 'Date');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <KeyboardTimePicker
                    margin='normal'
                    variant='inline'
                    id='time-picker'
                    label='Start Time'
                    value={this.state.startTime}
                    onChange={e => {
                      this.setState({ startTime: e });
                      this.handleStartTimeChange(e, 'Time');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </div>
          )}

          <div style={styles.checkboxContainer}>
            <StyledCheckbox
              value='limitEnd'
              checked={this.state.limitEnd}
              color='primary'
              onChange={e => {
                this.setState({
                  limitEnd: !this.state.limitEnd,
                  ...(this.state.limitEnd === false
                    ? {
                        endDateTime: new Date(Date.now())
                      }
                    : {})
                });
              }}
            />
            <span>{LangData.polls.limitEnd}</span>
          </div>

          {this.state.limitEnd && (
            <div style={styles.timePickerContainer}>
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    label='End Date'
                    value={this.state.endDate}
                    onChange={e => {
                      this.setState({ endDate: e });
                      this.handleEndTimeChange(e, 'Date');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <KeyboardTimePicker
                    margin='normal'
                    label='End Time'
                    id='time-picker'
                    value={this.state.endTime}
                    onChange={e => {
                      this.setState({ endTime: e });
                      this.handleEndTimeChange(e, 'Time');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </div>
          )}
        </div> */}

        <button
          onClick={() => this.saveNews()}
          disabled={this.isSubmitDisabled()}
          style={styles.continueBtn}
        >
          Post
        </button>
      </div>
    );
  }
}

const styles = {
  newsView: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: '#00356b',
    overflow: 'hidden',
    display: 'flex',
    color: Theme.TEXT_COLOR
  },
  inline: {
    display: 'inline-block',
    marginHorizontal: 3
  },
  table: {
    display: 'table',
    overflow: 'hidden',
    width: '100%'
  },
  fields: {
    width: '80%',
    display: 'table-cell'
  },
  poster: {
    display: 'table-cell',
    padding: 10
  },
  posterImage: {
    maxWidth: 100
  },

  eventsDetails: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  textInput: {
    fontFamily: 'inherit',
    border: 'none',
    fontSize: '15px',
    padding: '9px',
    width: '95%',
    borderRadius: '5px',
    margin: '5px 0'
  },
  continueBtn: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    marginTop: '5%',
    fontSize: '17px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    border: 'none',
    padding: '3%',
    borderRadius: '50px',
    width: '35%',
    marginBottom: '3%'
  },
  checkboxContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '17px'
  },
  timePickerContainer: {
    width: '85%',
    marginLeft: '10%'
  },
  newsDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
    marginBottom: '2%'
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid grey',
    borderRadius: '5px'
  },
  hideInputStyle: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1'
  },
  successContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px'
  },
  advancedContainerOpen: {
    visibility: 'visible',
    height: 'fit-content'
  },
  advancedContainerClose: {
    visibility: 'hidden',
    height: 0
  }
};

const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: Theme.SECONDARY_TEXT_COLOR,
      light: Theme.SECONDARY_TEXT_COLOR,
      dark: Theme.SECONDARY_TEXT_COLOR
    },
    primary: {
      main: Theme.SECONDARY_TEXT_COLOR,
      light: Theme.SECONDARY_TEXT_COLOR,
      dark: Theme.SECONDARY_TEXT_COLOR
    },
    textColor: {
      main: Theme.SECONDARY_TEXT_COLOR
    },
    datePicker: {
      selectColor: Theme.SECONDARY_TEXT_COLOR
    }
  }
});

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const TextInput = styled.input`
  font-family: inherit;
  border: none;
  font-size: 15px;
  padding: 9px;
  width: 95%;
  border-radius: 5px;
  margin: 5px 0;

  &:focus {
    outline: none;
    border: none;
  }
`;

const TextareaInput = styled.textarea`
  font-family: inherit;
  border: none;
  font-size: 15px;
  padding: 9px;
  width: 95%;
  border-radius: 5px;
  margin: 5px 0;

  &:focus {
    outline: none;
    border: none;
  }
`;
