import gql from 'graphql-tag';

export const meetingsQuery = gql`
    query allMeetings {
        allMeetings {
          id
          meetingName
          meetingTime
          isStarted
          isFinished
          meetingNumber
          meetingCode
          hostId
          hostEmail
          hostName
          participantsList {
            userId
            userEmail
            userPhoneNumber
            registeredAt
         }
        }
    }
`;
