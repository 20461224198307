import React, { Component, useState, useEffect, Fragment } from 'react';
import ProfileGalleryView from './profile-gallery.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import { LangData } from '../../../assets/locales/localization';
import PropTypes from 'prop-types';

export class UserPhotosView extends Component {
  static propTypes = {
    user: PropTypes.any,
    photosData: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      photos: PropTypes.arrayOf(PropTypes.any)
    })
  };

  render() {
    const { photosData } = this.props;

    if (photosData.loading) {
      return <LoadingSpinner bgcolor={'rgba(0, 0, 0, 0.2)'} />;
    } else if (photosData.photos && photosData.photos.length === 0) {
      return (
        <p style={styles.empty}>
          {this.props.user.name + LangData.userPhoto.empty}
        </p>
      );
    } else if (photosData.photos === null) {
      return (
        <p style={styles.empty}>
          {this.props.user.name + LangData.userPhoto.private}
        </p>
      );
    }

    return (
      <ProfileGalleryView
        noGradient={true}
        photos={photosData.photos}
        history={this.props.history}
        showDeleteOption={false}
      />
    );
  }
}
// return (
//   <Fragment>
//     {photosData.loading ? (
//       <LoadingSpinner bgcolor={'rgba(0, 0, 0, 0.2)'} />
//     ) : photosData.photos && photosData.photos.length === 0 ? (
//       <p style={styles.empty}>{props.user.name + LangData.userPhoto.empty}</p>
//     ) : photosData.photos === null ? (
//       <p style={styles.empty}>
//         {props.user.name + LangData.userPhoto.private}
//       </p>
//     ) : (
//       <ProfileGalleryView
//         noGradient={true}
//         photos={photosData.photos}
//         showDeleteOption={false}
//         history={props.history}
//       />
//     )}
//   </Fragment>
// );

export default UserPhotosView;

const styles = {
  empty: {
    textAlign: 'center'
  }
};
