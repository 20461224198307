import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo-temp';
import { FeedListView } from './feed-list.rn';
import { FeedQuery } from '../../graphql/feed.query';
import {
  popupReminderModal,
  setActiveEventModal,
  setReadIndicationForItem
} from '../../news-feed.actions';
import update from 'immutability-helper';
import { withErrorHandler } from '../../../common/hoc/error-handler.rn';
import {
  openImgModal,
  toggleComponent,
  setLastPosition,
  clearLastPosition
} from '../../../router/router.actions';
import { NewFeedItemSubscription } from '../../graphql/news-feed-item.subscription';

const PER_PAGE = 5;
let subscribed = false;

const withGqlData = graphql(FeedQuery, {
  name: 'feed',
  options: ({ categories, onlyReminders, currentUser }) => ({
    variables: {
      userId: currentUser ? currentUser.id : null,
      categories: (categories || []).map(cats => cats.id), // remove
      onlyReminders: !!onlyReminders,
      skip: 0,
      limit: PER_PAGE
    },
    fetchPolicy: 'cache-and-network'
  }),
  props : ({ ownProps: { categories, onlyReminders }, feed }) => ({
      loadMoreFeedData: () => {
        if (feed && feed.feedList && feed.feedList.hasMore) {
          return feed
            .fetchMore({
              variables: {
                categories: categories.map(cats => cats.id),
                onlyReminders,
                skip:
                  feed && feed.feedList && feed.feedList.items
                    ? feed.feedList.items.length
                    : 0,
                limit: PER_PAGE
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                const target =
                  prev && prev.feedList
                    ? prev
                    : { feedList: { hasMore: true, items: [] } };

                if (
                  fetchMoreResult &&
                  fetchMoreResult.feedList &&
                  fetchMoreResult.feedList.items &&
                  fetchMoreResult.feedList.items.length > 0
                ) {
                  return update(target, {
                    feedList: {
                      items: {
                        $push: fetchMoreResult.feedList.items
                      },
                      hasMore: { $set: fetchMoreResult.feedList.hasMore }
                    }
                  });
                }

                return update(prev, {
                    feedList: {
                      hasMore: { $set: false }
                    }
                });
              }
            })
            .catch(() => null);
        }

        return Promise.resolve();
      },
      // subscribeToMore: async () => {
      //   console.log('feed =>', feed)
      //   console.log('inside feed!', feed)
      //    return feed.subscribeToMore({
      //       document: NewFeedItemSubscription ,
      //       variables: {
      //       },
      //       updateQuery: (prev,  next) => {
      //         const target = prev && prev.feedList
      //         ? prev
      //         : { feedList: { hasMore: true, items: [] } };

      //         console.log('prev =>', prev)

         
      //         let itemAdded = next.subscriptionData.data.itemAdded

      //         if(itemAdded !== null) {
      //           return update(target, {
      //             feedList: {
      //               items: {
      //                 $unshift: [itemAdded]
      //               },
      //               hasMore: { $set: true }
      //             }
      //           });
      //         }
      //         return update(target, {
      //           feedList: {
      //             hasMore: { $set: false }
      //           }
      //         })
      //       }
      //     })
      // },
      feed
  }),
});

const mapStateToProps = ({ newsFeed, navigation, auth }) => ({
  categories: newsFeed.selectedCategories,
  highlightedDate: newsFeed.highlightedDate,
  shouldShowFirstTimeBell: newsFeed.shouldShowFirstTimeBell,
  hideNewBadgeForItems: newsFeed.hideNewBadgeForItems,
  activeEventModal: newsFeed.activeEventModal,
  lastScrollPosition: navigation.lastScrollPosition.lastScroll,
  currentComponent: navigation.navigationComponents.component,
  currentUser: auth.currentUser
  // isChatScreenActive: !!chat.currentChat
});

const mapDispatch = dispatch => ({
  dismissBellModal: () => dispatch(popupReminderModal(-1)),
  setActiveEventModal: event => dispatch(setActiveEventModal(event)),
  setReadIndicationForItem: items => dispatch(setReadIndicationForItem(items)),
  toggleComponent: component => dispatch(toggleComponent(component)),
  openImgModal: img => dispatch(openImgModal(img)),
  setLastPosition: position => dispatch(setLastPosition(position)),
  clearLastPosition: () => dispatch(clearLastPosition())
});

const withState = connect(mapStateToProps, mapDispatch);

const withError = withErrorHandler({
  apollo: ['feed']
});

export const FeedList = compose(
  withState,
  withGqlData,
  withError
)(FeedListView);
