import gql from 'graphql-tag';

export const myPhotosQuery = gql`
  query myPhotos($userId: ID) {
    photos: myPhotos(userId:$userId) {
      id
      imageThumb
      url
      approved
      label
    }
  }
`;
