import gql from 'graphql-tag';

export const createMaterialLogMutation = gql`
  mutation createMaterialLog(
   $userId: ID,
   $manualId: ID,
   $type: String,
   $clickedAt: String
  ) {
    createMaterialLog(
        userId: $userId,
        manualId: $manualId,
        type: $type,
        clickedAt: $clickedAt
    ) {
     _id
    }
  }
`;
