import React, { Fragment } from 'react';
import { ProfileScreen } from './profile/profile-screen.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faArrowLeft, faCog } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';
import { Redirect } from 'react-router';
import { refreshUser } from '../../account/account.util';
import { Theme } from '../../common/providers/colors';

const version = '7.2'


const ProfileModalView = props => {
  const refreshUserFunction = async () => {
    let user = await refreshUser();
    if (user) {
      props.setUser(user);
    }
  };

  const renderTopBar = () => {
    if (props.activeScreen === 'UserProfile') {
      return (
        <div style={styles.optionsRow} id='top_bar'>
          <div>
            <RowIcon
              icon={faArrowLeft}
              onClick={() => {
                props.toggleModal(false);
                props.toggleComponent(props.prevComponent);
                props.history.push('/');
                refreshUserFunction();
                // props.history.go(-1);
              }}
            />
          </div>
          <div>
            <RowIcon
              icon={faCog}
              onClick={() => {
                props.toggleComponent('Settings');
                props.history.push('settings');
              }}
            />
          </div>
        </div>
      );
    }
  };

  if (!props.currentUser) {
    return <Redirect to='/login' />;
  }

  return (
    <div style={styles.pageContainer}>
      {props.prevComponent !== 'ImageGalleryViewerModal' ? (
        <Fade left>
          <Fragment>
            {renderTopBar()}
            <ProfileScreen history={props.history} />
          </Fragment>
          <div style={styles.versionCont}>Version: {version}</div>
        </Fade>
      ) : (
        <Fragment>
          {renderTopBar()}
          <ProfileScreen history={props.history} />
          <div style={styles.versionCont}>Version: {version}</div>
        </Fragment>
      )}
    </div>
  );
};

export default ProfileModalView;

const styles = {
  versionCont: {
    position: 'fixed',
    bottom: '2vh',
    left: window.innerHeight < 500 ? '38vw' : '49vw',
    fonSize: '4vw',
    fontWeight: 'bold'
  },
  topBarContainer: {
    width: '100%',
    paddingHorizontal: 15,
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 5
  },
  headerTitle: {
    fontSize: 14,
    color: 'white'
  },
  topBarItem: {
    alignItems: 'center'
  },
  pageContainer: {
    minHeight: window.innerHeight,
    backgroundColor: Theme.BACKGROUND_COLOR,
    position: 'fixed',
    width: '100%',
    top: '0',
    overflowY: 'scroll'
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  profileImg: {
    width: window.innerWidth / 6 + 'px',
    height: window.innerWidth / 6 + 'px',
    borderRadius: '50%',

    backgroundSize: 'cover',
    border: '1px white solid'
  },
  profileName: {
    color: 'white',
    fontWeight: 'bold'
  }
};

const RowIcon = styled(FontAwesomeIcon)`
  color: ${Theme.TEXT_COLOR};
  font-size: ${window.innerWidth < 500 ? window.innerWidth / 18 + "px" : '3rem'};
  &:hover {
    color: #286cc0;
  }
  &:focus {
    color: #286cc0;
  }
`;
