import React, { Component } from 'react';
import { LangData } from '../../assets/locales/localization';
import EmptyNotice from '../components/empty-notice.rn';
import LoadingSpinner from '../components/loading-spinner.rn';
import { Theme } from '../providers/colors';

export const withErrorHandler = configOverride => {
  const config = Object.assign(
    {
      apollo: [],
      toNull: false
    },
    configOverride || {}
  );

  return function errorWrapper(WrappedComponent) {
    return class WithErrorHandler extends Component {
      constructor(props) {
        super(props);

        this.state = {
          tryingAgain: false,
          hasNetwork: true
        };
      }

      componentWillMount() {
        this.waitForNetwork();
      }

      waitForNetwork() {
        window.addEventListener('online', isConnected => {
          this.setState({
            hasNetwork: isConnected
          });
        });
      }

      tryAgain(queries) {
        this.setState({ tryingAgain: true });

        Promise.all(
          queries.map(query =>
            query.retry ? query.retry() : query.refetch().catch(() => null)
          )
        ).then(() => {
          this.setState({ hasNetwork: true, tryingAgain: false });
        });
      }

      render() {
        if (this.state.tryingAgain) {
          return <LoadingSpinner />;
        }

        const apolloHandlers = config.apollo
          .map(name => this.props[name])
          .filter(i => i);
        const queriesWithErrors = apolloHandlers.filter(item => item.error);
        const hasQueryErrors = queriesWithErrors.length > 0;

        if (hasQueryErrors) {
          // eslint-disable-next-line
          console.log(queriesWithErrors);
        }

        if (hasQueryErrors || !this.state.hasNetwork) {
          if (config.toNull) {
            return null;
          }

          return (
            <div style={{ flex: 1 }}>
              <EmptyNotice
                emoji='⛈️'
                title={LangData.errorHandler.whopse}
                titleColor='black'
                text={
                  !this.state.hasNetwork
                    ? LangData.errorHandler.check
                    : LangData.errorHandler.wrong
                }
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                <button
                  onClick={() =>
                    !this.state.hasNetwork
                      ? this.tryAgain(apolloHandlers)
                      : this.tryAgain(queriesWithErrors)
                  }
                  style={{
                    color: Theme.SECONDARY_TEXT_COLOR,
                    fontWeight: 'bold',
                    padding: '11px',
                    borderRadius: '20px',
                    fontSize: '15px',
                    fontFamily: 'inherit',
                    width: '100px',
                    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
                    border: 'none'
                  }}
                >
                  {LangData.errorHandler.again}
                </button>
              </div>
            </div>
          );
        }

        return <WrappedComponent {...this.props} />;
      }
    };
  };
};
