import React, { useState, useEffect, Fragment } from 'react';
import { LangData } from '../../assets/locales/localization';
import { ClassmateItem } from './classmate-item.rn';
import ErrorMsg from '../../common/components/error-msg.rn';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import EmptyNotice from '../../common/components/empty-notice.rn';
import { propType } from 'graphql-anywhere';
import { classmateItemFragment } from '../graphql/classmate-item.fragment';
import { Theme } from '../../common/providers/colors';

const ClassmateGrid = props => {
  const [data, setData] = useState([]);
  const [selectedClassmate, setSelectedClassmate] = useState({});

  useEffect(() => {
    if (props.data) {
      const classmates = props.data.classmates || [];
      setData(classmates);

      const filtered = classmates.filter(classmate =>
        [classmate.name, classmate.username].some(text =>
          text.toLowerCase().includes((props.filterTerm || '').toLowerCase())
        )
      );
      setData(filtered);
    }
  }, [props]);

  function _renderError() {
    return <ErrorMsg message={ErrorMsg.LOADING_MSG} />;
  }

  function _renderLoadingSpinner() {
    return <LoadingSpinner bgcolor={'rgba(0, 0, 0, 0.2)'} />;
  }

  function renderClassmateList() {
    const checkedArr = props.checkedItems || [];

    if (data.length === 0 && props.filterTerm !== '') {
      return (
        <div>
          <p />
        </div>
      );
    }

    return (
      <Fragment>
        {data.map(item => {
          return (
            <ClassmateItem
              key={item.id}
              isRelative={true}
              checked={!!checkedArr.find(user => user.id === item.id)}
              minimal={props.minimal}
              classmate={item}
              onItemClick={classmate => {
                if (props.origin === 'findFriends') {
                  props.onItemPress(classmate);
                } else {
                  if (props.validateModalOpen) {
                    if (!props.validateModalOpen(classmate)) {
                      return;
                    } else {
                      props.onItemPress(classmate);
                      props.toggleComponent('UserProfile');
                      props.history.push('userProfile');
                    }
                  }
                }
              }}
            />
          );
        })}
      </Fragment>
    );
  }

  function renderMsg() {
    return (
      <EmptyNotice
        text={LangData.classmateGrid.sorry}
        textColor={Theme.SECONDARY_TEXT_COLOR}
        titleColor={Theme.TEXT_COLOR}
        title={LangData.classmateGrid.noFound}
      />
    );
  }

  return (
    <Fragment>
      {!props.data ? null : props.data && props.data.loading ? (
        _renderLoadingSpinner()
      ) : props.data && props.data.error ? (
        _renderError()
      ) : data.length === 0 ? (
        renderMsg()
      ) : (
        <div style={styles.classMates}>{renderClassmateList()}</div>
      )}
    </Fragment>
  );
};

export default ClassmateGrid;

const styles = {
  classMates: {
    marginTop: '3%',
    textAlign: 'center',
    display: 'grid',
    width: '100%',
    gridTemplateColumns:
      window.innerWidth / 3 +
      'px ' +
      window.innerWidth / 3 +
      'px ' +
      window.innerWidth / 3 +
      'px'
  }
};
