import HebJSON from './he.json';
import EngJSON from './en.json';

let LANG;
if (localStorage.getItem('ethicTalkLan') === 'HEB') {
  LANG = 'HEB';
} else {
  LANG = 'ENG';
}

const getJSONByLang = () => {
  if (LANG === 'HEB') return HebJSON;
  if (LANG === 'ENG') return EngJSON;
};

export let LangData = getJSONByLang();

export function changeLang() {
  if (LANG === 'HEB') {
    LANG = 'ENG';
    localStorage.setItem('ethicTalkLan', 'ENG');
    LangData = getJSONByLang();
  } else if (LANG === 'ENG') {
    LANG = 'HEB';
    localStorage.setItem('ethicTalkLan', 'HEB');
    LangData = getJSONByLang();
  }
  window.location.href = '/';
}

// import LocalizedStrings from 'localized-strings';
// import en from './en.json';
// import he from './he.json';

// export const strings = new LocalizedStrings({
//   en: en,
//   he: he
// });
