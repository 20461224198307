import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const chatMessageAddedSubscription = gql`
    subscription {
        messageAdded {
            id
            chatId
            createdAt
            createdAtDay
            serverMessage
            content
            messageType
            author {
                ...ClassmateItem
            }
            imageThumb
            text
            date
        }
    }
    ${classmateItemFragment}
`