import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '../../../../common/providers/colors';

export class PollOptionView extends Component {
  static propTypes = {
    optionText: PropTypes.string.isRequired,
    answerPercentage: PropTypes.number,
    showAnswer: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    bold: PropTypes.bool,
    isCurrentAnswer: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { showAnswer, optionText, disabled, answerPercentage } = this.props;

    const boldStyle = this.props.bold
      ? { border: `2px solid ${Theme.SECONDARY_TEXT_COLOR}` }
      : { border: `1px solid ${Theme.SECONDARY_TEXT_COLOR}` };

    return (
      <div
        style={Object.assign(
          {
            background:
              'linear-gradient(to right, ' +
              Theme.SECONDARY_BACKGROUND_COLOR +
              ' ' +
              answerPercentage +
              '%, transparent 0%)'
          },
          styles.container,
          boldStyle
        )}
        disabled={disabled}
        onClick={() => this.props.onClick(optionText)}
      >
        <p style={styles.text}>{optionText}</p>
        <p style={styles.text}>{answerPercentage}%</p>
      </div>
    );
  }
}

const borderRadius = 30;
const itemHeight = 30;

const styles = {
  container: {
    flexDirection: 'row',
    display: 'flex',
    height: itemHeight,
    overflow: 'hidden',
    borderRadius,
    margin: 2,
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    padding: '0 3px'
  },

  text: {
    fontSize: 12,
    color: Theme.TEXT_COLOR,
    backgroundColor: 'rgba(0,0,0,0)'
  },
  option: {
    marginLeft: 5,
    width: '85%'
  },
  percentage: {
    right: 5
  }
};
