import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo-temp';
import { ChangePasswordScreen } from './change-password-screen.rn';
import { DISMISS_ERROR } from '../login.reducer';

const mapStateToProps = state => ({
  loading: state.intro.isLoading,
  user: state.auth.currentUser,
  showInstallPage: state.navigation.showInstallAppPage.show
  //   appVersion: state.main.appVersion
});

const withState = connect(mapStateToProps, dispatch => ({
  dismissError: () => dispatch(DISMISS_ERROR)
}));

export const ChangePassword = compose(
  withApollo,
  withState
)(ChangePasswordScreen);
