import { connect } from 'react-redux';
import { EFriendsListActiveState, lookForMore } from '../friends.actions';

const mapStateToProps = ({ friends }) => ({
  showHiddenStudents: friends.showHiddenStudents,
  searchTerm: friends.searchTerm,
  isRequests: friends.activeState === EFriendsListActiveState.REQUESTS,
  isGlobalSearch: friends.activeState === EFriendsListActiveState.GLOBAL_SEARCH,
  isFriendsList: friends.activeState === EFriendsListActiveState.FRIENDS_LIST,
});

export const withFriendsState = connect(mapStateToProps, dispatch => ({
  lookForMore: () => dispatch(lookForMore()),
}));
