import gql from 'graphql-tag';

export const setFcmTokenMutation = gql`
  mutation setFcmToken($fcmToken: String!) {
    setUserFcmToken(fcmToken: $fcmToken)
  }
`;

export const setUserFcmTokenUpdated = gql`
  mutation setUserFcmTokenUpdated(
    $userId: ID,
    $endpoint: String
    $expirationTime: String
    $p256dh: String
    $auth: String
  ) {
    setUserFcmTokenUpdated(
      userId: $userId,
      endpoint: $endpoint
      expirationTime: $expirationTime
      p256dh: $p256dh
      auth: $auth
    )
  }
`;
