import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Iframe from 'react-iframe';
import { Theme } from '../../common/providers/colors';

const width = window.innerWidth;

export class InstagramAuthModal extends Component {
  static propTypes = {
    accessToken: PropTypes.any,
    clientId: PropTypes.string,
    returnUrl: PropTypes.string,
    startAuth: PropTypes.func.isRequired,
    authDone: PropTypes.func.isRequired,
    onInit: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.onNavigationStateChange = this.onNavigationStateChange.bind(this);
    this.state = {
      open: false
    };
    this._closed = false;
  }

  clearCookies() {
    try {
      // We need to clear those cookies in order to prevent sharing the cookies
      // Cookie.clear('https://www.instagram.com/');
      // Cookie.clear('https://instagram.com/');
      // Cookie.clear('http://instagram.com/');
    } catch (e) {
      // nothing to do
    }
  }

  componentWillMount() {
    this.clearCookies();

    if (this.props.onInit) {
      this.props.onInit(this);
    }
    this._closed = false;
  }

  onNavigationStateChange(state) {
    if (this.props.returnUrl && state && state.url) {
      if (
        state.url.toLowerCase().startsWith(this.props.returnUrl.toLowerCase())
      ) {
        setTimeout(() => {
          if (!this._closed) {
            this._closed = true;
            this.close();
          }
        }, 1000);
      }
    }
  }

  open() {
    this.clearCookies();
    this.props.startAuth();

    this.setState({
      open: true
    });

    return new Promise(resolve => {
      this.closeResolve = resolve;
    });
  }

  close() {
    this.props.authDone();

    this.setState(
      {
        open: false
      },
      () => {
        if (this.closeResolve) {
          this.closeResolve();
          this.closeResolve = null;
        }
      }
    );
  }

  render() {
    if (!this.state.open || !this.props.accessToken || !this.props.returnUrl) {
      return null;
    }

    // eslint-disable-next-line
    const uri = `https://instagram.com/oauth/authorize/?client_id=${this.props.clientId}&redirect_uri=${this.props.returnUrl}&response_type=code&state=${this.props.accessToken}`;

    return (
      <Fragment>
        {this.state.open ? (
          <div
            style={styles.container}
            // onRequestClose={() => this.close()}
          >
            <div style={styles.exitButton} onClick={() => this.close(true)}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div style={styles.webViewContainer}>
              {/* <iframe
                src={uri}
                style={{ width: width - 20, height: window.innerHeight - 250 }}
                is='x-frame-bypass'
              /> */}
              <Iframe
                url={uri}
                width={width - 20}
                height={window.innerHeight - 250}
                id='myId'
              />
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: window.innerHeight,
    position: 'fixed',
    top: '0',
    backgroundColor: Theme.BACKGROUND_COLOR,
    zIndex: '9'
  },
  webViewContainer: {
    borderRadius: 10,
    height: 350,
    marginHorizontal: 10,
    overflow: 'hidden'
  },
  exitButton: {
    position: 'absolute',
    top: 50,
    left: 10,
    color: 'white'
  }
};
