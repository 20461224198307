import gql from 'graphql-tag';

export const studentFragment = gql`
  fragment StudentInfo on User {
    id
    username
    name
    firstName
    lastName
    profilePictureUrl
    facebook
    facebookName
    linkedin
    linkedinLink
    twitter
    instagram
    snapchat
    classYear
    major
    msjEmail
    courses {
      isManual
      identifier
      id
      fullName
    }
    defaultProfilePicture
    appVersion
    appVersionUpdated
  }
`;
