const PATH = 'js-accounts/';
const SET_USER = `${PATH}SET_USER`;
const SET_TOKENS = `${PATH}SET_TOKENS`;
const CLEAR_TOKENS = `${PATH}CLEAR_TOKENS`;
const CLEAR_USER = `${PATH}CLEAR_USER`;
const LOGGING_IN = `${PATH}LOGGING_IN`;
const SET_ORIGINAL_TOKENS = `${PATH}SET_ORIGINAL_TOKENS`;
const CLEAR_ORIGINAL_TOKENS = `${PATH}CLEAR_ORIGINAL_TOKENS`;
const SET_IMPERSONATED = `${PATH}SET_IMPERSONATED`;

export const loggingIn = isLoggingIn => ({
  type: LOGGING_IN,
  payload: {
    isLoggingIn
  }
});

export const setUser = user => ({
  type: SET_USER,
  payload: {
    user
  }
});

export const setTokens = tokens => ({
  type: SET_TOKENS,
  payload: {
    tokens
  }
});

export const clearTokens = () => ({
  type: CLEAR_TOKENS
});

export const clearUser = () => ({
  type: CLEAR_USER
});

export const setOriginalTokens = tokens => ({
  type: SET_ORIGINAL_TOKENS,
  payload: {
    tokens
  }
});

export const clearOriginalTokens = () => ({
  type: CLEAR_ORIGINAL_TOKENS
});

export const setImpersonated = isImpersonated => ({
  type: SET_IMPERSONATED,
  payload: {
    isImpersonated
  }
});
