import gql from 'graphql-tag';

export const registerMeetingMutation = gql`
    mutation registerToMeeting($userId: String, $userEmail: String, $userPhoneNumber: String, $meetingId: String) {
        registerToMeeting(userId: $userId, userEmail: $userEmail, userPhoneNumber: $userPhoneNumber, meetingId: $meetingId) {
            id
            meetingName
            meetingTime
            isStarted
            isFinished
            meetingCode
            hostId
            meetingNumber
            hostEmail
            hostName
            participantsList {
                userId
                userEmail
                userPhoneNumber
                registeredAt
            }
        }
    }
`
