import { connect } from 'react-redux';
import InstallAppView from './install-app.rn';
import { toggleComponent, setShowInstallPage } from '../router/router.actions';
import { compose } from 'redux';

const mapStateToProps = ({ navigation, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  setShowInstallPage: show => dispatch(setShowInstallPage(show))
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const InstallApp = compose(withState)(InstallAppView);
