import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FeedEventPreviewModal } from './modals/preview-modal.container';
// import { FullScreenImageModal } from '../../../common/components/full-screen-image-modal.rn';
import { FeedEventLiveModal } from './modals/live-modal.container';
// import { FullScreenModal } from '../../../common/components/full-screen-modal.rn';
import { analytics } from '../../../analytics/report';
import { Theme } from '../../../common/providers/colors';

class FeedEventModalView extends Component {
  static propTypes = {
    me: PropTypes.any,
    event: PropTypes.any,
    onClose: PropTypes.func,
    rsvp: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      imageModalUrl: null
    };
  }

  openImageModal(imageUrl) {
    analytics.report('Feed', 'FeedPosterClick', this.props.event.id);
    this.props.openImgModal(imageUrl);
    this.props.history.push('/fullScreenImageModal');
    this.props.toggleComponent('FullScreenImageModal');
  }

  rsvp(response) {
    if (this.props.event && this.props.event.id) {
      analytics.report('Feed', 'RSVP', this.props.event.id);

      this.props.rsvp(this.props.event, response);
    }
  }

  render() {
    const { event } = this.props;

    const started = event
      ? moment(
          event.startTime,
          event.allDay ? 'ddd, MMM D' : 'ddd, MMM D | hh:mm A'
        ).isBefore(moment())
      : null;
    const ended = event
      ? event.ended
        ? true
        : moment(
            event.endTime,
            event.allDay ? 'ddd, MMM D' : 'ddd, MMM D | hh:mm A'
          ).isBefore(moment())
      : null;
    let content = null;

    const appendToChild = event ? (
      <div></div>
    ) : // <FullScreenImageModal
    //   image={this.state.imageModalUrl}
    //   isOpen={this.state.imageModalUrl !== null}
    //   toggleFullSize={() => this.setState({ imageModalUrl: null })} />
    null;

    if (event) {
      if ((started && !ended) || ended) {
        // during or after the event
        content = (
          <FeedEventLiveModal
            ended={ended}
            me={this.props.me}
            onPosterClicked={url => {
              this.openImageModal(url);
            }}
            event={this.props.event}
            onRsvpChanged={response => this.rsvp(response)}
            onClose={this.props.onClose}
            history={this.props.history}
          />
        );
      } else if (!started && !ended) {
        // before event
        content = (
          <FeedEventPreviewModal
            me={this.props.me}
            onPosterClicked={url => this.openImageModal(url)}
            event={this.props.event}
            onRsvpChanged={response => this.rsvp(response)}
            onClose={this.props.onClose}
            history={this.props.history}
          />
        );
      }
    }

    return (
      <Fragment>
        {!!event ? (
          <div
            id='modalBackground'
            style={styles.modalBackground}
            onClick={() => this.props.onClose()}
          >
            {content}

            {appendToChild}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default FeedEventModalView;

const styles = {
  modalBackground: {
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'fixed',
    opacity: '0.97',
    zIndex: '9999999',
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
