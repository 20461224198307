import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  getUserSubscription,
  createNewNotificationSubscriptionForUser
} from '../../serviceWorker';
import { isSafari } from 'react-device-detect';

export class NotificationModalView extends Component {
  static propTypes = {
    setFcmToken: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  componentWillMount() {
    this.check();
  }

  async check() {
    if (isSafari) {
      return;
    }
    // get subscription from service worker
    let currentSubOnServiceWorker = await getUserSubscription();
    console.log('currentSubOnServiceWorker: ', currentSubOnServiceWorker);
    currentSubOnServiceWorker = JSON.parse(
      JSON.stringify(currentSubOnServiceWorker)
    );

    let isExistFcmTokenForUserOnDB;

    // if exist subscription from service worker
    if (currentSubOnServiceWorker) {
      console.log('current subscription exist on service worker');
      // get subscription defined for user
      if(this.props.currentUser)  {
        this.props.getUserFcmToken(this.props.currentUser.id).then(res => {
          // if  exist subscription for user on database
          if (res.data.getUserFcmToken) {
            // compare between subscription for user on database and subscription from service worker
            if (
              currentSubOnServiceWorker.endpoint !==
              res.data.getUserFcmToken.endpoint
            ) {
              console.log('current subscription does not match to user sub');
              currentSubOnServiceWorker.userId = this.props.currentUser.id
              this.props
                .setUserFcmTokenUpdated(currentSubOnServiceWorker)
                .catch(err => console.lof(err));
            } else {
              console.log(
                'subscription on database match subscription on service worker'
              );
            }
          } else {
            console.log('subscription does not exist for user on database');
            currentSubOnServiceWorker.userId = this.props.currentUser.id
            this.props
              .setUserFcmTokenUpdated(currentSubOnServiceWorker)
              .catch(err => console.lof(err));
          }
        });
      }
    } else {
      // create new subscription on service worker
      console.log('call to create new subscription');
      let newSub = await createNewNotificationSubscriptionForUser();
      console.log('call to server to set new sub', newSub);
      if (newSub) {
        currentSubOnServiceWorker.userId = this.props.currentUser.id
        this.props
          .setUserFcmTokenUpdated(newSub)
          .catch(err => console.lof(err));
      }
    }
  }

  render() {
    return <Fragment></Fragment>;
  }
}
