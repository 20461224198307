import React, { useEffect } from 'react';
import CategoryGridItem from './category-item.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import { LangData } from '../../../assets/locales/localization';
import { Theme } from '../../../common/providers/colors';

const NewsFeedCategoriesGridView = props => {
  useEffect(() => {
    return function cleanup() {
      props.setCategories();
    };
  }, []);

  function reset() {
    props.setCategories([]);
    props.toggleComponent('News_Feed');
  }

  function renderItem(category) {
    const isActive = !!props.selectedCategories.find(
      cat => cat.id === category.id
    );

    return (
      <CategoryGridItem
        key={category.id}
        onPress={() => props.toggleCategory(category)}
        category={category}
        active={isActive}
      />
    );
  }

  function save() {
    props.setCategories();
    props.toggleComponent('News_Feed');
  }

  function renderList() {
    return (
      <div style={styles.innerContainer}>
        <div style={styles.itemsContainer}>
          {props.categories.map(item => {
            return renderItem(item);
          })}
        </div>

        <div
          style={{
            justifyContent: 'center',
            marginRight: 120,
            display: 'flex'
          }}
        >
          <div onClick={() => reset()} style={styles.resetBtn}>
            <p style={styles.resetText}>{LangData.categoriesGrid.reset}</p>
          </div>

          <div onClick={() => save()} style={styles.btn}>
            <p style={styles.resetText}>{LangData.categoriesGrid.finish}</p>
          </div>
        </div>
      </div>
    );
  }

  function renderSpinner() {
    return <LoadingSpinner left={window.innerWidth} />;
  }

  return (
    <div style={styles.listContainer}>
      {props.categoriesLoading ? renderSpinner() : renderList()}
    </div>
  );
};

export default NewsFeedCategoriesGridView;

const styles = {
  resetText: {
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: 12,
    padding: '0',
    margin: '5px'
  },
  btn: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    color: Theme.SECONDARY_TEXT_COLOR,
    margin: '0 10px 20px',
    textAlign: 'center',
    width: '25%',
    height: 'fit-content'
  },
  resetBtn: {
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    color: Theme.SECONDARY_TEXT_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    margin: '0 10px 20px',
    textAlign: 'center',
    width: '25%',
    height: 'fit-content'
  },
  backFabStyle: {
    backgroundColor: 'white',
    margin: 10
  },
  listStyle: {
    flex: 1
  },
  listContainer: {
    position: 'fixed',
    bottom: '0',
    height: window.innerHeight,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'height 1s ease',
    backgroundColor: Theme.BACKGROUND_COLOR
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: '15%'
  },
  itemsContainer: {
    display: 'grid',
    gridTemplateColumns:
      window.innerWidth / 3 +
      'px ' +
      window.innerWidth / 3 +
      'px ' +
      window.innerWidth / 3 +
      'px',
    textAlign: 'center'
  }
};
