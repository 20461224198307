import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { LangData } from '../../assets/locales/localization';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { Theme } from '../../common/providers/colors';

export class CoursesPickerView extends Component {
  static propTypes = {
    updateFilter: PropTypes.func,
    onItemChange: PropTypes.func,
    checkedItems: PropTypes.array,
    search: PropTypes.string,
    skip: PropTypes.number,
    limit: PropTypes.number,
    courses: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allCourses: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };
  constructor(props) {
    super(props);
    this.ifExist = false;
    this.checkboxesStatus = [];
  }

  buildCheckBoxObject() {
    let checkboxObject = [];
    let tempObj = {};
    this.props.courses.allCourses.items.map((course, index) => {
      tempObj.course = course;
      tempObj.isChecked = false;
      tempObj.index = index;
      checkboxObject.push(tempObj);
      tempObj = {};
    });
    this.ifExist = true;
    this.checkboxesStatus = checkboxObject;
    return checkboxObject;
  }

  handleChange(courseId) {
    if (this.props.checkedItems.includes('courses')) {
      console.log('this.checkboxesStatus ', this.checkboxesStatus);
      let ind = this.checkboxesStatus.filter(
        element => element.course.identifier === courseId
      )[0].index;
      this.checkboxesStatus[ind].isChecked = !this.checkboxesStatus[ind]
        .isChecked;
      this.props.onItemChanged(
        `course_${courseId}`,
        this.checkboxesStatus[ind].isChecked
      );
    } else alert(LangData.coursesPicker.courses);
  }

  render() {
    const showList =
      this.props.courses &&
      this.props.courses.allCourses &&
      this.props.courses.allCourses.items;

    if (!showList) {
      return <LoadingSpinner />;
    }

    if (showList && !this.ifExist) this.buildCheckBoxObject();

    return (
      <div style={styles.mainContainer}>
        <TextInput
          type='text'
          value={this.props.search}
          placeholder={LangData.coursesPicker.search}
          onChange={e =>
            this.props.updateFilter({ skip: 0, search: e.target.value })
          }
          // style={styles.input}
        />

        {showList
          ? this.props.courses.allCourses.items.map((course, key) => (
              <div key={key} style={{ display: 'flex' }}>
                <StyledCheckbox
                  checked={this.props.checkedItems.includes(
                    `course_${course.identifier}`
                  )}
                  color='primary'
                  onChange={e =>
                    this.props.onItemChange(
                      `course_${course.identifier}`,
                      e.target.checked
                    )
                  }
                />

                <p
                  style={styles.courseName}
                >{`${course.identifier} (${course.fullName})`}</p>
              </div>
            ))
          : null}
      </div>
    );
  }
}

const styles = {
  mainContainer: {
    width: '80%',
    marginLeft: '15%',
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    height: '35px',
    width: '100%',
    border: 'none',
    paddingLeft: '10px',
    borderRadius: '10px',
    fontFamily: 'inherit'
  },
  courseName: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 14
  }
};

const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);

const TextInput = styled.input`
  height: 35px;
  width: 100%;
  border: none;
  padding-left: 10px;
  border-radius: 10px;
  font-family: inherit;

  &:focus {
    outline: none;
    border: none;
  }
`;
