import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ClassmateItem } from '../../../../classmates/components/classmate-item.rn';
import LoadingSpinner from '../../../../common/components/loading-spinner.rn';
import { LangData } from '../../../../assets/locales/localization';
import { Theme } from '../../../../common/providers/colors';

class AttendingListView extends Component {
  static propTypes = {
    eventId: PropTypes.string.isRequired,
    ended: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func,
    me: PropTypes.any,
    goingList: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any,
      networkStatus: PropTypes.number,
      eventGoingList: PropTypes.any
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      userIdModal: null
    };
  }

  renderItem(item, index) {
    return (
      <ClassmateItem
        disabled={this.props.me.id === item.id}
        key={index}
        isRelative={true}
        minimal={true}
        classmate={item}
        onItemClick={() => {
          this.setState({ userIdModal: item.id });
        }}
      />
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.modalUser) {
      const eventGoingList =
        nextProps.goingList &&
        nextProps.goingList.eventGoingList &&
        nextProps.goingList.eventGoingList.users
          ? nextProps.goingList.eventGoingList.users
          : [];

      this.setState({
        modalUser:
          eventGoingList.find(u => u.id === this.state.modalUser.id) || null
      });
    }
  }

  render() {
    const eventGoingList =
      this.props.goingList &&
      this.props.goingList.eventGoingList &&
      this.props.goingList.eventGoingList.users
        ? this.props.goingList.eventGoingList.users
        : [];

    return (
      <div style={styles.container}>
        {/* <StatelessUserProfileModal
          isOpen={!!this.state.userIdModal}
          toggleModal={() => this.setState({ userIdModal: null })}
          userId={this.state.userIdModal}
        /> */}
        {this.props.goingList.networkStatus === 1 ? (
          <LoadingSpinner />
        ) : eventGoingList.length === 0 ? (
          this.props.ended ? (
            <span style={styles.zeroAttend}>{LangData.attendingList.one}</span>
          ) : (
            <span style={styles.zeroAttend}>
              {LangData.attendingList.first}
            </span>
          )
        ) : (
          <div style={styles.list} onClick={e => e.stopPropagation()}>
            {eventGoingList.map((item, index) => {
              return this.renderItem(item, index);
            })}
          </div>
        )}
      </div>
    );
  }
}

export default AttendingListView;

const styles = {
  container: {
    flexGrow: 1,
    textAlign: 'center'
  },
  list: {
    display: 'grid',
    width: '100%',
    marginTop: '3%',
    gridTemplateColumns:
      window.innerWidth / 3.6 +
      10 +
      'px ' +
      (window.innerWidth / 3.6 + 10) +
      'px ' +
      (window.innerWidth / 3.6 + 10) +
      'px'
  },
  zeroAttend: {
    textAlign: 'center',
    marginTop: 30,
    color: Theme.SECONDARY_TEXT_COLOR,
    fontSize: 12,
    backgroundColor: 'transparent',
    fontStyle: 'italic'
  }
};
