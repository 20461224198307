import FeedEventPreviewModalView from './preview-modal.rn';
import { compose } from 'react-apollo-temp';
import { withGoingList } from './attending-list.container';
import { withErrorHandler } from '../../../../common/hoc/error-handler.rn';
import { connect } from 'react-redux';

const withError = withErrorHandler({
  apollo: ['goingList']
});

export const FeedEventPreviewModal = compose(
  withGoingList,
  withError
)(FeedEventPreviewModalView);
