import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Theme } from '../common/providers/colors';
import { refreshUser } from '../account/account.util';
import { getClient } from '../apollo'
import { userCompletedFirstLogin } from './graphql/userCompletedFirstLogin'
import { userTalkedWithYogi } from './graphql/userTalkedWithYogi'
import Yogi from '../assets/images/Yogi.png'
import { CreateUpdateNewsForm} from './AddPosts/AddPostContainer'
import yogiBlob from '../assets/images/yogiBlob.png'
import Chatbot from '../chatbot/Chatbot';
import SwipeableViews from 'react-swipeable-views';
import { FeedList } from '../news-feed/components/feed-list/feed-list.container';
import { Schedule } from '../schedule-view/components/schedule.container';
import { ChatList } from '../chat/components/chats-list/chat-list.container';
import { FriendsList } from '../friends/components/friends-list.container';
import { MyNotifications } from '../my-notifications/components/my-notifications.container';
import { NotificationModal } from '../notification/components/notification-modal.container';
import AddToHomescreen from 'react-add-to-homescreen';
import { NewsFeedCategoriesGrid } from '../news-feed/components/categories-grid/categories-grid.container';
import { updateYogiClickedMutation } from './graphql/updateYogiClicked';
import { updateYogiChatSympthomsMutation } from './graphql/updateYogiChatSympthoms';
import { createFeatureLogMutation } from '../account/createFeatureLog';

import './main-page-view.scss';
import { apolloClient } from '../apollo-subs';

const width = window.innerWidth

const addPostTextOptions = [
    "Tell us what's on your mind",
    "Share your experiences with yoga",
    "Tell us about your experiences with cancer",
    "Share your story with us",
    "Tell us your tips for beating cancer",
    "Tell us your tips for practicing yoga"
]

function GeneralView(props) {
    const [isYogiOpen, setIsYogiOpen] = useState(false);
    const [yogiChatId, setYogiChatId] = useState(null);
    const [updatedTalkWithYogi, setUpdatedTalkWithYogi] = useState(false)
    const [displayBubble, setDisplayBubble] = useState(true)
    const [randomTextSelected, setRandomTextSelected] = useState(addPostTextOptions[Math.floor(Math.random() * addPostTextOptions.length)])
    const [addPostOpen, setAddPostOpen] = useState(false)

    const playYogi = async () => {
        let soundOn = await localStorage.getItem('soundOn')
        if(soundOn !== null && soundOn === 'true') {
          let audio = new Audio('/sounds/yogiSound.mp3')
          audio.load()
          if(displayBubble === true) audio.play()
        }
      }
    


    useEffect(() => {
        document.getElementById('zmmtg-root').style.display = 'none'

        playYogi()
        setTimeout(() => {
          setDisplayBubble(false)
        }, 3000)
      }, [props.currentComponent]);

    const userTalkedWithYogiUpdate = () => {
    if (!props.currentUser.talkedWithYogi && !updatedTalkWithYogi) {
        getClient().mutate({
        mutation: userTalkedWithYogi,
        variables: {
            userId: props.currentUser.id
        }
        })
        .then(_ => {
            setUpdatedTalkWithYogi(true)
        })
    }
    }

    const handleAddToHomescreenClick = () => {
        alert(`
          1. Open Share menu
          2. Tap on "Add to Home Screen" button`);
    };

    const createFeatureLog = async (feature) => {
      apolloClient.mutate({
        mutation: createFeatureLogMutation,
        variables: {
          userId: localStorage.getItem("userId"),
          feature,
          clickedAt: new Date()
        }
      })
    }


    const updateYogiClicked = async () => {
      let userId = props.currentUser.id
      createFeatureLog("Yogi Chat")
      let result = await apolloClient.mutate({
        mutation: updateYogiClickedMutation,
        variables: {
          userId
        }
      })
      let yogiChatId = result.data.updateYogiClicked._id
      console.log("yogiChatId =>", yogiChatId)
      localStorage.setItem('yogi_id', yogiChatId)
      setYogiChatId(yogiChatId)
    };
    
    const updateYogi = () => {
      if(isYogiOpen === false) {
        updateYogiClicked()
        console.log("Yogi opened!")
      }
      setIsYogiOpen(!isYogiOpen)
      userTalkedWithYogiUpdate()
    }

    const updateYogiChatSympthoms = async (yogiChatId, sympthom, severity, watchTime) => {
      console.log("inside updateYogiChatSympthoms =>", yogiChatId, sympthom, severity, watchTime)
      let result = await apolloClient.mutate({
        mutation: updateYogiChatSympthomsMutation,
        variables: {
          yogiId: yogiChatId,
          name: sympthom,
          severity,
          watchTime
        }
      })

    }

    return (
        <div>
            <div style={styles.talkWithYourYogiContainer}>
            <button onClick={() => updateYogi()} style={props.currIndex === 0 ? styles.talkWithYourYogiBtn: {display: 'none'}}>
                {/* {isYogiOpen ? 'Close ' : 'Talk With '} Your Personal Yogi */}
                {
                    props.currIndex === 0 && displayBubble && !isYogiOpen && !updatedTalkWithYogi &&
                    <span style={styles.yogiBlobStyle}>
                    <span style={styles.yogiBlobTextStyle}>Hi, I'm your personal Yogi, Click on me to get started</span>
                    </span>
                }
                {!isYogiOpen && props.currIndex === 0  && <img src={Yogi} style={styles.yogiLogo}  alt="" /> }

                </button>
            </div>
            {  isYogiOpen && <div onClick={() => { setIsYogiOpen(!isYogiOpen); userTalkedWithYogiUpdate() }} style={styles.exitIcon}>x</div> }
            {
                props.currIndex === 0 &&
                <div style={styles.addNewPostContainer}>
                <button style={styles.addNewPostBtn} onClick={() => {
                    // props.toggleComponent('MyPosts');
                    // props.history.push('myPosts');
                    setAddPostOpen(!addPostOpen)
                }}>
                    <span style={
                      width < 500? 
                      {width:'80%',marginRight:'5%', fontSize: "4vw"}
                      : 
                      { width: '77%',marginRight:'5%', fontSize: "1.5vw"}
                    }>{randomTextSelected}</span>
                    <span style={styles.addPostToggler}>{addPostOpen ? '<' : '>'}</span>
                </button>
                </div>
            }
            {
                addPostOpen &&
                <CreateUpdateNewsForm currentUser={props.currentUser} updateAddPost={() => setAddPostOpen(!addPostOpen)}/>
                // <AddPost/>
            }
            <Chatbot {...props} isYogiOpen={isYogiOpen} updateYogiChatSympthoms={updateYogiChatSympthoms}/>
            <SwipeableViews
                index={props.currIndex}
                onChangeIndex={props.handleChangeIndex}
            // onScroll={() => { window.scrollTo(0, 1); console.log('scrool') }}
            // style={{ marginTop: window.innerWidth / 7.2 + 'px' }}
            >
                <FeedList history={props.history} />
                <ChatList history={props.history} currentUser={props.currentUser}/>
                <FriendsList history={props.history}/>
                <MyNotifications history={props.history} inComponent={props.currIndex === 3}/>
                {/* <Schedule history={props.history} /> */}

            </SwipeableViews>

         
            <NotificationModal />
            <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
            {props.currentComponent === 'Filter' && (
                <NewsFeedCategoriesGrid history={props.history} />
            )}
        </div>
    )
}

export default GeneralView;

const styles = {
    bubble: {
      backgroundColor: 'red',
      fontSize: '13px',
      height: 'fit-content',
      padding: '0.7% 2.2%',
      borderRadius: '50%',
      position: 'absolute',
      top: '18%',
      right: '6%'
    },
    mainPage: {
      // minHeight: '100vh',
      backgroundColor: Theme.BACKGROUND_COLOR
    },
    outerNav: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: window.innerWidth,
      paddingTop: '2%',
      position: 'fixed',
      bottom: 0,
      zIndex: 9,
      backgroundColor: '#4d4b62',
      borderRadius: '20px 20px 0 0',
      padding: '3% 0'
    },
    innerMenu: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%'
    },
    topNav: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: window.innerWidth,
      paddingTop: '2%',
      paddingBottom: width < 500 ? '0' : '2%',
      position: 'fixed',
      top: 0,
      zIndex: 99999,
      backgroundColor: Theme.BACKGROUND_COLOR
    },
    topNavGray: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: window.innerWidth,
      paddingTop: '2%',
      position: 'fixed',
      top: 0,
      zIndex: 99999,
      backgroundColor: Theme.GRAY_COLOR
    },
    innerNav: {
      textAlign: 'center',
      fontSize: '35px',
      color: Theme.LOWER_NAV_ICONS_COLOR,
      paddingBottom: '1%',
      display: 'flex'
    },
    innerNavSelected: {
      textAlign: 'center',
      fontSize: '35px',
      color: Theme.WHITE_COLOR,
      paddingBottom: '1%',
      borderBottom: `2px solid ${Theme.WHITE_COLOR}`
    },
    logo: {
      width: width < 500 ? '50%' : '20%',
      padding: '1%'
    },
    menu: {
      color: Theme.BLACK_COLOR,
      fontSize: '25px',
      marginLeft: width < 500 ? '0' : '5%'
    },
    fullPage: {
      minHeight: window.innerHeight,
      width: window.innerWidth,
      opacity: '0.9',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: '999999',
      textAlign: 'center',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '0'
    },
    msgContainer: {
      width: window.innerWidth / 2 + 'px',
      backgroundColor: 'white',
      fontFamily: 'inherit',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: Theme.TEXT_COLOR
    },
    btn: {
      color: Theme.TEXT_COLOR,
      backgroundColor: Theme.BACKGROUND_COLOR,
      border: 'none',
      padding: '7%',
      fontWeight: 'bold',
      fontSize: window.innerWidth / 24 + 'px',
      fontFamily: 'inherit',
      width: '50%',
      margin: '1%'
    },
    icon: {
      color: Theme.BACKGROUND_COLOR,
      fontSize: window.innerWidth / 7.2 + 'px',
      marginTop: '5%'
    },
    buttonsRow: {
      width: '100%',
      display: 'flex'
    },
    userAnswerInput: {
      border: '1px solid #dcd7c8',
      fontFamily: 'inherit',
      marginBottom: '10%',
      padding: '5%',
      fontSize: '15px',
      borderRadius: '15px'
    },
    talkWithYourYogiBtn: {
      width: width< 500? "100%": "60%",
      borderRadius: "25px",
      border: "none",
      padding: "5px 10px",
      backgroundColor: "transparent",
      color: '#665f70',
      // margin: '5%'
      display: 'flex',
      flexDirection: 'row-reverse',
      outline: '0'
    },
    exitIcon: {
      position: "absolute",
      zIndex: 10000,
      top: "20%",
      left: "94%",
      width: "20%",
      fontSize: "18px",
      color: "#978689",
      cursor: "pointer"
    },
    talkWithYourYogiContainer: {
      // marginTop: width < 500 ? window.innerWidth / 7.2 + 'px' : window.innerWidth / 11 + 'px',
      // width: 'fit-content',
      // display: "flex",
      // justifyContent: "center",
      position: 'absolute',
      zIndex: '10000',
      top: width < 500 ? '16%': "25%",
      left: width < 500 ?'80%': '73%',
      width:'20%',
      flexDirection: 'row-reverse'
  
    },
    outerNavSubMenuBtn: {
      width: '70%',
      borderRadius: '25px',
      padding: '2%',
      backgroundColor: '#e1c2b0',
      color: '#4D4B62',
      fontSize: 'large',
      marginBottom: '2%'
    },
    addNewPostContainer: {
      marginTop: width < 500 ? (window.innerWidth / 7.2  - 2 )+ 'px'  : window.innerWidth / 11 + 'px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    addNewPostBtn: {
      outline:'none',
      width: width < 500 ?  "80%": "55%",
      borderRadius: "25px",
      border: "none",
      padding: "5px 10px",
      backgroundColor: "#e1c2b0",
      color: '#665f70',
      margin: '5%',
      display: 'flex',
      justifyContent: "center",
      alignItems: 'center'
    },
    yogiLogo: {
      width: 'inherit'
    },
    yogiBlobStyle: {
      width: '230px',
      height: '100%',
      position: 'absolute',
      top: '-38%',
      backgroundImage: `url(${yogiBlob})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    yogiBlobTextStyle: {
      width: '30%',
      textAlign: 'center',
      fontSize: '11px'
    },
    addPostToggler: {
      display: 'flex',
      transform: 'rotate(90deg)',
      fontSize: '20px',
      /* padding: 4px 8px; */
      backgroundColor: 'rgb(77, 75, 98)',
      borderRadius: '50%',
      color: 'rgb(225, 194, 176)',
      fontWeight: 'bolder',
      width: '35px',
      height: '33px',
      justifyContent: 'center'
    }
  };
  