import { connect } from 'react-redux';
import { reloadUserProfile } from '../../auth/auth.actions';
import { startSnapchatEnrollment } from '../snapchat.actions';
import { SnapchatEnrollmentModal } from './snapchat-enrollment-view.rn';

const mapDispatchToProps = dispatch => ({
  startAuth: () => dispatch(startSnapchatEnrollment()),
  stopAuth: () => dispatch(startSnapchatEnrollment(false)),
  authDone: () => dispatch(reloadUserProfile('snapchat'))
});

const mapProps = ({ snapchat, auth }) => ({
  isLoading: snapchat.isLoading,
  user: auth.currentUser
});

const withState = connect(mapProps, mapDispatchToProps, null);

export const SnapchatEnrollment = withState(SnapchatEnrollmentModal);
