import gql from 'graphql-tag';

export const uploadLiveFeedImageMutation = gql`
    mutation uploadImageToEventFeed($eventId: String!, $imageData: String!) {
        uploadImageToEventFeed(imageData: $imageData, eventId: $eventId) {
            id
            pending
            approved
            rejectionReason
            photoUrl
            thumbUrl: photoUrl(params: { height: "200", width: "200", crop: FILL })
            uploadedBy {
                id
                name
                profilePictureUrl
            }
        }
    }
`;
