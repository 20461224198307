import gql from 'graphql-tag';
import { newsFeedNewsFragment } from '../../posts/graphql/news-feed-news-item.fragment';

export const NewFeedItemSubscription = gql`
    subscription itemAdded {
        itemAdded {
            ...NewsFields
        }
    }

    ${newsFeedNewsFragment}
`;
