import React, { useState, useEffect } from 'react';
import FHimage from '../assets/images/LogoDark.png';
import { useReactPWAInstall } from "react-pwa-install";
import Fade from 'react-reveal/Fade';
import { Theme } from '../common/providers/colors';
import './install-app.scss'
import { isAndroid, isIOS } from 'react-device-detect';


function InstallAppView(props) {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const DidMount = () => {
    let deferredPrompt;


    window.addEventListener('beforeinstallprompt', function (e) {
      e.preventDefault();
      deferredPrompt = e;
      // showAddToHomeScreen();
    });

    document.getElementById("install-btn").click()


    // function showAddToHomeScreen() {
    //   let a2hsBtn = document.querySelector('.ad2hs-prompt');
    //   a2hsBtn.style.display = 'block';
    //   a2hsBtn.addEventListener('click', addToHomeScreen);
    // }

    // function addToHomeScreen() {
    //   let a2hsBtn = document.querySelector('.ad2hs-prompt'); // hide our user interface that shows our A2HS button
    //   a2hsBtn.style.display = 'none'; // Show the prompt
    //   deferredPrompt.prompt(); // Wait for the user to respond to the prompt
    //   deferredPrompt.userChoice.then(function (choiceResult) {
    //     if (choiceResult.outcome === 'accepted') {
    //       console.log('User accepted the A2HS prompt');
    //     } else {
    //       console.log('User dismissed the A2HS prompt');
    //     }

    //     deferredPrompt = null;
    //   });
    // }
  };

  useEffect(DidMount, []);

 
  const handleClick = () => {
    pwaInstall({
      title: "Install Yocas",
      features: (
      <div>
        <p style={styles.description}>
          Please note, we recommend installing this app on your device
        </p>
        <p style={styles.description}>
          You may want to install it on your device, so you won’t have to login everytime
        </p>
       </div>
      ),
    })
    .then(() => {
      // console.log("App installed successfully or instructions for install shown")
      if(isIOS) {
      props.setShowInstallPage(false);
      props.history.push('login');
      }
    
    })
    .catch(() => {
      console.log("User opted out from installing")
      props.setShowInstallPage(false);
      props.history.push('login');
    });
  };

  return (
    <div>
      <div style={styles.mainContainer}>
        <img src={FHimage} style={styles.imageLogo} alt=""/>

        {/* <p style={styles.description}>
          Please note, we recommend installing this app on your device
        </p>
        <p style={styles.description}>
          You may want to install it on your device, so you won’t have to login everytime
        </p> */}
        <div style={styles.optionsRow}>
          <button style={styles.button} id="install-btn" onClick={handleClick} hidden>
          </button>
        </div>
      </div>
    </div>
  );
}
export default InstallAppView;


const styles = {
  mainContainer: {
    height: window.innerHeight,
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color:'#4D4B62',
    backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.95), rgb(237,204,181,0.95))'
  },
  description: {
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '3%',
    marginBottom: '7%',
    padding: '2%'
  },
  optionsRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  button: {
    color: '#E1C2B0',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    marginTop: '5%',
    fontSize: '17px',
    backgroundColor: '#4D4B62',
    border: 'none',
    padding: '3%',
    borderRadius: '50px',
    marginBottom: '3%',
    width: '45%'
  },
  imageLogo: {
    width: '60%'
  }
};
