import React, { useState, Fragment, useEffect } from 'react';
import ProfileImage from '../../../common/components/profile-image.rn';
import { ProfilePictureMenu } from './profile-picture-menu.container';
import { UploadPhotoMenu } from '../gallery/upload-photo-menu.container';
import { YourPhotos } from '../gallery/your-photos.container';
import { LangData } from '../../../assets/locales/localization';
import styled from 'styled-components';
import { Theme } from '../../../common/providers/colors';

const width = window.innerWidth

const version = '5.6'

class ProfileScreenView extends React.Component { 

  constructor(props) {
    super(props)
  
    this.state = {
    }
  }

  




  
render() {
  return (
    <div style={styles.profileContainer} id='profile_modal_container'>
      {this.props.user && (
        <Fragment>
          <ProfilePictureMenu user={this.props.user} />
          <p style={styles.nameText}>{this.props.user.name}</p>
          <UploadPhotoMenu />

          <div style={styles.hr}></div>

          <YourPhotos currentUser={this.props.user} {...this.props} />
        </Fragment>
      )}
    </div>
  );
};
}

export default ProfileScreenView;

const styles = {
  versionCont: {
    position: 'fixed',
    bottom: '2vh',
    fontWeight: 'bold',
    fontSize: '15px'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nameText: {
    color: Theme.TEXT_COLOR,
    fontSize: width < 500 ? window.innerWidth / 18.75 + 'px' : '2rem',
    margin: 0,
    fontWeight: 'bold'
  },
  majorText: {
    color: Theme.TEXT_COLOR,
    fontSize: window.innerWidth / 28.846 + 'px',
    textAlign: 'center',
    marginHorizontal: '7%'
  },
  hr: {
    marginTop: window.innerWidth / 25 + 'px',
    width: '90%',
    height: window.innerWidth / 187.5 + 'px',
    backgroundColor: Theme.BACKGROUND_COLOR
  }
};
