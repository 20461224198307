import { getClient } from '../apollo';
import { meQuery } from './graphql/me.query';
import { checkUpdate } from './graphql/check-update-version-query';

export const EAuthActions = {
  RELOAD_USER: 'RELOAD_USER',
  USER_LOADED: 'USER_LOADED'
};

export const reloadUserProfile = (reason = null) => dispatch => {
  dispatch({
    type: EAuthActions.RELOAD_USER,
    reason
  });

  getClient()
    .query({
      query: meQuery,
      fetchPolicy: 'network-only'
    })
    .then(({ data }) => {
      dispatch({
        type: EAuthActions.USER_LOADED,
        user: data.me
      });
    })
    .catch(e => {
      console.log('Cant reload user', e);
    });

  getClient()
    .query({
      query: checkUpdate,
      variables: {
        ver: localStorage.getItem('ver') || ''
      },
      fetchPolicy: 'network-only'
    })
    .then(({ data }) => {
      if (data.checkUpdate !== localStorage.getItem('ver')) {
        window.location.reload(true);
        localStorage.setItem('ver', data.checkUpdate);
      }
    })
    .catch(e => {
      console.log('Cant reload version', e);
    });
};

export const setUser = user => ({
  type: EAuthActions.USER_LOADED,
  user
});
