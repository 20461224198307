import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { navigation } from './router/router.reducer';
import { courses } from './courses/courses.reducer';
import { intro } from './login/login.reducer';
import { profile } from './profile/profile.reducer';
import { auth } from './auth/auth.reducer';
import { schedule } from './schedule-view/schedule.reducer';
import { newsFeed } from './news-feed/news-feed.reducer';
import { friends } from './friends/friends.reducer';
import { chat } from './chat/chat.reducer';
import alert from './alert/alert.reducer';
// import {
//   socialNetworkNavigationMiddleware,
//   socialNetworkNavigationReducer
// } from './social-network/social-network.navigation';
// import { main } from './main/main.reducer';
import { facebook } from './facebook/facebook.reducer';
import { linkedin } from './linkedin/linkedin.reducer';
import { instagram } from './instagram/instagram.reducer';
import { snapchat } from './snapchat/snapchat.reducer';
import { twitter } from './twitter/twitter.reducer';
import { pollsReducer } from './posts/posts.reducer';
import { students } from './posts/components/students/students.reducer';
import { comments } from './news-feed/comments.reducer';
import { coursesOnPosts } from './posts/components/courses/courses.reducer';
import { manuals } from './manuals/manuals.reducer';
import { videos } from './videos/videos.reducer';

export const configureStore = () => {
  const middlewares = [thunk, createLogger()];
  const composeArgs = [];
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancers = compose(
    applyMiddleware(
      ...[
        ...middlewares
        // rootNavigationMiddleware,
        // feedNavigationMiddleware,
        // profileNavigationMiddleware,

        // socialNetworkNavigationMiddleware
      ]
    ),
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f,
    ...composeArgs
  );

  return createStore(
    combineReducers({
      //   main,
      navigation,
      //   socialNetworkNavigationReducer,
      courses,
      schedule,
      intro,
      profile,
      auth,
      facebook,
      linkedin,
      instagram,
      snapchat,
      newsFeed,
      twitter,
      friends,
      chat,
      pollsReducer,
      students,
      comments,
      alert,
      coursesOnPosts,
      manuals,
      videos
    }),
    enhancers
  );
};

export const store = configureStore();
