import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../assets/locales/localization';

class ErrorMsg extends Component {
  static LOADING_MSG = LangData.errorMsg.loading;
  static propTypes = {
    message: PropTypes.string,
    noImage: PropTypes.bool
  };

  renderImage() {
    if (!this.props.noImage) {
      return <img src={require('../../assets/images/upset.png')} />;
    }
    return null;
  }
  render() {
    return (
      <div style={styles.container}>
        {this.renderImage()}
        <p style={styles.text}>{LangData.errorMsg.sorry}</p>
        <p style={styles.text}>{this.props.message}</p>
      </div>
    );
  }
}

export default ErrorMsg;

const styles = {
  container: {
    alignItems: 'center',
    margin: 5,
    marginTop: 20
  },
  text: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center'
  }
};
