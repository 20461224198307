import gql from 'graphql-tag';

export const friendRemovedSubscription = gql`
    subscription  {
        friendRemoved {
            addedBy
            createdAt
            updatedAt
            userAdded
            status
        }
    }
`