import { breadcrumb } from '../bugsnag';
import { HIDE_NEW_BADGE, REMINDER_EVER_SET } from '../core/constants/storage';

export const ENewsFeedActions = {
  TOGGLE_CATEGORY: 'news-feed/TOGGLE_CATEGORY',
  SET_CATEGORIES: 'news-feed/SET_CATEGORIES',
  SET_ITEMS_READ: 'news-feed/SET_ITEMS_READ',
  HIGHLIGHT_DATE: 'news-feed/HIGHLIGHT_DATE',
  REMINDER_SET: 'news-feed/REMINDER_SET',
  CLEAR_BADGE: 'news-feed/CLEAR_BADGE',
  SET_ACTIVE_EVENT_MODAL: 'news-feed/SET_ACTIVE_EVENT_MODAL',
  RESET_NEWS_FEED_NAVIGATION: 'news-feed/RESET_NEWS_FEED_NAVIGATION',
  SET_LIVE_FEED_MODAL_ACTIVE_TAB: 'news-feed/SET_LIVE_FEED_MODAL_ACTIVE_TAB'
};

export const resetNewsFeedNavigation = () => ({
  type: ENewsFeedActions.RESET_NEWS_FEED_NAVIGATION
});

export const setLiveFeedModalActiveTab = tabIndex => ({
  type: ENewsFeedActions.SET_LIVE_FEED_MODAL_ACTIVE_TAB,
  tabIndex
});

export const setActiveEventModal = event => ({
  type: ENewsFeedActions.SET_ACTIVE_EVENT_MODAL,
  event
});

export const setCategories = newState => (dispatch, getState) => {
  const state = getState();
  const selectedCategories =
    newState || state.newsFeed.selectedCategoriesOnView;

  dispatch({
    type: ENewsFeedActions.SET_CATEGORIES,
    categories: selectedCategories || []
  });
};

export const toggleCategory = category => {
  breadcrumb('news-feed', `toggleCategory: ${category.name}`);

  return {
    type: ENewsFeedActions.TOGGLE_CATEGORY,
    category
  };
};

export const popupReminderModal = newState => dispatch => {
  if (newState === -1) {
    document.body.style.position = '';
    dispatch({
      type: ENewsFeedActions.REMINDER_SET,
      close: true
    });
  }

  if (newState === true) {
    let res = localStorage.getItem(REMINDER_EVER_SET);

    if (res === null) {
      document.body.style.position = 'fixed';
      localStorage.setItem(REMINDER_EVER_SET, 'true');

      dispatch({
        type: ENewsFeedActions.REMINDER_SET
      });
    }
  }
};

export const setReadIndicationForItem = items => async dispatch => {
  dispatch({
    type: ENewsFeedActions.SET_ITEMS_READ,
    items
  });

  const existing = await localStorage.getItem(HIDE_NEW_BADGE);

  if (!existing) {
    await localStorage.setItem(HIDE_NEW_BADGE, JSON.stringify({}));
  }

  await localStorage.setItem(HIDE_NEW_BADGE, JSON.stringify({ items }));
};
