import { compose, graphql } from 'react-apollo-temp';
import { connect } from 'react-redux';
import { toggleComponent } from '../../router/router.actions';
import { MeetingsView } from './meetings.component';
import { DISMISS_ERROR } from '../../login/login.reducer';

const mapStateToProps = ({ navigation, auth }) => ({
    currentComponent: navigation.navigationComponents.component,
    prevComponent: navigation.navigationComponents.prevComponent,
    currentUser: auth.currentUser
});

const mapDispatch = dispatch => ({
    toggleComponent: component => dispatch(toggleComponent(component)),
    dismissError: () => dispatch(DISMISS_ERROR)

});
  
const withState = connect(mapStateToProps, mapDispatch);

export const Meetings = compose(withState)(MeetingsView)