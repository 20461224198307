import { combineReducers } from 'redux';
import { ENewsFeedActions } from './news-feed.actions';
import { EMainActions } from '../main/main.actions';

function selectedCategoriesOnView(state = [], action) {
  switch (action.type) {
    case ENewsFeedActions.SET_CATEGORIES:
      return action.categories;
    case ENewsFeedActions.TOGGLE_CATEGORY:
      if (state.find(category => category.id === action.category.id)) {
        return state.filter(category => category.id !== action.category.id);
      }

      return [...state, action.category];
    case 'js-accounts/CLEAR_USER':
    case EMainActions.NAVIGATE_PARENT:
      return [];
    default:
      return state;
  }
}

function selectedCategories(state = [], action) {
  switch (action.type) {
    case ENewsFeedActions.SET_CATEGORIES:
      return action.categories;
    case 'js-accounts/CLEAR_USER':
    case EMainActions.NAVIGATE_PARENT:
      return [];
    default:
      return state;
  }
}

function shouldShowFirstTimeBell(state = false, action) {
  switch (action.type) {
    case ENewsFeedActions.REMINDER_SET:
      return !action.close;
    default:
      return state;
  }
}

function hideNewBadgeForItems(state = {}, action) {
  switch (action.type) {
    case ENewsFeedActions.SET_ITEMS_READ:
      return Object.assign(state, action.items);
    default:
      return state;
  }
}

function activeEventModal(state = null, action) {

  switch (action.type) {
    case EMainActions.CLOSE_ALL_MODALS:
      return null;
    case ENewsFeedActions.SET_ACTIVE_EVENT_MODAL:
      return action.event;
    default:
      return state;
  }
}

function liveModalActiveTab(state = null, action) {
  switch (action.type) {
    case EMainActions.CLOSE_ALL_MODALS:
      return 0;
    case ENewsFeedActions.SET_ACTIVE_EVENT_MODAL:
      if (action.event === null) {
        return 0;
      }
      return state;

    case ENewsFeedActions.SET_LIVE_FEED_MODAL_ACTIVE_TAB:
      return action.tabIndex;
    default:
      return state;
  }
}

export const newsFeed = combineReducers({
  selectedCategoriesOnView,
  selectedCategories,
  shouldShowFirstTimeBell,
  hideNewBadgeForItems,
  activeEventModal,
  liveModalActiveTab
});
