import { graphql } from 'react-apollo-temp';
import update from 'immutability-helper';
import { FeedNewsItemView } from './feed-news-item.rn';
import { setReadIndicationForItem } from '../../../news-feed.actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createUpdateCommentMutation } from '../../../graphql/create-update-comment.mutation';
import { updatePostLikes } from '../../../graphql/update-event-likes';
import { deleteCommentMutation } from '../../../graphql/delete-comment.mutation';
import { toggleComponent } from '../../../../router/router.actions';
import { toggleUserProfileModal } from '../../../../profile/profile.actions';


const mapStateToProp = ({ auth, comments }) => ({
  currentUser: auth.currentUser,
  skip: comments.skip,
  limit: comments.limit
});
const withState = connect(mapStateToProp, dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  setReadIndicationForItem: items => dispatch(setReadIndicationForItem(items)),
  onItemPress: user => dispatch(toggleUserProfileModal(!!user, user.id)),

}));

const withMutation = graphql(createUpdateCommentMutation, {
  props: ({ ownProps, mutate }) => ({
    createUpdateComment: (userId, commentId, comment) =>
      mutate({
        variables: {
          userId,
          commentId,
          comment
        }
      })
  }),
  // options: {
  //   refetchQueries: ['Feed']
  // }
});

export const withDeleteComment = graphql(deleteCommentMutation, {
  props: ({ mutate }) => ({
    deleteComment: commentId => mutate({ variables: { commentId } })
  }),
  options: {
    refetchQueries: ['Feed']
  }
});

const withUpdateLikesMutation = graphql(updatePostLikes, {
  props: ({ ownProps, mutate }) => ({
    updatePostLikes: (itemId, likes) => mutate({ variables: { itemId, likes } })
  }),
  options: {
    refetchQueries: ['Feed']
  }
});

export const FeedNewsItem = compose(
  withMutation,
  withUpdateLikesMutation,
  withState,
  withDeleteComment
)(FeedNewsItemView);
