import gql from 'graphql-tag';

export const createVideoLogMutation = gql`
  mutation createVideoLog(
    $userId: ID,
    $videoId: ID,
    $startedAt: String,
    $isFinished: Boolean,
    $category: String,
    $type: String,
    $videoData: String,
    $videoDuration: String,
    $videoLabel: String,
    $videoName: String
  ) {
    createVideoLog(
        userId: $userId,
        videoId: $videoId,
        startedAt: $startedAt,
        isFinished: $isFinished,
        category: $category,
        type: $type,
        videoData: $videoData,
        videoDuration: $videoDuration,
        videoLabel: $videoLabel,
        videoName: $videoName,
    ) {
        _id
    }
  }
`;
