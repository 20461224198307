import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../assets/locales/localization';
import styled from 'styled-components';
import { Theme } from '../../common/providers/colors';

const DIGITS = '0123456789';

export class VerifyCodeView extends Component {
  static propTypes = {
    doVerify: PropTypes.func.isRequired,
    cancelEnroll: PropTypes.func.isRequired,
    verificationError: PropTypes.bool,
    codeItems: PropTypes.array.isRequired,
    resetForm: PropTypes.func.isRequired,
    setCode: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.codeInputs = [];
    this.state = {
      cancelVerificationOpen: false
    };
  }

  toggleCancelVerificationModal(open) {
    this.setState({
      cancelVerificationOpen: open
    });
  }

  jumpInput(index, isNext = true) {
    const toIndex = index + (isNext ? 1 : -1);

    setTimeout(() => {
      if (this.codeInputs[toIndex]) {
        this.codeInputs[toIndex].focus();
      } else if (index > 0) {
        this.codeInputs[index].blur();
      }
    }, 1);
  }

  onCodeDigitInput(digit, index) {
    if (DIGITS.indexOf(digit) === -1) {
      return;
    }

    const isEmpty = digit === '';

    if (this.props.codeItems[index] === null && !isEmpty) {
      this.onValueChange(digit, index);
      this.jumpInput(index, true);
    } else if (this.props.codeItems[index] !== null && !isEmpty) {
      this.onValueChange(digit, index);
      this.jumpInput(index, true);
    } else if (isEmpty && this.props.codeItems[index] === null) {
      this.jumpInput(index, false);
    } else if (isEmpty && this.props.codeItems[index] !== null) {
      this.onValueChange(null, index);
      this.jumpInput(index, false);
    }
  }

  onValueChange(value, index) {
    let newValue = value;
    if (newValue === '') {
      newValue = null;
    }
    const code = this.props.codeItems.slice(0);
    code[index] = newValue;
    this.props.setCode(code);
  }

  isInvalidCode() {
    return this.props.codeItems.filter(item => item).length < 4;
  }

  verifyCode() {
    this.props.doVerify(this.props.currentUser.id, this.props.codeItems.join(''));
  }

  cancelVerification() {
    this.props.cancelEnroll();
    this.toggleCancelVerificationModal(false);
    this.props.resetForm();
  }

  render() {
    return (
      <div style={{ alignItems: 'center', justifyContent: 'center' }}>
        {/* <ConfirmationModal
          title= {strings.verifyCodeView.classmates}
          question= {strings.verifyCodeView.continue}
          onClose={() => this.toggleCancelVerificationModal(false)}
          open={this.state.cancelVerificationOpen}
          options={[
            {
              name: strings.verifyCodeView.yes,
              onPress: () => this.cancelVerification(),
              color: Theme.ACTIVE2_COLOR,
              iconName: 'frown-o',
            },
            {
              name: strings.verifyCodeView.no,
              onPress: () => this.toggleCancelVerificationModal(false),
              color: Theme.ACTIVE1_COLOR,
              iconName: 'smile-o',
            },
          ]} /> */}
        <span style={styles.description}> {LangData.verifyCodeView.staff}</span>
        <span style={styles.note}> {LangData.verifyCodeView.digits}</span>
        {this.props.verificationError ? (
          <span style={styles.errorText}> {LangData.verifyCodeView.error}</span>
        ) : null}
        <div style={styles.digitsContainer}>
          {new Array(4).fill('').map((value, index) => (
            <TextInput
              type='text'
              selectTextOnFocus={true}
              autoCapitalize='none'
              autoCorrect={false}
              keyboardType='numeric'
              value={this.props.codeItems[index] || ''}
              key={String(index)}
              ref={ref => (this.codeInputs[index] = ref)}
              maxLength={1}
              onChange={e => this.onCodeDigitInput(e.target.value, index)}
              placeholder=''
              underlineColorAndroid='transparent'
              // style={styles.digitInput}
              autoFocus={false}
            />
          ))}
        </div>
        <span style={styles.note}>{LangData.verifyCodeView.message}</span>
        <span
          style={styles.cancelNote}
          onPress={() => this.toggleCancelVerificationModal(true)}
        >
          {LangData.verifyCodeView.wrong}{' '}
        </span>
        <div
          disabled={this.isInvalidCode()}
          style={styles.button}
          onClick={() => this.verifyCode()}
        >
          <span
            style={[
              styles.buttonText,
              this.isInvalidCode() ? { opacity: 0.7 } : {}
            ]}
          >
            {' '}
            {LangData.verifyCodeView.verify}
          </span>
        </div>
      </div>
    );
  }
}

const styles = {
  buttonText: {
    color: 'white',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontWeight: 'bold'
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 20,
    backgroundColor: '#FFC412',
    marginTop: 20
  },
  digitsContainer: {
    flexDirection: 'row'
  },
  description: {
    textAlign: 'center',
    fontWeight: '400',
    margin: 10
  },
  note: {
    textAlign: 'center',
    fontWeight: '400',
    margin: 3,
    fontSize: 11,
    color: Theme.TEXT_COLOR,
    fontStyle: 'italic'
  },
  cancelNote: {
    textAlign: 'center',
    fontWeight: '400',
    margin: 3,
    fontSize: 11,
    color: '#FFC412',
    fontStyle: 'italic'
  },
  errorText: {
    textAlign: 'center',
    fontWeight: '400',
    margin: 10,
    fontSize: 11,
    color: '#FFC412',
    fontStyle: 'italic'
  },
  digitInput: {
    marginHorizontal: 10,
    textAlign: 'center',
    textAlignVertical: 'center',
    color: '#aaaaaa',
    fontSize: 30,
    paddingTop: 0,
    paddingBottom: 0,
    width: 40,
    height: 50,
    borderWidth: 1,
    borderColor: '#aaaaaa',
    borderRadius: 15,
    marginBottom: 10
  },
  inputError: {
    borderColor: '#FFC412',
    backgroundColor: '#FFC412',
    color: 'white'
  }
};

const TextInput = styled.input`
    text-align: center;
    text-align-vertical: center;
    color: #aaaaaa;
    font-size: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 40px;
    height: 50px;
    border-width: 1;
    border-color: #aaaaaa,
    border-radius: 15px;
    margin-bottom: 10px;

  &:focus {
    outline: none;
    border: none;
  }
`;
