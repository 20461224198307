import React, { Component } from 'react';
import { refreshByUserId } from '../../account/account.util';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { Videos } from './videos.container';
import { Theme } from '../../common/providers/colors';

const width = window.innerWidth


class WrapVideosView extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loadData: false
        }
    }


    componentWillMount = async () => {
        this.setState({ loadData: true})
        console.log('props state', this.props.match.params)
        const { userId } = this.props.match.params
        let user = await refreshByUserId(userId);
        console.log('user1 =>', user)
        if (user) {
          this.props.setUser(user);
          console.log('update user!')
        }
        this.setState({ loadData: false})
        this.props.history.push({
            pathname: '/videos',
            state: {
              yocasType: 'session'
            }
          });
    }

    render() {
        if(this.state.loadData) return (
            <LoadingSpinner />
        )
        else return (
            <div style={{
                width: window.innerWidth,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: window.innerHeight,
                backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.95), rgb(237,204,181,0.95))',
                color: Theme.TEXT_COLOR
            }}>
            </div>
        )
    }
    
}

export default WrapVideosView;

