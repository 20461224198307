import { graphql } from 'react-apollo-temp';
import { connect } from 'react-redux';
import { withEditEntity } from './with-edit-feed-item.container';
import { TargetPickerFormView } from './target-picker-form.component';
import { updateNewsFeedItemTargetsQuery } from '../graphql/update-news-feed-item-targets.mutation';
import { publicationTargetsQuery } from '../graphql/publication-targets.query';
import { compose } from 'redux';

const mapDispatch = dispatch => ({
  // navigate: route => dispatch(push(route)),
});

const withState = connect(
  ({ router }) => ({
    // currectLocation: router.location.pathname,
  }),
  mapDispatch
);

const withData = graphql(publicationTargetsQuery, {
  name: 'targets',
  options: {
    fetchPolicy: 'network-only'
  }
});

const withMutation = graphql(updateNewsFeedItemTargetsQuery, {
  props: ({ ownProps, mutate }) => ({
    updateTargets: (itemId, targets) =>
      mutate({ variables: { itemId, targets } })
  })
});

export const TargetPickerForm = compose(
  withState,
  withData,
  withMutation,
  withEditEntity
)(TargetPickerFormView);
