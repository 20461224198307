import React, { useEffect, useState, Fragment } from 'react';
import CategoryImage from './category-image.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../common/providers/colors';

const CategoryGridItem = props => {
  const [category, setCategory] = useState(props.category);
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  function renderCheck() {
    if (!active) {
      return null;
    }

    return <FontAwesomeIcon icon={faCheck} style={styles.checkIcon} />;
  }

  return (
    <Fragment>
      {category ? (
        <div style={styles.itemMargin} onClick={() => props.onPress()}>
          <div
            style={
              active
                ? styles.categoryContainerSelected
                : styles.categoryContainer
            }
          >
            <CategoryImage category={category} />
            {renderCheck()}
          </div>
          <p style={styles.categoryName}>{category.name}</p>
        </div>
      ) : null}
    </Fragment>
  );
};
export default CategoryGridItem;

const styles = {
  itemMargin: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px'
  },
  categoryName: {
    textAlign: 'center',
    color: Theme.TEXT_COLOR,
    fontSize: 15,
    marginTop: 3,
    fontWeight: 'bold'
  },
  categoryContainer: {
    height: 50,
    width: 50,
    borderRadius: 10,
    overflow: 'hidden',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  categoryContainerSelected: {
    border: '2px solid white',
    borderRadius: '10px',
    position: 'relative'
  },
  categoryContainer: {
    // border: `2px solid ${Theme.TEXT_COLOR}`
  },
  checkIcon: {
    position: 'absolute',
    bottom: '1px',
    right: '2px',
    color: Theme.SECONDARY_TEXT_COLOR
  }
};
