import { graphql } from 'react-apollo-temp';
import update from 'immutability-helper';
import { FeedPollItemView } from './feed-poll-item.rn';
import { answerPollMutation } from '../../../graphql/answer-poll.mutation';
import { deleteCommentMutation } from '../../../graphql/delete-comment.mutation';
import { setReadIndicationForItem } from '../../../news-feed.actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createUpdateCommentMutation } from '../../../graphql/create-update-comment.mutation';
import { updatePostLikes } from '../../../graphql/update-event-likes';

const withData = graphql(answerPollMutation, {
  props: ({ mutate }) => ({
    answerPoll: (userId, poll, answerIndex) =>
      mutate({
        variables: {
          userId,
          pollId: poll.id,
          answerIndex
        },
        updateQueries: {
          Feed: (
            prev,
            {
              mutationResult: {
                data: { answerPoll }
              }
            }
          ) => {
            const itemIndex = prev.feedList.items.findIndex(
              item => item.id === poll.id
            );

            return update(prev, {
              feedList: {
                items: {
                  [itemIndex]: {
                    $set: {
                      ...poll,
                      possibleAnswers: answerPoll,
                      userAnswered: answerIndex
                    }
                  }
                }
              }
            });
          }
        }
      })
  })
});
const mapStateToProp = ({ auth, comments }) => ({
  currentUser: auth.currentUser,
  skip: comments.skip,
  limit: comments.limit
});
const withState = connect(mapStateToProp, dispatch => ({
  setReadIndicationForItem: items => dispatch(setReadIndicationForItem(items))
}));

export const withDeleteComment = graphql(deleteCommentMutation, {
  props: ({ mutate }) => ({
    deleteComment: commentId => mutate({ variables: { commentId } })
  }),
  options: {
    refetchQueries: ['Feed']
  }
});

const withMutation = graphql(createUpdateCommentMutation, {
  props: ({ ownProps, mutate }) => ({
    createUpdateComment: (userId, commentId, comment) =>
      mutate({
        variables: {
          userId, 
          commentId,
          comment
        }
      })
  }),
  // options: {
  //   refetchQueries: ['Feed']
  // }
});

const withUpdateLikesMutation = graphql(updatePostLikes, {
  props: ({ ownProps, mutate }) => ({
    updatePostLikes: (itemId, likes) => mutate({ variables: { itemId, likes } })
  }),
  options: {
    refetchQueries: ['Feed']
  }
});

export const FeedPollItem = compose(
  withMutation,
  withUpdateLikesMutation,
  withData,
  withState,
  withDeleteComment
)(FeedPollItemView);
