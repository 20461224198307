import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Theme } from '../../common/providers/colors';

const Alert = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <Fade bottom duration={500}>
      <div key={alert.id} style={msgStyle}>
        {alert.msg}
      </div>
    </Fade>
  ));

Alert.propTypes = {
  alerts: propTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);

const msgStyle = {
  width: '100%',
  position: 'fixed',
  bottom: 0,
  backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
  height: '40px',
  color: Theme.TEXT_COLOR,
  fontWeight: 'bold',
  fontSize: '15px',
  zIndex: '99999',
  paddingTop: '10px',
  textAlign: 'center'
};
