import { graphql } from 'react-apollo-temp';
import { uploadToCloudinaryMutation } from '../graphql/upload-to-cloudinary.mutation';
import { getDataFromFile } from '../../cloudinary/utils/upload';

export const withImageUploadMutation = graphql(uploadToCloudinaryMutation, {
  props: ({ ownProps, mutate }) => ({
    uploadFile: (fsFile, tag, fileData, filePath, publicId, update) =>
      mutate({
        variables: {
          ...getDataFromFile(fsFile),
          filePath: filePath,
          publicId: publicId,
          tag
        },
        optimisticResponse: {
          __typename: 'Mutation',
          uploadToCloudinary: {
            id: `upload_temp_id_${Date.now().toString()}`,
            filePath: `data:image/jpeg;base64,${fsFile.data}`,
            __typename: 'CloudinaryFileUpload'
          }
        },
        update
      })
  })
});
