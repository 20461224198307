import React, { useState, Fragment, useEffect } from 'react';
import ProfileImage from './profile-image.rn';
import { Theme } from '../../common/providers/colors';
import { LangData } from '../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons';

const ProfileCard = props => {
  const [icon, setIcon] = useState(null);
  const [user, setUser] = useState(null);
  const [emphasisColor, setEmphasisColor] = useState(Theme.TEXT_COLOR);

  useEffect(() => {
    setUser(props.user);

    if (props.user.friendRequestStatus === 'APPROVED') {
      setEmphasisColor(Theme.SECONDARY_BACKGROUND_COLOR);
      setIcon(faUserFriends);
    } else if (
      props.user.friendRequestStatus === 'PENDING' ||
      props.user.friendRequestStatus === 'SENT'
    ) {
      setEmphasisColor('#aaaaaa');
      setIcon(faUser);
    }
  }, [props]);

  return (
    <Fragment>
      {user && (
        <div
          id='firstDivOnProfileCard'
          onClick={() => props.onPress && props.onPress()}
          //   onClose={props.onClose}
          //   disabled={!props.onPress}
          //   containerStyle={[styles.card, props.containerStyle || {}]}
          style={styles.parent}
        >
          <div style={styles.imageContainer} id='secondDivOnProfileCard'>
            <ProfileImage
              showBorder={true}
              borderColor={emphasisColor}
              withFullSize={true}
              imageUrl={user.profilePictureUrl}
              fullSizeUrl={user.profilePictureUrl}
              size={70}
              history={props.history}
            />
            {icon ? (
              <div
                style={Object.assign(
                  { borderColor: emphasisColor, color: emphasisColor },
                  styles.iconContainer
                )}
              >
                <FontAwesomeIcon icon={icon} />
              </div>
            ) : null}
          </div>

          <p style={styles.nameText}>{user.name}</p>
          <p style={styles.majorText}>{user.major}</p>
          {user.friendsSince && (
            <p style={styles.friendsSince}>
              {LangData.profileCard.date + ' ' + user.friendsSince}
            </p>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ProfileCard;

const styles = {
  card: {
    marginHorizontal: 0
  },
  parent: {
    backgroundColor: 'whitesmoke',
    borderRadius: '20px',
    margin: '7%',
    padding: '5%',
    height: window.innerWidth / 1.6 + 'px'
  },
  nameText: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
    paddingBottom: 2,
    color: Theme.TEXT_COLOR
  },
  majorText: {
    textAlign: 'center',
    color: Theme.TEXT_COLOR,
    fontSize: 13,
    marginVertical: 5,
    minHeight: 20
  },
  friendsSince: {
    color: Theme.TEXT_COLOR,
    fontSize: 13,
    marginTop: 3,
    marginBottom: '15%',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  imageContainer: {
    position: 'relative',
    height: window.innerWidth / 4.7 + 'px',
    width: window.innerWidth / 4.7 + 'px',
    margin: '0 auto',
    marginTop: '10%'
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderWidth: 1,
    borderRadius: 12,
    backgroundColor: 'white',
    bottom: '5px',
    left: '60px',
    position: 'absolute',
    border: '1px solid'
  }
};
