import React, { Component } from 'react';
import './meetings.css';

const jitsiContainerId = "jitsi-container-id";


export class Video extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillMount = () => {
        console.log("props video =>", this.props.match.params.name)
        let meetingName = this.props.match.params.name
        let username = this.props.match.params.username
        this.initialiseJitsi(meetingName, username)
    }

    loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise(resolve => {
          resolveLoadJitsiScriptPromise = resolve;
        });
        const script = document.createElement("script");
        script.src = "https://meet.jit.si/external_api.js";
        script.async = true;
        script.onload = () => resolveLoadJitsiScriptPromise(true);
        document.body.appendChild(script);
        return loadJitsiScriptPromise;
    }

    initialiseJitsi = async (meetingName, username) => {
        console.log('room in jitsi =>', meetingName)
        if (!window.JitsiMeetExternalAPI) {
          await this.loadJitsiScript();
        }
     


        let roomName = meetingName
        roomName = roomName.replace(/'/g, "")
        roomName = roomName.replace(/&/g, "and")


        const _jitsi = new window.JitsiMeetExternalAPI("v.dualitmeestdomian.com", {
          parentNode: document.getElementById(jitsiContainerId),
          roomName,
          userInfo: {
            displayName: username
          },
          configOverwrite: {
            disableAudioLevels: true,
          },
          interfaceConfigOverwrite: {
            TOOLBAR_ALWAYS_VISIBLE: true
          },
          onload: () => {
            console.log('video loaded!')
          },
        });

    

        this.setState({ jitsi:_jitsi });

    }

    render(){
        return (
            <div className="video-container">
                 <div 
                        id={jitsiContainerId} 
                        style={{ 
                            height: "100%" , 
                            position:"fixed", 
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            // zIndex: 1020,
                        }} 
                    >
                
                    </div>
            </div>
        )
    }
}