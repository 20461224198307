import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faChevronUp,
  faChevronDown,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { LangData } from '../../assets/locales/localization';
import { Theme } from '../../common/providers/colors';

const ScheduleDrawerView = props => {
  const [isDrawerFullHeight, setIsDrawerFullHeight] = useState(false);
  const [isSearchDisplay, setIsSearchDisplay] = useState(false);

  const toggleDrawerHeight = () => {
    setIsDrawerFullHeight(!isDrawerFullHeight);
  };

  const toggleSearchDisplay = () => {
    setIsSearchDisplay(!isSearchDisplay);
  };

  return (
    <Fragment>
      <div
        style={
          props.currentComponent !== 'courses' || props.activeCourse === null
            ? styles.classMateConatinerClose
            : isDrawerFullHeight
            ? styles.classMateConatinerOpen
            : styles.classMateConatinerHalfOpen
        }
      >
        <div style={styles.searchRow}>
          <div
            styles={styles.searchIconContainer}
            onClick={toggleSearchDisplay}
          >
            <FontAwesomeIcon
              icon={isSearchDisplay ? faTimes : faSearch}
              style={styles.serachIcon}
            />
          </div>
          <div style={styles.middleContainer}>
            {props.activeCourse && !isSearchDisplay && (
              <p style={styles.className}>
                {LangData.classmatesDrawer.classmates.toUpperCase() +
                  ' ' +
                  props.activeCourse.name}
              </p>
            )}

            {props.activeCourse && isSearchDisplay && (
              <input
                type='text'
                placeholder={LangData.classmatesDrawer.find}
                style={styles.input}
              />
            )}
          </div>
          <div styles={styles.arrowIconConatiner} onClick={toggleDrawerHeight}>
            <FontAwesomeIcon
              icon={isDrawerFullHeight ? faChevronDown : faChevronUp}
              style={styles.serachIcon}
            />
          </div>
        </div>
        <div style={styles.classMates}></div>
      </div>
    </Fragment>
  );
};

export default ScheduleDrawerView;

const styles = {
  classMateConatinerClose: {
    display: 'none'
  },
  classMateConatinerOpen: {
    backgroundColor: 'white',
    position: 'fixed',
    bottom: '0',
    zIndex: '88',
    height: window.innerWidth / 0.6369 + 'px',
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'height 1s ease'
  },
  classMateConatinerHalfOpen: {
    backgroundColor: 'white',
    position: 'fixed',
    bottom: '0',
    zIndex: '88',
    height: window.innerWidth / 2.435 + 'px',
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    WebkitBoxShadow: '0px -3px 3px rgba(50, 50, 50, 0.75)',
    MozBoxShadow: '0px -3px 3px rgba(50, 50, 50, 0.75)',
    boxShadow: '0px -3px 3px rgba(50, 50, 50, 0.75)',
    transition: 'height 1s ease'
  },
  searchRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    color: Theme.TEXT_COLOR,
    padding: '1%',
    fontSize: window.innerWidth / 23 + 'px',
    borderBottom: `2px solid ${Theme.TEXT_COLOR}`,
    fontWeight: 'bold'
  },
  middleContainer: {
    width: '90%'
  },
  searchIcon: {
    transition: 'display 1s ease'
  },
  className: {
    textAlign: 'center',
    margin: '0'
  },
  input: {
    width: '100%',
    fontFamily: 'inherit',
    color: Theme.SECONDARY_TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: '15px'
  }
};
