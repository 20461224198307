import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const connectMutation = gql`
  mutation connect($currentUserId: ID, $userId: String!) {
    connect(currentUserId:$currentUserId, userId: $userId) {
      ...ClassmateItem
    }
  }

  ${classmateItemFragment}
`;
