import { NotificationsListView } from './notifications-list.rn';
import { graphql, withApollo } from 'react-apollo-temp';
import { myNotificationsQuery } from '../../graphql/notifications.query';
import update from 'immutability-helper';
import { compose } from 'redux';
import { setNotificationsReadStateMutation } from '../../graphql/notify-read-state.mutation';
import { unreadNotificationCountQuery } from '../../graphql/unread-notifications-count.query';
// import { chatWithUser } from '../../../chats/chat.actions';
import { toggleUserProfileModal } from '../../../profile/profile.actions';
import { connect } from 'react-redux';
import { withErrorHandler } from '../../../common/hoc/error-handler.rn';
import { toggleComponent } from '../../../router/router.actions';

const withSetNotificationsReadState = graphql(
  setNotificationsReadStateMutation,
  {
    props: ({ ownProps, mutate }) => ({
      notifyReadStatus: (userId, notificationId) =>
        mutate({
          variables: {
            userId, 
            notificationId
          },
          // update: (store, { data: setNotificationsReadState }) => {
          //   store.writeQuery({
          //     query: unreadNotificationCountQuery,
          //     variables: {},
          //     data: {
          //       unreadNotificationsCount:
          //         setNotificationsReadState.setNotificationsReadState
          //     }
          //   });
          // }
        })
    })
  }
);

const withMyNotifications = graphql(myNotificationsQuery, {
  name: 'notifications',
  options: (props) => {
    return {
      variables: {
        userId: props.currentUser ?  props.currentUser.id : null,
        cursor: null
      },
      fetchPolicy: 'network-only'
    }
  },
  props: ({ notifications, currentUser }) => {
    const loadOlder = () => {
      if (
        notifications &&
        notifications.notifications &&
        notifications.notifications.cursor
      ) {
        notifications.fetchMore({
          variables: {
            cursor: notifications.notifications.cursor,
            userId: currentUser? currentUser.id : null
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              fetchMoreResult.notifications &&
              fetchMoreResult.notifications.items
            ) {
              const target =
                prev && prev.notifications
                  ? prev
                  : {
                      notifications: { items: [], hasMore: false, cursor: null }
                    };

              return update(target, {
                notifications: {
                  cursor: { $set: fetchMoreResult.notifications.cursor },
                  hasMore: { $set: fetchMoreResult.notifications.hasMore },
                  items: {
                    $push: Array.from(fetchMoreResult.notifications.items)
                  }
                }
              });
            }

            return prev;
          }
        });
      }
    };

    const reload = () =>
      notifications.refetch({
        cursor: null
      });

    if (!notifications) {
      return {
        loading: false,
        notifications: [],
        loadOlder,
        reload
      };
    }

    return {
      loading: notifications.networkStatus === 1,
      notifications:
        notifications &&
        notifications.notifications &&
        notifications.notifications.items
          ? notifications.notifications.items
          : [],
      loadOlder,
      reload
    };
  }
});

const withError = withErrorHandler({
  apollo: ['notifications']
});

const mapStateToProps = ({ navigation, profile, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  profile: profile.userProfileModal,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleModal: (isOpen, userId) =>
    dispatch(toggleUserProfileModal(isOpen, userId)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const NotificationsList = compose(
  withMyNotifications,
  withState,
  withSetNotificationsReadState,
  withError,
  withApollo
)(NotificationsListView);
