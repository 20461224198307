import React, { useEffect, useState, useRef } from 'react';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import EmptyNotice from '../../common/components/empty-notice.rn';
import { FriendsSuggestions } from './friends-suggestions.container';
import {
  EFriendsListActiveState,
  EFriendsListFilterType
} from '../friends.actions';
import ProfileCard from '../../common/components/profile-card.rn';
import { LangData } from '../../assets/locales/localization';
import { Theme } from '../../common/providers/colors';
import logoSrc from '../../assets/images/Logo1.png';
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  AutoSizer
} from 'react-virtualized';
import styled from 'styled-components';
import { friendAddedSubscription } from '../graphql/friend-added.subscription';
import { friendRemovedSubscription } from  '../graphql/friend-removed.subscription'
import { apolloClient } from '../../apollo-subs';
import { getUserById } from '../../news-feed/graphql/get-user-by-id';

const width= window.innerWidth
 
const FriendsGridView = props => {
  const [userIdModal, setUserIdModal] = useState(null);
  const [users, setUsers] = useState(null);
  const [currentListSize, setCurrentListSize]  =  useState(0)
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [skipCount, setSkipCount] = useState(0);
  const [loadData, setLoadData] = useState(false);
  const [showButton, setShowButton] = useState(false);
  let infiniteLoaderRef = useRef(null);
  let listRef = useRef(null);
  let cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 70
  });


  const subscribeToNewUsers = () => {
    apolloClient.subscribe({
      query: friendAddedSubscription,
      variables: {}
    })
    .subscribe({
      next: async (data) => {
        console.log('friend added!', data)
        let newConnection = data.friendAdded
        let currentId = localStorage.getItem('userId')

        if(newConnection.userAdded.toString() === currentId.toString() ||
        newConnection.addedBy.toString() === currentId.toString()) {
          let otherUserId = newConnection.addedBy.toString() === currentId.toString() ? newConnection.userAdded : newConnection.addedBy
          let res = await apolloClient.mutate({
            mutation: getUserById,
            variables: { userId: otherUserId.toString() }
          })
          console.log('res =>', res)
          if(users) {
            let cpy = [...users]
            let idx = cpy.findIndex(user => user.id.toString() === otherUserId.toString())
            if(idx === -1) setUsers(prevState => [res.data.userById, ...prevState])
          }
  
        }
      }
    })
  }

  const subscribeToRemovedUsers = () => {
    apolloClient.subscribe({
      query: friendRemovedSubscription,
      variables: {}
    })
    .subscribe({
      next: (data) => {
        console.log('friend removed!', data)
        let removedConnection = data.friendRemoved
        let currentId = localStorage.getItem('userId')
        if(removedConnection.userAdded.toString() ===currentId.toString() ||
        removedConnection.addedBy.toString() === currentId.toString()) {
          let otherUserId = removedConnection.addedBy.toString() === currentId.toString() ? removedConnection.userAdded : removedConnection.addedBy
          console.log('users =>', users, props.connections.myConnections)
          if(users) {
            let cpy = [...users]
            console.log('cpy =>', cpy)

            let idx = cpy.findIndex(user => {
              console.log('user.id', user.id)
              console.log('otheruser.id', otherUserId)
              return user.id.toString() === otherUserId.toString()})
            console.log('idx =>', idx)
            if(idx > -1) {
              cpy.splice(idx, 1)
              setUsers([...cpy])
            }
          }
          else {
            let cpy = props.connections.myConnections ? [...props.connections.myConnections] : []
            let idx = cpy.findIndex(user => user.id.toString() === otherUserId.toString())
            if(idx > -1) {
              cpy.splice(idx, 1)
              setUsers([...cpy])
            }
          }
        }
      }
    })
  }

  useEffect(() => {
    console.log('users in use effect =>', users)
    subscribeToNewUsers()
    subscribeToRemovedUsers()
  }, 
  [users])


  const normalizeImgSrc = (src) => {
    let newSizeSrc = src.replace("h_100,w_100", "h_60")
    return newSizeSrc
  }





  useEffect(() => {
    console.log('props.isGlobalSearch', props.isGlobalSearch)
    console.log('props after', props)
  
    if (props.isGlobalSearch || props.activeState === "GLOBAL_SEARCH") {
      console.log('inside global!')
      setUsers(
        (props.searchResults &&
          props.searchResults.searchStudents &&
          props.searchResults.searchStudents.items) ||
          []
      );

      console.log('props.searchResults', props.searchResults)

      setLoading(props.searchResults.networkStatus === 1);
      setHasMore(
        props.searchResults &&
          props.searchResults.searchStudents &&
          props.searchResults.searchStudents.hasMore
      );
    } else {
      if (props.connections && props.connections.myConnections) {
        setUsers(props.connections.myConnections);
        setCurrentListSize(props.connections.myConnections)
      }
      setLoading(props.connections.networkStatus === 1);
    }
  }, [props]);



  useEffect(() => {
    if(!props.isGlobalSearch) {
      if(props.connections.myConnections) {
        setUsers(props.connections.myConnections)
        console.log('users', props.connections.myConnections)
      }
      if(props.connections.myConnections &&props.connections.myConnections.length !== currentListSize) {
        setLoadMore(false);
        setCurrentListSize(props.connections.myConnections.length)
      }
      if (
        props.connections.myConnections &&
        props.connections.myConnections.length % 10 !== 0
      ) {
        setLoadMore(false);
      }
    }
   
  }, [props.connections]);

  

  function renderItem(item) {
    return (
     
      <div
        key={item.id}
        onClick={() => {
          setUserIdModal(item.id);
          props.onItemPress(item);
          props.toggleComponent('UserProfile');
          props.history.push('userProfile', {user: item});
        }}
        style={styles.friendContainer}
      >
        <div style={styles.imageContainer}>
          {
            item.profilePictureUrl&&
            <img
            src={normalizeImgSrc(item.profilePictureUrl)}
            style={styles.profileImg}
            alt=""
          />
          }
        </div>
        <div style={styles.usernameContainer}>
          <p style={styles.username}>{item.name}</p>
        </div>
      </div>
    );
  }

  function renderNote(totalItems) {
    if (totalItems === 0) {
      return null;
    }

    let text = '';
    if (props.activeState === EFriendsListActiveState.GLOBAL_SEARCH) {
      text =
        LangData.friendsGrid.totalOf +
        ' ' +
        totalItems +
        ' ' +
        LangData.friendsGrid.students;
    } else if (props.activeState === EFriendsListActiveState.FRIENDS_LIST) {
      text =
        LangData.friendsGrid.totalOf +
        ' ' +
        totalItems +
        ' ' +
        LangData.friendsGrid.connection;
    }
    if (text === '') {
      return null;
    }
    return <p style={styles.noteText}>{text}</p>;
  }

  function renderLoadMore(text = LangData.friendsGrid.search) {
    return (
      <button style={styles.renderMoreBtn} onClick={() => props.lookForMore()}>
        {text}
      </button>
    );
  }

  function renderNoItemsNote(totalItems, loading, hasMore) {
    if (totalItems > 0 || loading) {
      return null;
    }

    if (props.activeState === EFriendsListFilterType.IGNORED) {
      return (
        <EmptyNotice
          title={LangData.friendsGrid.title}
          text={LangData.friendsGrid.looks}
          emoji='😎'
        />
      );
    } else if (props.activeState === EFriendsListFilterType.PENDING) {
      return (
        <EmptyNotice
          title={LangData.friendsGrid.pending}
          text={LangData.friendsGrid.expand}
          emoji='😮'
        />
      );
    } else if (props.activeState === EFriendsListFilterType.SENT) {
      return (
        <EmptyNotice
          title={LangData.friendsGrid.sent}
          text={LangData.friendsGrid.expand}
        />
      );
    } else if (props.activeState === EFriendsListActiveState.GLOBAL_SEARCH) {
      if (hasMore) {
        return (
          <div style={{ flex: 1 }}>
            <EmptyNotice
              title={LangData.friendsGrid.noFound}
              text={LangData.friendsGrid.sorryLong}
              subTitle={LangData.friendsGrid.broaden}
              emoji='😥'
            />
            {renderLoadMore()}
          </div>
        );
      }

      return (
        <EmptyNotice
          title={LangData.friendsGrid.noFound}
          text={LangData.friendsGrid.sorry}
          emoji='😥'
        />
      );
    } else if (props.activeState === EFriendsListActiveState.FRIENDS_LIST) {
      return (
        <div style={{ flex: 1 }}>
            <EmptyNotice
            containerStyle={{ justifyContent: 'flex-start' }}
            title={LangData.friendsGrid.snob}
            text={LangData.friendsGrid.expand}
            emoji='😮'
          />
          <FriendsSuggestions history={props.history}/>
        
        </div>
      );
    }

    return null;
  }

  const isBottom = (element) => {
    return element.scrollHeight - element.scrollTop === element.clientHeight
  }

  const handleScroll = e => {
    const wrappedElement = document.getElementById('profileCardsContainer');
    let bottom = isBottom(wrappedElement)
    if (bottom) {
      // setShowButton(true)
      loadMoreClicked()
      console.log('hasMore', hasMore)
      // let currentSkip = skipCount
      // props.loadMoreConnectionsData(currentSkip + 10);
      // setSkipCount(currentSkip + 10)
    }
  };

  const loadMoreClicked = () => {
    setShowButton(false)
    setLoadMore(true)
    let currentSkip = skipCount
    props.loadMoreConnectionsData(currentSkip + 10);
    setSkipCount(currentSkip + 10)
    

  }



  return (
    <div style={{ width: '100%', marginTop: '5%' }}>
      {loading ? (
        null//<LoadingSpinner />
      ) : users ? (
        <div style={styles.container}>
          {/* {hasMore &&
            users.length > 0 &&
            renderLoadMore(LangData.friendsGrid.broad)} */}
          {renderNoItemsNote(users.length, loading, hasMore)}
          {users.length > 0 && (
            <div
              style={{...styles.profileCardsContainer, height: window.innerHeight * 0.65 + 25}}
              id='profileCardsContainer'
              onScroll={e => handleScroll(e)}
            >
              {users.map((item, index) => {
                return renderItem(item);
              })}

            </div>
          )}
              {/* { !loadMore && showButton && <div style={styles.buttonContainer}><CustomButton onClick={loadMoreClicked}>Load More</CustomButton></div>} */}

        </div>

   
      ) : null}
    </div>
  );
};

export default FriendsGridView;
const styles = {
  profileCardsContainer: {
    width: width < 500 ? '100%' : '102%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    overflowY: 'scroll'
  },

  list: {
    paddingBottom: 120,
    flexGrow: 1
  },
  container: {
    width: '100%',
    // height: window.innerHeight * 0.9,
    overflowY: 'none'
  },
  noteText: {
    color: 'white',
    textAlign: 'center',
    fontSize: 15,
    marginTop: 10
  },
  renderMoreBtn: {
    textAlign: 'center',
    color: Theme.TEXT_COLOR,
    backgroundColor: Theme.BACKGROUND_COLOR,
    fontWeight: 'bold',
    fontFamily: 'inherit',
    fontSize: '14px',
    padding: '1%',
    borderRadius: '20px',
    width: '70%',
    border: '2px solid white',
    margin: '0 auto',
    display: 'block'
  },
  friendContainer: {
    width: '95%',
    maxHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2%'
  },
  imageContainer: {
    width: '30%',
    maxHeight: '60px',
    backgroundColor: '#e0c2b0',
    height: '60px',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '15px 0 0 15px'
  },
  usernameContainer: {
    width: '70%',
    backgroundColor: '#4d4b62',
    color: Theme.WHITE_COLOR,
    borderRadius: '0px 15px 15px 0px',
    height: '60px'
  },
  profileImg: {
    maxHeight: '60px',
    width: '100%',
    borderRadius: '15px 0px 0px 15px',
    objectFit: 'fill'
  },
  username: {
    textAlign: 'left',
    marginLeft: '10%',
    fontWeight: 'bold'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: '2%'
  }
};

const CustomButton = styled.button`
  background-color: ${Theme.DARK_BLUE_COLOR};
  justify-content: center;
  align-items: center;
  border: none;
  color: ${Theme.WHITE_COLOR};
  font-weight: bold;
  border-radius: 15px;
  padding: 1% 4%;
  height: 30px;
  white-space: nowrap;
  text-align: center;

  &:focus {
    outline: none;
    border: none;
  }
`;