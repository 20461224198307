import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const FeedNewsItemFragment = gql`
  fragment FeedNewsItem on NewsFeedItemType {
    id
    title
    description
    posterImageUrl
    thumbUrl
    link
    creationDate
    displayCreationDate
    categories {
      id
      name
      icon
    }
    mainCategory {
      id
      name
      icon
    }
    displayAuthor {
      id
      profilePictureUrl
      name
      admin
    }
    author {
      ...ClassmateItem
      id
      profilePictureUrl
      name
      admin
    }
    isNew
    likes
    comments {
      id
      parentId
      userId
      userName
      createdAt
      body
      email
      updatedAt
      reported
      likes
      postId
      userProfileImg
    }
    isSportsNews
    sportsCategory
  }

  ${classmateItemFragment}


`;
