import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { LangData } from '../../assets/locales/localization';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Theme } from '../../common/providers/colors';
const PLATFORMS = [
  {
    identifier: 'NEWS_FEED',
    name: LangData.publicationPlatformPickerForm.homePage
  }
];

export class PublicationPlatformPickerFormView extends Component {
  static propTypes = {
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    navigate: PropTypes.func.isRequired,
    publish: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      platforms: ['NEWS_FEED'],
      done: false,
      loading: false,
      result: 0,
      isChecked: false
    };
  }

  componentWillMount() {
    const { editEntity } = this.props;

    if (editEntity && editEntity.platforms) {
      this.setState({
        platforms: this.props.editEntity.platforms
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { editEntity } = nextProps;

    if (editEntity && editEntity.platforms) {
      this.setState({
        platforms: nextProps.editEntity.platforms
      });
    }
  }

  handlePlatformChange(pId, isChecked) {
    if (isChecked) {
      console.log('pid', [...this.state.platforms, pId]);
      this.setState({
        platforms: [...this.state.platforms, pId]
      });
    } else {
      console.log(
        'platforms',
        this.state.platforms.filter(id => id !== pId)
      );

      this.setState({
        platforms: this.state.platforms.filter(id => id !== pId)
      });
    }
    this.setState({ isChecked: !this.state.isChecked });
  }

  publish() {
    console.log('type', this.props.type);
    switch (this.props.type) {
      case 'poll':
        this.props
          .publish(this.props.poll.createUpdatePoll.id, this.state.platforms)
          .then(({ data }) => {
            console.log(
              'data',
              data,
              this.props.poll.createUpdatePoll.id,
              this.state.platforms
            );
            this.setState({ done: true, result: data.publishToFeed });
          });
        break;
      case 'event':
        this.props
          .publish(this.props.poll.createUpdateEvent.id, this.state.platforms)
          .then(({ data }) => {
            console.log(
              'data',
              data,
              this.props.poll.createUpdateEvent.id,
              this.state.platforms
            );
            this.setState({ done: true, result: data.publishToFeed });
          });
        break;
      case 'news':
        this.props
          .publish(this.props.poll.createUpdateNews.id, this.state.platforms)
          .then(({ data }) => {
            console.log(
              'data',
              data,
              this.props.poll.createUpdateNews.id,
              this.state.platforms
            );
            this.setState({ done: true, result: data.publishToFeed });
          });
        break;
        default:
        break
    }
  }

  skip() {
    this.props._setClose(true, 'closeModal');
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }

    if (this.state.done) {
      return (
        <div style={styles.successContainer}>
          <span style={{ color: 'white' }}>
            {LangData.publicationPlatformPickerForm.success}
          </span>

          <button onClick={() => this.skip()} style={styles.continueBtn}>
            {LangData.publicationPlatformPickerForm.return}
          </button>
        </div>
      );
    }

    return (
      <div>
        <span style={{ color: 'white', textAlign: 'left' }}>
          {LangData.publicationPlatformPickerForm.platforms}
        </span>
        {PLATFORMS.map(platform => (
          <div
            key={platform.identifier}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <StyledCheckbox
              checked={this.state.isChecked}
              color='primary'
              onChange={() =>
                this.handlePlatformChange(
                  platform.identifier,
                  !this.state.isChecked
                )
              }
            />
            <span style={styles.platformName}>{platform.name}</span>
          </div>
        ))}

        <div style={styles.displayFlex}>
          <button onClick={() => this.skip()} style={styles.continueBtn}>
            {LangData.publicationPlatformPickerForm.skip}
          </button>

          <button
            disabled={!this.state.platforms.length}
            onClick={() => this.publish()}
            style={styles.continueBtn}
          >
            {LangData.publicationPlatformPickerForm.continue}
          </button>
        </div>
      </div>
    );
  }
}

const styles = {
  platformName: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontWeight: 'bold',
    textAlign: 'left'
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  continueBtn: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    fontSize: '15px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    border: 'none',
    padding: '3%',
    borderRadius: '50px',
    width: '150px',
    margin: '5%'
  },
  successContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px'
  }
};

const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);
