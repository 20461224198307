import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../common/providers/colors';

const width = window.innerWidth;

export class TwitterAuthModal extends Component {
  static propTypes = {
    accessToken: PropTypes.any,
    redirectUrl: PropTypes.string,
    returnUrl: PropTypes.string,
    startAuth: PropTypes.func.isRequired,
    authDone: PropTypes.func.isRequired,
    onInit: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.onNavigationStateChange = this.onNavigationStateChange.bind(this);

    this.state = {
      open: false
    };

    this._closed = false;
  }

  componentWillMount() {
    this.clearCookies();

    if (this.props.onInit) {
      this.props.onInit(this);
    }
    this._closed = false;
  }

  onNavigationStateChange(state) {
    const url = state.url.toLowerCase();

    if (
      url.startsWith(this.props.returnUrl.toLowerCase()) ||
      url.includes('denied')
    ) {
      setTimeout(() => {
        if (!this._closed) {
          this._closed = true;
          this.close(url.includes('denied'));
        }
      }, 1000);
    }
  }

  clearCookies() {
    try {
      // Cookie.clear('https://www.twitter.com/');
      // Cookie.clear('https://twitter.com/');
      // Cookie.clear('http://twitter.com/');
      // Cookie.clear('http://api.twitter.com/');
      // Cookie.clear('https://api.twitter.com');
    } catch (e) {
      // nothing to do
    }
  }

  open() {
    this.clearCookies();

    this.setState({
      open: true
    });

    return new Promise(resolve => {
      this.closeResolve = resolve;
    });
  }

  close(skipUserLoad = false) {
    if (!skipUserLoad) {
      this.props.authDone();
    }

    this.setState(
      {
        open: false
      },
      () => {
        if (this.closeResolve) {
          this.closeResolve();
          this.closeResolve = null;
        }
      }
    );
  }

  render() {
    if (
      !this.state.open ||
      !this.props.accessToken ||
      !this.props.redirectUrl
    ) {
      return null;
    }

    const uri = this.props.redirectUrl;

    return (
      <Fragment>
        {this.state.open ? (
          <div
            style={styles.container}
            // onRequestClose={() => this.close()}
          >
            <div style={styles.exitButton} onClick={() => this.close(true)}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {this.state.open && (
              <div style={styles.webViewContainer}>
                <iframe
                  src={uri}
                  style={{
                    width: width - 20,
                    height: window.innerHeight - 250
                  }}
                  is='x-frame-bypass'
                />
              </div>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: window.innerHeight,
    position: 'fixed',
    top: '0',
    backgroundColor: Theme.BACKGROUND_COLOR,
    zIndex: '9'
  },
  exitButton: {
    position: 'absolute',
    top: 50,
    left: 10,
    color: 'white'
  },
  webViewContainer: {
    borderRadius: 10,
    marginHorizontal: 10,
    height: 480,
    overflow: 'hidden'
  }
};
