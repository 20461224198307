export const toggleComponent = component => ({
  type: 'change_component',
  payload: component
});

export const openImgModal = imgSrc => ({
  type: 'OPEN_MODAL',
  payload: imgSrc
});

export const closeImgModal = () => ({
  type: 'CLOSE_MODAL'
});

export const openGalleryModal = images => ({
  type: 'OPEN_GALLERY_MODAL',
  payload: images
});

export const closeGalleryModal = () => ({
  type: 'CLOSE_GALLERY_MODAL'
});

export const setLastPosition = position => ({
  type: 'set_last_position',
  payload: position
});

export const clearLastPosition = () => ({
  type: 'clear_last_position'
});

export const setShowInstallPage = show => ({
  type: 'set_show_install_page',
  payload: show
});
