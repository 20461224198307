import gql from 'graphql-tag';

export const friendRequestsStatusQuery = gql`
  query friendRequestsStatus($userId: ID) {
    friendRequestsStatus(userId: $userId) {
      ignoredCount
      pendingCount
      sentCount
    }
  }
`;
