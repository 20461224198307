import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notificationsStyles } from './common';

export class UserGalleryNotificationItem extends Component {
  static propTypes = {
    item: PropTypes.any
  };

  render() {
    const { item } = this.props;

    return (
      <div style={notificationsStyles.container}>
        <div style={notificationsStyles.imageGalleryContainer}>
          <img
            src={item.photoUrl}
            style={{
              opacity: 0.8,
              height: 65,
              width: 65,
              borderRadius: 15
            }}
          />
        </div>
        <div style={notificationsStyles.contentContainer}>
          <span style={notificationsStyles.text}>{item.content}</span>
          <span style={notificationsStyles.date}>{item.time}</span>
        </div>
      </div>
    );
  }
}
