import { graphql } from 'react-apollo-temp';

import UploadPhotoMenuView from './upload-photo-menu.rn';

import { addPhotoMutation } from '../../graphql/add-photo.mutation';
import { myPhotosQuery } from '../../graphql/my-photos.query';
// import { togglePermissionsErrorModal } from '../../../main/main.actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { withPermissionsState } from '../../../notification/hoc/with-permissions-state';

const withMutation = graphql(addPhotoMutation, {
  props: ({ mutate }) => ({
    addPhoto: (userId, photoId, label) =>
      mutate({
        variables: { userId, photoId, label },
        // update: (store, { data: { addPhoto } }) => {
        //   const existingState = store.readQuery({
        //     query: myPhotosQuery,
        //   });

        //   if (!existingState || !existingState.photos) {
        //     return;
        //   }

        //   (existingState.photos || []).unshift(addPhoto);

        //   store.writeQuery({
        //     query: myPhotosQuery,
        //     data: existingState
        //   });
        // }
      }).catch(() => null)
  })
});

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  // openPermissionsErrorModal: type => dispatch(togglePermissionsErrorModal(true, type)),
});

const withState = connect(mapStateToProps, mapDispatchToProps);

export const UploadPhotoMenu = compose(
  withState,
  withMutation
)(UploadPhotoMenuView);
