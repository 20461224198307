import React from 'react';

export const BurgerMenu = () => {
    return (
        <div style={styles.burgerContainer}>
            <div style={styles.topAndBottomBurger}></div>
            <div style={styles.middleInBurger}></div>
            <div style={styles.topAndBottomBurger}></div>
        </div>
    )
}

export default BurgerMenu

const styles = {
    burgerContainer: {
        width:'20px'
    },
    topAndBottomBurger: {
        height: '2px',
        width: "100%",
        backgroundColor: '#726f7e',
        borderRadius: '25px'
    },
    middleInBurger: {
        height: '2px',
        width: "70%",
        backgroundColor: '#726f7e',
        marginBottom: '5px',
        marginTop: '5px',
        borderRadius: '25px'
    }
}