import { accountsClient, client, accountsPassword } from './account.init';
import { store } from '../store';
import {
  loggingIn,
  setTokens,
  setUser,
  clearTokens,
  clearUser
} from './account.actions';
import gql from 'graphql-tag';
import { userFieldsFragment } from '../login/graphql/me.fragment';
import { getPersistor } from '../apollo';
import { toggleComponent } from '../router/router.actions';
import { apolloClient } from '../apollo-subs';
import { getUserQuery } from './getUser.query';
import { updateUserLoginMutation } from './updateUserLogin.mutation';

export const refreshSession = async () => {
  store.dispatch(loggingIn(true));
  const tokens = await accountsClient.refreshSession();
  if (tokens) {
    store.dispatch(setTokens(tokens));
    let user = await getUser();
    if (user) {
      store.dispatch(setUser(user));
    }
  } else {
    store.dispatch(clearTokens());
    store.dispatch(clearUser());
  }
  store.dispatch(loggingIn(false));
  store.dispatch(toggleComponent('News_Feed'));

  return tokens;
};

export const logout = async () => {
  getPersistor().purge();
  await client.resetStore();
  localStorage.clear()
  // await accountsClient.logout();
  store.dispatch(clearTokens());
  store.dispatch(clearUser());
};

export const login = async (username, password) => {
  store.dispatch(loggingIn(true));

  try {
    const { tokens } = await accountsClient.loginWithService('password', {
      user: {
        username: username.toLowerCase()
      },
      password
    });

    store.dispatch(setTokens(tokens));
    let user = await getUser();

    if (user) {
      store.dispatch(setUser(user));
      updateUserLogin()
    }
  } catch (err) {
    throw err;
  } finally {
    store.dispatch(loggingIn(false));
    store.dispatch(toggleComponent('News_Feed'));
  }
};


export const updateUserLogin = async () => {
  let userIdFromStorage = await localStorage.getItem('userId')
  try {
    const response = await apolloClient.mutate({
      mutation: updateUserLoginMutation,
      variables: {
        userId: userIdFromStorage
      },
      fetchPolicy: 'network-only'
    })
  }catch(err) {
    console.log("updateUserLogin error:", err)
    return null 
  }
}


export const getUser = async () => {
  let userIdFromStorage = await localStorage.getItem('userId')
  try {
    const response = await apolloClient.query({
      query: getUserQuery,
      variables: {
        userId: userIdFromStorage
      },
      fetchPolicy: 'network-only'
    });


    let currentVersion = localStorage.getItem('version') || '';
    if (response.data.getUser) {
      store.dispatch(setUser(response.data.getUser));

      if (
        response.data.getUser.appVersionUpdated !== currentVersion.toString() &&
        !window.location.href.includes('changePassword')
      ) {
        // window.location.reload(true);
      }
      localStorage.setItem('version', response.data.getUser.appVersionUpdated);
    }

    return response.data.getUser;
  } catch (err) {
    console.log('getUser error:', err);

    return null;
  }
};

export const resetPassword = async (token, password) => {
  const { tokens } = await accountsPassword.resetPassword(token, password);
  await accountsClient.setTokens(tokens);
  if (tokens) {
    store.dispatch(setTokens(tokens));
    let user = await getUser();
    if (user) {
      store.dispatch(setUser(user));
    }
  }
};

export const refreshByUserId = async (userId) => {
  try {
    const response = await client.query({
      query: gql`
        query getUser($userId: ID){
          getUser(userId: $userId) {
            ...UserFields
          }
        }
        ${userFieldsFragment}
      `,
      variables: {
        userId
      },
      fetchPolicy: 'network-only'
    });

    return response.data.getUser;
  } catch (err) {
    console.log('getUser error:', err);

    return null;
  }
}

export const refreshUser = async () => {
  try {
    let userId = localStorage.getItem('userId')
    const response = await client.query({
      query: gql`
        query getUser($userId: ID){
          getUser(userId: $userId) {
            ...UserFields
          }
        }
        ${userFieldsFragment}
      `,
      variables: {
        userId
      },
      fetchPolicy: 'network-only'
    });

    return response.data.getUser;
  } catch (err) {
    console.log('getUser error:', err);

    return null;
  }
};
