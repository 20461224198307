import React, { Fragment, useState, useEffect } from 'react';
import { LangData } from '../../assets/locales/localization';
import logoSrc from '../../assets/images/default_profile.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { login, getUser } from '../../account/account.util';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import ErrorMessage from './error-message.rn';
import { analytics } from '../../analytics/report';
import { isAndroid, isIOS } from 'react-device-detect';
import { Theme } from '../../common/providers/colors';
import { store } from '../../store';
import { setUser } from '../../auth/auth.actions';

const LoginScreenView = props => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [shouldHidePassword, setShouldHidePassword] = useState(true);
  const [error, setError] = useState(null);
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function handleFocus() {
    if (isIOS) {
      setWindowSize({
        width: window ? window.innerWidth : undefined,
        height: '300px'
      });
    } else {
      setWindowSize(getSize());
    }
  }

  function handlePasswordFocus() {
    if (isIOS) {
      setWindowSize({
        width: window ? window.innerWidth : undefined,
        height: '330px'
      });
    } else {
      setWindowSize(getSize());
    }
  }

  function handleBlur() {
    if (isIOS) {
      setWindowSize({
        width: window ? window.innerWidth : undefined,
        height: window ? window.innerHeight : undefined
      });
    }
  }

  function getSize() {
    return {
      width: window ? window.innerWidth : undefined,
      height: window ? window.innerHeight : undefined
    };
  }

  const loginFunc = async () => {
    setLoading(true);
    try {
      await login(userName, password);
      setLoading(false);
      analytics.report('Auth', 'Login');
      // let user  = await getUser()
      // analytics.setUser(user);
      // store.dispatch(setUser(user))
      
      props.history.push('/');
    } catch (e) {
      const { message } = e;
      console.log(e, 'error');
      let errMsg = LangData.introScreen.invalid;

      if (message.includes('Network request failed')) {
        errMsg = LangData.introScreen.available;
      } else if (message.includes('Unauthorized app user')) {
        errMsg = LangData.introScreen.unauthorized;
      }
      setLoading(false);
      setError(errMsg);
    }
  };
  const isDisabled =
    userName === '' || password === '' || error !== null || loading;

  return (
    <Fragment>
      {!window.matchMedia('(display-mode: standalone)').matches &&
        // isAndroid &&
        props.showInstallPage &&
        props.history.push('/installApp')}
      {props.user && props.history.push('/')}
      {windowSize && (
        <div
          style={Object.assign(
            { height: windowSize.height },
            styles.mainContainer
          )}
        >
          <div style={styles.formContainer}>
            <img src={logoSrc} alt='logo' style={styles.logoImg} />
            <div style={styles.inputDiv}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faUser} />
                <input
                  type='text'
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                  style={styles.inputDiv.input}
                  // onFocus={() => handleFocus()}
                  // onBlur={() => handleBlur()}
                  placeholder='Username'
                />
              </label>
            </div>
            <div style={styles.inputDiv}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faKey} />
                <input
                  type='password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  style={styles.inputDiv.input}
                  // onFocus={() => handlePasswordFocus()}
                  // onBlur={() => handleBlur()}
                  placeholder='Password'
                />
              </label>
            </div>
            <div
              onClick={() => {
                props.history.push('forgotPassword');
              }}
              style={{ fontSize: 20 }}
            >
              Forgot Password?
            </div>
          </div>
          <div style={styles.footerContainer}>
            <div
              style={isDisabled ? styles.submitBtnDis : styles.submitBtn}
              onClick={loginFunc}
            >
              {LangData.introScreen.login}
            </div>
          </div>
        </div>
      )}

      {(loading || props.loading) && <LoadingSpinner />}

      {error && (
        <ErrorMessage
          key='error message'
          show={error}
          message={error}
          onPress={() => {
            setError(null);
            setPassword('');
            props.dismissError();
          }}
        />
      )}
    </Fragment>
  );
};

export default LoginScreenView;

const styles = {
  mainContainer: {
    backgroundColor: 'rgb(226, 226, 226)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: Theme.TEXT_COLOR
  },
  logoImg: {
    height: window.innerWidth / 6 + 'px',
    width: window.innerWidth / 6 + 'px',
    margin: '10% 10% 2% 10%'
  },
  inputDiv: {
    backgroundColor: 'white',
    borderRadius: window.innerWidth / 12 + 'px',
    padding: '2.5%',
    width: window.innerWidth / 1.894 + 'px',
    marginBottom: '2%',
    input: {
      WebkitAppearance: 'none',
      outline: 'none',
      backgroundColor: 'white',
      border: 'none',
      height: window.innerWidth / 12 + 'px',
      width: window.innerWidth / 2.12 + 'px',
      fontSize: window.innerWidth / 24 + 'px',
      paddingLeft: window.innerWidth / 180 + 'px',
      fontFamily: 'inherit'
    }
  },
  trouble: {
    alignSelf: 'flex-end',
    marginRight: '22%'
  },
  aboutRow: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    margin: '1%'
  },
  submitBtn: {
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    color: Theme.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    padding: '3%',
    fontWeight: 'bold',
    opacity: '0.8'
  },
  submitBtnDis: {
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    color: Theme.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    padding: '3%',
    fontWeight: 'bold',
    opacity: '0.6'
  }
};
