import React, { useState, Fragment } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from './loading-spinner.rn';
import { connect } from 'react-redux';
import {
  toggleComponent,
  closeGalleryModal
} from '../../router/router.actions';
import './carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Redirect } from 'react-router';

const ImageGalleryViewerModal = props => {
  const [currImgIndex, setCurrImgIndex] = useState(
    props.images ? props.images.firstIndex : null
  );
  const [images, setImages] = useState(
    props.images ? props.images.images : null
  );

  const getElements = numElements => {
    const elements = [];
    for (let i = 0; i < numElements; ++i) {
      elements.push(
        <img
          alt=""
          src={images[i].url ? images[i].url : images[i]}
          style={styles.img}
          key={i}
        />
      );
    }

    return elements;
  };

  if (!props.currentUser) {
    return <Redirect to='/login' />;
  }

  return (
    <div style={styles.mainContainer}>
      {images && (
        <Fragment>
          <div style={styles.topRow}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => {
                // props.history.go(-1);
                if (
                  props.currentComponent === 'Chat' ||
                  props.currentComponent === 'News_Feed' ||
                  props.currentComponent === 'Videos' ||
                  props.currentComponent === 'Notifications'
                ) {
                  props.history.push('/');
                }
                if (props.currentComponent === 'UserProfile') {
                  props.history.push('userProfile');
                }
                if (props.currentComponent === 'ChatScreen') {
                  props.history.push('chatScreen');
                }
                if (props.currentComponent === 'Profile') {
                  props.history.push('profile');
                }
                if (props.currentComponent === 'Courses') {
                  props.history.push('courses');
                }
                if (props.currentComponent === 'Friends') {
                  props.history.push('/');
                  props.toggleComponent('Friends');
                }
                // props.toggleComponent(props.prevComponent);

                props.closeGalleryModal();
              }}
            />
          </div>
          <div style={styles.imageContainer} id='styles.imageContainer'>
            <Carousel
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              infiniteLoop={true}
              selectedItem={currImgIndex}
              onChange={e => setCurrImgIndex(e)}
            >
              {getElements(images.length)}
            </Carousel>
          </div>

          <div style={styles.footer} id='styles.footer'>
            <span>
              {images[currImgIndex].label ? images[currImgIndex].label : ' '}
            </span>
            <span>{currImgIndex + 1 + ' of ' + images.length}</span>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = ({ navigation, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  firstIndex: navigation.galleryModal.firstIndex,
  images: navigation.galleryModal.images,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  closeGalleryModal: () => dispatch(closeGalleryModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageGalleryViewerModal);

const styles = {
  mainContainer: {
    width: window.innerWidth,
    height: window.innerHeight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'black'
  },
  topRow: {
    display: 'flex',
    color: 'white',
    padding: '10px',
    height: window.innerHeight * 0.03
  },
  img: {
    width: '100%',
    minHeight: '50%',
    maxHeight: window.innerHeight * 0.9
  },
  imageContainer: {
    width: '100%',
    height: window.innerHeight * 0.9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  leftArrow: {
    color: 'white',
    position: 'absolute',
    fontSize: '20px',
    top: '45%',
    left: '10px'
  },
  rightArrow: {
    color: 'white',
    position: 'absolute',
    fontSize: '20px',
    top: '45%',
    right: '10px'
  },
  footer: {
    color: 'white',
    height: window.innerHeight * 0.07,
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '13px',
    marginBottom: '2px',
    justifyContent: 'center'
  }
};
