import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const searchStudentsQuery = gql`
  query searchStudents($userId: ID, $text: String!, $showHidden: Boolean) {
    searchStudents(userId:$userId, text: $text, showHidden: $showHidden) {
      items {
        ...ClassmateItem
      }
      hasMore
    }
  }

  ${classmateItemFragment}
`;
