import { ChatListView } from './chat-list.rn';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  setSearchTerm,
  chatNavigate,
  navigateToFriendsList
} from '../../chat.actions';
import { myChatsQuery } from '../../graphql/my-chats.query';
import { graphql, withApollo } from 'react-apollo-temp';
import { withErrorHandler } from '../../../common/hoc/error-handler.rn';
import { withFriendsRequestsStatus } from '../../../friends/components/friends-list.container';
import { toggleComponent } from '../../../router/router.actions';
// import { withPermissionsState } from '../../../notification/hoc/with-permissions-state';

const mapStateToProp = ({ chat, navigation }) => ({
  searchTerm: chat.searchTerm,
  currentComponent: navigation.navigationComponents.component
});

const mapDispatch = dispatch => ({
  navigateToChat: chat => dispatch(chatNavigate(chat)),
  navigateToFriendsList: toRequests =>
  dispatch(navigateToFriendsList(toRequests)),
  setSearchTerm: search => dispatch(setSearchTerm(search)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const withState = connect(mapStateToProp, mapDispatch);

export const withChats = graphql(myChatsQuery, {
  name: 'chatsData',
  options: (props) => {
    return {
      fetchPolicy: 'network-only',
      variables: { 
        userId: props.currentUser ?  props.currentUser.id : null,
        withMe: false
      }
    }
  }
});

const withError = withErrorHandler({
  apollo: ['chatsData']
});

export const ChatList = compose(
  withApollo,
  withFriendsRequestsStatus,
  withState,
  withChats,
  withError
  // withPermissionsState
)(ChatListView);
