import gql from 'graphql-tag';

export const updateWasUsefulMutation = gql`
  mutation updateWasUseful(
    $videoLogId: ID,
    $wasUseful: Boolean
  ) {
    updateWasUseful(
        videoLogId: $videoLogId,
        wasUseful: $wasUseful
    ) {
        _id
    }
  }
`;
