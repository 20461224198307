import React, { Component } from 'react';
import PropTypes, { string } from 'prop-types';
import SecondStageModal from '../secondStage';
import { CategoriesPicker } from '../categories-picker.container';
import moment from 'moment';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import DateFnsUtils from '@date-io/date-fns';
import { LangData } from '../../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faImages,
  faTrashAlt
} from '@fortawesome/free-regular-svg-icons';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './poll.scss';
import { uploadToCloudinary } from '../../../cloudinary/utils/upload';
import styled from 'styled-components';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../common/providers/colors';

export class CreateEventFormView extends Component {
  static propTypes = {
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    loading: PropTypes.bool,
    createUpdateEvent: PropTypes.func.isRequired,
    navigateSecondStage: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isUpdating: false,
      mainCategory: '5ee08f9514d8e012c0c05f31',
      categories: ['5ee08f9514d8e012c0c05f31'],
      eventName: '',
      eventLocation: '',
      description: '',
      posterImage: null,
      startDate: null,
      startTime: null,
      startDateTime: new Date(),
      endDate: null,
      endTime: null,
      endDateTime: new Date(),
      allDay: false,
      lastEventDate: null,
      lastEventImages: [],
      whoToContact: this.props.currentUserName,
      xiboText: '',
      pinned: false,
      menuVisible: false,
      imageType: '',
      currentEvent: null,
      secondStageOpen: false,
      selectedCategoriesIds: [],
      selectedMainCategoryId: '',
      isFinished: false,
      uploadMultiple: false,
      targetGroups: ['all'],
      done: false,
      isAdvancedOpen: false
    };
    this.paths = [];
  }

  async handleEventImage(e) {
    let file = e.target.files[0];
    requestAnimationFrame(async () => {
      this.setState({ menuVisible: false });
      console.log('file=  ', file);
      await this.createImage(file, 'event_image');
    });
  }

  async handlePrevEventImage(e) {
    let file = e.target.files[0];
    requestAnimationFrame(async () => {
      this.setState({ menuVisible: false });
      console.log('file=  ', file);
      await this.createImage(file, 'prev_event_image');
    });
  }

  async createImage(file, tag) {
    const fileData = await toBase64(file);
    const base64 = `data:image/jpeg;base64,${fileData}`;
    const uploadResult = await uploadToCloudinary(file, null, tag);

    const publicId = uploadResult.public_id;
    const filePath = uploadResult.secure_url;

    return this.props.uploadFile(
      file,
      tag,
      base64,
      filePath,
      publicId,
      (store, { data: { uploadToCloudinary } }) => {
        const imageUrl = uploadToCloudinary.filePath;
        if (tag === 'event_image') {
          this.setState({ posterImage: imageUrl });
          this.setState({ posterImageUrl: imageUrl });
        } else if (
          tag === 'prev_event_image' &&
          imageUrl.includes('res.cloudinary')
        ) {
          let tempArr = this.state.lastEventImages;
          tempArr.push(imageUrl);
          this.setState({ lastEventImages: tempArr });
        }
      }
    );
  }

  navigateSecondStage(event) {
    requestAnimationFrame(() => {
      this.setState({ secondStageOpen: true });
      this.props.navigateSecondStage(event);
      window.scrollTo(0, 0);
    });
  }

  isSubmitDisabled() {
    return (
      this.state.eventName === null ||
      this.state.eventName === '' ||
      this.state.mainCategory === null ||
      this.state.startDate === null ||
      this.state.endDate === null ||
      this.state.categories.length === 0
    );
  }

  componentDidMount() {
    const { editEntity } = this.props;
    this.setEditEntity(editEntity);
  }

  _setCategories = (value, stateKey) => {
    console.log('changed', value, stateKey);
    this.setState({ [stateKey]: value });
  };

  openImagesMenu(gotFrom) {
    this.setState({ menuVisible: !this.state.menuVisible });
    this.setState({ imageType: 'poster' });
  }

  removeImage(toRemove) {
    this.setState({
      lastEventImages: this.state.lastEventImages.filter(
        (image, index) => index !== toRemove
      )
    });
  }

  setEditEntity(editEntity) {
    if (editEntity) {
      const startMoment = editEntity.startTime
        ? moment(editEntity.startTime)
        : null;
      const endMoment = editEntity.endTime ? moment(editEntity.endTime) : null;
      const prevMoment = editEntity.previousEventDate
        ? moment(editEntity.previousEventDate).toDate()
        : null;

      this.setState({
        eventName: editEntity.title,
        eventLocation: editEntity.locationName,
        description: editEntity.description,
        categories: editEntity.categories.map(item => item.id),
        mainCategory: editEntity.mainCategory.id,
        posterImage: editEntity.posterImageUrl,
        whoToContact: editEntity.whoToContact || '',
        xiboText: editEntity.xiboText || '',
        lastEventDate: prevMoment,
        allDay: editEntity.allDay,
        pinned: editEntity.pinned,
        startDate: null,
        startTime: null,
        startDateTime: null,
        endDate: null,
        endTime: null,
        endDateTime: null,
        lastEventImages: editEntity.previousEventImages
      });
    }
  }
  _setCloseSecond = (value, stateKey) => {
    console.log('changed2', value, stateKey);
    this.setState({ [stateKey]: value }, () => {
      console.log('this.state.isFinished', this.state.isFinished);
      if (this.state.isFinished) {
        this.props._setClosePoll(true, 'closePool');
      }
    });
  };

  onMainCategoryChange = value => {
    this.setState({ mainCategory: value });
  };

  onCategoriesChange = categories => {
    this.setState({ categories });
  };

  handleStartTimeChange(e, type) {
    let currStartTime = this.state.startDateTime;
    if (type === 'Date') {
      currStartTime.setFullYear(e.getFullYear());
      currStartTime.setMonth(e.getMonth());
      currStartTime.setDate(e.getDate());
    } else if (type === 'Time') {
      currStartTime.setHours(e.getHours());
      currStartTime.setMinutes(e.getMinutes());
    }

    this.setState({
      startDateTime: moment(currStartTime, 'DD-MM-YYYY HH:mm:ss').toDate()
    });
  }

  handleEndTimeChange(e, type) {
    let currEndTime = this.state.endDateTime;
    if (type === 'Date') {
      currEndTime.setFullYear(e.getFullYear());
      currEndTime.setMonth(e.getMonth());
      currEndTime.setDate(e.getDate());
    } else if (type === 'Time') {
      currEndTime.setHours(e.getHours());
      currEndTime.setMinutes(e.getMinutes());
    }

    this.setState({
      endDateTime: moment(currEndTime, 'DD-MM-YYYY HH:mm:ss').toDate()
    });
  }
  setTargetGroup = data => {
    this.setState({ targetGroups: data });
  };

  next() {
    this.setState({ isUpdating: true });

    this.props
      .createUpdateEvent(this.props.editEntityId, {
        location: this.state.eventLocation,
        name: this.state.eventName,
        description: this.state.description,
        startTime: this.state.startDateTime ? this.state.startDateTime : null,
        endTime: this.state.endDateTime ? this.state.endDateTime : null,
        allDay: this.state.allDay,
        whoToContact: this.state.whoToContact,
        xiboText: this.state.xiboText,
        previousEventDate: this.state.lastEventDate,
        previousEventImages: this.state.lastEventImages.filter(
          item => item !== '' && item !== null
        ),
        poster: this.state.posterImageUrl,
        mainCategory: this.state.mainCategory,
        categories: this.state.categories,
        pinned: true
      })
      .then(({ data }) => {
        this.setState({ currentEvent: data });
        this.navigateSecondStage(data);
        this.props.updateTargets(
          data.createUpdateEvent.id,
          this.state.targetGroups
        );
        this.props.publish(data.createUpdateEvent.id, 'NEWS_FEED');

        this.setState({ done: true });
        this.setState({ isUpdating: false });
        // this.props.navigate(`/admin/events/publish/${data.createUpdateEvent.id}`);
      })
      .catch(e => this.setState({ isUpdating: false, error: e }));
  }

  render() {
    if (this.props.loading || this.state.isUpdating) {
      return <LoadingSpinner />;
    }

    if (this.state.done) {
      setTimeout(() => this.props._setClosePoll(true, 'closePool'), 1900);
      return (
        <div style={styles.successContainer}>
          <span style={{ color: 'white' }}>
            {LangData.publicationPlatformPickerForm.success}
          </span>

          <button
            onClick={() => this.props._setClosePoll(true, 'closePool')}
            style={styles.continueBtn}
          >
            {LangData.publicationPlatformPickerForm.return}
          </button>
        </div>
      );
    }

    return (
      <div style={styles.newsView}>
        {/* Event Details */}
        <h2 style={{ textAlign: 'center', fontSize: 20 }}>
          {LangData.events.details}
        </h2>
        <div style={styles.newsDetailsContainer}>
          <div style={{ width: '70%', paddingRight: 20 }}>
            <TextInput
              type='text'
              value={this.state.eventName}
              placeholder={LangData.events.name}
              onChange={e => this.setState({ eventName: e.target.value })}
            />

            <TextInput
              type='text'
              value={this.state.eventLocation}
              placeholder={LangData.events.place}
              onChange={e => this.setState({ eventLocation: e.target.value })}
            />
            <TextInput
              type='text'
              value={this.state.description}
              placeholder={LangData.events.description}
              onChange={e => this.setState({ description: e.target.value })}
            />
            <TextInput
              type='text'
              value={this.state.whoToContact}
              placeholder={LangData.events.contact}
              onChange={e => this.setState({ whoToContact: e.target.value })}
              style={styles.textInput}
            />
          </div>

          {/* Poster Image */}
          <div
            style={{
              width: '30%',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 2,
              borderColor: '#c7c1c1'
            }}
          >
            {this.state.posterImage && (
              <label htmlFor='upload_img_event'>
                <div style={styles.imgContainer}>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={e => this.handleEventImage(e)}
                    style={styles.hideInputStyle}
                    name='upload_img_event'
                    id='upload_img_event'
                  />

                  <img
                    style={{ width: '100%', height: '100%', borderRadius: 5 }}
                    src={this.state.posterImageUrl}
                  />
                </div>
              </label>
            )}
            {!this.state.posterImage && (
              <label htmlFor='upload_img_event'>
                <div style={styles.imgContainer}>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={e => this.handleEventImage(e)}
                    style={styles.hideInputStyle}
                    name='upload_img_event'
                    id='upload_img_event'
                  />
                  <FontAwesomeIcon
                    style={{ color: '#c7c1c1' }}
                    icon={faImage}
                  />
                </div>
              </label>
            )}
          </div>
        </div>

        {/* Date & Time view */}
        <div className='pollsView' style={{ width: '85%' }}>
          <h2 style={{ textAlign: 'center', fontSize: 20 }}>
            {LangData.events.datetime}
          </h2>
          <div style={styles.checkboxContainer}>
            <StyledCheckbox
              checked={this.state.allDay}
              color='primary'
              onChange={e => {
                this.setState({
                  allDay: !this.state.allDay
                });
              }}
            />
            <span>{LangData.events.allDay}</span>
          </div>
          <div style={styles.timePickerContainer}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='MM/dd/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  label='Start Date'
                  value={this.state.startDate}
                  autoOk={true}
                  onChange={e => {
                    this.setState({ startDate: e });
                    this.handleStartTimeChange(e, 'Date');
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
                {!this.state.allDay && (
                  <KeyboardTimePicker
                    margin='normal'
                    variant='inline'
                    id='time-picker'
                    label='Start Time'
                    value={this.state.startTime}
                    onChange={e => {
                      this.setState({ startTime: e });
                      this.handleStartTimeChange(e, 'Time');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                )}
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </div>
          <div style={styles.timePickerContainer}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='MM/dd/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  autoOk={true}
                  label='End Date'
                  value={this.state.endDate}
                  onChange={e => {
                    this.setState({ endDate: e });
                    this.handleEndTimeChange(e, 'Date');
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
                {!this.state.allDay && (
                  <KeyboardTimePicker
                    margin='normal'
                    label='End Time'
                    id='time-picker'
                    value={this.state.endTime}
                    onChange={e => {
                      this.setState({ endTime: e });
                      this.handleEndTimeChange(e, 'Time');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                )}
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </div>
        </div>
        {/* Advanced Settings */}
        <h2 style={{ textAlign: 'center', fontSize: 20 }}>
          Advanced{' '}
          <FontAwesomeIcon
            icon={this.state.isAdvancedOpen ? faChevronUp : faChevronDown}
            onClick={() =>
              this.setState({ isAdvancedOpen: !this.state.isAdvancedOpen })
            }
          />
        </h2>

        <div
          style={
            this.state.isAdvancedOpen
              ? styles.advancedContainerOpen
              : styles.advancedContainerClose
          }
        >
          {/* Categories Picker */}
          <h2
            style={{
              textAlign: 'center',
              fontSize: 16
            }}
          >
            Choose event categories
          </h2>
          <CategoriesPicker
            categories={this.state.categories}
            mainCategory={this.state.categories[this.state.mainCategory]}
            onMainCategoryChange={this.onMainCategoryChange}
            onCategoriesChange={this.onCategoriesChange}
          />

          {/* Pinned Event */}
          {/* <div style={{ width: '85%', color: 'white' }}>
          <span>{LangData.events.pinned}</span>
          <div style={styles.switchContainer}>
            <label
              style={this.state.pinned ? styles.switchChecked : styles.switch}
            >
              <input
                type='checkbox'
                style={styles.checkboxInput}
                value={this.state.pinned}
                onChange={() => this.setState({ pinned: !this.state.pinned })}
              />
              <span
                style={this.state.pinned ? styles.slider : styles.sliderBefore}
              ></span>
            </label>
            <span style={{ fontSize: '11px', marginLeft: '4%' }}>
              {LangData.events.visible}
            </span>
          </div>
        </div> */}

          {/* Previous Event  */}
          <div className='pollsView' style={{ width: '85%', marginTop: '5%' }}>
            <h2
              style={{ textAlign: 'center', fontSize: 16, marginBottom: '-7%' }}
            >
              {LangData.events.previous}
            </h2>

            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='MM/dd/yyyy'
                  margin='normal'
                  autoOk={true}
                  id='date-picker-inline'
                  label={LangData.events.previousDate}
                  value={this.state.lastEventDate}
                  onChange={e => {
                    this.setState({ lastEventDate: e });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>

            <label htmlFor='upload_images_prev'>
              <div style={{ color: Theme.TEXT_COLOR }}>
                <input
                  type='file'
                  accept='image/*'
                  onChange={e => this.handlePrevEventImage(e)}
                  style={styles.hideInputStyle}
                  name='upload_images_prev'
                  id='upload_images_prev'
                />
                <FontAwesomeIcon
                  icon={faImages}
                  style={{ marginRight: '2%' }}
                />
                <span>{LangData.events.images}</span>
              </div>
            </label>

            <div style={styles.galleryContainer}>
              {this.state.lastEventImages.map((item, index) => {
                return (
                  <div style={styles.relativeDiv} key={index}>
                    <img src={item} style={styles.image} />

                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={styles.trashIcon}
                      onClick={() => {
                        this.removeImage(index);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <SecondStageModal
            poll={this.state.currentEvent}
            type='event'
            onClose={() => this.setState({ secondStageOpen: false })}
            isOpen={this.state.secondStageOpen && !this.state.isFinish}
            _setCloseSecond={this._setCloseSecond}
            setGroups={this.setTargetGroup}
          />
        </div>

        {/* Submit Button  */}
        <button
          onClick={() => this.next()}
          disabled={this.isSubmitDisabled()}
          style={styles.continueBtn}
        >
          Post
        </button>
      </div>
    );
  }
}

const styles = {
  newsView: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: '#00356b',
    overflow: 'hidden',
    display: 'flex',
    color: Theme.TEXT_COLOR
  },
  inline: {
    display: 'inline-block',
    marginHorizontal: 3
  },
  table: {
    display: 'table',
    overflow: 'hidden',
    width: '100%'
  },
  fields: {
    width: '80%',
    display: 'table-cell'
  },
  poster: {
    display: 'table-cell',
    padding: 10
  },
  posterImage: {
    maxWidth: 100
  },
  eventsView: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderTopWidth: 1,
    borderTopColor: Theme.SECONDARY_TEXT_COLOR,
    overflow: 'hidden'
  },
  eventsDetails: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  textInput: {
    fontFamily: 'inherit',
    border: 'none',
    fontSize: '15px',
    padding: '9px',
    width: '95%',
    borderRadius: '5px',
    margin: '5px 0'
  },
  continueBtn: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    marginTop: '5%',
    fontSize: '17px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    border: 'none',
    padding: '3%',
    borderRadius: '50px',
    width: '35%',
    marginBottom: '3%'
  },
  checkboxContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '17px'
  },
  timePickerContainer: {
    width: '85%',
    marginLeft: '10%',
    color: Theme.TEXT_COLOR
  },
  newsDetailsContainer: {
    display: 'flex',
    width: '85%',
    marginBottom: '2%'
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #c7c1c1',
    borderRadius: '5px'
  },
  hideInputStyle: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1'
  },
  // switch: {
  //   position: 'relative',
  //   display: 'inline-block',
  //   width: window.innerWidth / 7.826 + 'px',
  //   height: window.innerWidth / 15.652 + 'px',
  //   borderRadius: window.innerWidth / 18 + 'px',
  //   backgroundColor: '#1114A4'
  // },
  // switchChecked: {
  //   position: 'relative',
  //   display: 'inline-block',
  //   width: window.innerWidth / 7.826 + 'px',
  //   height: window.innerWidth / 15.652 + 'px',
  //   borderRadius: window.innerWidth / 18 + 'px',
  //   backgroundColor: '#034522'
  // },
  checkboxInput: {
    opacity: '0',
    width: '0',
    height: '0'
  },
  sliderBefore: {
    position: 'absolute',
    content: '""',
    height: window.innerWidth / 18.947 + 'px',
    width: window.innerWidth / 18.947 + 'px',
    left: window.innerWidth / 360 + 'px',
    bottom: window.innerWidth / 180 + 'px',
    backgroundColor: 'white',
    transition: 'all 0.4s ease 0s',
    borderRadius: '50%'
  },
  slider: {
    position: 'absolute',
    content: '""',
    height: window.innerWidth / 18.947 + 'px',
    width: window.innerWidth / 18.947 + 'px',
    left: window.innerWidth / -360 + 'px',
    bottom: window.innerWidth / 180 + 'px',
    backgroundColor: 'white',
    transition: 'all 0.4s ease 0s',
    borderRadius: window.innerWidth / 10.588 + 'px',
    transform: 'translateX(26px)'
  },
  galleryContainer: {
    display: 'grid',
    width: '100%',
    marginTop: '3%',
    gridTemplateColumns:
      window.innerWidth / 3.6 +
      10 +
      'px ' +
      (window.innerWidth / 3.6 + 10) +
      'px ' +
      (window.innerWidth / 3.6 + 10) +
      'px'
  },
  relativeDiv: { position: 'relative' },
  waitingApprovaImage: {
    opacity: 0.4,
    borderRadius: window.innerWidth / 20.7 + 'px',
    height: window.innerWidth / 3.6 + 'px',
    width: window.innerWidth / 3.6 + 'px'
  },
  image: {
    borderRadius: window.innerWidth / 20.7 + 'px',
    height: window.innerWidth / 3.6 + 'px',
    width: window.innerWidth / 3.6 + 'px',
    boxShadow: '10px 0px 43px 0px rgba(0,0,0,0.75)'
  },
  trashIcon: {
    color: 'darkgrey',
    fontSize: window.innerWidth / 30 + 'px',
    position: 'absolute',
    top: window.innerWidth / 60 + 'px',
    right: window.innerWidth / 22.5 + 'px',
    fontWeight: 'bold'
  },
  successContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px'
  },
  advancedContainerOpen: {
    visibility: 'visible',
    height: 'fit-content',
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  advancedContainerClose: {
    visibility: 'hidden',
    height: 0
  }
};
const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: Theme.SECONDARY_TEXT_COLOR,
      light: Theme.SECONDARY_TEXT_COLOR,
      dark: Theme.SECONDARY_TEXT_COLOR
    },
    primary: {
      main: Theme.SECONDARY_TEXT_COLOR,
      light: Theme.SECONDARY_TEXT_COLOR,
      dark: Theme.SECONDARY_TEXT_COLOR
    },
    textColor: {
      main: Theme.SECONDARY_TEXT_COLOR
    },
    datePicker: {
      selectColor: Theme.SECONDARY_TEXT_COLOR
    }
  }
});
export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }
  });

const TextInput = styled.input`
  font-family: inherit;
  border: none;
  font-size: 15px;
  padding: 9px;
  width: 95%;
  border-radius: 5px;
  margin: 5px 0;

  &:focus {
    outline: none;
    border: none;
  }
`;
