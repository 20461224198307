import { graphql } from 'react-apollo-temp';
import { categoriesQuery } from '../../common/graphql/categories.query';
import { CategoriesPickerView } from './categories-picker.rn';

export const withCategories = graphql(categoriesQuery, {
  name: 'categoriesData',
  options: {
    fetchPolicy: 'network-only'
  }
});

export const CategoriesPicker = withCategories(CategoriesPickerView);
