import React, { useEffect, useState } from 'react';

const CategoryImage = props => {
  const [category, setCategory] = useState(null);
  useEffect(() => {
    setCategory(props.category);
  }, [props]);

  return (
    <div style={styles.container}>
      {category && category.icon ? (
        <img src={category.icon} style={styles.image} />
      ) : null}
    </div>
  );
};

export default CategoryImage;

const styles = {
  container: {
    height: 50,
    width: 50,
    display: 'flex',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: 30,
    height: 30,
    marginTop: 5
  }
};
