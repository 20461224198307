import gql from 'graphql-tag';
import { userFieldsFragment } from '../../login/graphql/me.fragment';

export const meQuery = gql`
  query Me {
    me: getUser {
      ...UserFields
    }
  }

  ${userFieldsFragment}
`;
