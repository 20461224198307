import { CreateEventFormView } from './events.rn';
import { graphql } from 'react-apollo-temp';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createUpdateEventMutation } from '../../graphql/create-update-event-mutation';
import { withEditEntity } from '../with-edit-feed-item.container';
import { toggleMyPosts } from '../../posts.actions';
import { navigateSecondStage } from '../../posts.actions';
import { uploadToCloudinaryMutation } from '../../../common/graphql/upload-to-cloudinary.mutation';
import { getDataFromFile } from '../../../cloudinary/utils/upload';
import { updateNewsFeedItemTargetsQuery } from '../../graphql/update-news-feed-item-targets.mutation';
import { publishMutation } from '../../graphql/publish-feed-item.mutation';

const mapDispatch = dispatch => ({
  navigateSecondStage: event => dispatch(navigateSecondStage(event)),
  toggleModal: (isOpen, userId) => dispatch(toggleMyPosts(isOpen)),
  close: () => dispatch(navigateSecondStage(null))
});

const mapStateToProps = ({ auth }) => ({
  currentUserName: auth.currentUser.name
});

export const withImageUploadMutation = graphql(uploadToCloudinaryMutation, {
  props: ({ ownProps, mutate }) => ({
    uploadFile: (fsFile, tag, fileData, filePath, publicId, update) =>
      mutate({
        variables: {
          ...getDataFromFile(fsFile),
          filePath: filePath,
          publicId: publicId,
          tag
        },
        optimisticResponse: {
          __typename: 'Mutation',
          uploadToCloudinary: {
            id: `upload_temp_id_${Date.now().toString()}`,
            filePath: `data:image/jpeg;base64,${fileData}`,
            __typename: 'CloudinaryFileUpload'
          }
        },
        update
      })
  })
});

const withState = connect(mapStateToProps, mapDispatch);

const withMutation = graphql(createUpdateEventMutation, {
  props: ({ ownProps, mutate }) => ({
    createUpdateEvent: (id, event) =>
      mutate({
        variables: {
          eventId: id,
          event
        }
      })
  })
});

const withTargetMutation = graphql(updateNewsFeedItemTargetsQuery, {
  props: ({ ownProps, mutate }) => ({
    updateTargets: (itemId, targets) =>
      mutate({ variables: { itemId, targets } })
  })
});

const withPublicationMutation = graphql(publishMutation, {
  props: ({ ownProps, mutate }) => ({
    publish: (itemId, platforms) => mutate({ variables: { itemId, platforms } })
  })
});

export const CreateEventForm = compose(
  withImageUploadMutation,
  withState,
  withMutation,
  withEditEntity,
  withTargetMutation,
  withPublicationMutation
)(CreateEventFormView);
