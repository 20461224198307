import gql from 'graphql-tag';

export const newsFeedNewsFragment = gql`
  fragment NewsFields on NewsFeedItemType {
    id
    title
    description
    posterImageUrl
    thumbUrl
    link
    
    creationDate
    displayCreationDate
    categories {
      id
      name
      icon
    }
    mainCategory {
      id
      name
      icon
    }
    displayAuthor {
      id
      profilePictureUrl
      name
      admin
    }
    author {
      id
      profilePictureUrl
      name
      admin
    }
    isNew
    likes
    comments {
      id
      parentId
      userId
      userName
      createdAt
      body
      email
      updatedAt
      reported
      likes
      postId
      userProfileImg
    }
    isSportsNews
    sportsCategory
  }
`;
