export class AuthorizationMiddleware {
  static token;

  static setToken(token) {
    AuthorizationMiddleware.token = token;
  }

  static getHeader() {
    return {
      'accounts-access-token': AuthorizationMiddleware.token || ''
    };
  }
}
