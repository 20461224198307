import gql from 'graphql-tag';
import { FeedNewsItemFragment } from './feed-news-item.fragment';
import { FeedPollItemFragment } from './feed-poll-item.fragment';
import { FeedEventItemFragment } from './feed-event-item.fragment';
import { FeedSportsEventItemFragment } from './feed-sports-event-item.fragment';

export const feedItemByIdQuery = gql`
  query feedItem($id: String!) {
    feedItem(id: $id) {
      __typename
      ...FeedNewsItem
      ...FeedPollItem
      ...FeedEventItem
      ...FeedSportsEventItem
    }
  }

  ${FeedNewsItemFragment}
  ${FeedPollItemFragment}
  ${FeedEventItemFragment}
  ${FeedSportsEventItemFragment}
`;