import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InstagramAuth } from '../instagram/components/instagram-auth-view.container';
import { TwitterAuth } from '../twitter/components/twitter-auth-view.container';
import { SnapchatEnrollment } from '../snapchat/components/snapchat-enrollment-view.container';
// import { ConfirmationModal } from '../common/components/confirmation-modal.rn';
import { FacebookAuth } from '../facebook/components/facebook-auth-view.container';
import { LinkedinAuth } from '../linkedin/components/linkedin-auth-view.container';
import { LangData } from '../assets/locales/localization';

export class EditLinkView extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    resetLink: PropTypes.func,
    setUser: PropTypes.func,
    network: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      confirmRemove: false,
      duringRemoval: false
    };

    this.facebookModal = null;
    this.linkedinModal = null;
  }

  removeSocialNetwork() {
    this.setState({
      confirmRemove: true
    });
  }

  updateSocialNetwork(network) {
    try {
      switch (network) {
        case 'LinkedIn': {
          return this.linkedinModal.open().then(() => {
            this.props.onClose();
          });
        }
        case 'Facebook': {
          return this.facebookModal.open().then(() => {
            this.props.onClose();
          });
        }
        case 'Snapchat': {
          return this.snapchatModal.open().then(() => {
            this.props.onClose();
          });
        }
        case 'Instagram': {
          return this.instagramModal.open().then(() => {
            this.props.onClose();
          });
        }
        case 'Twitter': {
          return this.twitterModal.open().then(() => {
            this.props.onClose();
          });
        }
        default: {
          return Promise.resolve();
        }
      }
    } catch (e) {
      return Promise.resolve();
    }
  }

  confirmRemove() {
    this.setState({ duringRemoval: true });

    this.props
      .resetLink(this.props.currentUser.id, this.props.network.toUpperCase())
      .catch(() => null)
      .then(result => {
        this.setState({ duringRemoval: false, confirmRemove: false }, () => {
          if (result && result.data && result.data.resetSocialLink) {
            this.props.setUser(result.data.resetSocialLink);
          }

          this.props.onClose();
        });
      });
  }

  render() {
    let temp = this.props.network;
    return (
      <div visible={this.props.visible} onClose={() => this.props.onClose()}>
        <FacebookAuth onInit={ref => (this.facebookModal = ref)} />
        <LinkedinAuth onInit={ref => (this.linkedinModal = ref)} />

        {/* <ConfirmationModal
          loading={this.state.duringRemoval}
          // options={[
          //   {
          //     name: strings.editLinkMenu.yes,
          //     onPress: () => this.confirmRemove(),
          //     color: Theme.ACTIVE2_COLOR,
          //   },
          //   {
          //     name: strings.editLinkMenu.no,
          //     onPress: () => {
          //       this.setState({ confirmRemove: false });
          //       this.props.onClose();
          //     },
          //     color: Theme.ACTIVE1_COLOR,
          //   },
          // ]}
          title={LangData.editLinkMenu.sure}
          question={LangData.editLinkMenu.remove + { name: temp }}
          open={this.state.confirmRemove}
          onClose={() => {
            this.setState({ confirmRemove: false });
            this.props.onClose();
          }}
        /> */}
        <div onClick={() => this.removeSocialNetwork(this.props.network)}>
          <span>trash icon</span>
          <span>
            {' '}
            {this.props.network
              ? LangData.editLinkMenu.remove +
                ' ' +
                { name: this.props.network }
              : ''}
          </span>
        </div>

        <div onClick={() => this.updateSocialNetwork(this.props.network)}>
          <span>refresh icon</span>
          <span>
            {this.props.network
              ? LangData.editLinkMenu.updateAccount +
                ' ' +
                { name: this.props.network }
              : ''}
          </span>
        </div>

        <div onClick={() => this.props.onClose()}>
          <span>close icon</span>
          <span>{LangData.editLinkMenu.close}</span>
        </div>

        <InstagramAuth onInit={modal => (this.instagramModal = modal)} />
        <TwitterAuth onInit={modal => (this.twitterModal = modal)} />
        <SnapchatEnrollment onInit={modal => (this.snapchatModal = modal)} />
      </div>
    );
  }
}
