import gql from 'graphql-tag';

export const meetingRemovedSubscription = gql`
    subscription {
        meetingRemoved {
            id
            meetingName
            meetingTime
            hostId
            hostEmail
            meetingNumber
            hostName
            isStarted
            isFinished
            meetingCode
            participantsList {
              userId
              userEmail
              userPhoneNumber
              registeredAt
           }
        }
    }
`