import gql from 'graphql-tag';
import { privacyFieldsFragment } from '../../profile/graphql/privacy-fields.fragment';

export const userFieldsFragment = gql`
  fragment UserFields on LoginUserType {
    id
    msjEmail
    username
    name
    firstName
    appVersionUpdated
    talkedWithYogi
    receiveMessages
    profilePictureUrl
    major
    classYear
    classification
    phoneNumber
    privacy {
      ...PrivacyFields
    }
    twitter
    instagram
    snapchat
    facebook
    facebookName
    linkedin
    linkedinLink
    duringProfilePictureApproval
    canImpersonate
    appVersionUpdated
    flags {
        isFirstLogin
      }
    originalUser {
      id
      msjEmail
      classification
      name
      flags {
        isFirstLogin
      }
      profilePictureUrl
      major
      classYear
      privacy {
        ...PrivacyFields
      }
      facebook
      facebookName
      linkedin
      twitter
      instagram
      snapchat
      linkedinLink
      appVersion
      appVersionUpdated
      fcmTokenUpdated {
        endpoint
        expirationTime
      }
      duringProfilePictureApproval
      canImpersonate
      appVersionUpdated
    }
    wayToGetCode
    openEndedQuestion
    lastTimeOpenEndedAnswered
  }

  ${privacyFieldsFragment}
`;
