import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const myChatsQuery = gql`
    query($userId: ID, $withMe: Boolean) {
        chats(userId: $userId, withMe:$withMe) {
            id
            title
            unreadMessagesCount
            enabled
            createdAt
            lastMessage {
                createdAt
                content
                messageType
            }
            lastUpdate
            users {
                ...ClassmateItem
            }
        }
    }

    ${classmateItemFragment}
`;
