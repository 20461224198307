import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notificationsStyles } from './common';
import ProfileImage from '../../../../common/components/profile-image.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export class ProfilePictureNotificationItem extends Component {
  static propTypes = {
    item: PropTypes.any
  };

  render() {
    const { item } = this.props;

    return (
      <div style={notificationsStyles.container}>
        <div style={notificationsStyles.imageContainer}>
          <ProfileImage
            imageUrl={null}
            fullSizeUrl={null}
            showBorder={false}
            size={50}
            withFullSize={false}
          />
        </div>
        <div style={notificationsStyles.contentContainer}>
          <span style={notificationsStyles.text}>{item.content}</span>
          <span style={notificationsStyles.date}>{item.time}</span>
        </div>
        <div style={notificationsStyles.extraContainer}>
          <ProfileImage
            style={styles.oldProfilePic}
            imageUrl={item.originalPhotoUrl}
            showBorder={false}
            size={30}
            withFullSize={false}
          />
          <FontAwesomeIcon
            icon={item.approved ? faAngleRight : faTimes}
            style={{ marginHorizontal: item.approved ? 0 : 2 }}
          />

          <ProfileImage
            imageUrl={item.photoUrl}
            showBorder={false}
            size={30}
            withFullSize={false}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  extraContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 65,
    flex: 0
  },
  oldProfilePic: {
    opacity: 0.4
  }
};
