import { combineReducers } from 'redux';
import { EFacebookActions } from './facebook.actions';
import { EAuthActions } from '../auth/auth.actions';

const isDuringAuthentication = (state = false, action) => {
  if (action.type === EFacebookActions.START_AUTH ||
    (action.type === EAuthActions.RELOAD_USER && action.reason === 'facebook')) {
    return true;
  }

  if (action.type === EAuthActions.USER_LOADED) {
    return false;
  }

  return state;
};

export const facebook = combineReducers({
  isDuringAuthentication,
});
