import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import { ProfilePictureNotificationItem } from './items/profile-picture-notification.rn';
import { SocialNetworkNotificationItem } from './items/social-network-notification.rn';
import { TextNotificationItem } from './items/text-notification.rn';
import { EventWallApprovalNotificationItem } from './items/event-wall-picture-approval-notification.rn';
import { UserGalleryNotificationItem } from './items/user-gallery-approval-notification.rn';
import { FeedEventModal } from '../../../news-feed/components/feed-event-modal/feed-event-modal.container';
import { classmateItemFragment } from '../../../classmates/graphql/classmate-item.fragment';
import debounce from 'lodash.debounce';
import { StatelessUserProfileModal } from '../../../profile/components/profile/user-profile-modal.container';
import EmptyNotice from '../../../common/components/empty-notice.rn';
import { LangData } from '../../../assets/locales/localization';
import { Theme } from '../../../common/providers/colors';
import { NotificationAddedSubscription } from '../../graphql/notification-added.subscription';
import { apolloClient } from '../../../apollo-subs';
import { NotificationUpdatedSubscription } from '../../graphql/notification-updated.subscription';

const width = window.innerWidth;

export class NotificationsListView extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    notifications: PropTypes.array,
    loadOlder: PropTypes.func,
    reload: PropTypes.func,
    close: PropTypes.func,
    notifyReadStatus: PropTypes.func,
    client: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      userModal: null,
      profileModalOpen: false,
      eventModal: null,
      scrollPosition: 0,
      myNotifications: null,
      newExist: false,
      enterComponent: false,
      isNotified: false
    };

    this.notifyReadStatus = debounce(this.notifyReadStatus.bind(this), 100);
  }

  subscribeToUpdates = () => {
    apolloClient.subscribe({
      query: NotificationUpdatedSubscription,
      variables: {}
    })
    .subscribe({
      next: (data) => {
        console.log('got upadted notification from subs!', data.notificationUpdated)
        let updatedNotification = data.notificationUpdated
        if(updatedNotification !== null)  {
          let currentId = localStorage.getItem('userId')
          if(currentId.toString() === updatedNotification.userId.toString()) {
            console.log('equals')
            let idx = this.state.myNotifications.findIndex(item => item.id.toString() === updatedNotification.id.toString())
            console.log('idx', idx)
            console.log('this.state.notification', this.state.myNotifications)
            if(idx !== -1){
                let cpy = [...this.state.myNotifications]
                cpy[idx] = updatedNotification
                console.log('new cpy notification =>', cpy)
                this.setState({ myNotifications: cpy })
  
            }
          }
        }
      }
    })
  }

  subscribeToData = () => {
    apolloClient.subscribe({
      query: NotificationAddedSubscription,
      variables: {}
    })
    .subscribe({
      next: (data) => {
        console.log('got new notification from subs!', data.notificationAdded)
        let newItem = data.notificationAdded
        if(this.props.inComponent) {
          newItem.read = false
        }
        this.setState({ myNotifications: [newItem, ...this.state.myNotifications]}, () => console.log('this.state.notidications', this.state.myNotifications))
      }
    })
  }

  loadMoreData() {
    this.props.loadOlder();
  }

 
  notifyReadStatus(userId, notificationId) {
    console.log('notify!', notificationId)
    if (notificationId) {
    this.props.notifyReadStatus(userId, notificationId).catch(() => null);
    let cpy = [...this.state.myNotifications]
    let newItems = []
    cpy.map(elem => { 
      let item = {...elem}
      item.read = true
      newItems.push(item)
    })
    this.setState({ myNotifications: newItems })
    }
  }

  componentWillMount = () => {
    this.subscribeToData()
    this.subscribeToUpdates()
  }

  componentDidMount = () => {
    if (this.props.notifications &&  this.props.notifications.length > 0 && this.props.myNotifications === null) {
      this.setState({ myNotifications: this.props.notifications})
    }
  }


  componentDidUpdate = () => {
    console.log('inside did update notif!',this.props.inComponent)
    if(this.props.inComponent && !this.state.enterComponent) {
      console.log('enter component!')
      this.setState({ enterComponent: true})
      // setTimeout(() => {
     
      // }, 5000)
    }
    if(!this.props.inComponent && this.state.enterComponent) {
      console.log('exit component!')
      console.log('notifications =>', this.state.myNotifications)
      let currentId = localStorage.getItem('userId')
      console.log('currentId', currentId)
      if(this.state.myNotifications.length > 0 && !this.state.isNotified){
        this.notifyReadStatus(currentId, this.state.myNotifications[0].id);
        this.setState({ isNotified: true})
      }
    }
  }




  componentWillReceiveProps(newProps) {
    console.log('props this.state.myNotifications', this.state.myNotifications)
    console.log('this.state.myNotifications', this.state.myNotifications)
    if((newProps.notifications && this.state.myNotifications === null) || 
       (newProps.notifications && this.state.myNotifications.length === 0)) {
      this.setState({ myNotifications: newProps.notifications})
    }

    if (this.state.userModal) {
      const updatedUser = this.props.client.readFragment({
        id: `User:${this.state.userModal.id}`,
        fragment: classmateItemFragment
      });

      this.setState({
        userModal: updatedUser
      });
    }
  }

  renderItem(item) {
    if (item.__typename === 'ListTitle') {
      return (
        <span style={styles.titleStyle} key={item.id}>
          {item.title}
        </span>
      );
    }

    let content = null;
    let isDisabled = false;
    let onClick = () => null;

    if (item.__typename === 'ProfilePictureNotification') {
      content = <ProfilePictureNotificationItem item={item} />;
      onClick = () => {
        this.props.toggleComponent('Profile');
        this.props.history.push('/profile');
      };
    } else if (item.__typename === 'BaseNotificationType') {
      content = <SocialNetworkNotificationItem item={item} />;
      onClick = () => {
        this.props.toggleModal(true, item.otherUser.id);
        if(item.content.includes("like") || item.content.includes('comment')) {
          // this.props.toggleComponent('News_Feed');
          // this.props.history.push('/');
          let currentId = localStorage.getItem('userId')
          this.props.history.push(`/show-post/${item.relatedFeedItemId}/${currentId}`)
        } else {
          this.props.toggleComponent('UserProfile');
          this.props.history.push('/userProfile');
        }
      };
    } else if (item.__typename === 'TextNotification') {
      content = <TextNotificationItem item={item} />;
      isDisabled = true;
    } else if (item.__typename === 'EventWallPictureApprovalNotification') {
      content = <EventWallApprovalNotificationItem item={item} />;
      onClick = () => {
        this.setState({ eventModal: item.event });
        let position = document.documentElement.scrollTop;
        document.body.style.position = 'fixed';
        this.setState({
          scrollPosition: position
        });
      };
    } else if (item.__typename === 'UserGalleryNotification') {
      content = <UserGalleryNotificationItem item={item} />;
      onClick = () => {
        this.props.toggleComponent('Profile');
        this.props.history.push('/profile');
      };
    }

    if (content && content !== null) {
      return (
        <div disabled={isDisabled} onClick={onClick} key={item.id}>
          {content}
        </div>
      );
    }

    return null;
  }

  onRefresh() {
    this.setState({
      refreshing: true
    });

    this.props
      .reload()
      .catch(() => null)
      .then(() => {
        this.setState({
          refreshing: false
        });
      });
  }

  get notificationsArray() {
    const notifications = Array.from(this.state.myNotifications || []);
    const firstUnreadItemIndex = notifications.findIndex(
      notification => !notification.read
    );

    if (firstUnreadItemIndex >= 0) {
      notifications.unshift({
        __typename: 'ListTitle',
        id: 'new',
        title: 'New'
      });
    } 
    const firstReadItemIndex = notifications.findIndex(
      notification => notification.read
    );

    if (firstReadItemIndex >= 0) {
      notifications.splice(firstReadItemIndex, 0, {
        __typename: 'ListTitle',
        id: 'earlier',
        title: 'Earlier'
      });
    }

    return notifications;
  }

  render() {
    if (this.props.loading) {
      return <LoadingSpinner left={window.innerWidth * 3} />;
    }

    const data = this.notificationsArray;

    if (data && data.length === 0) {
      return (
        <EmptyNotice
          emoji='⚒'
          subTitle={LangData.notificationsList.soon}
          title={LangData.notificationsList.nothing}
        />
      );
    }

    return (
      <div id="notif-container" style={styles.container}>
        <FeedEventModal
          onClose={() => {
            document.body.style.position = '';
            window.scrollTo(0, this.state.scrollPosition);
            this.setState({ eventModal: null });
          }}
          event={this.state.eventModal}
          history={this.props.history}
        />

        {this.state.refreshing && (
          <LoadingSpinner left={window.innerWidth * 3} />
        )}

        <div style={styles.list}>
          {data.map(item => {
            return this.renderItem(item);
          })}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    flexGrow: 1
  },
  list: {
    flexGrow: 1,
    marginTop: '18%',
  },
  titleStyle: {
    fontWeight: 'bold',
    fontSize: 14,
    marginLeft: 20,
    color: Theme.TEXT_COLOR
  }
};
