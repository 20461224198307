import gql from 'graphql-tag';

export const instagramDetailsQuery = gql`
    query {
        instagramAuth {
            clientId
            returnUrl
        }
    }
`;
