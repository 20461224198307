import { combineReducers } from 'redux';

export const CONTINUE_SESSION = { type: 'session/continue' };
export const DISMISS_ERROR = { type: 'forms/dismiss' };
export const LOGGING_OUT = { type: 'session/logging_out' };

export const loggingOut = () => dispatch => {
  dispatch(LOGGING_OUT);
};

const isLoading = (state = false, action) => {
  if (
    action.type === 'js-accounts/CLEAR_USER' ||
    action.type === 'js-accounts/CLEAR_TOKENS' ||
    action.type === 'js-accounts/SET_USER' ||
    action.type === 'FORMS/FORM_ERROR' ||
    action.type === LOGGING_OUT.type ||
    action.type === DISMISS_ERROR.type
  ) {
    return false;
  }

  if (action.type === CONTINUE_SESSION.type) {
    return true;
  }

  if (action.type === 'js-accounts/LOGGING_IN') {
    return action.payload.isLoggingIn;
  }

  return state;
};

export const intro = combineReducers({
  isLoading
});
