import gql from 'graphql-tag';
import { FeedNewsItemFragment } from './feed-news-item.fragment';
import { FeedPollItemFragment } from './feed-poll-item.fragment';
import { FeedEventItemFragment } from './feed-event-item.fragment';
import { FeedSportsEventItemFragment } from './feed-sports-event-item.fragment';

export const FeedQuery = gql`
  query Feed($userId: ID, $categories: [String], $onlyReminders: Boolean, $skip: Int, $limit: Int) {
    feedList(userId: $userId, categories: $categories, onlyReminders: $onlyReminders, skip: $skip, limit: $limit) {
      items {
        __typename
        ...FeedNewsItem
        ...FeedPollItem
        ...FeedEventItem
        ...FeedSportsEventItem
      }
      hasMore
    }
  }

  ${FeedNewsItemFragment}
  ${FeedPollItemFragment}
  ${FeedEventItemFragment}
  ${FeedSportsEventItemFragment}
`;
