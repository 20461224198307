import React, { useState, useRef, useEffect, Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { ChatList } from '../chat/components/chats-list/chat-list.container';
import { Schedule } from '../schedule-view/components/schedule.container';
import { FriendsList } from '../friends/components/friends-list.container';
import { FeedList } from '../news-feed/components/feed-list/feed-list.container';
import { NewsFeedCategoriesGrid } from '../news-feed/components/categories-grid/categories-grid.container';
import { Redirect } from 'react-router';
import { NotificationModal } from '../notification/components/notification-modal.container';
import './main-page-view.scss';
import ReactAudioPlayer from 'react-audio-player';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faBell,
  faCalendarAlt
} from '@fortawesome/free-regular-svg-icons';
import {
  faHome,
  faBars,
  faPlusCircle,
  faQuestion
} from '@fortawesome/free-solid-svg-icons';
import { MyNotifications } from '../my-notifications/components/my-notifications.container';
import { Menu } from '../menu/menu.container';
// import { FilterPosts } from '../filter-posts/filter-posts.container';
import AddToHomescreen from 'react-add-to-homescreen';
import { Theme } from '../common/providers/colors';
import logoSrc from '../assets/images/LogoDark.png';
import ProfileImage from '../common/components/profile-image.rn';
import { refreshUser } from '../account/account.util';
import { Animated } from 'react-animated-css';
import BurgerMenu from '../burgerMenu/BurgerMenu'
import notificationsLogoLight from '../assets/images/notificationsLogoLight.png';
import friendsLogoLight from '../assets/images/friendsLogoLight.png';
import homeLight from '../assets/images/homeLight.png';
import chatsLogoLight from '../assets/images/chatsLogoLight.png';
import plusLogoLight from '../assets/images/plusLogoLight.png';
import closeLogoLight from '../assets/images/closeLogoLight.png';
import Chatbot from '../chatbot/Chatbot';
import { getClient } from '../apollo'
import { userTalkedWithYogi } from './graphql/userTalkedWithYogi'
import { userCompletedFirstLogin } from './graphql/userCompletedFirstLogin'
import Yogi from '../assets/images/Yogi.png'
import yogiBlob from '../assets/images/yogiBlob.png'
import {CreateUpdateNewsForm} from './AddPosts/AddPostContainer'
import { NotificationAddedSubscription } from '../my-notifications/graphql/notification-added.subscription'
import { apolloClient } from '../apollo-subs';
import { friendAddedSubscription } from '../friends/graphql/friend-added.subscription';
import { chatAddedSubscription } from '../chat/graphql/chat-added.subscription';
import { chatMessageAddedSubscription } from '../chat/graphql/message-added.subscription';
import { GeneralComponent } from "./general-page-container";
import './main-page-view.scss';

const width = window.innerWidth

const addPostTextOptions = [
  "Tell us what's on your mind",
  "Share your experiences with yoga",
  "Tell us about your experiences with cancer",
  "Share your story with us",
  "Tell us your tips for beating cancer",
  "Tell us your tips for practicing yoga"
]

function MainView(props) {
  const [currIndex, setCurrIndex] = useState(
    props.currentComponent === 'Menu'
      ? 4
      : props.currentComponent === 'Notifications'
        ? 3
        : props.currentComponent === 'Chat'
          ? 1
          : props.currentComponent === 'Friends' ? 
              2
            : 0
  );
  const [isMenuOpen, setIsMenuOpen] = useState(width < 500 ? false : true);
  const [isYogiOpen, setIsYogiOpen] = useState(false);
  const [isLowerSubMenuOpen, setIsLowerSubMenuOpen] = useState(false)
  const [updatedTalkWithYogi, setUpdatedTalkWithYogi] = useState(false)
  const [addPostOpen, setAddPostOpen] = useState(false)
  const [displayBubble, setDisplayBubble] = useState(true)
  const [countBubble, setCountBubble] = useState(0)
  const [countFriendsBubble, setCountfriendsBubble] = useState(0)
  const [countChatBubble, setCountChatBubble] = useState(0)
  const [randomTextSelected, setRandomTextSelected] = useState(addPostTextOptions[Math.floor(Math.random() * addPostTextOptions.length)])
  const [currentChat, setCurrentChat] = useState(null)
  let _intervalHandler  =  null
  // const [showOpenQuestionModal, setShowOpenQuestionModal] = useState(false);
  // const [userAnswerToOpenQuestion, setUserAnswerToOpenQuestion] = useState('');


  



  const handleAddToHomescreenClick = () => {
    alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);
  };

  const handleChangeIndex = async index => {
    setCurrIndex(index);
    if (index === 0) props.toggleComponent('News_Feed');
    if (index === 1) props.toggleComponent('Chat');
    if (index === 2) props.toggleComponent('Friends');
    if (index === 3) props.toggleComponent('Notifications');
    if (index === 4) props.toggleComponent('Schedule')

    scrollToTop();
  };

  const updateUserFirstLogin = () => {
    if (props.currentUser && props.currentUser.flags.isFirstLogin) {
      getClient().mutate({
        mutation: userCompletedFirstLogin,
        variables: {
          userId: props.currentUser.id
        }
      })
        .then(_ => {
          const storedUser = JSON.parse(localStorage.getItem('user'))
          storedUser.flags.isFirstLogin = false;
          localStorage.setItem('user', JSON.stringify(storedUser))
        })
    }
  }

  const playYogi = async () => {
    let soundOn = await localStorage.getItem('soundOn')
    if(soundOn !== null && soundOn === 'true') {
      let audio = new Audio('/sounds/yogiSound.mp3')
      audio.load()
      if(displayBubble === true) audio.play()
    }
  }


  const subscribeToNewFriends = () => {
    apolloClient.subscribe({
      query: friendAddedSubscription,
      variables: {}
    })
    .subscribe({
      next: (data) => {
        console.log('friend added bubble!', data)
        let newConnection = data.friendAdded
        let currentId = localStorage.getItem('userId')

        if(newConnection.userAdded.toString() === currentId.toString()) {
          setCountfriendsBubble(prevState => prevState + 1)
        }
       
      }
    })
  }

  const subscribeToNewMesseages = () => {
    apolloClient.subscribe({
      query: chatMessageAddedSubscription,
      variables: {}
    })
    .subscribe({
      next: (data) => {
        console.log('new message sent subs', data)
      }
    })
  }

  const subscribeToNewChats =  () => {
    apolloClient.subscribe({
      query: chatAddedSubscription,
      variables: {}
    })
    .subscribe({
      next: (data) => {
        console.log('new chat bubble!', data)
        let newChat = data.chatAdded
        let usersIds = []
        newChat.users.map(user => usersIds.push(user.id))
        if(usersIds.includes(this.props.currentUser.id.toString())) {
          setCountChatBubble(prevState => prevState + 1)
        }
      }
    })
  }

  const subscribeToNewNotifications = () => {
    apolloClient.subscribe({
      query: NotificationAddedSubscription,
      variables: {}
    })
    .subscribe({
      next: (data) => {
        console.log('new notification subs!', data)
        console.log('props currentUser', props.currentUser)
        console.log('countBubble', countBubble)
        let newNotification = data.notificationAdded
        let currentId = localStorage.getItem('userId')
        if(currentId) {
          if(newNotification.userId.toString() === currentId) {
            if(currIndex === 3) setCountBubble(0);
            else setCountBubble(prevState => prevState + 1)
          }
        }
       
      }
    })
  }


  useEffect(() => {
    document.getElementById('zmmtg-root').style.display = 'none'
    console.log('currentIndex =>', currIndex)
  }, [])


  useEffect(() => {
    document.getElementById('zmmtg-root').style.display = 'none'
    window.scrollTo(0, 1)
    if (props.currentComponent === 'News_Feed') {setCurrIndex(0);}
    if (props.currentComponent === 'Schedule') setCurrIndex(4);
    if (props.currentComponent === 'Chat') setCurrIndex(1);
    if (props.currentComponent === 'Notifications') setCurrIndex(3);
    if (props.currentComponent === 'Friends') setCurrIndex(2);
    if (props.currentComponent === 'Menu') setCurrIndex(0);
    updateUserFirstLogin()
    subscribeToNewNotifications()
    subscribeToNewFriends()
    subscribeToNewChats()
    subscribeToNewMesseages()
  }, [props.currentComponent]);


  const getMessagesObj = () => {
    let messages = { messages: [], hasMore: false };

    try {
      let chat =  null;
      if (
        props.chatData &&
        props.chatData.chat &&
        props.chatData.chat.users
      ) {
        setCurrentChat(props.chatData.chat);
        chat =  props.chatData.chat
      } else if (
        props.chat &&
        props.chat.users &&
        props.chat.users.length > 0
      ) {
        setCurrentChat(props.chat)
        chat = props.chat
      }
      else {
        setCurrentChat(null);
        chat = null;
      }
      // eslint-disable-next-line
      messages = chat.messages || messages;
    } catch (e) {
      // nothing...
    }

    return messages;
  }






  const refreshUserFunction = async () => {
    let user = await refreshUser();
    console.log('user1 =>', user)
    if (user) {
      props.setUser(user);
      console.log('update user!')
    }
  };


  useEffect( () => {
    refreshUserFunction()
  }, [props])


  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  if (!props.currentUser) {
    let url_string = window.location.href;
    console.log('url string =>', url_string)
    let url = new URL(url_string);
    let eventId = url.searchParams.get('id');
    let videoId = url.searchParams.get('video');
    if (eventId) localStorage.setItem('eventToDisplay', eventId);
    else localStorage.setItem('eventToDisplay', 'none');
    if (videoId) localStorage.setItem('videoToDisplay', videoId);
    else localStorage.setItem('videoToDisplay', 'none');
    return <Redirect to='/intro' />;
  }
 

  let videoIdFromUrl = localStorage.getItem('videoToDisplay');
  console.log('videoIdFromUrl', videoIdFromUrl)
  if (videoIdFromUrl && videoIdFromUrl !== 'none') {
    props.history.push('/videos');
    props.toggleComponent('Videos');
  }



  const userTalkedWithYogiUpdate = () => {
    if (!props.currentUser.talkedWithYogi && !updatedTalkWithYogi) {
      getClient().mutate({
        mutation: userTalkedWithYogi,
        variables: {
          userId: props.currentUser.id
        }
      })
        .then(_ => {
          setUpdatedTalkWithYogi(true)
        })
    }
  }

  return (
    <div style={styles.mainPage} className='main-page-container'>
      <div
        style={
          props.currentComponent === 'Schedule'
            ? styles.topNavGray
            : styles.topNav
        }
      >
        <div
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
          style={styles.menu}
        >
          <BurgerMenu />
          {/* <FontAwesomeIcon icon={faBars} /> */}
        </div>

        <img src={logoSrc} style={styles.logo} alt="" />

        <ProfileImage
          showBorder={false}
          withFullSize={false}
          height={35}
          width={35}
          imageUrl={
            props.currentUser ? props.currentUser.profilePictureUrl : null
          }
          onClick={() => {
            refreshUserFunction();
            props.toggleComponent('Profile');
            props.history.push('profile');
          }}
        />
      </div>
   

          <GeneralComponent 
            currIndex={currIndex}
            handleChangeIndex={handleChangeIndex}
            history={props.history}
          />
             {isMenuOpen && (
                <Menu
                history={props.history}
                isOpen={isMenuOpen}
                closeMenu={() => setIsMenuOpen(false)}
                setIndex={index => setCurrIndex(index)}
                />
            )}
      {/* {(props.currentComponent === 'News_Feed' ||
        props.currentComponent === 'Filter') && (
        <FilterPosts history={props.history} />
      )} */}

      {
        width < 500 &&
        <div style={styles.outerNav}>
          {
            isLowerSubMenuOpen &&
            <Fragment>
              <button onClick={() => {
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'Pose'
                  }
                });
              }} style={styles.outerNavSubMenuBtn}>Yoga Poses</button>
              <button onClick={() => {
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'session'
                  }
                });
              }} style={styles.outerNavSubMenuBtn}>Yoga Session</button>
              <button onClick={() => {
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'modification'
                  }
                });
              }} style={styles.outerNavSubMenuBtn}>Yoga Modifications</button>
            </Fragment>
          }
          <div style={styles.innerMenu}>
            <div
              style={currIndex === 0 ? styles.innerNavSelected : styles.innerNav}
              onClick={() => {
                setCurrIndex(0);
                props.toggleComponent('News_Feed');
              }}
            >
              <img src={homeLight}  alt="" style={{ height: '5vh'}}/>
            </div>
            <div
              style={currIndex === 1 ? styles.innerNavSelected : styles.innerNav}
              onClick={() => {
                setCurrIndex(1);
                props.toggleComponent('Chat');
              }}
            >
              {
                countChatBubble > 0 &&  <div style={styles.chatBubble}>{countChatBubble}</div>
              }
              <img src={chatsLogoLight}  alt="" style={{ height: '5vh'}}/>
            </div>
            <div
              style={styles.innerNav}
              onClick={() => {
                setIsLowerSubMenuOpen(!isLowerSubMenuOpen)
              }}
            >
              <img src={!isLowerSubMenuOpen ? plusLogoLight : closeLogoLight} alt="" style={{ height: '5vh'}}/>
            </div>
            <div
              style={currIndex === 2 ? styles.innerNavSelected : styles.innerNav}
              onClick={() => {
                setCurrIndex(2);
                props.toggleComponent('Friends');
              }}
            >
              {
                countFriendsBubble > 0 &&  <div style={styles.friendsBubble}>{countFriendsBubble}</div>
              }
              <img src={friendsLogoLight} alt="" style={{ height: '5vh'}}/>
            </div>
            <div
              style={currIndex === 3 ? styles.innerNavSelected : styles.innerNav}
              onClick={() => {
                setCountBubble(0); 
                setCurrIndex(3);
                props.toggleComponent('Notifications');
              }}
            >
              {
                countBubble > 0 &&  <div style={styles.bubble}>{countBubble}</div>
              }
              <img src={notificationsLogoLight} alt="" style={{ height: '5vh'}}/>
            </div>
          </div>
        </div>
      }

      {/* {showOpenQuestionModal && (
        <div style={styles.fullPage}>
          <Animated
            animationIn='pulse'
            animationOut='fadeOut'
            isVisible={showOpenQuestionModal}
          >
            <div style={styles.msgContainer}>
              <FontAwesomeIcon icon={faQuestion} style={styles.icon} />
              <p>{props.currentUser.openEndedQuestion}</p>
              <input
                type='text'
                value={userAnswerToOpenQuestion}
                onChange={e => setUserAnswerToOpenQuestion(e.target.value)}
                style={styles.userAnswerInput}
              />

              <div style={styles.buttonsRow}>
                <button
                  style={styles.btn}
                  onClick={() => {
                    setShowOpenQuestionModal(false);
                    props.answerOpenEndedQuestion(
                      props.currentUser.openEndedQuestion,
                      userAnswerToOpenQuestion
                    );
                  }}
                >
                  Send
                </button>
                <button
                  style={styles.btn}
                  onClick={() => {
                    setShowOpenQuestionModal(false);
                    props.answerOpenEndedQuestion(
                      props.currentUser.openEndedQuestion,
                      'skip'
                    );
                  }}
                >
                  Skip
                </button>
              </div>
            </div>
          </Animated>
        </div>
      )} */}
    </div>
  );
}

export default MainView;

const styles = {
  bubble: {
    backgroundColor: 'red',
    fontSize: '13px',
    height: 'fit-content',
    padding: '0.7% 2.2%',
    borderRadius: '50%',
    position: 'absolute',
    top: '18%',
    right: '6%'
  },
  mainPage: {
    // minHeight: '100vh',
    backgroundColor: Theme.BACKGROUND_COLOR
  },
  outerNav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    position: 'fixed',
    bottom: 0,
    zIndex: 9,
    backgroundColor: '#4d4b62',
    borderRadius: '20px 20px 0 0',
    padding: '0'
  },
  innerMenu: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  topNav: {
    display: 'flex',
    justifyContent:  width < 500 ?'space-around': 'space-between',
    alignItems: 'center',
    width: window.innerWidth,
    paddingRight: width < 500? 0 : '3vw',
    paddingTop: '2%',
    paddingBottom: width < 500 ? '0' : '2%',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    backgroundColor: Theme.BACKGROUND_COLOR
  },
  topNavGray: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    backgroundColor: Theme.GRAY_COLOR
  },
  innerNav: {
    textAlign: 'center',
    fontSize: '35px',
    color: Theme.LOWER_NAV_ICONS_COLOR,
    paddingBottom: '1%',
    display: 'flex'
  },
  innerNavSelected: {
    textAlign: 'center',
    fontSize: '35px',
    color: Theme.WHITE_COLOR,
    paddingBottom: '1%',
    borderBottom: `2px solid ${Theme.WHITE_COLOR}`
  },
  logo: {
    width: width < 500 ? '50%' : '20%',
    padding: '1%'
  },
  menu: {
    color: Theme.BLACK_COLOR,
    fontSize: '25px',
    marginLeft: width < 500 ? '0' : '5%'
  },
  fullPage: {
    minHeight: window.innerHeight,
    width: window.innerWidth,
    opacity: '0.9',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '999999',
    textAlign: 'center',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0'
  },
  msgContainer: {
    width: window.innerWidth / 2 + 'px',
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Theme.TEXT_COLOR
  },
  btn: {
    color: Theme.TEXT_COLOR,
    backgroundColor: Theme.BACKGROUND_COLOR,
    border: 'none',
    padding: '7%',
    fontWeight: 'bold',
    fontSize: window.innerWidth / 24 + 'px',
    fontFamily: 'inherit',
    width: '50%',
    margin: '1%'
  },
  icon: {
    color: Theme.BACKGROUND_COLOR,
    fontSize: window.innerWidth / 7.2 + 'px',
    marginTop: '5%'
  },
  buttonsRow: {
    width: '100%',
    display: 'flex'
  },
  userAnswerInput: {
    border: '1px solid #dcd7c8',
    fontFamily: 'inherit',
    marginBottom: '10%',
    padding: '5%',
    fontSize: '15px',
    borderRadius: '15px'
  },
  talkWithYourYogiBtn: {
    width: "100%",
    borderRadius: "25px",
    border: "none",
    padding: "5px 10px",
    backgroundColor: "transparent",
    color: '#665f70',
    // margin: '5%'
    display: 'flex',
    flexDirection: 'row-reverse',
    outline: '0'
  },
  exitIcon: {
    position: "absolute",
    zIndex: 10000,
    top: "18%",
    left: "94%",
    width: "20%",
    fontSize: "18px",
    color: "#978689",
    cursor: "pointer"
  },
  talkWithYourYogiContainer: {
    // marginTop: width < 500 ? window.innerWidth / 7.2 + 'px' : window.innerWidth / 11 + 'px',
    // width: 'fit-content',
    // display: "flex",
    // justifyContent: "center",
    position: 'absolute',
    zIndex: '10000',
    top: '16%',
    left:'80%',
    width:'20%',
    flexDirection: 'row-reverse'

  },
  outerNavSubMenuBtn: {
    width: '70%',
    borderRadius: '25px',
    padding: '2%',
    backgroundColor: '#e1c2b0',
    color: '#4D4B62',
    fontSize: 'large',
    marginBottom: '2%'
  },
  addNewPostContainer: {
    marginTop: width < 500 ? (window.innerWidth / 7.2  - 2 )+ 'px'  : window.innerWidth / 11 + 'px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  addNewPostBtn: {
    outline:'none',
    width: "80%",
    borderRadius: "25px",
    border: "none",
    padding: "5px 10px",
    backgroundColor: "#e1c2b0",
    color: '#665f70',
    margin: '5%',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
  },
  yogiLogo: {
    width: 'inherit'
  },
  yogiBlobStyle: {
    width: '230px',
    height: '100%',
    position: 'absolute',
    top: '-38%',
    backgroundImage: `url(${yogiBlob})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  yogiBlobTextStyle: {
    width: '30%',
    textAlign: 'center',
    fontSize: '11px'
  },
  addPostToggler: {
    display: 'inline-block',
    transform: 'rotate(90deg)',
    fontSize: '20px',
    padding: '4px 8px',
    backgroundColor: '#4D4B62',
    borderRadius: '50%',
    color: '#E1C2B0',
    fontWeight: 'bolder'
  }
};
