import { ActiveChatScreenModalView } from './active-chat-screen-modal.rn';
import { connect } from 'react-redux';
import { chatNavigate } from '../../chat.actions';

const mapStateToProps = ({ chat, auth }) => ({
  currentChat: chat.currentChat,
  currentUser: auth.currentUser
});

const mapDispatch = dispatch => ({
  close: () => dispatch(chatNavigate(null))
});

const withState = connect(mapStateToProps, mapDispatch);

export const ActiveChatScreenModal = withState(ActiveChatScreenModalView);
