import { getUploadConfig } from './upload-config';
import { fetchWithProgress } from './fetch-with-progress';

const getFilenameFromPath = path => {
  // eslint-disable-next-line
  const regex = /([^\/]+)\.[a-z]+$/i;

  return regex.exec(path)[1];
};

export const getDataFromFile = (id, filePath) => {
  return {
    uri: 1,
    type: 1,
    name: 1,
    id,
    filePath
  };
};

export async function uploadToCloudinary(
  fileData,
  onProgress = null,
  tags = []
) {
  try {
    const uploadConfig = await getUploadConfig();
    const body = new FormData();

    body.append('upload_preset', uploadConfig.presetName.toString());
    body.append('tags', ['app_upload', ...tags].join(','));
    body.append('file', fileData);

    return await fetchWithProgress(
      uploadConfig.uploadUrl,
      { method: 'POST', body },
      onProgress
    );
  } catch (e) {
    console.log('Failed to upload to Cloudinary: ', e);

    return null;
  }
}
