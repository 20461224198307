import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withImageUploadMutation } from '../../../common/hoc/upload-to-cloudinary.container';
import { compose } from 'redux';
import { withCurrentUser } from '../../../common/hoc/with-current-user.container';
import { SendMessageInputView } from './send-message-input.rn';
import { chatQuery } from '../../graphql/chat-data.query';
import moment from 'moment';
import { uploadToCloudinary } from '../../../cloudinary/utils/upload';
import { apolloClient } from '../../../apollo-subs';
import { sendMessageMutation } from '../../graphql/send-message.mutation';
const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const withUploadAndCreateMessage = ComposedComponent =>
  class extends Component {
    static propTypes = {
      uploadFile: PropTypes.func.isRequired,
      currentUser: PropTypes.any.isRequired,
      onSendMessage: PropTypes.func.isRequired
    };

    async createImageMessage(chatId, file, tag) {
      const messageTempId = `temp_${Date.now().toString()}`;
      const fileData = await toBase64(file);
      const base64 = `data:image/jpeg;base64,${fileData}`;
      // const uploadResult = await uploadToCloudinary(file, null, tag);

      // const publicId = uploadResult.public_id;
      // const filePath = uploadResult.secure_url;

      let data = await this.props.uploadAzureFile(
        file,
        tag,
        base64,
        chatId)
    
      console.log('data =>', data)
      const imageUrl = data.filePath;
            // const chatState = store.readQuery({
            //   query: chatQuery,
            //   variables: {
            //     id: chatId,
            //     cursor: null
            //   }
            // });

   

      apolloClient.mutate({
        mutation: sendMessageMutation,
        variables: {
          userId: this.props.currentUser.id,
          chatId: chatId,
          content: base64,
          messageType: "IMAGE",
          imageData: imageUrl
        }
      })

            // chatState.chat.messages.messages.push(messageObject);

            // store.writeQuery({
            //   query: chatQuery,
            //   variables: {
            //     id: chatId,
            //     cursor: null
            //   },
            //   data: chatState
            // });

            // if (!uploadToCloudinary.id.startsWith('upload_temp_id_')) {
            //   this.props.onSendMessage('IMAGE', uploadToCloudinary.id, base64);
            // }
        //   } catch (e) {
        //     // nothing to do here
        //   }
        // }
      }


    render() {
      return (
        <ComposedComponent
          {...this.props}
          createImageMessage={this.createImageMessage.bind(this)}
        />
      );
    }
  };

export const SendMessageInput = compose(
  withCurrentUser,
  withImageUploadMutation,
  withUploadAndCreateMessage
)(SendMessageInputView);
