import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddToCalendar from 'react-add-to-calendar';
import { LangData } from '../..//assets/locales/localization';
import './calendar-export.scss';

export class CalendarExportHandler extends Component {
  static propTypes = {
    onInit: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {}

  render() {
    return (
      <div onClick={e => e.stopPropagation()}>
        <AddToCalendar event={this.props.event} />
      </div>
    );
  }
}
