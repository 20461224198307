import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ProfileImage from '../../../common/components/profile-image.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../common/providers/colors';

export class ChatUsersListModal extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  renderUsersListItem(item) {
    return (
      <div style={styles.userItem}>
        <ProfileImage
          showBorder={true}
          withFullSize={false}
          height={40}
          width={40}
          imageUrl={item.profilePictureUrl}
        />
        <div style={styles.details}>
          <span style={styles.name}>{item.name}</span>
          {item.major && item.major !== '' ? (
            <span style={styles.major}>{item.major}</span>
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.props.isOpen ? (
          <div
            id='rw36'
            style={styles.backgroundWaitApprove}
            // onRequestClose={() => this.props.onClose()}
          >
            <div
              disabled={true}
              onClose={() => this.props.onClose()}
              style={styles.modal}
            >
              <div
                style={styles.closeStyle}
                onClick={() => this.props.onClose()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
              {this.props.users.map((item, index) => {
                return this.renderUsersListItem(item, index);
              })}
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const styles = {
  userItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    display: 'flex'
  },
  details: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  list: {
    flexGrow: 1
  },
  name: {
    fontWeight: 'bold',
    color: Theme.TEXT_COLOR
  },
  major: {
    color: Theme.TEXT_COLOR,
    fontSize: 12
  },
  backgroundWaitApprove: {
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'fixed',
    opacity: '0.97',
    zIndex: '9999999',
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modal: {
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: window.innerWidth / 15 + 'px',
    marginBottom: window.innerWidth / 18.75 + 'px',
    color: Theme.TEXT_COLOR,
    width: '85%',
    padding: '3%'
  }
};
