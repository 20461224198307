import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Theme } from '../../common/providers/colors';

export class AllStudentsView extends Component {
  static propTypes = {
    updateFilter: PropTypes.any,
    search: PropTypes.string,
    skip: PropTypes.number,
    limit: PropTypes.number,
    filterType: PropTypes.string,
    students: {
      statisticsData: PropTypes.any,
      error: PropTypes.any,
      loading: PropTypes.bool,
      allStudents: {
        total: PropTypes.number.isRequired,
        totalForFilter: PropTypes.number,
        items: PropTypes.func
      }
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      students: props.students.allStudents || [],
      isAll: false
    };
  }

  render() {
    let data = this.props.statisticsData || {};

    return (
      <div>
        {!data.users ? (
          <LoadingSpinner />
        ) : (
          <div>
            <StyledCheckbox
              value='limitStart'
              checked={this.state.isAll}
              color='primary'
              onChange={() => {
                console.log('all students');
                this.setState({ isAll: !this.state.isAll });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);
