import gql from 'graphql-tag';

export const createUpdateCommentMutation = gql`
  mutation createUpdateComment($userId: ID, $commentId: String, $comment: CommentInputType!) {
    createUpdateComment(userId: $userId, commentId: $commentId, comment: $comment) {
      id
      userId
      parentId
      body
      email
      userName
      likes
      createdAt
      updatedAt
      postId
      userProfileImg
    }
  }
`;
