import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { propType } from 'graphql-anywhere/lib/index';
import { privacyFieldsFragment } from '../profile/graphql/privacy-fields.fragment';
// import { ConfirmationModal } from '../common/components/confirmation-modal.rn';
import { SettingsToggleItem } from './settings-toggle-item.component';
import { SocialEditableButton } from './social-editable-button.rn';
import { EditLink } from './edit-link-menu.container';
import { InstagramAuth } from '../instagram/components/instagram-auth-view.container';
import { TwitterAuth } from '../twitter/components/twitter-auth-view.container';
import { SnapchatEnrollment } from '../snapchat/components/snapchat-enrollment-view.container';
import { ApolloClient } from 'apollo-client';
import { FacebookAuth } from '../facebook/components/facebook-auth-view.container';
import { LinkedinAuth } from '../linkedin/components/linkedin-auth-view.container';
import { LangData } from '../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router';
import { logout } from '../account/account.util';
import PromptMessage from './prompt-message';
import { Theme } from '../common/providers/colors';
import { getClient } from '../apollo';
import { updateSendMessagesMutation } from './graphql/update-messages.mutation';

const width = window.innerWidth

export class SettingsView extends Component {
  static propTypes = {
    privacySettings: propType(privacyFieldsFragment).isRequired,
    updatePrivacy: PropTypes.func.isRequired,
    updatePrivacyLocally: PropTypes.func.isRequired,
    appVersion: PropTypes.string,
    user: PropTypes.any,
    reloadUser: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    client: PropTypes.instanceOf(ApolloClient).isRequired
  };

  constructor(props) {
    super(props);

    this.tempPrivacySettings = null;
    this.tempChanges = null;
    this.instagramModal = null;
    this.snapchatModal = null;
    this.twitterModal = null;

    this.state = {
      modalOpen: false,
      actionsMenu: null,
      promptLogout: false,
      playsounds: true,
      sendMessages: true
    };
  }

  confirmed() {
    this.tempPrivacySettings = null;
    this.update(this.tempChanges);
    this.tempChanges = null;

    this.setState({
      modalOpen: false
    });
  }

  canceled() {
    this.props.updatePrivacyLocally(this.tempPrivacySettings, {}, false);
    this.tempPrivacySettings = null;
    this.tempChanges = null;

    this.setState({
      modalOpen: false
    });
  }

  validateWithModal(changes, title) {
    this.tempPrivacySettings = this.props.privacySettings;
    this.tempChanges = changes;
    this.props.updatePrivacyLocally(this.props.privacySettings, changes);
    const value = Object.values(changes)[0];

    this.setState({
      modalTitle: title,
      modalOpen: value === false
    });

    if (value === true) {
      this.confirmed(changes);
    }
  }

  update(changes) {
    this.props.updatePrivacy(this.props.privacySettings, changes);
  }

  changeMessages = async (value) => {
    console.log('change messages!', value)
    this.setState({ sendMessages: value })
    let client = getClient()
    client.mutate({
      mutation: updateSendMessagesMutation,
      variables: {
        userId: localStorage.getItem('userId'),
        value
      }
    })
  }

  changeSound = async (value) => {
    console.log('changes sound!', value)
    this.setState({ playsounds: value})
    await localStorage.setItem('soundOn', value)
  }

  componentWillMount = () => {
    let currentState = localStorage.getItem('soundOn')
    console.log('currentState', currentState)
    if(this.props.currentUser) {
      console.log('this.props.currentUser', this.props.currentUser)
      if(this.props.currentUser.receiveMessages !== null && this.props.currentUser.receiveMessages === false) this.setState({ sendMessages: false })
    }
    if(currentState !== null && currentState === 'true') {
      this.setState({ playsounds: true }, () => console.log('playsounds', this.state.playsounds))
    } else this.setState({ playsounds: false },  () => console.log('playsounds', this.state.playsounds))
  }

  openAuthModal(socialNetworkName) {
    if (this.snapchatModal && socialNetworkName === 'snapchat') {
      this.snapchatModal.open();
    } else if (this.twitterModal && socialNetworkName === 'twitter') {
      this.twitterModal.open();
    } else if (this.instagramModal && socialNetworkName === 'instagram') {
      this.instagramModal.open();
    } else if (this.facebookModal && socialNetworkName === 'facebook') {
      this.facebookModal.open();
    } else if (this.linkedinModal && socialNetworkName === 'linkedin') {
      this.linkedinModal.open();
    }
  }

  editSocialNetwork(socialNetworkName) {
    this.setState({ actionsMenu: socialNetworkName });
  }

  storeData = async lang => {
    try {
      await localStorage.setItem('@APP:languageCode', lang);
    } catch (e) {
      console.log('error while saving language', e);
    }

    // strings.setLanguage(lang)
    this.setState({});
  };

  changeLanguage(lang) {
    switch (lang) {
      case 'heb':
        this.storeData('he');
        // RNI18nManager.forceRTL(true);

        break;
      case 'eng':
        this.storeData('en');
        // RNI18nManager.forceRTL(false);
        break;
        default: 
        break;
    }
  }

  onColorChange() {
    if (localStorage.getItem('option-colors') === 'first') {
      localStorage.setItem('option-colors', 'second');
    } else if (localStorage.getItem('option-colors') === 'second') {
      localStorage.setItem('option-colors', 'third');
    } else {
      localStorage.setItem('option-colors', 'first');
    }
    window.location.reload(false);
  }

  render() {
    if (!this.props.currentUser) {
      return <Redirect to='/login' />;
    }

    const isDuringSnapchatEnrollment =
      this.props.user.snapchat === 'DURING_ENROLLMENT';

    return (
      <Fragment>
        <div style={styles.container}>
          <div style={styles.optionsRow}>
            <div>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={styles.icon}
                onClick={() => {
                  this.props.toggleComponent(this.props.prevComponent);
                  if (this.props.prevComponent === 'Profile') {
                    this.props.history.push('/profile');
                  } else {
                    this.props.history.push('/');
                  }

                  // this.props.history.go(-1);
                }}
              />
            </div>
            <span style={styles.title}>Settings</span>
            <div
              onClick={() => {
                console.log('fff');
                this.setState({ promptLogout: true });
                // logout()
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} style={styles.logoutIcon} />
            </div>
          </div>

          <InstagramAuth onInit={modal => (this.instagramModal = modal)} />
          <TwitterAuth onInit={modal => (this.twitterModal = modal)} />
          {/* <SnapchatEnrollment onInit={modal => (this.snapchatModal = modal)} /> */}
          <FacebookAuth onInit={modal => (this.facebookModal = modal)} />
          <LinkedinAuth onInit={modal => (this.linkedinModal = modal)} />

          {/* <EditLink
          visible={this.state.actionsMenu !== null}
          network={this.state.actionsMenu}
          onClose={() => this.setState({ actionsMenu: null })}
        /> */}
          {/* <ConfirmationModal
          title={this.state.modalTitle || ''}
          question={LangData.settings.continue}
          onClose={() => this.canceled()}
          open={this.state.modalOpen}
          options={[
            {
              name: LangData.settings.yes,
              onPress: () => this.confirmed(),
              color: '#FFC412',
              iconName: 'frown-o'
            },
            {
              name: LangData.settings.no,
              onPress: () => this.canceled(),
              color: '#FFC412',
              iconName: 'smile-o'
            }
          ]}
        /> */}
          <div
            style={
              width < 500 ? 
              { width: '90%', display: 'flex', flexDirection: 'column' }
              :
              { width: '90%', display: 'flex', flexDirection: 'column', overflow: 'scroll', height: '79vh' }
            }
          >
            <div style={styles.rowContainer}>
              <span style={styles.titleText}>{LangData.settings.settings}</span>
              <span style={styles.settingText}>{LangData.settings.offOn}</span>
            </div>
            <SettingsToggleItem
              icon={'reminder'}
              active={this.props.privacySettings.push}
              onChange={value =>
                this.validateWithModal(
                  { push: value },
                  LangData.settings.notifications
                )
              }
            >
              <div style={styles.textItem}>
                <span style={styles.title}>{LangData.settings.messages}</span>
                <span style={styles.subtitle}>
                  {LangData.settings.messages}
                </span>
              </div>
            </SettingsToggleItem>
            <SettingsToggleItem
              icon={'bell'}
              active={this.state.playsounds}
              onChange={value =>
               this.changeSound(value)
              }
            >
              <div style={styles.textItem}>
                <span style={styles.title}>App Sounds</span>
                <span style={styles.subtitle}>
                  General app sounds
                </span>
              </div>
            </SettingsToggleItem>
            <SettingsToggleItem
              icon={'bell'}
              active={this.state.sendMessages}
              onChange={value =>
               this.changeMessages(value)
              }
            >
              <div style={styles.textItem}>
                <span style={styles.title}>Messages</span>
                <span style={styles.subtitle}>
                  Receive messages
                </span>
              </div>
            </SettingsToggleItem>
            <div style={styles.rowContainer}>
              <span style={styles.titleText}>{LangData.settings.privacy}</span>
              <span style={styles.settingText}>
                {LangData.settings.hideShow}
              </span>
            </div>
            <SettingsToggleItem
              icon={'picture'}
              active={this.props.privacySettings.displayPicture}
              onChange={value => this.update({ displayPicture: value })}
            >
              <div style={styles.textItem}>
                <span style={styles.title}>{LangData.settings.pictures}</span>
                <span style={styles.subtitle}>{LangData.settings.profile}</span>
              </div>
            </SettingsToggleItem>
            <SettingsToggleItem
              icon={'info'}
              active={this.props.privacySettings.directoryInfo}
              onChange={value => this.update({ directoryInfo: value })}
            >
              <div style={styles.textItem}>
                <span style={styles.title}>{LangData.settings.personal}</span>
                <span style={styles.subtitle}>{LangData.settings.major}</span>
              </div>
            </SettingsToggleItem>
            <SettingsToggleItem
              icon={'email'}
              active={this.props.privacySettings.msgEmail}
              onChange={value => this.update({ msgEmail: value })}
            >
              <div style={styles.textItem}>
                <span style={styles.title}>{LangData.settings.email}</span>
                <span style={styles.subtitle}>{this.props.user.msjEmail}</span>
              </div>
            </SettingsToggleItem>
            <SettingsToggleItem
              icon={'palette'}
              hideToggle={true}
              onColorChange={() => this.onColorChange()}
            >
              <div style={styles.textItem}>
                <span style={styles.title}>{LangData.settings.palette}</span>
                <span style={styles.subtitle}>Change App Color Palette</span>
              </div>
            </SettingsToggleItem>
            {/* 
          <SettingsToggleItem
            hideToggle={!this.props.user.instagram}
            icon={'Instagram'}
            settingSocialIcon={true}
            active={this.props.privacySettings.instagram}
            onChange={value => this.update({ instagram: value })}
          >
            <SocialEditableButton
              onAddPress={() => this.openAuthModal('instagram')}
              onEditPress={() => this.editSocialNetwork('Instagram')}
              name={
                this.props.user.instagram
                  ? LangData.settings.instagram
                  : LangData.settings.addIns
              }
              existingLink={this.props.user.instagram}
              color={'#FFC412'}
            />
          </SettingsToggleItem>
          <SettingsToggleItem
            hideToggle={!this.props.user.snapchat || isDuringSnapchatEnrollment}
            icon={'Snapchat'}
            settingSocialIcon={true}
            active={this.props.privacySettings.snapchat}
            onChange={value => this.update({ snapchat: value })}
          >
            <SocialEditableButton
              onAddPress={() => this.openAuthModal('snapchat')}
              onEditPress={() => this.editSocialNetwork('Snapchat')}
              name={
                isDuringSnapchatEnrollment
                  ? LangData.settings.verify
                  : this.props.user.snapchat
                  ? LangData.settings.snapchat
                  : LangData.settings.addSna
              }
              existingLink={
                !this.props.user.snapchat || isDuringSnapchatEnrollment
                  ? null
                  : `@${this.props.user.snapchat}`
              }
              color={'#FFC412'}
            />
          </SettingsToggleItem>

          <SettingsToggleItem
            hideToggle={!this.props.user.twitter}
            icon={'Twitter'}
            settingSocialIcon={true}
            active={this.props.privacySettings.twitter}
            onChange={value => this.update({ twitter: value })}
          >
            <SocialEditableButton
              onAddPress={() => this.openAuthModal('twitter')}
              onEditPress={() => this.editSocialNetwork('Twitter')}
              name={
                this.props.user.twitter
                  ? LangData.settings.twitter
                  : LangData.settings.addTwi
              }
              existingLink={this.props.user.twitter}
              color={'#FFC412'}
            />
          </SettingsToggleItem>

          <SettingsToggleItem
            hideToggle={!this.props.user.facebook}
            icon={'Facebook'}
            settingSocialIcon={true}
            active={this.props.privacySettings.facebook}
            onChange={value => this.update({ facebook: value })}
          >
            <SocialEditableButton
              onAddPress={() => this.openAuthModal('facebook')}
              onEditPress={() => this.editSocialNetwork('Facebook')}
              name={
                this.props.user.facebook
                  ? LangData.settings.facebook
                  : LangData.settings.addFac
              }
              existingLink={this.props.user.facebook}
              color={'#FFC412'}
            />
          </SettingsToggleItem>

          <SettingsToggleItem
            hideToggle={!this.props.user.linkedin}
            icon={'Linkedin'}
            settingSocialIcon={true}
            active={this.props.privacySettings.linkedin}
            onChange={value => this.update({ linkedin: value })}
          >
            <SocialEditableButton
              onAddPress={() => this.openAuthModal('linkedin')}
              onEditPress={() => this.editSocialNetwork('LinkedIn')}
              name={
                this.props.user.linkedin
                  ? LangData.settings.linkedin
                  : LangData.settings.addLin
              }
              existingLink={this.props.user.linkedin}
              color={'#FFC412'}
            />
          </SettingsToggleItem> */}
          </div>
          <div style={styles.footerContainer}>
            <div style={styles.appVersionContainer}>
              <span style={styles.appVersionText}>
                v{this.props.appVersion}
              </span>
              <span style={styles.dualitiText}>
                Powered by{' '}
                <img
                  src={require('../assets/images/dualiti.png')}
                  style={{ resizeMode: 'contain', width: 52, height: 15 }}
                />
              </span>
            </div>
          </div>
        </div>

        {this.state.promptLogout && (
          <PromptMessage
            message='Are you sure you want to logout?'
            onSubmit={() => {
              this.setState({ promptLogout: false });
              logout();
            }}
            onCancel={() => {
              this.setState({ promptLogout: false });
            }}
          />
        )}
      </Fragment>
    );
  }
}

const styles = {
  appVersionText: {
    color: Theme.TEXT_COLOR,
    fontSize: 13,
    fontWeight: 'bold',
    marginRight: 5
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    width: '90%'
  },
  icon: {
    color: Theme.TEXT_COLOR,
    fontSize: width<500?window.innerWidth / 18 + 'px':'20px'
  },
  logoutIcon: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontSize: width<500?window.innerWidth / 18 + 'px':'20px'
  },
  dualitiText: {
    alignItems: 'center',
    justifyContent: 'center',
    color: Theme.TEXT_COLOR,
    fontSize: 13,
    fontWeight: 'bold',
    marginRight: 5
  },
  appVersionContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  footerContainer: {
    flex: 0.2,
    flexDirection: 'row',
    display: 'flex',
    position: 'absolute',
    bottom: '0',
    alignSelf: 'flex-end'
  },
  container: {
    minHeight: window.innerHeight,
    backgroundColor: Theme.BACKGROUND_COLOR,
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textItem: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    color: Theme.TEXT_COLOR,
    fontSize: 15,
    fontWeight: 'bold'
  },
  subtitle: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontSize: 14
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row', 
    alignItems: 'center'
  },
  titleText: {
    flex: 0.8,
    color: Theme.TEXT_COLOR,
    fontSize: 15.5,
    fontWeight: 'bold'
  },
  settingText: {
    flex: 0.2,
    fontSize: 15.5,
    textAlign: 'right',
    fontWeight: 'bold',
    color: Theme.TEXT_COLOR
  },
  impersonateContainer: {
    marginHorizontal: 10
  }
};
