import gql from 'graphql-tag';

export const userCompletedFirstLogin = gql`
  mutation userCompletedFirstLogin($userId:String!) {
    userCompletedFirstLogin(
        userId: $userId
    ){
        id
    }
  }
`;