import gql from 'graphql-tag';

export const userRemindersQuery = gql`
    query userReminders($userId: ID) {
        userReminders(userId: $userId) {
            _id
            userId
            name
            phoneNumber
            notification
            time {
                timeType
            }
        }
    }
`