import gql from 'graphql-tag';

export const answerPollMutation = gql`
    mutation answerPoll($userId: ID,$pollId: String!, $answerIndex: Int!) {
        answerPoll(userId: $userId, pollId: $pollId, answerIndex: $answerIndex) {
            answer
            value
        }
    }
`;
