import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../assets/locales/localization';
import {
  faSearch,
  faTimes,
  faDownload,
  faArrowLeft,
  faBars,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { Redirect } from 'react-router';
import { Theme } from '../../common/providers/colors';
import ProfileImage from '../../common/components/profile-image.rn';
import logoSrc from '../../assets/images/LogoDark.png';
import { refreshUser } from '../../account/account.util';
import { Menu } from '../../menu/menu.container';
import BurgerMenu from '../../burgerMenu/BurgerMenu';
import { createMaterialLogMutation } from '../graphql/createMaterialLog';
import { apolloClient } from '../../apollo-subs';

const width = window.innerWidth


export class ManualsView extends Component {
  static propTypes = {
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    search: PropTypes.string.isRequired,
    updateFilter: PropTypes.func.isRequired,
    manuals: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allManuals: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  constructor() {
    super();

    this.state = {
      currentSrc: null,
      isMenuOpen: false
    };
  }

  updatePagination(nextData) {
    if (nextData) {
      this.props.updateFilter({ skip: this.props.skip + this.props.limit });
    } else {
      let newSkip = this.props.skip - this.props.limit;

      if (newSkip < 0) {
        newSkip = 0;
      }

      this.props.updateFilter({ skip: newSkip });
    }
  }

  resetFilter() {
    this.props.updateFilter({ skip: 0, search: '' });
  }

  updateSearch(newValue) {
    this.props.updateFilter({ skip: 0, search: newValue });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.manualToManage !== null &&
      nextProps.manuals &&
      nextProps.manuals.allmanuals &&
      nextProps.manuals.allmanuals.items
    ) {
      this.setState({
        manualToManage: nextProps.manuals.allmanuals.items.find(
          manual => manual.id === this.state.manualToManage.id
        )
      });
      this.setState({
        allItems: this.props.manuals.allManuals.items
      });
    }
  }

  refreshUserFunction = async () => {
    let user = await refreshUser();
    if (user) {
      this.props.setUser(user);
    }
  };

  manualClicked = (item) => {
    console.log("item clicked", item)
    let isManual = item.name.toLowerCase().includes("manual") ? true : false
    console.log("isManual =>", isManual)
    apolloClient.mutate({
      mutation: createMaterialLogMutation,
      variables: {
        userId: localStorage.getItem('userId'),
        manualId: item.id,
        type: isManual ? "manual": "research",
        clickedAt: new Date()
      }
    })


  }

  render() {
    const totalForFilter = this.props.manuals.allManuals
      ? this.props.manuals.allManuals.total
      : 0;
    const items = this.props.manuals.allManuals
      ? this.props.manuals.allManuals.items
      : [];

    if (!this.props.currentUser) {
      return <Redirect to='/login' />;
    }

    return (
      <div style={styles.mainContainer}>
        <div
          style={
            styles.topNav
          }
        >
          <div
            onClick={() => {
              this.setState({
                isMenuOpen: true
              });
            }}
            style={styles.menu}
          >
            <BurgerMenu/>
          </div>

          <img src={logoSrc} style={styles.logo} alt=""/>

          <ProfileImage
            showBorder={false}
            withFullSize={false}
            height={35}
            width={35}
            imageUrl={
              this.props.currentUser ? this.props.currentUser.profilePictureUrl : null
            }
            onClick={() => {
              this.refreshUserFunction();
              this.props.toggleComponent('Profile');
              this.props.history.push('profile');
            }}
          />
        </div>
        {this.state.isMenuOpen && (
          <Menu
            history={this.props.history}
            isOpen={this.state.isMenuOpen}
            closeMenu={() => this.setState({ isMenuOpen: false })}
            setIndex={index => { }}
          />
        )}
        {/* Top Options Row */}
        <div style={styles.optionsRow} id='optionsRow'>
          {/* <div
            onClick={() => {
              this.props.toggleComponent(this.props.prevComponent);
              this.props.history.push('/');
              // this.props.history.go(-1);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div> */}
          <div>
            <span style={{ fontSize: '20px' }}>Yoga Science</span>
          </div>
          <div></div>
        </div>

        {/* Search Container */}
        <div style={styles.searchContainer}>
          <TextInput
            type='text'
            placeholder={LangData.manuals.search}
            onChange={e => this.updateSearch(e.target.value)}
            value={this.props.search}
            style={styles.inputSearch}
          />
          <FontAwesomeIcon icon={faSearch} style={styles.searchIcon} />

          <div style={styles.clearIcon}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => this.resetFilter()}
              style={styles.clearInput}
            />
          </div>

        </div>

        {/* All Manuals Preview */}
        {this.props.manuals.loading && <LoadingSpinner />}
        <div style={styles.manualsContainer}>
          {items.map(item => {
            return (
              <div
                style={styles.itemMainContainer}
                key={item.id}
                onClick={() => {
                    this.manualClicked(item)
                    this.setState({
                    currentSrc: item.data
                  })}
                }
              >
                <div style={styles.leftContainer}></div>
                <div style={styles.itemContainer}>
                  <span>
                    <b>{item.name}</b>
                  </span>
                  <span>{item.label}</span>
                </div>
              </div>
            );
          })}
        </div>
        {/* Selected Manual Modal */}
        {this.state.currentSrc && (
          <div style={styles.iframeContainer}>
            <div style={styles.optionsRow}>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => this.setState({ currentSrc: null })}
                style={styles.closeIframe}
              />
              <a
                target='_blank'
                href={this.state.currentSrc}
                download
                style={styles.href}
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </div>
            <iframe
              src={
                'https://docs.google.com/viewer?url=' +
                this.state.currentSrc +
                '&embedded=true'
              }
              style={styles.iframe}
            />
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  logo: {
    width:  width < 500 ? '48vw': '20vw'
  },
  mainContainer: {
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: window.innerHeight,
    backgroundColor: '#f1e8dd',
    color: Theme.TEXT_COLOR,
    marginTop: "60px"
  },
  searchIcon: {
    // left: '32px',
    // top: '14px',
    fontSize: '14px',
    position: 'absolute',
    left: width < 500 ? '16.5vw' : '15vw',
    marginTop: width < 500 ? '1%' : '0.7vh'
  },
  clearIcon: {
    left: width < 500 ? window.innerWidth / 1.2869 : '84vw',
    paddingTop: width < 500 ? '5px' : 0,
    fontSize: '12px',
    position: 'absolute'
  },
  searchContainer: {
    backgroundColor: '#f1e8dd',
    width: '85%',
    borderBottomColor: Theme.BACKGROUND_COLOR,
    borderBottomWidth: 2,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    opacity: 0.7,
  },
  manualsContainer: {
    width: '100%',
    marginTop: '5%',
    overflow: 'auto'
  },
  itemMainContainer: {
    display: 'flex',
    margin: 'auto',
    maxHeight: '70px',
    width: '90%',
    marginBottom: '5%'
  },
  leftContainer: {
    width: '30%',
    backgroundColor: '#e0c2b0',
    borderRadius: '30px 0 0 30px'
  },
  itemContainer: {
    backgroundColor: '#4d4b62',
    padding: width < 500 ?'3%' : '1%',
    color: Theme.WHITE_COLOR,
    display: 'flex',
    borderRadius: '0 30px 30px 0',
    flexDirection: 'column',
    overflow: width < 500 ? 'scroll' : 'auto',
    width: '70%',
    fontSize: '13px'
  },
  iframeContainer: {
    width: '100%',
    zIndex: '9',
    position: 'absolute',
    top: 0,
    height: '100%',
    backgroundColor: Theme.BACKGROUND_COLOR,
    opacity: '0.97',
    display: 'flex',
    flexDirection: 'column'
  },
  closeIframe: {
    margin: '3%'
  },
  iframe: {
    height: '80%',
    border: 'none',
    borderRadius: '6px',
    margin: '0 1%'
  },
  optionsRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  href: {
    color: 'inherit',
    textDecoration: 'none',
    margin: '2%'
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'center',
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    width: '90%'
  },
  inputSearch: {
    backgroundColor: '#f3decb',
    margin: '0 8%',
    padding: "10px 30px"
  },
  topNav: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: window.innerWidth,
    padding: '5% 0',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    backgroundColor: Theme.BACKGROUND_COLOR
  },
  clearInput: {
    margin: '12% 2% 15% 5%',
    marginBottom: '20%',
    borderRadius: "50%",
    padding: "2px 2px",
    width: "13px",
    height: "13px",
    border: "2px solid #545267",
    color: Theme.NAV_LINK_COLOR,
    marginTop: '7px'
  },
};

const TextInput = styled.input`
  padding-right: 30px;
  height: 25px;
  align-self: stretch;
  font-size: 15px;
  font-family: inherit;
  color: ${Theme.TEXT_COLOR};
  outline: none;
  border: none;
  padding-left: 7%;
  font-weight: bold;
  margin-left: 3%;
  margin-right: 3%;
  position: relative;
  background-color: white;
  border-radius: 15px;
  width: 100%;

  &:focus {
    outline: none;
    border: none;
  }
`;
