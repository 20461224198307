import React, { Fragment, useEffect } from 'react';
import { LangData } from '../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Animated } from 'react-animated-css';
import { Theme } from '../../common/providers/colors';

const ErrorMessage = props => {
  return (
    <div style={styles.fullPage}>
      <Animated
        animationIn='pulse'
        animationOut='fadeOut'
        isVisible={props.error}
      >
        <div style={styles.msgContainer}>
          <FontAwesomeIcon icon={faExclamationCircle} style={styles.icon} />
          <p>{props.message}</p>
          <button style={styles.btn} onClick={props.onPress}>
            {LangData.errorMessage.again}
          </button>
        </div>
      </Animated>
    </div>
  );
};

export default ErrorMessage;

const styles = {
  fullPage: {
    minHeight: window.innerHeight + 25,
    width: window.innerWidth,
    // opacity: '0.9',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '99999999999',
    textAlign: 'center',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0
    // top: '0'
  },
  msgContainer: {
    height: window.innerWidth / 2 + 'px',
    width: window.innerWidth / 2 + 'px',
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  btn: {
    color: Theme.TEXT_COLOR,
    backgroundColor: Theme.BACKGROUND_COLOR,
    border: 'none',
    padding: '7%',
    fontWeight: 'bold',
    fontSize: window.innerWidth / 24 + 'px',
    fontFamily: 'inherit',
    width: '100%'
  },
  icon: {
    color: Theme.BACKGROUND_COLOR,
    fontSize: window.innerWidth / 7.2 + 'px',
    marginTop: '5%'
  }
};
