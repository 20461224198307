import WrapVideosView from './wrap-videos.component';
import { withApollo, compose, graphql } from 'react-apollo-temp';
import { connect } from 'react-redux';
import { toggleComponent } from '../../router/router.actions';
import { setUser } from '../../auth/auth.actions';

const mapStateToProps = ({ navigation, auth }) => ({
  prevComponent: navigation.navigationComponents.prevComponent,
  currentComponent: navigation.navigationComponents.component,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  setUser: user => dispatch(setUser(user)),
});

export const withState = connect(mapStateToProps, mapDispatchToProps);

export const WrapVideos = compose(withApollo, withState)(WrapVideosView);
