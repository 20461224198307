import gql from 'graphql-tag';

export const linkedinDetailsQuery = gql`
    query {
        linkedinAuth {
            clientId
            returnUrl
        }
    }
`;
