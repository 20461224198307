import { AccountsClient } from '@accounts/client';
import { AccountsClientPassword } from '@accounts/client-password';
import { AccountsGraphQLClient } from '@accounts/graphql-client';
import { initClient } from '../apollo';
import config from '../core/config/config';
import { userFieldsFragment } from '../login/graphql/me.fragment';

export const client = initClient(config);

const graphQLInterface = new AccountsGraphQLClient({
  graphQLClient: client,
  userFieldsFragment
});

export const accountsClient = new AccountsClient(
  {
    tokenStorage: localStorage
  },
  graphQLInterface
);

export const accountsPassword = new AccountsClientPassword(accountsClient, {
  hashPassword: password => password
});
