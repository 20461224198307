import React, { useState, useEffect } from 'react';
import ProfileCard from '../../common/components/profile-card.rn';
import { StatelessUserProfileModal } from '../../profile/components/profile/user-profile-modal.container';

const FriendsSuggestionsView = props => {
  const [userIdModal, setUserIdModal] = useState(null);
  const [suggestions, setSuggestions] = useState([])
  useEffect(() => {
    if(props.suggestions && props.suggestions.friendsSuggestions) setSuggestions(props.suggestions.friendsSuggestions)
    else setSuggestions([])
    console.log('suggestion =>', suggestions)
  }, [props]);


  return (
    <div style={styles.container}>
      {userIdModal !== null && (
        <StatelessUserProfileModal
          isOpen={userIdModal !== null}
          toggleModal={() => setUserIdModal(null)}
          userId={userIdModal}
          fromSug={true}
          history={props.history}
        />
      )}

      <div style={styles.scroll}>
        {suggestions.map((user, index) => (
          <ProfileCard
            key={index}
            onPress={() => setUserIdModal(user.id)}
            style={{ flex: 1 }}
            containerStyle={{
              width: 360 / 3,
              height: 180,
              marginHorizontal: 2
            }}
            user={user}
            history={props.history}
          />
        ))}
      </div>
    </div>
  );
};

export default FriendsSuggestionsView;

const styles = {
  scroll: {
    height: 180
  },
  container: {
    flex: 1,
    height: 180
  }
};
