import React from 'react';
import { graphql } from 'react-apollo-temp';
import UserPhotosView from './user-photos.rn';
import { photosQuery } from '../../graphql/photos.query';
import { withErrorHandler } from '../../../common/hoc/error-handler.rn';
import { compose } from 'redux';

const withData = graphql(photosQuery, {
  name: 'photosData',
  options: (props) => {
    return ({
    fetchPolicy: 'network-only',
    variables: {
      currentUserId: props.currentUser ? props.currentUser.id : null,
      userId: props.user? props.user.id : null
    }
  })}
});

const withError = withErrorHandler({
  apollo: ['photosData']
});

export const UserPhotos = compose(withData, withError)(UserPhotosView);
