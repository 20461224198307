import gql from 'graphql-tag';

export const userMeetingsQuery = gql`
    query userLiveStreaming($userId: ID) {
        userLiveStreaming(userId: $userId) {
            meetingName
            meetingTime
            meetingNumber
        }
    }
`