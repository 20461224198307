import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { store } from './store';
import config from './core/config/config';

export let bugsnagClient;
let environment = 'dev';
if (environment === 'dev') {
  bugsnagClient = {
    setUser: () => null,
    clearUser: () => null
  };
} else {
  // bugsnag = new Client('dadeb9213569331a04ad0286a40593af');
  bugsnagClient = bugsnag('dadeb9213569331a04ad0286a40593af');
}

export const reportError = error => {
  if (environment === 'dev') {
    return;
  }

  try {
    const state = store.getState();
    const user = state.auth.currentUser || {};
    bugsnagClient.setUser(
      user.id || user._id || 'Unauthenticated',
      user.username
    );

    bugsnagClient.notify(error, report => {
      report.metadata = {
        device: {
          environment
        },
        redux: state,
        user,
        config
      };
    });
  } catch (e) {
    // Nothing to do
  }
};

export const breadcrumb = (actionType, description = '') => {
  if (environment === 'dev') {
    return;
  }

  bugsnagClient.leaveBreadcrumb(description, { type: actionType });
};
