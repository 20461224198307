import { getClient } from '../apollo';
// import { unreadNotificationCountQuery } from '../my-notifications/graphql/unread-notifications-count.query';

// import { myNotificationsQuery } from '../my-notifications/graphql/notifications.query';

export const EMyPostsActions = {
  TOGGLE_POSTS_MODAL: '@posts/TOGGLE_POSTS_MODAL',
  NAVIGATE_TO_SECOND_STAGE: '@posts/NAVIGATE_TO_SECOND_STAGE'
};

export const toggleMyPosts = isOpen => {
  console.log('insides toggleMyPosts');
  return {
    type: EMyPostsActions.TOGGLE_POSTS_MODAL,
    isOpen
  };
};

export const navigateSecondStage = poll => {
  console.log('inside navigateSecondStage');
  return {
    type: EMyPostsActions.NAVIGATE_TO_SECOND_STAGE,
    poll
  };
};

export async function refreshNotificationsList() {
  // const client = getClient();
  // try {
  //   const existingState = client.readQuery({ query: unreadNotificationCountQuery });
  //   const prevCount = existingState ? existingState.unreadPostsCount : 0;
  //   const newState = await client.query({ query: unreadNotificationCountQuery, fetchPolicy: 'network-only' });
  //   const newCount = newState && newState.data && newState.data.unreadPostsCount ? newState.data.unreadPostsCount : 0;
  //   if (newCount > prevCount) {
  //     playSound();
  //   }
  //   await client.query({ query: myNotificationsQuery, variables: { cursor: null }, fetchPolicy: 'network-only' });
  // } catch (e) {
  //   // nothing to do here
  // }
}
