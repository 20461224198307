import { combineReducers } from 'redux';
import moment from 'moment';
import { EScheduleActions } from './schedule.actions';
import { EMainActions } from '../main/main.actions';

export const NEXT_MONTHS_TO_DISPLAY = 3;
export const PREV_MONTHS_TO_DISPLAY = 1;

const getRelevantMonths = () => {
  const currentMonth = moment();
  const months = [];

  for (let i = 1; i <= PREV_MONTHS_TO_DISPLAY; i += 1) {
    months.push(moment(currentMonth).subtract(i, 'month'));
  }

  months.push(currentMonth);

  for (let i = 1; i <= NEXT_MONTHS_TO_DISPLAY; i += 1) {
    months.push(moment(currentMonth).add(i, 'month'));
  }

  return months;
};

const relevantMonths = (state = getRelevantMonths()) => state;

const activeCourse = (state = null, action) => {
  if (action.type === EScheduleActions.ACTIVE_COURSE) {
    return action.course;
  }

  if (action.type === EMainActions.NAVIGATE_PARENT || action.type === EMainActions.NAVIGATE_CHILD) {
    return null;
  }

  return state;
};

export const schedule = combineReducers({
  relevantMonths,
  activeCourse,
});
