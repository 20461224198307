import gql from 'graphql-tag';

export const privacyFieldsFragment = gql`
    fragment PrivacyFields on PrivacyType {
        displayPicture
        msgEmail
        facebook
        linkedin
        directoryInfo
        push
        twitter
        instagram
        snapchat
    }
`;
