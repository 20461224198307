import React, { useState, useEffect, Fragment } from 'react';
import { CourseIcon } from '../../common/components/course-icon';
import { LangData } from '../../assets/locales/localization';
import defaultProfileImg from '../../assets/images/default_profile.jpeg';
import courseIcon from '../../assets/images/course_icon.png';
import { Theme } from '../../common/providers/colors';
const width = window.innerWidth

const CourseListItem = props => {
  const [course, setCourse] = useState({
    identifier: '',
    location: '',
    fullName: ''
  });
  const [teacherImg, setTeacherImg] = useState(defaultProfileImg);

  useEffect(() => {
    setCourse(props.course);
    if (props.course.teacher.profilePictureUrl)
      setTeacherImg(props.course.teacher.profilePictureUrl);
  }, [props.course]);

  return (
    <div
      style={props.active ? styles.itemContainerActive : styles.itemContainer}
      onClick={props.onPress}
    >
      {course && course.teacher && teacherImg && (
        <Fragment>
          <div style={styles.leftContainer}>
            <div style={styles.courseType}>GROUP</div>
            <div style={styles.courseShortName}>{course.name}</div>
            <div></div>
          </div>
          <div style={styles.rightContainer}>
            <div style={styles.nameRow}>
              <img src={courseIcon} style={styles.courseIcon} />
              <span> {course.fullName}</span>
            </div>
            <div style={styles.teacher}>
              <img src={teacherImg} style={styles.teacherImg} />
              <span>{course.teacher.fullName}</span>
            </div>
            <div></div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CourseListItem;

const styles = {
  itemContainer: {
    backgroundColor: 'white',
    color: Theme.TEXT_COLOR,
    width: width<500?'87%':'60%',
    height: width<500?window.innerWidth / 3.2727 + 'px':'100%',
    display: 'flex',
    borderRadius: window.innerWidth / 21.176 + 'px',
    margin:
      window.innerWidth / 72 + 'px 0px ' + window.innerWidth / 24 + 'px 0px',
    padding: '2%'
  },
  itemContainerActive: {
    backgroundColor: 'white',
    color: Theme.TEXT_COLOR,
    width: width<500?'87%':'60%',
    height: width<500?window.innerWidth / 3.2727 + 'px':'100%',
    display: 'flex',
    borderRadius: window.innerWidth / 21.176 + 'px',
    margin:
      window.innerWidth / 72 + 'px 0px ' + window.innerWidth / 24 + 'px 0px',
    padding: '2%',
    border: `3px solid ${Theme.SECONDARY_TEXT_COLOR}`
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '30%',
    borderRight: `2px solid ${Theme.TEXT_COLOR}`
  },
  courseType: {
    border: `2px ${Theme.SECONDARY_TEXT_COLOR} solid`,
    borderRadius: window.innerWidth / 36 + 'px',
    padding: '3%',
    fontWeight: 'bold',
    width: 'fit-content',
    color: Theme.SECONDARY_TEXT_COLOR,
    fontSize: width<500?window.innerWidth / 32.727 + 'px':'20px'
  },
  courseShortName: {
    fontWeight: 'bold',
    color: Theme.SECONDARY_TEXT_COLOR
  },
  rightContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '3%'
  },
  nameRow: { display: 'flex', alignItems: 'center', fontWeight: 'bold' },
  courseIcon: {
    height: width<500?window.innerWidth / 14.4 + 'px':'50px',
    width: width<500?window.innerWidth / 12 + 'px':'50px'
  },
  teacher: {
    display: 'flex',
    alignItems: 'center'
  },
  teacherImg: {
    height: width<500?window.innerWidth / 9 + 'px':'100px',
    width: width<500?window.innerWidth / 9 + 'px':'100px',
    borderRadius: '50%',
    marginRight: '2%'
  }
};
