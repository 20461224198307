import gql from 'graphql-tag';
import { privacyFieldsFragment } from './privacy-fields.fragment';

export const updatePrivacyMutation = gql`
    mutation updatePrivacy($userId: ID, $config: PrivacyConfigInputType) {
        updatePrivacy(userId: $userId, config: $config) {
            ...PrivacyFields
        }
    }

    ${privacyFieldsFragment}
`;
