import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const friendsSuggestionsQuery = gql`
  query friendsSuggestions($userId: ID, $amount: Int) {
    friendsSuggestions(userId: $userId, amount:$amount) {
      ...ClassmateItem
    }
  }

  ${classmateItemFragment}
`;
