import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../assets/locales/localization';
import Select from 'react-select';

export const CategoriesPickerView = props => {
  const [categoriesOptions, setCategoriesOptions] = useState(null);

  const [selectedCaergories, setSelectedCaergoriest] = useState([
    props.categories
  ]);
  const [selctedMainCategory, setSelctedMainCategory] = useState(
    props.mainCategory
  );

  useEffect(() => {
    if (props.categoriesData.categories) {
      let tempOptionsForSelect = [];

      for (var i = 0; i < props.categoriesData.categories.length; i++) {
        tempOptionsForSelect.push({
          label: props.categoriesData.categories[i].name,
          value: props.categoriesData.categories[i].id
        });
      }

      setCategoriesOptions(tempOptionsForSelect);
    }
  }, [props]);

  const handleCategorySelect = e => {
    setSelectedCaergoriest(e);
    let onlyIdCatergoriesArr = [];
    for (var i = 0; i < e.length; i++) {
      onlyIdCatergoriesArr.push(e[i].value);
    }
    props.onCategoriesChange(onlyIdCatergoriesArr);
  };

  const handleMainCategorySelect = e => {
    setSelctedMainCategory(e);
    props.onMainCategoryChange(e.value);
  };

  return (
    <div style={{ width: '85%', borderRadius: '30px' }}>
      {categoriesOptions && (
        <Select
          isMulti
          name='categoriesOptions'
          options={categoriesOptions}
          placeholder={LangData.categoriesPicker.choose}
          onChange={e => handleCategorySelect(e)}
          closeMenuOnSelect={false}
          autoFocus={false}
          isSearchable={true}
          defaultValue={{
            label: 'Yoga',
            value: '5ee08f9514d8e012c0c05f31'
          }}
        />
      )}
      <div style={{ marginTop: 5 }} />
      {selectedCaergories.length > 0 && (
        <Select
          isMulti={false}
          name='categoriesMainOptions'
          options={selectedCaergories}
          placeholder={LangData.categoriesPicker.main}
          onChange={e => handleMainCategorySelect(e)}
          closeMenuOnSelect={false}
          autoFocus={false}
          isSearchable={true}
          defaultValue={{
            label: 'Yoga',
            value: '5ee08f9514d8e012c0c05f31'
          }}
        />
      )}
    </div>
  );
};
