import gql from 'graphql-tag';

export const addFriendsToChatMutation = gql`
    mutation addFriendsToChat ($userId: ID, $chatId: String!, $usersIds: [String!]!){
        addFriendsToChat(userId: $userId, chatId: $chatId, usersIds: $usersIds) {
            id
            title
            unreadMessagesCount
            lastUpdate
            createdAt
            lastMessage {
                createdAt
                content
            }
            enabled
            users {
                name
                id
                profilePictureUrl
            }
        }
    }
`;
