import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const chatQuery = gql`
    query chat($userId: ID, $id: String!, $cursor: String, $withMe: Boolean) {
        chat(userId: $userId, id: $id, cursor: $cursor, withMe: $withMe) {
            id
            title
            users {
                ...ClassmateItem
            }
            lastUpdate
            enabled
            messages {
                messages {
                    id
                    createdAt
                    createdAtDay
                    serverMessage
                    content
                    imageThumb
                    messageType
                    author {
                        ...ClassmateItem
                    }
                }
                hasMore
                cursor
            }
        }
    }

    ${classmateItemFragment}
`;
