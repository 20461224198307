import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { analytics } from '../../analytics/report';
import { FeedEventItem } from '../../news-feed/components/feed-list/feed-event-item/feed-event-item.container';
import { FeedEventModal } from '../../news-feed/components/feed-event-modal/feed-event-modal.container';
import { Redirect } from 'react-router';
import moment from 'moment';
import CourseListItem from '../../courses/components/course-list-item.rn';
// import { CalendarExportHandler } from '../../calendar-export/component/calendar-export.rn';
import { ScheduleDrawer } from './schedule-drawer.container';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import EmptyNotice from '../../common/components/empty-notice.rn';
import {
  NEXT_MONTHS_TO_DISPLAY,
  PREV_MONTHS_TO_DISPLAY
} from '../schedule.reducer';
// import { MSJCalendar } from '../../common/components/msj-calendar.rn';
import { LangData } from '../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { Theme } from '../../common/providers/colors';
import { getClient } from '../../apollo';
import { userRemindersQuery } from '../graphql/userReminders.query';
import { userMeetingsQuery } from '../graphql/userMeetings.query';
import Calendar from './calendar';
export class ScheduleView extends Component {
  constructor(props) {
    super(props);

    this._exportHandler = null;
    this.state = {
      scrollPosition: 0,
      modalNews: null,
      data: [],
      exported: {},
      dataMapping: {},
      currentDate: moment(),
      selectedDate: moment(),
      currentDisplayedWeek: null,
      eventsOnDate: [],
      reminders: [],
      lives: [],
      events: [],
      selectedDateEvents: []
    };
  }


  componentWillMount = () => {
    this.getUserNotifications()
    this.getUserReminders()
  }

  getUserNotifications = () => {
    let client = getClient()
    client.query({
      query: userMeetingsQuery,
      variables: {
        userId: localStorage.getItem("userId")
      }
    })
    .then(res => {
      console.log('user meetings =>', res.data.userLiveStreaming)
      this.setState({ lives: res.data.userLiveStreaming }, () => {
        let eventsCpy = [...this.state.events]
        console.log('lives', res.data.userLiveStreaming)
        this.state.lives.map(live => {
          console.log('some =>', eventsCpy.some(elem => elem.title === "LIVE"))
          if(eventsCpy.some(elem => moment(live.meetingTime).format("YYYY-MM-DD") === moment(elem.start).format("YYYY-MM-DD") ) ===  false) {
            eventsCpy.push({
              ...live,
              key: live._id,
              title: 'LIVE',
              start: moment(live.meetingTime).format("MM/DD/YYYY hh:MM:ss Z"),
              end: moment(live.meetingTime).format("MM/DD/YYYY hh:MM:ss Z"),
              allDay: false,
              hexColor: "rgb(0 178 245)"
            })
        }
        })
        this.setState({ events: eventsCpy})
      })
    })
  }


  getUserReminders = () => {
    let client = getClient()
    client.query({
      query: userRemindersQuery,
      variables: {
        userId: localStorage.getItem("userId")
      }
    })
    .then(res => {
      console.log('user reminders =>', res.data.userReminders)
      this.setState({ reminders: res.data.userReminders }, () => {
        let eventsCpy = [...this.state.events]
        this.state.reminders.map(reminder => {
          if(eventsCpy.some(elem => moment(reminder.time.timeType).format("MM/DD/YYYY") === moment(elem.start).format("MM/DD/YYYY") ) ===  false) {
            eventsCpy.push({
              ...reminder,
              key: reminder._id,
              title: 'SESSION',
              start: moment(reminder.time.timeType).format("MM/DD/YYYY hh:MM:ss Z"),
              end: moment(reminder.time.timeType).format("MM/DD/YYYY hh:MM:ss Z"),
              allDay: false,
              hexColor: "rgb(0 245 41)"
            })
          }
         
        })
        this.setState({ events: eventsCpy})
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.eventsOnDate.length === 0 &&
      nextProps.schedule &&
      nextProps.schedule.eventsSchedule
    ) {
      this.setEventsForDate(this.state.currentDate);
    }
  }

  async exportClassEvent(item) {
    if (this._exportHandler) {
      let notes = '';

      if (item.course && item.course.teacher) {
        notes = LangData.schedule.with + ' ' + item.course.teacher.fullName;
      }

      const result = await this._exportHandler.exportEvent(
        item.eventTitle,
        item.exportStartDate,
        item.exportEndDate,
        item.location || '',
        notes,
        item.startTime === item.endTime
      );

      if (result && result.eventId) {
        this.setState(
          { exported: { ...this.state.exported, [item.id]: true } },
          () => {
            this.setState({
              dataMapping: this.getDataMapping(
                this.props.schedule.monthSchedule
              )
            });
          }
        );
      }
    }
  }

  getDataMapping(data) {
    let result = {};
    const startDay = moment()
      .subtract(PREV_MONTHS_TO_DISPLAY, 'month')
      .startOf('month');
    const endDay = moment()
      .add(NEXT_MONTHS_TO_DISPLAY, 'month')
      .endOf('month');
    let iterDay = moment(startDay);

    if (startDay.isBefore(endDay)) {
      while (!iterDay.isSame(endDay, 'day')) {
        result[iterDay.format('YYYY-MM-DD')] = [];
        iterDay = iterDay.add(1, 'day');
      }
    }

    result = data.reduce((prev, rawItem) => {
      const item = { ...rawItem, exported: this.state.exported[rawItem.id] };

      if (!prev[item.groupingStartDate]) {
        prev[item.groupingStartDate] = [];
      }

      if (!prev[item.groupingEndDate]) {
        prev[item.groupingEndDate] = [];
      }

      prev[item.groupingStartDate].push(item);

      if (item.groupingStartDate !== item.groupingEndDate) {
        prev[item.groupingEndDate].push({ ...item, id: `${item.id}_end` });
      }

      return prev;
    }, result);

    return result;
  }

  renderItem(item) {
    const hideExport = this.state.exported[item.id] === true;

    return (
      <CourseListItem
        onExport={hideExport ? null : () => this.exportClassEvent(item)}
        onPress={() =>
          item && item.course && item.course.identifier
            ? this.props.setActiveCourse(item.course.identifier)
            : null
        }
        disabled={!item || !item.course || !item.course.identifier}
        course={item.course}
        hideArrow={true}
        active={false}
        overrideTitle={item.eventTitle}
        scheduleDate={item.startDate}
        endDate={item.endDate}
        endTime={item.endTime}
        startTime={item.startTime}
      />
    );
  }

  getCalendarMonthDays(date) {
    const days = [];
    const momentDate = moment(date);
    const daysInMonth = momentDate.clone().daysInMonth();
    let currentDay = 0;
    let finished = false;

    for (let i = 0; !finished; i++) {
      finished =
        i > 26 &&
        (i + 1) % 7 === 0 &&
        (currentDay === daysInMonth || currentDay < 7);
      currentDay = momentDate
        .clone()
        .startOf('month')
        .day(i)
        .date();
      days.push({
        day: currentDay,
        date: momentDate
          .clone()
          .startOf('month')
          .day(i),
        weekDay: i % 7,
        isDayOfMonth: !(i < 7 && currentDay > 7) && !(i > 27 && currentDay < 7)
      });
    }

    return days;
  }

  renderCalendarDays(date) {
    const dates = this.getCalendarMonthDays(date);
    const rows = [];
    let index = 0;

    while (dates.length) {
      rows.push(this.renderDaysRow(dates.splice(0, 7), index++));
    }

    return <div style={styles.calendarDays}>{rows}</div>;
  }

  renderDaysRow(dates, key) {
    return (
      <div style={styles.weekRow} key={key}>
        {dates.map((value, index) => {
          return (
            <span
              onClick={() => this.onDaySelect(value)}
              key={index}
              style={
                this.state.selectedDate.format().substring(0, 10) ===
                value.date.format().substring(0, 10)
                  ? styles.selectedDay
                  : !value.isDayOfMonth
                  ? styles.dayNotOnMonth
                  : styles.dayNotSelected
              }
            >
              {value.day}{' '}
            </span>
          );
        })}
      </div>
    );
  }

  previousMonth() {
    const { currentDate } = this.state;
    const previousMonth = currentDate.clone().subtract(1, 'month');

    let newState = { currentDate: previousMonth, wasPreviousCalled: false };

    if (
      this.state.selectedDate &&
      !this.state.selectedDate.isSame(previousMonth, 'month')
    ) {
      newState.selectedDate = previousMonth.startOf('month');
    }

    this.setState(newState);
    this.setEventsForDate(newState.selectedDate);
  }

  nextMonth() {
    const { currentDate } = this.state;
    const nextMonth = currentDate.clone().add(1, 'month');

    let newState = { currentDate: nextMonth, wasPreviousCalled: false };

    if (
      this.state.selectedDate &&
      !this.state.selectedDate.isSame(nextMonth, 'month')
    ) {
      newState.selectedDate = nextMonth.startOf('month');
    }

    this.setState(newState);
    this.setEventsForDate(newState.selectedDate);
  }

  setEventsForDate(newDate) {
    if (
      this.props.schedule &&
      this.props.schedule.eventsSchedule &&
      this.props.schedule.eventsSchedule.length > 0
    ) {
      let events = [];
      let selectedDay = moment(newDate).format('ddd, MMM D');

      this.props.schedule.eventsSchedule.map(event => {
        if (selectedDay.length === 10) {
          if (
            event.startTime.substring(0, 10) === selectedDay.substring(0, 10)
          ) {
            events.push(event);
          }
        } else if (selectedDay.length === 11) {
          if (
            event.startTime.substring(0, 11) === selectedDay.substring(0, 11)
          ) {
            events.push(event);
          }
        }
      });
      this.setState({ eventsOnDate: events });
    }
  }

  onDaySelect(dayObject) {
    this.setState({ selectedDate: dayObject.date }, () => {
      if (!dayObject.isDayOfMonth) {
        if (dayObject.day > 7) {
          this.previousMonth();
        } else {
          this.nextMonth();
        }
      }
    });
    this.setEventsForDate(dayObject.date);
  }

  openEventModal(event) {
    let position = document.documentElement.scrollTop;
    document.body.style.position = 'fixed';
    this.setState({ scrollPosition: position });
    if (this.state.modalNews === null) {
      this.props.setActiveEventModal(event);
    } else {
      this.setState(
        {
          modalNews: null
        },
        () => {
          this.props.setActiveEventModal(event);
        }
      );
    }
  }

  getDayName(name) {
    console.log(name);
    if (name === 'Sun') return 'Sunday';
    if (name === 'Mon') return 'Monday';
    if (name === 'Tue') return 'Tuesday';
    if (name === 'Wed') return 'Wednesday';
    if (name === 'Thu') return 'Thursday';
    if (name === 'Fri') return 'Friday';
    if (name === 'Sat') return 'Saturday';
  }

  renerEvents() {
    let events = this.state.eventsOnDate;
    return events.map(event => {
      return (
        <div
          key={event.id}
          style={styles.itemContainer}
          onClick={() => {
            analytics.report('Feed', 'EventView', event.id);
            this.openEventModal(event);
          }}
        >
          <div style={styles.dateContainer}>
            <span>{event.startTime.substring(9, 11)}</span>
            <span>{event.startTime.substring(5, 8).toUpperCase()}</span>
          </div>
          <div style={styles.dayAndHourContainer}>
            <div style={styles.dayAndHour}>
              <FontAwesomeIcon icon={faClock} />
              <span style={{ color: Theme.DARK_BLUE_COLOR }}>
                {event.exportStartTime.substring(11, 16)}
              </span>
              <span style={{ color: Theme.DARK_BLUE_COLOR }}>
                {' '}
                {this.getDayName(event.startTime.substring(0, 3))}
              </span>
            </div>
            <div style={styles.descriptionContainer}>
              <h4 style={{ margin: '2%', marginLeft: '5%' }}>{event.title}</h4>
              <p style={{ margin: '2%', color: '#D6D6DB', marginLeft: '5%' }}>
                {event.description}
              </p>
            </div>
          </div>
        </div>
      );
    });
  }

  updateSelectedDate = (date) => {
    console.log('inside update selected date=>', date)
    let filteredEvents = []
    let filteredReminders = this.state.reminders.filter(elem => moment(elem.time.timeType).format('MM/DD/YYYY') === date)
    let filteredLives = this.state.lives.filter(elem => moment(elem.meetingTime).format('MM/DD/YYYY') === date)
    console.log('filteredLives', filteredLives)
    filteredReminders.map(elem => {
      filteredEvents.push({
        _id: elem._id,
        title: "Session Reminder",
        time: elem.time.timeType,
        type: "Reminder"
      })
    })
    filteredLives.map(elem => {
      filteredEvents.push({
        _id: elem._id,
        title: `Live Stream - ${elem.meetingName}`,
        time: elem.meetingTime,
        type: "Live"
      })
    })
    console.log('selectedDateEvents', filteredEvents)
    this.setState({ selectedDateEvents: filteredEvents })
  }

  closeEventModel = () => {
    document.body.style.position = '';
    window.scrollTo(0, this.state.scrollPosition);
    requestAnimationFrame(() => {
      this.props.setActiveEventModal(null);
    });
  };

  render() {
       if (!this.props.currentUser) {
      return <Redirect to='/login' />;
    }

    if (this.props.schedule.loading) {
      return <LoadingSpinner left={window.innerWidth} />;
    }

    return (
      <div style={styles.container}>
        <div style={styles.calendarTitle}>My Calendar</div>
        <Calendar 
          events={this.state.events}
          updateSelectedDate={this.updateSelectedDate}
        />
          <div style={styles.calendarEventsCont}>
            <div style={styles.selectedDate}>Selected Date: {this.state.selectedDate.format('MM/DD/YYYY')}</div>
          {
            this.state.selectedDateEvents.length > 0 &&
            this.state.selectedDateEvents.map(selectedDate => {
              return (
                <div key={selectedDate._id} style={styles.eventCont}>
                  <div style={styles.innerEventCont}>
                    <div style={selectedDate.type === "Live" ? styles.livePoint : styles.reminderPoint}></div>
                    <div style={styles.eventTitle}>{selectedDate.title}</div>
                  </div>
                  <div style={styles.eventTime}>{moment(selectedDate.time).format('MM/DD/YYYY HH:mm')}</div>
                </div>
              )
            })
          }
          </div>
        </div>
    )
  }

  // render() {
  //   if (!this.props.currentUser) {
  //     return <Redirect to='/login' />;
  //   }

  //   if (this.props.schedule.loading) {
  //     return <LoadingSpinner left={window.innerWidth} />;
  //   }

  //   return (
  //     <div style={styles.container}>
  //        <div style={styles.calendarContainer}>
  //         <div style={styles.calendarHeader}>
  //           <div
  //             style={styles.monthArrowContainer}
  //             onClick={() => this.previousMonth()}
  //           >
  //             <FontAwesomeIcon icon={faChevronLeft} />
  //           </div>
  //           <div style={styles.monthHeader}>
  //             {this.state.currentDate.format('MMMM YYYY').toUpperCase()}
  //           </div>
  //           <div
  //             style={styles.monthArrowContainer}
  //             onClick={() => {
  //               this.nextMonth();
  //             }}
  //           >
  //             <FontAwesomeIcon icon={faChevronRight} />
  //           </div>
  //         </div>
  //         <div style={styles.daysHeaders}>
  //           <div style={styles.dayHeader}>S</div>
  //           <div style={styles.dayHeader}>M</div>
  //           <div style={styles.dayHeader}>T</div>
  //           <div style={styles.dayHeader}>W</div>
  //           <div style={styles.dayHeader}>T</div>
  //           <div style={styles.dayHeader}>F</div>
  //           <div style={styles.dayHeader}>S</div>
  //         </div>
  //         <div style={styles.divider}></div>
  //         <div key={this.state.currentDate} style={styles.daysContainer}>
  //           {this.renderCalendarDays(this.state.currentDate)}
  //         </div>
  //       </div>
  //       <div style={styles.eventsContainer}>
  //         {/* {/* <h3>Upcoming Events</h3> */}
  //         {this.state.eventsOnDate && this.state.eventsOnDate.length > 0 ? (
  //           this.renerEvents()
  //         ) : (
  //           <EmptyNotice
  //             text={'No events on the selected date '}
  //             emoji='🏖'
  //             title={'No Events'}
  //           />
  //         )}
  //       </div> 

  //        <FeedEventModal
  //          onClose={this.closeEventModel}
  //          event={this.props.activeEventModal}
  //          history={this.props.history}
  //        /> 
  //     </div>
  //   );
  // }
}

const styles = {
  selectedDate: {
    fontSize: '17px',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: '1%'
  },
  calendarEventsCont: {
    width: '80%',
    maxHeight: '16vh',
    overflowY: 'scroll'
  },
  eventTitle: {
    fontWeight: 'bold',
    fontSize: '16px'
  },
  innerEventCont: {
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  eventCont: {
    width: '80%',
  },
  livePoint: {
    width: '3vw',
    backgroundColor: 'rgb(0 178 245)',
    height: '1.5vh',
    borderRadius: '50px',
    marginRight: '2vw'
  },
  reminderPoint: {
    width: '3vw',
    backgroundColor: 'rgb(0 245 41)',
    height: '1.5vh',
    borderRadius: '50px',
    marginRight: '2vw'
  },
  calendarTitle: {
    fontSize: '5vw',
    fontWeight: 'bold',
    color: '#4d4b62'
  },
  container: {
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Theme.BACKGROUND_COLOR,
    height: window.innerHeight,
    marginTop: '16%'
  },
  calendarContainer: {
    color: Theme.WHITE_COLOR,
    backgroundColor: Theme.GRAY_COLOR,
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '5%',
    borderRadius: '0 0 20px 20px'
  },
  sideMenu: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  sideMenuItem: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  sideMenuText: {
    color: Theme.BACKGROUND_COLOR,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 5
  },
  daysContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: Theme.WHITE_COLOR
  },
  calendar: {
    width: window.innerWidth,
    height: '100%',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column'
  },

  monthArrow: {
    width: 9,
    height: 10
  },
  divider: {
    width: '88%',
    backgroundColor: Theme.WHITE_COLOR,
    height: '2px',
    borderRadius: '50%',
    marginTop: '1%'
  },
  monthArrowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    paddingLeft: 20,
    paddingRight: 20,
    color: Theme.WHITE_COLOR
  },
  calendarDays: {
    width: '100%'
  },
  dayContainer: {
    borderWidth: 1,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderRadius: 5,
    position: 'relative',
    color: '#35355b',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 23,
    height: 26
  },

  day: {
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px',
    borderColor: '#35355b',
    width: 15,
    height: 23,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  weekRow: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 15
  },

  calendarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '15%',
    color: Theme.TEXT_COLOR
  },

  monthHeader: {
    height: 25,
    width: 135,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: Theme.WHITE_COLOR
    // borderStyle: 'solid',
    // borderWidth: 1,
    // borderRadius: 5
  },

  daysHeaders: {
    width: '85%',
    display: 'flex',
    justifyContent: 'space-between',
    color: Theme.WHITE_COLOR,
    fontWeight: 'bold',
    marginTop: 13,
    marginBottom: 5
  },

  dayHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 23
  },

  todayLabel: {
    position: 'absolute',
    color: 'rgb(160, 160, 167)',
    bottom: -14,
    fontSize: 9
  },

  calendarAppear: {
    opacity: 1,
    transition: 'all 300ms',
    transform: 'translate(0, 0)'
  },

  selectedDay: {
    backgroundColor: Theme.DARK_BLUE_COLOR,
    borderRadius: '50%',
    width: '5%',
    textAlign: 'center',
    padding: '1%'
  },
  dayNotSelected: {
    borderRadius: '50%',
    width: '5%',
    textAlign: 'center',
    padding: '1%'
  },
  dayNotOnMonth: {
    borderRadius: '50%',
    width: '5%',
    textAlign: 'center',
    padding: '1%',
    color: '#A8A6A9'
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    color: Theme.WHITE_COLOR,
    fontWeight: 'bold',
    width: '90%'
  },
  itemContainer: {
    marginBottom: '1%',
    position: 'relative',
    display: 'flex'
  },
  eventsContainer: {
    width: '90%',
    color: Theme.WHITE_COLOR
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#BEBBB3',
    borderRadius: '50%',
    color: Theme.BLACK_COLOR,
    width: '55px',
    height: '55px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dayAndHour: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%'
  },
  descriptionContainer: {
    width: '100%',
    backgroundColor: '#777787',
    padding: '1%',
    borderRadius: '20px',
    marginTop: '1%'
  },
  dayAndHourContainer: {
    width: '80%',
    marginLeft: '2%'
  }
};
