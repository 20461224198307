import { graphql } from 'react-apollo-temp';
import { uploadLiveFeedImageMutation } from '../../../graphql/upload-live-feed-image.mutation';
import UploadEventFeedImageView from './upload-event-image.rn';
import { togglePermissionsErrorModal } from '../../../../main/main.actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withErrorHandler } from '../../../../common/hoc/error-handler.rn';
import update from 'immutability-helper';
import { setAlert } from '../../../../alert/alert.actions';

const withUploadMutation = graphql(uploadLiveFeedImageMutation, {
  withRef: true,
  props: ({ ownProps, mutate }) => ({
    uploadFeedImage: (imageData, eventId) =>
      mutate({
        variables: {
          imageData,
          eventId
        },
        optimisticResponse: {
          __typename: 'Mutation',
          uploadImageToEventFeed: {
            __typename: 'EventLiveFeedPhoto',
            id: `temp_${Date.now()}`,
            pending: true,
            rejectionReason: null,
            approved: false,
            uploadedBy: {
              __typename: 'User',
              id: ownProps.currentUser.id,
              name: ownProps.currentUser.name,
              profilePictureUrl: ownProps.currentUser.profilePictureUrl
            },
            photoUrl: imageData,
            thumbUrl: imageData
          }
        },
        updateQueries: {
          eventLiveFeedImages: (
            prev,
            {
              mutationResult: {
                data: { uploadImageToEventFeed }
              }
            }
          ) =>
            update(prev, {
              feedItem: {
                liveFeedImages: {
                  $unshift: [uploadImageToEventFeed]
                }
              }
            })
        }
      })
  })
});

const withError = withErrorHandler({
  apollo: ['data']
});

const withState = connect(
  ({ auth }) => ({
    currentUser: auth.currentUser
  }),
  dispatch => ({
    openPermissionsErrorModal: type =>
      dispatch(togglePermissionsErrorModal(true, type)),
    setAlert: message => dispatch(setAlert(message, 'success'))
  })
);

export const UploadEventFeedImage = compose(
  withState,
  withUploadMutation,
  withError
)(UploadEventFeedImageView);
