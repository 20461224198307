import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo-temp';
import { reloadUserProfile } from '../../auth/auth.actions';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';
import { startLinkedinAuth } from '../linkedin.actions';
import { linkedinDetailsQuery } from '../graphql/linkedin-details.query';
import { LinkedinAuthModal } from './linkedin-auth-view.rn';

const mapStateToProps = ({ auth }) => ({
  accessToken: auth.accessToken
});

const mapDispatchToProps = dispatch => ({
  startAuth: () => dispatch(startLinkedinAuth()),
  authDone: () => dispatch(reloadUserProfile('linkedin'))
});

const withData = graphql(linkedinDetailsQuery, {
  name: 'linkedin',
  withRef: true,
  props: ({ linkedin }) => {
    if (linkedin.loading || linkedin.error) {
      return {};
    }

    return {
      clientId: linkedin.linkedinAuth.clientId,
      returnUrl: linkedin.linkedinAuth.returnUrl
    };
  }
});

const withError = withErrorHandler({
  apollo: ['linkedin']
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const LinkedinAuth = compose(
  withState,
  withData,
  withError
)(LinkedinAuthModal);
