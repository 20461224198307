import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';
import { FeedEventItemFragment } from '../../news-feed/graphql/feed-event-item.fragment';

export const myNotificationsQuery = gql`
  query myNotifications($userId: ID, $cursor: String) {
    notifications(userId: $userId, cursor: $cursor) {
      hasMore
      cursor
      items {
        __typename
        id
        content
        read
        time
        relatedFeedItemId
        ... on BaseNotificationType {
          approved
          originalPhotoUrl
          photoUrl
        }
        ... on BaseNotificationType {
          title
        }
        ... on BaseNotificationType {
          otherUser {
            ...ClassmateItem
          }
        }
        ... on BaseNotificationType {
          event {
            ...FeedEventItem
          }
          approved
          photoUrl
        }
        ... on BaseNotificationType {
          approved
          photoUrl
        }
      }
    }
  }
  
  ${classmateItemFragment}
  ${FeedEventItemFragment}
`;
