import gql from 'graphql-tag';

export const updateYogiClickedMutation = gql`
  mutation updateYogiClicked($userId:String!) {
    updateYogiClicked(
        userId: $userId
    ){
      _id
    }
  }
`;
