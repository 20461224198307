import { graphql } from 'react-apollo-temp';
import { eventGoingQuery } from '../../../graphql/event-going.query';
import { compose } from 'react-apollo-temp';
import AttendingListView from './attending-list.rn';

export const withGoingList = graphql(eventGoingQuery, {
  name: 'goingList',
  skip: ({ event }) => event === null,
  options: ({ event }) => ({
    variables: {
      eventId: event ? event.id : null
    },
    pollInterval: 30 * 1000,
    fetchPolicy: 'cache-and-network'
  })
});

export const AttendingList = compose(withGoingList)(AttendingListView);
