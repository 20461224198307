import gql from 'graphql-tag';

export const FeedPollItemFragment = gql`
  fragment FeedPollItem on NewsFeedItemType {
    id
    question
    categories {
      id
      name
      icon
    }
    mainCategory {
      id
      name
      icon
    }
    possibleAnswers {
      answer
      value
    }
    author {
      id
      profilePictureUrl
      name
      admin
    }
    userAnswered
    isNew
    likes
    comments {
      id
      parentId
      userId
      userName
      createdAt
      body
      email
      updatedAt
      reported
      likes
      postId
      userProfileImg
    }
    creationDate
    displayCreationDate
  }
`;
