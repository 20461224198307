import gql from 'graphql-tag';


export const photoAddedSubsctiption = gql`
    subscription photoAdded {
        photoAdded {
            userId
            id
            imageThumb
            url
            approved
            label
        }
    }
`