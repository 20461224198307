export function fetchWithProgress(url, opts = {}, onProgress) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);

    const headers = opts.headers || {};
    for (const k in headers) {
      if (headers[k]) {
        xhr.setRequestHeader(k, opts.headers[k]);
      }
    }

    xhr.onload = e => res(JSON.parse(e.target.response));
    xhr.onerror = rej;

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = event => {
        if (event.lengthComputable) {
          const progress = event.loaded / event.total * 100;
          onProgress(progress);
        }
      };
    }

    xhr.send(opts.body);
  });
}
