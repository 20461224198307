import React from 'react';
import { connect } from 'react-redux';
import { setAgendaActiveCourse } from '../schedule.actions';
import ScheduleDrawerView from './schedule-drawer.rn';
// import { withScrollingFix } from '../../common/providers/scrolling-handler-provider';
import { compose } from 'redux';

const mapDispatch = dispatch => ({
  setActiveCourse: course => dispatch(setAgendaActiveCourse(course))
});

const mapStateToProp = ({ schedule }) => ({
  activeCourse: schedule.activeCourse
});

const withState = connect(mapStateToProp, mapDispatch);

export const ScheduleDrawer = compose(
  //   withScrollingFix,
  withState
)(ScheduleDrawerView);
