import React, { Fragment, useEffect, useState } from 'react';
import { LangData } from '../../assets/locales/localization';
import ProfileImage from '../../common/components/profile-image.rn';
import { classmateItemFragment } from '../graphql/classmate-item.fragment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUserFriends,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../common/providers/colors';

const ClassmateItemView = props => {
  const [icon, setIcon] = useState(null);
  const [emphasisColor, setEmphasisColor] = useState(
    Theme.SECONDARY_BACKGROUND_COLOR
  );

  useEffect(() => {
    if (props.classmate.friendRequestStatus === 'APPROVED') {
      setEmphasisColor(Theme.SECONDARY_TEXT_COLOR);
      setIcon(faUserFriends);
    } else if (
      props.classmate.friendRequestStatus === 'PENDING' ||
      props.classmate.friendRequestStatus === 'SENT'
    ) {
      setEmphasisColor('#aaaaaa');
      setIcon(faUser);
    }
  }, [props]);

  return (
    <Fragment>
      <div
        disabled={props.disabled}
        onClick={() => {
          props.onItemClick(props.classmate);
        }}
        style={styles.container}
      >
        <div
          style={Object.assign(
            { borderColor: emphasisColor, color: emphasisColor },
            styles.imageContainer
          )}
        >
          <ProfileImage
            showBorder={true}
            borderColor={emphasisColor}
            style={styles.image}
            imageUrl={props.classmate.profilePictureUrl}
          />
          {icon && (
            <div
              style={Object.assign(
                { borderColor: emphasisColor, color: emphasisColor },
                styles.iconContainer
              )}
            >
              <FontAwesomeIcon icon={icon} />
            </div>
          )}
          {props.checked ? (
            <FontAwesomeIcon icon={faCheck} style={{ position: 'absolute' }} />
          ) : null}
        </div>

        <div style={styles.textContainer}>
          <p style={styles.title}>
            {props.me && props.me.id === props.classmate.id
              ? LangData.classmateItem.you
              : props.classmate.name}
          </p>
          {props.minimal ? null : (
            <p style={styles.subTitle} numberOfLines={2}>
              {props.classmate.major}
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const SCREEN_WIDTH = window.innerWidth;
const ITEM_WIDTH = 100;
const ITEM_HEIGHT = 120;
export const CLASSMATE_ITEM_MARGIN = (SCREEN_WIDTH - ITEM_WIDTH * 3) / 8;

const styles = {
  imageContainer: {
    borderRadius: '50%',
    border: '2px solid ',
    width: window.innerWidth / 4.8 + 'px',
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
    height: window.innerWidth / 4.8 + 'px'
  },
  iconContainer: {
    width: 'fit-content',
    position: 'absolute',
    right: '-4px',
    bottom: '5px',
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '3px',
    border: '1px solid ',
    fontSize: '12px'
  },
  title: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontWeight: 'bold',
    margin: 0
  },
  container: {
    marginBottom: '5%'
  }
};

export const ClassmateItem = connect(({ auth }) => ({ me: auth.currentUser }))(
  ClassmateItemView
);
