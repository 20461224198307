export const EMainNavigationScreens = {
  NewsFeed: 0,
  Profile: 1,
  Courses: 2
};

export const EMainActions = {
  NAVIGATE_PARENT: 'main/NAVIGATE_PARENT',
  NAVIGATE_CHILD: 'main/NAVIGATE_CHILD',
  APP_VERSION: 'main/APP_VERSION',
  TOGGLE_PERMISSIONS_ERROR: 'main/PERMISSIONS_ERROR',
  TOGGLE_CORNER_MENU: 'main/TOGGLE_CORNER_MENU',
  CLOSE_SIDE_MENU: 'main/CLOSE_SIDE_MENU',
  CLOSE_ALL_MODALS: 'main/CLOSE_ALL_MODALS'
};

export const closeModals = () => ({
  type: EMainActions.CLOSE_ALL_MODALS
});

export const navigateToParent = screenIndex => {
  try {
    // Keyboard.dismiss();
  } catch (e) {
    // nothing to do there
  }

  return {
    type: EMainActions.NAVIGATE_PARENT,
    screenIndex
  };
};

export const closeSideMenu = () => ({
  type: EMainActions.CLOSE_SIDE_MENU
});

export const navigateToChild = routeName => ({
  type: EMainActions.NAVIGATE_CHILD,
  routeName
});

export const setAppVersion = version => ({
  type: EMainActions.APP_VERSION,
  version
});

export const togglePermissionsErrorModal = (isOpen, missingPermission) => ({
  type: EMainActions.TOGGLE_PERMISSIONS_ERROR,
  isOpen,
  missingPermission
});

export const toggleCornerMenu = isOpen => ({
  type: EMainActions.TOGGLE_CORNER_MENU,
  isOpen
});
