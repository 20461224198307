import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo-temp';
import { CoursesView } from './courses.component';
import { allCoursesQuery } from '../../graphql/all-courses.query';
import { updateCoursesFilter, editEntity } from './courses.actions';
import { removeCourseMutation } from '../../graphql/remove-course.mutation';
import { withStatistics } from '../with-statistics.container';

const mapStateToProps = ({ coursesOnPosts }) => ({
  skip: coursesOnPosts.skip,
  limit: coursesOnPosts.limit,
  search: coursesOnPosts.search,
  editModalOpen: coursesOnPosts.isEditMode,
  editModeEntity: coursesOnPosts.editEntity
});

const mapDispatch = dispatch => ({
  updateFilter: filterChanges => dispatch(updateCoursesFilter(filterChanges)),
  toggleEditMode: entity => dispatch(editEntity(entity))
});

const withState = connect(mapStateToProps, mapDispatch);

const withData = graphql(allCoursesQuery, {
  name: 'courses',
  options: props => {
    let extraVars = {
      preferedIds: []
    };

    if (
      props.search === '' &&
      props.checkedItems &&
      props.checkedItems.length > 0
    ) {
      extraVars = {
        perferedIds: props.checkedItems
          .map(group =>
            group.startsWith('course_') ? group.replace('course_', '') : null
          )
          .filter(item => item)
      };
    }

    return {
      fetchPolicy: 'network-only',
      variables: {
        skip: props.skip,
        limit: props.limit,
        search: props.search,
        ...extraVars
      }
    };
  }
});

export const withCourses = compose(withState, withData);

const withMutation = graphql(removeCourseMutation, {
  options: {
    refetchQueries: ['allCourses', 'statistics']
  },
  props: ({ ownProps, mutate }) => ({
    removeCourse: courseId => mutate({ variables: { courseId } })
  })
});

export const Courses = withCourses(withMutation(withStatistics(CoursesView)));
