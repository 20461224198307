import React, { useState, useEffect } from 'react';
import ProfileImage from '../../../common/components/profile-image.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../common/providers/colors';

const UserInfo = props => {
  const [icon, setIcon] = useState(null);
  const [isFriends, setIsFriends] = useState(false);
  const [emphasisColor, setEmphasisColor] = useState(
    Theme.SECONDARY_TEXT_COLOR
  );

  useEffect(() => {
    if (props.user.friendRequestStatus === 'APPROVED') {
      setEmphasisColor(Theme.SECONDARY_TEXT_COLOR);
      setIcon(faUserFriends);
      setIsFriends(true);
    } else if (
      props.user.friendRequestStatus === 'PENDING' ||
      props.user.friendRequestStatus === 'SENT'
    ) {
      setEmphasisColor('#aaaaaa');
      setIcon(faUser);
      setIsFriends(false);
    }
  }, [props]);

  const renderSocialMedia = () => {
    const socialMedias = [
      'msjEmail',
      'twitter',
      'snapchat',
      'instagram'
    ].filter(name => !!props.user[name]);

    if (socialMedias.length === 0) {
      return null;
    }

    return (
      <div style={styles.socialMediaContainer}>
        {socialMedias.map(name => (
          <div
            key={name}
            style={styles.socialMedia}
            onClick={() =>
              name === 'msjEmail'
                ? window.open('mailto:' + props.user.msjEmail)
                : props.onSocialClick(name, props.user[name])
            }
          >
            <FontAwesomeIcon icon={faEnvelope} style={styles.iconStyle} />
          </div>
        ))}
      </div>
    );
  };

  const { user } = props;

  return (
    <div style={styles.container}>
      <div
        style={Object.assign(
          { borderColor: emphasisColor, color: emphasisColor },
          styles.imageContainer
        )}
      >
        <ProfileImage
          showBorder={true}
          borderColor={emphasisColor}
          style={styles.image}
          imageUrl={user.profilePictureUrl}
          fullSizeUrl={user.profilePictureUrl}
          withFullSize={true}
          history={props.history}
        />
        {icon && (
          <div
            style={Object.assign(
              { borderColor: emphasisColor, color: emphasisColor },
              styles.iconContainer
            )}
          >
            <FontAwesomeIcon icon={icon} />
          </div>
        )}
      </div>

      <div style={styles.mainContainer}>
        <div style={styles.infoContainer}>
          <p style={styles.nameText}>{user.name}</p>
          {user.major ? <p style={styles.majorText}>{user.major}</p> : null}
          { isFriends && <p style={styles.majorText}>Friends</p>}
        </div>
        {renderSocialMedia()}
      </div>
    </div>
  );
};

export default UserInfo;

const IMAGE_SIZE = 75;

const styles = {
  container: {
    display: 'flex',
    padding: '3%'
  },
  mainContainer: {
    marginLeft: 15,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  infoContainer: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  imageContainer: {
    borderRadius: '50%',
    border: '2px solid ',
    width: window.innerWidth / 4.8 + 'px',
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
    height: window.innerWidth / 4.8 + 'px'
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: IMAGE_SIZE / 2,
    marginBottom: 2
  },
  nameText: {
    color: Theme.TEXT_COLOR,
    fontSize: 20
  },
  majorText: {
    color: Theme.TEXT_COLOR,
    fontSize: 13
  },
  emailContainer: {
    backgroundColor: '#e6ecf4',
    marginTop: 5,
    borderRadius: 15,
    flex: 0,
    flexGrow: 0,
    flexShrink: 0
  },
  emailText: {
    marginLeft: 5,
    color: Theme.TEXT_COLOR,
    fontSize: 12
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderWidth: 1,
    borderRadius: 12,
    backgroundColor: '#fff',
    bottom: 0,
    right: 0,
    position: 'absolute',
    border: '1px solid'
  },
  socialMediaContainer: {
    flex: 0,
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  socialMedia: {
    flex: 0,
    width: 20,
    height: 20,
    marginRight: 15
  },
  iconStyle: {
    color: Theme.TEXT_COLOR
  }
};
