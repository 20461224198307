import gql from 'graphql-tag';

export const uploadToCloudinaryMutation = gql`
  mutation uploadToCloudinary(
    $uri: String!
    $type: String!
    $name: String!
    $filePath: String
    $publicId: String
    $tag: String
  ) {
    uploadToCloudinary(
      uri: $uri
      type: $type
      name: $name
      filePath: $filePath
      publicId: $publicId
      tag: $tag
    ) {
      id
      filePath
    }
  }
`;
