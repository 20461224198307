import { combineReducers } from 'redux'
import { ECommentsViewActions } from './comments.actions'
import { LOCATION_CHANGE } from 'react-router-redux'

export const COMMENTS_PER_PAGE = 3;

const skip = (state = 0, action) => {
    if (action.type === ECommentsViewActions.CHANGE_FILTER && action.skip !== undefined) {
      return action.skip;
    }
  
    if (action.type === LOCATION_CHANGE) {
      return 0;
    }
  
    return state;
};

const limit = (state = COMMENTS_PER_PAGE, action) => {
    if (action.type === ECommentsViewActions.CHANGE_FILTER && action.limit !== undefined) {
      return action.limit;
    }
  
    if (action.type === LOCATION_CHANGE) {
      return COMMENTS_PER_PAGE;
    }
  
    return state;
};

export const comments = combineReducers({
    skip,
    limit
});