import { combineReducers } from 'redux';
import { ECoursesViewActions } from './courses.actions';
import { EMainActions } from '../main/main.actions';

function activeCourse(state = null, action) {
  switch (action.type) {
    case ECoursesViewActions.CHANGE_ACTIVE_ITEM:
      return action.payload;
    case 'js-accounts/CLEAR_USER':
      return null;
    default:
      return state;
  }
}

function isDrawerOpen(state = false, action) {
  switch (action.type) {
    case ECoursesViewActions.TOGGLE_DRAWER:
      return action.newState;
    case EMainActions.NAVIGATE_PARENT:
      return false;
    default:
      return state;
  }
}

export const courses = combineReducers({
  activeCourse,
  isDrawerOpen
});
