import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo-temp';
import { reloadUserProfile } from '../../auth/auth.actions';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';
import { facebookDetailsQuery } from '../graphql/facebook-details.query';
import { startFacebookAuth } from '../facebook.actions';
import { FacebookAuthModal } from './facebook-auth-view.rn';

const mapStateToProps = ({ auth }) => ({
  accessToken: auth.accessToken
});

const mapDispatchToProps = dispatch => ({
  startAuth: () => dispatch(startFacebookAuth()),
  authDone: () => dispatch(reloadUserProfile('facebook'))
});

const withData = graphql(facebookDetailsQuery, {
  name: 'facebook',
  withRef: true,
  props: ({ facebook }) => {
    console.log(facebook);
    if (facebook.loading || facebook.error) {
      return {};
    }

    return {
      appId: facebook.facebookAuth.appId,
      returnUrl: facebook.facebookAuth.returnUrl,
      stateParam: facebook.facebookAuth.stateParam
    };
  }
});

const withError = withErrorHandler({
  apollo: ['twitter']
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const FacebookAuth = compose(
  withState,
  withData,
  withError
)(FacebookAuthModal);
