import React, { Component } from 'react';
import PropTypes from 'prop-types';
import defaultProfileImage from '../../assets/images/default_profile.jpeg';
import Avatar from '@material-ui/core/Avatar';

export class ProfileAvatar extends Component {
  static propTypes = {
    style: PropTypes.any,
    src: PropTypes.string,
    size: PropTypes.number
  };

  render() {
    return (
      <Avatar
        style={this.props.style || null}
        src={this.props.src || defaultProfileImage}
        size={this.props.size || 40}
      />
    );
  }
}
