import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const eventGoingQuery = gql`
  query eventGoingList($eventId: String!) {
    eventGoingList(eventId: $eventId) {
      users {
        ...ClassmateItem
      }
      total
      mutual
    }
  }

  ${classmateItemFragment}
`;
