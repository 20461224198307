import React from 'react';
import { connect } from 'react-redux';
import ClassmateDrawerView from './classmates-drawer.rn.js';
import {
  changeActiveCourse,
  toggleDrawer
} from '../../courses/courses.actions';
import { toggleComponent } from '../../router/router.actions';

import { compose } from 'redux';

const mapDispatchToProps = dispatch => ({
  setActiveCourse: course => dispatch(changeActiveCourse(course)),
  toggleDrawer: newState => dispatch(toggleDrawer(newState)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const mapStateToProp = ({ courses, navigation }) => ({
  activeCourse: courses.activeCourse,
  isDrawerOpen: courses.isDrawerOpen,

  currentComponent: navigation.navigationComponents.component
});

const withState = connect(mapStateToProp, mapDispatchToProps);

export const CoursesDrawer = compose(withState)(ClassmateDrawerView);
