import React, { useState, useEffect, Fragment } from 'react';
import ProfileImage from '../../../../common/components/profile-image.rn';
import { FeedEventLikesUsersListModal } from './feed-event-likes-user-modal';
import Comment from './render-comment.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faCommentAlt,
  faShareSquare
} from '@fortawesome/free-regular-svg-icons';
import {
  faThumbsUp as fasThumbsUp,
  faPrayingHands
} from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Theme } from '../../../../common/providers/colors';

function Comments(props) {
  const [viewCommentsContainer, setViewCommentsContainer] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [usersListOpen, setUsersListOpen] = useState(false);
  const [usersWhoLikedPost, setUsersWhoLikedPost] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLikeOptions, setShowLikeOptions] = useState(false);
  const [update, setUpdate] = useState(0);
  const [like, setLike] = useState(props.likeEventExtractor());

  useEffect(() => {
    if (
      JSON.parse(props.eventLikes) &&
      JSON.parse(props.eventLikes).length > 0
    ) {
      getUsersWhoLikedPost();
    }
  }, []);

  useEffect(() => {
    setLike(props.likeEventExtractor());
  }, [props.likeEventExtractor()]);

  useEffect(() => {
    setUpdate(update + 1);
    if (showLikeOptions) setShowLikeOptions(false);
    getUsersWhoLikedPost();
  }, [like]);

  const getUsersWhoLikedPost = async () => {
    let tempUserArray = await props.likesEventsExtractUsers();
    setUsersWhoLikedPost(tempUserArray);
  };

  const copyEventId = () => {
    const el = document.createElement('textarea');
    el.value = 'https://yocas.now.sh/?id=' + props.postId;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    props.setAlert('Event Link Copied to clipboard');
  };

  const countByType = (type) => {
    let likesObject = JSON.parse(props.eventLikes)
    console.log('likesObject', likesObject)
    let filteredByType = likesObject.filter(elem => elem.type === type)
    console.log('filteredType count', filteredByType.length)
    return filteredByType.length
  }

  let childrenComments = [];

  return (
    <Fragment>
      <div onClick={e => e.stopPropagation()}>
  

        <div id='rowOptionsContainer' style={props.data.length  > 0 ? styles.rowOptionsContainer: {...styles.rowOptionsContainer, width: '82%'}}>
      
          <div
            style={styles.optionDiv}
            onClick={() => {
              if (like) {
                props.likeEventPost();
                setLike(!like);
              } else {
                setShowLikeOptions(true);
              }
              getUsersWhoLikedPost();
            }}
          >
            {showLikeOptions ? (
              <div style={styles.likeOptions}>
                <div
                  style={styles.iconContainer}
                  onClick={() => {
                    props.likeEventPost('like');
                    setLike(!like);
                    setShowLikeOptions(false);
                  }}
                >
                  <FontAwesomeIcon
                    icon={fasThumbsUp}
                    style={styles.iconOption}
                  />
                </div>
                <div
                  style={styles.iconContainer}
                  onClick={() => {
                    props.likeEventPost('namaste');
                    setLike(!like);
                    setShowLikeOptions(false);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPrayingHands}
                    style={styles.iconOption}
                  />
                </div>
                <div
                  style={styles.iconContainer}
                  onClick={() => {
                    props.likeEventPost('love');
                    setLike(!like);
                    setShowLikeOptions(false);
                  }}
                >
                  <img 
                    alt=""
                    src={require('../../../../assets/images/Love.png')}
                    style={styles.myIconOption}
                  />
                </div>
                <div
                  style={styles.iconContainer}
                  onClick={() => {
                    props.likeEventPost('happy');
                    setLike(!like);
                    setShowLikeOptions(false);
                  }}
                >
                  <img 
                    alt=""
                    src={require('../../../../assets/images/Happy.png')}
                    style={styles.miniIconOptions}
                  />
                </div>
                <div
                  style={styles.iconContainer}
                  onClick={() => {
                    props.likeEventPost('sad');
                    setLike(!like);
                    setShowLikeOptions(false);
                  }}
                >
                  <img 
                    alt=""
                    src={require('../../../../assets/images/Sad.png')}
                    style={styles.myIconOption}
                  />
                </div>
              </div>
            ) : null}
            <FontAwesomeIcon
              icon={
                props.likeEventExtractor() || like ? fasThumbsUp : faThumbsUp
              }
              style={styles.icon}
            />
            <span>Like</span>
          </div>

          <div
            onClick={e => setViewCommentsContainer(true)}
            style={styles.optionDiv}
          >
            <FontAwesomeIcon icon={faCommentAlt} style={styles.icon} />
            <span>Comment</span>
          </div>

          
          
       
          
          {props.showShare && (
            <div onClick={e => copyEventId()} style={styles.optionDiv}>
              <FontAwesomeIcon icon={faShareSquare} style={styles.icon} />
              <span>Share</span>
            </div>
          )}

          {JSON.parse(props.eventLikes) &&
            JSON.parse(props.eventLikes).length > 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setUsersListOpen(true);
                  let position = document.documentElement.scrollTop;
                  document.body.style.position = 'fixed';
                  setScrollPosition(position);
                }}
              >
                {
                  JSON.parse(props.eventLikes).length > 0 &&
                  <div style={styles.likesIcon}>
                    <FontAwesomeIcon icon={fasThumbsUp} />
                  </div>
                }
               { 
                  countByType("namaste") > 0 &&
                  <div style={styles.likesIcon}>
                    <FontAwesomeIcon icon={faPrayingHands} />
                  </div>
                }
                {
                  countByType("namaste") > 0 &&
                  <span>{countByType("namaste")}</span>
                }
                {
                  countByType("love") > 0 &&
                  <div style={styles.otherActionIcon}>
                    <img 
                      alt=""
                      src={require('../../../../assets/images/Love.png')}
                      style={{width: '24px'}}
                    />
                  </div>
                }
                {
                  countByType("love") > 0 &&
                  <span>{countByType("love")}</span>
                }
                {
                countByType("happy") > 0 &&
                <div style={styles.otherActionIcon}>
                  <img 
                    alt=""
                    src={require('../../../../assets/images/Happy.png')}
                    style={{width: '24px'}}
                  />
                </div>
                }
                {
                  countByType("happy") > 0 &&
                  <span>{countByType("happy")}</span>
                }
                {
                  countByType("sad") > 0 &&
                  <div style={styles.otherActionIcon}>
                    <img 
                      alt=""
                      src={require('../../../../assets/images/Sad.png')}
                      style={{width: '24px'}}
                    />
                  </div>
                }
                {
                  countByType("sad") > 0 &&
                  <span>{countByType("sad")}</span>
                }
              </div>
            )}
       
       {props.data.length > 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '1%'
              }}
              onClick={e => setViewCommentsContainer(!viewCommentsContainer)}
            >
              <FontAwesomeIcon icon={faComment} style={styles.commIcon} />
              <span>{props.data.length}</span>
            </div>
          )}
       
        </div>
        

        {viewCommentsContainer && (
          <div id='commentsContainer'>
            <div id='inputCommentContainer'>
              <div
                id='inputRow'
                style={styles.inputRow}
                onClick={e => e.stopPropagation()}
              >
                <ProfileImage
                  showBorder={false}
                  withFullSize={false}
                  height={20}
                  width={20}
                  imageUrl={props.viewingUserName.profilePictureUrl}
                />
                <TextInput
                  type='text'
                  placeholder='Add a comment...'
                  value={commentInput}
                  onChange={e => setCommentInput(e.target.value)}
                  // style={styles.commentInput}
                />
              </div>
              {commentInput !== '' && (
                <button
                  onClick={e => {
                    e.stopPropagation();
                    props.saveAction(commentInput, null);
                    setCommentInput('');
                  }}
                  style={styles.postBtn}
                >
                  Post
                </button>
              )}
            </div>

            <div id='allCommentsContainer' style={{
              overflowY: 'scroll',
              maxHeight: '50vh'
            }}>
              {props.data.map(item => {
                if (item.parentId) {
                  childrenComments.push(item);
                  return;
                }
                return (
                  <Comment
                    key={item.id}
                    comment={item}
                    likeAction={props.likeAction}
                    likeExtractor={props.likeExtractor}
                    viewingUserName={props.viewingUserName}
                    saveAction={props.saveAction}
                    deleteAction={props.deleteAction}
                    childrenComments={childrenComments}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>

      <FeedEventLikesUsersListModal
        users={usersWhoLikedPost}
        onClose={() => {
          document.body.style.position = '';
          window.scrollTo(0, scrollPosition);
          setUsersListOpen(false);
        }}
        isOpen={usersListOpen}
      />
    </Fragment>
  );
}

const styles = {
  myIconOption: {
    width: '40px'
  },
  miniIconOptions: {
    width: '34px'
  },
  rowOptionsContainer: {
    display: 'flex',
    alignItems: 'center',
    color: Theme.TEXT_COLOR,
    marginTop: '2%',
    fontSize: '18px',
    justifyContent: 'space-between',
  },
  optionDiv: {
    marginRight: '5%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1%',
    position: 'relative'
  },
  icon: {
    marginRight: '10%',
    color: Theme.TEXT_COLOR
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2%'
  },
  inputRowChild: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2%',
    width: '70%',
    marginLeft: '12%',
    marginBottom: '2%'
  },
  commentInput: { width: '90%', padding: '1%', fontFamily: 'inherit' },
  postBtn: {
    border: 'none',
    borderRadius: '15px',
    color: 'white',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    padding: '2% 5%',
    display: 'block',
    marginTop: '2%',
    marginLeft: '7%'
  },
  postBtnChild: {
    border: 'none',
    borderRadius: '15px',
    color: 'white',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    padding: '2% 5%',
    display: 'block',
    marginTop: '2%',
    marginLeft: '13%'
  },
  userCommentCotainer: {
    display: 'flex',
    margin: '2% 0% 2% 0%'
  },
  userComment: {
    flex: 0.1,
    display: 'flex',
    alignItems: 'center',
    marginRight: '2%'
  },
  commentContent: {
    flex: '0.8',
    display: 'flex',
    flexDirection: 'column'
  },
  commentDate: {
    fontSize: '10px',
    color: 'gray'
  },
  commentOptions: {
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  userName: {
    fontSize: '12px',
    fontWeight: 'bold'
  },
  contentContainer: {
    backgroundColor: 'aliceblue',
    borderRadius: '12px',
    padding: '1% 5%',
    paddingLeft: '5%',
    paddingTop: '1%',
    paddingBottom: '1%',
    paddingRight: '5%',
    display: 'flex',
    flexDirection: 'column'
  },
  otherActionIcon: {
    backgroundColor: 'transparent',
    color: '#4d4b62',
    borderRadius: '50%',
    fontSize: '10px',
    // marginRight: '1%',
    // width: '10px',
    // height: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  likesIcon: {
    backgroundColor: 'transparent',
    color: '#4d4b62',
    padding: '10%',
    borderRadius: '50%',
    fontSize: '15px',
    // marginRight: '1%',
    // width: '10px',
    // height: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  commIcon: {
    backgroundColor: 'transparent',
    color: '#4d4b62',
    padding: '10%',
    borderRadius: '50%',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  likeOptions: {
    position: 'absolute',
    top: window.innerWidth / -9,
    left: window.innerWidth / -90,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRadius: '15px',
    width: window.innerWidth / 2,
    padding: '2%',
    border: '1px solid',
    fontSize: '25px',
    WebkitBoxShadow: '2px -1px 8px -1px rgba(0,0,0,0.75)',
    MozBoxShadow: '2px -1px 8px -1px rgba(0,0,0,0.75)',
    boxShadow: '2px -1px 8px -1px rgba(0,0,0,0.75)'
  }
};

export default Comments;

const TextInput = styled.input`
  width: 90%;
  padding: 1%;
  font-family: inherit;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  margin-left: 2px;

  &:focus {
    outline: none;
    border: none;
  }
`;
