import gql from 'graphql-tag';

export const setVideoViewMutation = gql`
  mutation videoView(
    $userId: ID, 
    $videoId: String
    $pauseMinute: String
    $isCompleted: Boolean
    $videoName: String
    $surveyAnswer: String
  ) {
    videoView(
      userId: $userId,
      videoView: {
        videoId: $videoId
        pauseMinute: $pauseMinute
        isCompleted: $isCompleted
        videoName: $videoName
        surveyAnswer: $surveyAnswer
      }
    )
  }
`;
