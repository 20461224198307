import gql from 'graphql-tag';

export const meetingUpdatedSubscription = gql`
    subscription {
        meetingUpdated {
            id
            meetingName
            meetingTime
            isStarted
            isFinished
            meetingNumber
            hostId
            hostEmail
            hostName
            meetingCode
            participantsList {
              userId
              userEmail
              userPhoneNumber
              registeredAt
           }
        }
    }
`