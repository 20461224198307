import React, { Component, Fragment } from 'react';
import { apolloClient } from '../../apollo-subs';
import { Theme } from '../../common/providers/colors';
import { updateMeetingFinishedMutation } from '../graphql/updte-meeting-finished.mutation';


export default class MeetingFinished extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }

    componentWillMount = () => {
        this.finishMeeting()
    }

    finishMeeting = () => {
        let meetingId = localStorage.getItem("meetingId")
        console.log('meetingId =>', meetingId)
        apolloClient.mutate({
            mutation: updateMeetingFinishedMutation,
            variables: {
                meetingId
            }
        })
        .then(res => {
            localStorage.removeItem("meetingId")
            setTimeout(() => {
                this.props.history.push('/')
            }, 3000)
        })
    }

    render() {
        return (
            <div>
                <div style={styles.mainContainer}>
                    <div>
                        Thank you for participanting live streaming 
                    </div>
                </div>
            </div>
        )
    }
    
}

const styles = {
    mainContainer: {
      width: window.innerWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: window.innerHeight,
      backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.95), rgb(237,204,181,0.95))',
      color: Theme.TEXT_COLOR,
      justifyContent: 'center',
      fontSize: '24px',
      textAlign: 'center'
    },
    optionsRow: {
        display: 'flex',
        padding: '5%',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: Theme.TEXT_COLOR,
        fontWeight: 'bold',
        width: '71%',
        marginLeft: '-25%'
    },
}
