import gql from 'graphql-tag';
import { newsFeedNewsFragment } from './news-feed-news-item.fragment';

export const createUpdateNewsMutation = gql`
  mutation createUpdateNews($userId: ID, $newsId: String, $news: NewsInput!) {
    createUpdateNews(userId: $userId, newsId: $newsId, news: $news) {
      ...NewsFields
    }
  }

  ${newsFeedNewsFragment}
`;
