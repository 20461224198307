import { getConnection } from './core/network/connection';

let client;
let persistor;

export const initClient = config => {
  if (!client) {
    const port = config.server.port > 0 ? `:${config.server.port}` : '';
    const conn = getConnection(`${config.server.protocol}://${config.server.host}${port}${config.server.path}`);
    client = conn.client;
    persistor = conn.persistor;
  }

  return client;
};

export const getClient = () => client;
export const getPersistor = () => persistor;
