import { graphql } from 'react-apollo-temp';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ClassmateGrid from './classmate-grid.rn';
import { ClassmateForCourseQuery } from '../graphql/classmate-for-course.query';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';
import { toggleUserProfileModal } from '../../profile/profile.actions';
import { toggleComponent } from '../../router/router.actions';

const withGqlData = graphql(ClassmateForCourseQuery, {
  withRef: true,
  skip: ({ activeCourse }) => {
    return !activeCourse;
  },
  options: ({ userId, activeCourse }) => {
    return {
      variables: { userId, course: activeCourse.identifier || '' },
      fetchPolicy: 'cache-and-network'
    };
  }
});

const withError = withErrorHandler({
  apollo: ['data']
});

const mapDispatch = dispatch => ({
  onItemPress: user => {
    return dispatch(toggleUserProfileModal(!!user, user.id));
  },
  toggleComponent: component => dispatch(toggleComponent(component))
});
const mapState = ({ profile }) => ({
  isOpen: profile.userProfileModal.isOpen
});
const withState = connect(mapState, mapDispatch);

export const Classmates = compose(
  withState,
  withGqlData,
  withError
)(ClassmateGrid);
