import gql from 'graphql-tag';

export const friendAddedSubscription = gql`
    subscription  {
        friendAdded {
            addedBy
            createdAt
            updatedAt
            userAdded
            status
        }
    }
`