import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DURING_SNAPCHAT_ENROLLMENT } from '../snapchat.actions';
import { LangData } from '../../assets/locales/localization';
import styled from 'styled-components';
import { Theme } from '../../common/providers/colors';

const width = window.innerWidth;

export class EnrollmentFormView extends Component {
  static propTypes = {
    doEnroll: PropTypes.func.isRequired,
    user: PropTypes.any,
    close: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      enrollmentUsername: ''
    };
  }

  startEnroll() {
    this.props.doEnroll(this.props.user.id, this.state.enrollmentUsername);
  }

  renderDoneMessage() {
    return (
      <div>
        <sapn style={styles.description}>
          {LangData.enrollmentFormView.great}
        </sapn>
        <sapn style={styles.description}>
          {LangData.enrollmentFormView.details}
        </sapn>
        <sapn style={styles.description}>
          {LangData.enrollmentFormView.verification}
        </sapn>
        <div style={styles.enrollButton} onClick={() => this.props.close()}>
          <sapn style={styles.buttonText}>
            {LangData.enrollmentFormView.close}
          </sapn>
        </div>
      </div>
    );
  }

  getUserSnapchat() {
    if (this.props.user.snapchat && this.props.user.snapchat !== '') {
      return this.props.user.snapchat;
    }

    return null;
  }

  render() {
    const currentUserSnapchat = this.getUserSnapchat();

    if (currentUserSnapchat === DURING_SNAPCHAT_ENROLLMENT) {
      return this.renderDoneMessage();
    }

    const buttonDisabled = this.state.enrollmentUsername === '';

    return (
      <div style={{ alignItems: 'center', justifyContent: 'center' }}>
        <span style={styles.description}>
          {LangData.enrollmentFormView.enter}
        </span>
        <div style={styles.inputContainer}>
          <TextInput
            type='text'
            autoCapitalize='none'
            autoCorrect={false}
            onChange={e => {
              this.setState({ enrollmentUsername: e.target.value });
            }}
            placeholder={LangData.enrollmentFormView.username}
            underlineColorAndroid='transparent'
            style={{ width: width * 0.5 }}
            // style={styles.snapchatUsernameField}
            autoFocus={false}
          />
        </div>
        <div
          disabled={buttonDisabled}
          style={styles.enrollButton}
          onClick={() => this.startEnroll()}
        >
          <span style={styles.buttonText}>
            {LangData.enrollmentFormView.continue}
          </span>
        </div>
      </div>
    );
  }
}

const styles = {
  buttonText: {
    color: 'white',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontWeight: 'bold'
  },
  enrollButton: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    padding: 5,
    flexDirection: 'row',
    paddingHorizontal: 20,
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    marginTop: 20
  },
  description: {
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 10
  },
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10
  },
  snapchatUsernameField: {
    textAlign: 'center',
    textAlignVertical: 'center',
    color: '#aaaaaa',
    fontSize: 15,
    paddingTop: 0,
    paddingBottom: 0,
    width: width * 0.5,
    height: 50,
    borderWidth: 1,
    borderColor: '#aaaaaa',
    borderRadius: 15,
    marginBottom: 10
  }
};
const TextInput = styled.input`
  text-align: center;
  text-align-vertical: center;
  color: #aaaaaa;
  font-size: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 50px;
  border-width: 1px;
  border-color: #aaaaaa;
  border-radius: 15px;
  margin-bottom: 10px;

  &:focus {
    outline: none;
    border: none;
  }
`;
