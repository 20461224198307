const config = {
  server: {
    protocol: 'https', //http // https
    host: 'Be.YOCAS.life',//'Be.YOCAS.life', // localhost
    port: 4000,
    path: '/graphql'
  },
  cleanStorage: false
};

export default config;
