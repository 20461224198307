import { compose, graphql } from 'react-apollo-temp';
import { allVideosQuery } from '../graphql/all-videos-query';
import { VideosView } from './videos.component';
import { connect } from 'react-redux';
import { updateVideosFilter, editEntity } from '../videos.actions';
import { setVideoViewMutation } from '../graphql/set-video-view.mutation';
import { toggleComponent } from '../../router/router.actions';

const mapStateToProps = ({ videos, navigation, auth }) => ({
  skip: videos.skip,
  limit: videos.limit,
  search: videos.search,
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  currentUser: auth.currentUser
});

const mapDispatch = dispatch => ({
  updateFilter: filterChanges => dispatch(updateVideosFilter(filterChanges)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const withState = connect(mapStateToProps, mapDispatch);

const withSetVideoView = graphql(setVideoViewMutation, {
  props: ({ ownProps, mutate }) => ({
    videoView: (userId, videoId, pauseMinute, isCompleted, videoName, surveyAnswer) =>
      mutate({
        variables: {
          userId, 
          videoId,
          pauseMinute,
          isCompleted,
          videoName,
          surveyAnswer
        }
      })
  })
});

const withData = graphql(allVideosQuery, {
  name: 'videos',
  options: props => {
    const yocasType = props.history.location.state ? props.history.location.state.yocasType : null
    const duration = props.history.location.state ? props.history.location.state.duration : null
    let extraVars = {
      perferedIds: [],
      yocasType: yocasType ? yocasType : '',
      duration: duration ? duration : null
    };

    if (
      props.search === '' &&
      props.checkedItems &&
      props.checkedItems.length > 0
    ) {
      extraVars = {
        perferedIds: props.checkedItems
          .map(group =>
            group.startsWith('video_') ? group.replace('video_', '') : null
          )
          .filter(item => item),
        yocasType: yocasType ? yocasType : '',
        duration: duration ? duration : null
      };
    }

    return {
      fetchPolicy: 'network-only',
      variables: {
        skip: props.skip,
        limit: props.limit,
        search: props.search,
        ...extraVars
      }
    };
  }
});

export const Videos = compose(
  withState,
  withData,
  withSetVideoView
)(VideosView);
