import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const leaveGroupChatMutation = gql`
    mutation leaveGroupChat($userId: ID, $chatId: String!){
        leaveGroupChat(userId: $userId, chatId: $chatId) {
            id
            title
            unreadMessagesCount
            enabled
            createdAt
            lastMessage {
                createdAt
                content
                messageType
            }
            lastUpdate
            users {
                ...ClassmateItem
            }
        }
    }

    ${classmateItemFragment}
`;
