import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const sendMessageMutation = gql`
    mutation sendMessage ($userId: ID, $content: String, $messageType: String!, $imageData: String, $chatId: String!){
        sendMessage(userId: $userId, chatId: $chatId, content: $content, messageType: $messageType, imageData: $imageData) {
            id
            createdAt
            createdAtDay
            serverMessage
            content
            imageThumb
            author {
                ...ClassmateItem
            }
            messageType
        }
    }

    ${classmateItemFragment}
`;
