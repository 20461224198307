import { combineReducers } from 'redux';

import { AuthorizationMiddleware } from '../core/network/authorization-middleware';
import { EAuthActions } from './auth.actions';
import { bugsnagClient } from '../bugsnag';

const accessTokenHandler = (state = null, action) => {
  if (action.type === 'js-accounts/SET_TOKENS') {
    const { tokens } = action.payload;
    AuthorizationMiddleware.setToken(tokens.accessToken);

    return tokens.accessToken;
  }

  return state;
};

const currentUser = (state = null, action) => {
  if (action.type === 'js-accounts/SET_USER') {
    const { user } = action.payload;
    bugsnagClient.setUser(
      user.id || user._id || 'Unauthenticated',
      user.username
    );

    return user;
  } else if (action.type === EAuthActions.USER_LOADED) {
    const { user } = action;
    bugsnagClient.setUser(
      user.id || user._id || 'Unauthenticated',
      user.username
    );

    return action.user;
  } else if (action.type === 'js-accounts/CLEAR_USER') {
    bugsnagClient.clearUser();

    try {
      // We need to clear those cookies in order to prevent sharing the cookies
      // session of Instagram login
      // Cookie.clear('https://www.instagram.com/');
      // Cookie.clear('https://instagram.com/');
      // Cookie.clear('http://instagram.com/');
    } catch (e) {
      // nothing to do
    }

    return null;
  }

  return state;
};

export const auth = combineReducers({
  accessToken: accessTokenHandler,
  currentUser
});
