import gql from 'graphql-tag';

export const setAnswerOpenQeustionMutation = gql`
  mutation answerOpenEndedQuestion($userId: ID, $questionText: String, $answerText: String) {
    answerOpenEndedQuestion(
      userId: $userId,
      questionText: $questionText,
      answerText: $answerText
    )
  }
`;
