import { EditLinkView } from './edit-link-menu.rn';
import { compose, graphql } from 'react-apollo-temp';
import { resetSocialLinkMutation } from '../profile/graphql/reset-social-link.mutation';
import { connect } from 'react-redux';
import { setUser } from '../auth/auth.actions';

const withMutation = graphql(resetSocialLinkMutation, {
  props: ({ ownProps, mutate }) => ({
    resetLink: (userId, type) =>
      mutate({
        variables: { userId, type }
      })
  })
});

const mapDispatch = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

const withState = connect(null, mapDispatch);

export const EditLink = compose(withState, withMutation)(EditLinkView);
