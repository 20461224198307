import { getClient } from '../../apollo';
import { cloudinaryConfigQuery } from '../graphql/cloudinary-config.query';
import { apolloClient } from '../../apollo-subs';

let uploadConfig = null;

export async function getUploadConfig() {
  if (!uploadConfig) {
    const result = await apolloClient.query({
      query: cloudinaryConfigQuery,
      fetchPolicy: 'network-only'
    });
    uploadConfig = result.data.cloudinaryConfig;
  }
  return uploadConfig;
}
