import { graphql } from 'react-apollo-temp';
import { feedItemByIdQuery } from '../../news-feed/graphql/feed-item-by-id.query';

export const withEditEntity = graphql(feedItemByIdQuery, {
  skip: ownProps => !ownProps.editEntityId || ownProps.editEntityId === '',
  options: ownProps => ({
    options: {
      fetchPolicy: 'network-only'
    },
    variables: {
      id: ownProps.editEntityId
    }
  }),
  props: ({ ownProps, data }) => {
    console.log('data inside container', data);
    return {
      loading: data.loading,
      editEntity: data.loading || data.error ? null : data.feedItem
    };
  }
});
