import gql from 'graphql-tag';

export const photosQuery = gql`
  query photos($currentUserId: ID, $userId: String!) {
    photos(currentUserId:$currentUserId, filter: { userId: $userId }) {
      id
      imageThumb
      approved
      url
      label
    }
  }
`;
