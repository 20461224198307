import { connect } from 'react-redux';
import { MyNotificationsView } from './my-notifications.rn';
import { toggleComponent } from '../../router/router.actions';
import { compose } from 'redux';

const mapStateToProps = ({ navigation, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component))
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const MyNotifications = compose(withState)(MyNotificationsView);
