import React, { useState, useEffect } from 'react';
import { IntroScreen } from '../login/components/login-container';
import { Main } from '../main/main-page-container';
import { Settings } from '../settings/settings.container';
import ScrollMemory from 'react-router-scroll-memory';
import { ProfileModal } from '../profile/components/profile-modal.container';
import { UserProfileModal } from '../profile/components/profile/user-profile-modal.container';
import ImageGalleryViewerModal from '../common/components/image-gallery-viewer-modal';
import FullScreenImageModal from '../common/components/full-screen-image-modal.rn';
import { NewChatModal } from '../chat/components/new-chat/new-chat-modal.rn';
import { ActiveChatScreenModal } from '../chat/components/chat-screen/active-chat-screen-modal.container';
import Alert from '../alert/components/alert';
import { MyPosts } from '../posts/components/my-posts.container';
import { MyNotifications } from '../my-notifications/components/my-notifications.container';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { InstallApp } from '../main/install-app-container';
import { connect } from 'react-redux';
import { toggleComponent } from './router.actions';
import { ForgotPassword } from '../login/components/forgot-password.container';
import { ChangePassword } from '../login/components/change-password.container';
import { Manuals } from '../manuals/components/manuals.container';
import { FriendsList } from '../friends/components/friends-list.container';
import { Courses } from '../courses/components/courses.container';
import { Videos } from '../videos/components/videos.container';
import { Meetings } from '../meetings/components/meetings.containers';
import { VideoContainer } from '../meetings/components/video.container';
import MeetingFinished from '../meetings/components/meeting-finished.component';
import Intro from '../intro/intro';
import WelcomePage from '../login/components/welcome-page-screen';
import { GeneralComponent } from '../main/general-page-container';
import { ChatList } from '../chat/components/chats-list/chat-list.container';
import { SinglePost } from '../posts/components/singlePost.container';
import { WrapVideos } from '../videos/components/wrap-videos.container';

const Navigation = props => {
  // const [showInstallMessage, setShowInstallMesssage] = useState(
  //   isIOS && !window.matchMedia('(display-mode: standalone)').matches
  // );

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (showInstallMessage) setShowInstallMesssage(false);
  //   }, 9000);
  // }, []);


  // Handle back button clicked
  window.addEventListener('popstate', function(event) {
    console.log('back clicked');
    // window.history.pushState({}, '');
    // props.toggleComponent(props.prevComponent);
  });

  return (
    <Router>
      <ScrollMemory />
      <Alert />

      <Switch>
      <Route basename="/index.html" exact path='/welcome' component={WelcomePage} />
        <Route exact path='/login' component={ForgotPassword} />
        <Route exact path='/' component={Main} />
        <Route exact path='feed' component={GeneralComponent} />
        <Route exact path='/profile' component={ProfileModal} />
        <Route exact path='/userProfile' component={UserProfileModal} />
        <Route exact path='/notifications' component={MyNotifications} />
        {/* <Route exact path='/changePassword' component={ChangePassword} />
        <Route exact path='/forgotPassword' component={ForgotPassword} /> */}
        <Route exact path='/settings' component={Settings} />
        <Route exact path='/createNewChat' component={NewChatModal} />
        <Route exact path='/chatScreen' component={ActiveChatScreenModal} />
        <Route exact path='/installApp' component={InstallApp} />
        <Route exact path='/myPosts' component={MyPosts} />
        <Route exact path='/manuals' component={Manuals} />
        <Route exact path='/friends' component={FriendsList} />
        <Route exact path='/show-post/:postId/:userId' component={SinglePost} />
        <Route exact path="/meetings" component={Meetings} />
        <Route exact path="/video/:name/:username" component={VideoContainer} />
        <Route exact path="/meeting-finished" component={MeetingFinished}/>
        <Route exact path='/Videos' component={Videos} />
        <Route path={'/videos/:userId'} render={props => <WrapVideos {...props} />} />
        <Route exact path='/courses' component={Courses} />
        <Route exact path='/chats' component={ChatList}/>
        <Route
          exact
          path='/imageGalleryViewerModal'
          component={ImageGalleryViewerModal}
        />
        <Route
          exact
          path='/fullScreenImageModal'
          component={FullScreenImageModal}
        />
        <Route exact path='/Intro' component={Intro} />
      </Switch>
    </Router>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component))
});

const mapStateToProps = ({ navigation }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
