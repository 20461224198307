import { connect } from 'react-redux';

import ProfileScreenView from './profile-screen.rn';
import { toggleProfileModal } from '../../profile.actions';

const withState = connect(
  ({ auth }) => ({ user: auth.currentUser }),
  dispatch => ({
    toggleProfileModal: isOpen => dispatch(toggleProfileModal(isOpen))
  })
);

export const ProfileScreen = withState(ProfileScreenView);
