import { connect } from 'react-redux';
import { combineReducers } from 'redux';

const initialState = {
  prevComponent: null,
  component: 'Login'
};

export const navigationComponents = (state = initialState, action) => {
  if (action.type === 'change_component') {
    return {
      prevComponent: state.component,
      component: action.payload
    };
  }

  return state;
};

export const lastScrollPosition = (state = { lastScroll: 0 }, action) => {
  if (action.type === 'set_last_position') {
    return {
      lastScroll: action.payload
    };
  } else if (action.type === 'clear_last_position') {
    return {
      lastScroll: 0
    };
  }

  return state;
};

export const showInstallAppPage = (state = { show: true }, action) => {
  if (action.type === 'set_show_install_page') {
    return {
      show: action.payload
    };
  }

  return state;
};

const imageModal = (state = { isOpen: false, imgSrc: null }, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL':
      return {
        isOpen: false,
        imgSrc: null
      };
    case 'OPEN_MODAL':
      return {
        isOpen: true,
        imgSrc: action.payload
      };
    default:
      return state;
  }
};

const galleryModal = (
  state = { firstIndex: null, images: null, isOpen: false },
  action
) => {
  switch (action.type) {
    case 'CLOSE_GALLERY_MODAL':
      return {
        isOpen: false,
        firstIndex: null,
        images: null
      };
    case 'OPEN_GALLERY_MODAL':
      return {
        isOpen: true,
        images: action.payload
      };
    default:
      return state;
  }
};

export const navigation = combineReducers({
  lastScrollPosition,
  imageModal,
  navigationComponents,
  galleryModal,
  showInstallAppPage
});
