import gql from 'graphql-tag';

export const scheduleQuery = gql`
  query ScheduleForDate($userId: ID, $months: [String]) {
    eventsSchedule(userId: $userId, months: $months) {
      id
      title
      allDay
      description
      posterImageUrl
      thumbUrl
      creationDate
      displayCreationDate
      cancelled
      startTime
      endTime
      exportStartTime
      exportEndTime
      locationName
      previousEventDate
      previousEventImages
      previousEventImagesThumbs
      userReminder
      categories {
        id
        name
        icon
      }
      mainCategory {
        id
        name
        icon
      }
      author {
        profilePictureUrl
        name
        admin
      }
      whoToContact
      userRSVP
      isNew
      likes
    }
  }
`;
