import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DURING_SNAPCHAT_ENROLLMENT } from '../snapchat.actions';
import { VerifyCode } from './verify-code-view.container';
import { EnrollmentForm } from './enrollment-form-container.rn';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faSnapchatGhost } from '@fortawesome/free-brands-svg-icons';
import { Theme } from '../../common/providers/colors';

const width = window.innerWidth;

export class SnapchatEnrollmentModal extends Component {
  static propTypes = {
    startAuth: PropTypes.func.isRequired,
    authDone: PropTypes.func.isRequired,
    stopAuth: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    user: PropTypes.any,
    onInit: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      open: false,
      verifyPasswordStep: false
    };
    this._resolve = null;
  }

  componentWillMount() {
    if (this.props.onInit) {
      this.props.onInit(this);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.open) {
      return;
    }

    const currentUserSnapchat = nextProps.user ? nextProps.user.snapchat : null;

    if (
      currentUserSnapchat !== null &&
      currentUserSnapchat !== DURING_SNAPCHAT_ENROLLMENT
    ) {
      this.close(true);
    }
  }

  open() {
    this.props.startAuth();

    this.setState({
      open: true,
      verifyPasswordStep:
        this.props.user.snapchat === DURING_SNAPCHAT_ENROLLMENT
    });

    return new Promise(resolve => {
      this._resolve = resolve;
    });
  }

  close(reloadUser = false) {
    this.setState(
      {
        open: false
      },
      () => {
        if (this._resolve) {
          this._resolve();
        }

        if (reloadUser) {
          this.props.authDone();
        } else {
          this.props.stopAuth();
        }
      }
    );
  }

  renderSpinner() {
    if (this.props.isLoading) {
      return <LoadingSpinner />;
    }

    return null;
  }

  getUserSnapchat() {
    if (this.props.user.snapchat && this.props.user.snapchat !== '') {
      return this.props.user.snapchat;
    }

    return null;
  }

  renderChildView() {
    if (this.props.isLoading) {
      return this.renderSpinner();
    }

    if (this.state.verifyPasswordStep) {
      return (
        <VerifyCode
          resetForm={() => this.setState({ verifyPasswordStep: false })}
        />
      );
    }

    return <EnrollmentForm close={reload => this.close(reload)} />;
  }

  render() {
    return (
      <Fragment>
        {this.state.open ? (
          <div
            style={styles.container}
            // onRequestClose={() => this.close()}
          >
            <div style={styles.container}>
              <div onClick={() => this.close()} style={styles.closeIcon}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <FontAwesomeIcon icon={faSnapchatGhost} />
              {this.renderChildView()}
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: window.innerHeight,
    position: 'fixed',
    top: '0',
    backgroundColor: Theme.BACKGROUND_COLOR,
    zIndex: '9'
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontWeight: 'bold'
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    padding: 5,
    flexDirection: 'row',
    paddingHorizontal: 20,
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    marginTop: 20
  },
  loading: {
    margin: 10
  },
  digitsContainer: {
    flexDirection: 'row'
  },
  description: {
    textAlign: 'center',
    fontWeight: '400',
    margin: 10
  },
  note: {
    textAlign: 'center',
    fontWeight: '400',
    margin: 10,
    fontSize: 11,
    color: Theme.TEXT_COLOR,
    fontStyle: 'italic'
  },
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10
  },
  container: {
    marginHorizontal: 20,
    backgroundColor: Theme.BACKGROUND_COLOR,
    borderRadius: 10,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  snapchatUsernameField: {
    textAlign: 'center',
    textAlignVertical: 'center',
    color: '#aaaaaa',
    fontSize: 15,
    paddingTop: 0,
    paddingBottom: 0,
    width: width * 0.5,
    height: 50,
    borderWidth: 1,
    borderColor: '#aaaaaa',
    borderBottomColor: 'transparent',
    borderRadius: 15,
    marginBottom: 10
  },
  digitInput: {
    marginHorizontal: 10,
    textAlign: 'center',
    textAlignVertical: 'center',
    color: '#aaaaaa',
    fontSize: 30,
    paddingTop: 0,
    paddingBottom: 0,
    width: 40,
    height: 50,
    borderWidth: 1,
    borderColor: '#aaaaaa',
    borderBottomColor: 'transparent',
    borderRadius: 15,
    marginBottom: 10
  },
  closeIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: 3,
    padding: 7,
    color: 'white'
  }
};
