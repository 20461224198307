import gql from 'graphql-tag';
import { classmateItemFragment } from './classmate-item.fragment';

export const ClassmateForCourseQuery = gql`
    query ClassmateForCourse($userId: ID, $course: String!){
        classmates(userId: $userId, courseIdentifier: $course){
            ...ClassmateItem
        }
    }
    ${classmateItemFragment}
`;
