import { PollsView } from './polls.rn';
import { graphql } from 'react-apollo-temp';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createUpdatePollMutation } from '../../graphql/create-update-poll.mutation';
import { withEditEntity } from '../with-edit-feed-item.container';
import { toggleMyPosts } from '../../posts.actions';
import { navigateSecondStage } from '../../posts.actions';
import { updateNewsFeedItemTargetsQuery } from '../../graphql/update-news-feed-item-targets.mutation';
import { publishMutation } from '../../graphql/publish-feed-item.mutation';

const mapDispatch = dispatch => ({
  navigateSecondStage: poll => dispatch(navigateSecondStage(poll)),
  toggleModal: (isOpen, userId) => dispatch(toggleMyPosts(isOpen)),

  close: () => dispatch(navigateSecondStage(null))
});

// const withState = connect(
//   ({ profile }) => profile.userProfileModal,
//   dispatch => ({
//
//   }),
// );
const withState = connect(null, mapDispatch);

const withTargetMutation = graphql(updateNewsFeedItemTargetsQuery, {
  props: ({ ownProps, mutate }) => ({
    updateTargets: (itemId, targets) =>
      mutate({ variables: { itemId, targets } })
  })
});

const withPublicationMutation = graphql(publishMutation, {
  props: ({ ownProps, mutate }) => ({
    publish: (itemId, platforms) => mutate({ variables: { itemId, platforms } })
  })
});

const withMutation = graphql(createUpdatePollMutation, {
  props: ({ ownProps, mutate }) => ({
    createUpdatePoll: (pollId, poll) =>
      mutate({
        variables: {
          pollId,
          poll
        }
      })
  })
});

export const Polls = compose(
  withState,
  withTargetMutation,
  withMutation,
  withEditEntity,
  withPublicationMutation
)(PollsView);
