import React, { useEffect, useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCog,
  faUserFriends,
  faUsers,
  faBullhorn,
  faVideo,
  faTimes,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarAlt,
  faFile,
  faComments,
  faBell
} from '@fortawesome/free-regular-svg-icons';
import { Theme } from '../common/providers/colors';
import { logout } from '../account/account.util';
import PromptMessage from '../settings/prompt-message';
import Fade from 'react-reveal/Fade';
import menuBackground from '../assets/images/menuBackground.png'
import yogaSessionsLogo from '../assets/images/yogaSessionsLogo.png';
import yogaPosesLogo from '../assets/images/yogaPosesLogo.png';
import yogaModificationsLogo from '../assets/images/yogaModificationsLogo.png';
import manualsLogo from '../assets/images/manualsLogo.png';
import friendsLogo from '../assets/images/friendsLogo.png';
import calendarLogo from '../assets/images/calendar.png';
import videoMeeting from '../assets/images/videoMeeting.png';
import notificationsLogo from '../assets/images/notificationsLogo.png';
import chatsLogo from '../assets/images/chatsLogo.png';
import groupsLogo from '../assets/images/groupsLogo.png';
import settingsLogo from '../assets/images/settingsLogo.png';
import logoutLogo from '../assets/images/logoutLogo.png';
import yogaScienceLogo from '../assets/images/yogaScienceLogo.png';
import { apolloClient } from '../apollo-subs';
import { createFeatureLogMutation } from '../account/createFeatureLog';

const width = window.innerWidth

const MenuView = props => {
  const [promptLogout, setPromptLogOut] = useState(false);


  useEffect(() => {
    console.log('show zendesk')
    let button = document.getElementById("show-zendesk")
    console.log('button', button)
    if(button) {
      console.log('click show')
      button.click()
    }
    return () => {
      console.log('hide zendesk')
      let button = document.getElementById("hide-zendesk")
      console.log('button', button)
      if(button) {
        console.log('click hide')
        button.click()
      }
    }
  }, [])

  const createFeatureLog = async (feature) => {
    apolloClient.mutate({
      mutation: createFeatureLogMutation,
      variables: {
        userId: localStorage.getItem("userId"),
        feature,
        clickedAt: new Date()
      }
    })
  }


  return (
    <Fragment>
      <Fade left>
        <div style={width<500?styles.mainContainer:styles.mainContainerDesktop}>
          <div style={styles.centerContainer}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => props.closeMenu()}
              style={styles.closeContainer}
            />
            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Notifications")
                props.closeMenu()
                props.toggleComponent('Notifications');
                props.setIndex(4);
              }}
            >
              <img src={notificationsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Notifications</span>
            </div>
            <span style={styles.seperator}/>

            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Live Streaming")
                props.toggleComponent('Meetings');
                props.history.push('meetings');
              }}
            >
              <img src={videoMeeting} style={styles.icon} alt=""/>
              <span style={styles.title}>Live Streaming</span>
            </div>
            <span style={styles.seperator}/>

            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Yoga Sessions")
                props.toggleComponent('Videos');
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'session'
                  }
                });
              }}
            >
              <img src={yogaSessionsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Yoga Sessions</span>
            </div>
            <span style={styles.seperator}/>

            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Yoga Poses")
                props.toggleComponent('Videos');
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'Pose'
                  }
                });
              }}
            >
              <img src={yogaPosesLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Yoga Poses</span>
            </div>
            <span style={styles.seperator}/>
            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Yoga Modifications")
                props.toggleComponent('Videos');
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'modification'
                  }
                });
              }}
            >
              <img src={yogaModificationsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Yoga Modifications</span>
            </div>
            <span style={styles.seperator}/>
            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Groups")
                props.toggleComponent('Courses');
                props.history.push('courses');
              }}
            >
              <img src={groupsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Groups</span>
            </div>
            <span style={styles.seperator}/>
            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Friends")
                props.history.push('/');
                props.toggleComponent('Friends');
                props.closeMenu()
              }}
            >
              <img src={friendsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Friends</span>
            </div>
            <span style={styles.seperator}/>
  
            {/* <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Videos');
                // props.setIndex(3);
                props.history.push('videos');
              }}
            >
              <FontAwesomeIcon icon={faVideo} style={styles.icon} />
              <span>Videos</span>
            </div> */}

            {/* <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('News_Feed');
                props.setIndex(0);
              }}
            >
              <FontAwesomeIcon icon={faBullhorn} style={styles.icon} />
              <span>News Feed</span>
            </div> */}

        
            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Chats")
                props.closeMenu()
                props.toggleComponent('Chat');
                props.setIndex(3);
              }}
            >
              <img src={chatsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Chats</span>
            </div>
            <span style={styles.seperator}/>
         

            {/* <div
              style={styles.linkContainer}
              onClick={() => {
                props.closeMenu()
                props.toggleComponent('Schedule');
                props.setIndex(4);
              }}
            >
              <img src={calendarLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>My Calendar</span>
            </div>
            <span style={styles.seperator}/> */}

            <div
              style={styles.linkContainer}
              onClick={() => {
                createFeatureLog("Yoga Science")
                props.toggleComponent('Manuals');
                props.history.push('manuals');
              }}
            >
              <img src={yogaScienceLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Yoga Science</span>
            </div>
            <span style={styles.seperator}/>
            {/* <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Schedule');
                props.setIndex(1);
              }}
            >
              <FontAwesomeIcon icon={faCalendarAlt} style={styles.icon} />
              <span>Schedule</span>
            </div> */}
          </div>

          <div style={styles.bottomContainer}>
            <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Settings');
                props.history.push('settings');
              }}
            >
              <img src={settingsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Settings</span>
            </div>
            <span style={styles.seperator}/>
            <div
              style={styles.linkContainer}
              onClick={() => {
                setPromptLogOut(true);
              }}
            >
              <img src={logoutLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Log Out</span>
            </div>
          </div>
          <div
              style={styles.linkContainer}
            ></div>
        </div>
      </Fade>
      {promptLogout && (
        <PromptMessage
          message='Are you sure you want to logout?'
          onSubmit={() => {
            setPromptLogOut(false);
            logout();
            props.history.push('/intro');
          }}
          onCancel={() => {
            setPromptLogOut(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default MenuView;

const styles = {
  mainContainer: {
    color: Theme.TEXT_COLOR,
    width: width<500?'60%':'30%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    minHeight: window.innerHeight,
    zIndex: 999,
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundImage: `url(${menuBackground})`,
    backgroundSize: '100% 100%',
    opacity: 0.95,
    padding: '2%',
    justifyContent: width<500?'space-between':'unset',
    fontWeight: 'bold',
    backgroundRepeat: 'no-repeat'
  },
  mainContainerDesktop:{
    color: Theme.TEXT_COLOR,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    minHeight: window.innerHeight,
    zIndex: 999,
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor:'white',
    backgroundSize: '100% 100%',
    opacity: 0.95,
    justifyContent: width<500?'space-between':'unset',
    fontWeight: 'bold',
    backgroundRepeat: 'no-repeat',
    borderRadius:'0 0.75rem 0.75rem 0',
    boxShadow:'rgba(0, 0, 0, 0.04) 15px 15px 15px, rgba(0, 0, 0, 0.44) 15px 15px 15px'
  },
  pageTitle: {
    width: '100%',
    padding: '1% 5%'
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '1% 5%'
  },
  userName: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '5%'
  },
  otherLinksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1% 5%',
    fontSize: '19px'
  },
  linksRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '1% 5%'
  },
  linkContainer: {
    width: width<500?'100%':'15vw',
    color: Theme.NAV_LINK_COLOR,
    display: 'flex',
    flexDirection:width<500?'row':'row',
    alignItems: 'center',
    padding: width<500?'1%':'3%',
    margin: width<500?'5%':'0',
    marginBottom: '5%',
    marginTop: '5%',
    fontSize: width<500?'larger':'15px',
    textAlign:'center'
  },
  icon: {
    margin: width<500?'unset':'0',
    marginRight: width<500?'5%':'1vw',
    width: width<500?'13%':'3vw'
  },
  closeContainer: {
    margin: width<500?'23% 2% 4% 5%':'0% 2% 10% 5%',
    // marginBottom: '20%',
    borderRadius: "50%",
    padding: "2px 2px",
    width: "20px",
    height: "20px",
    border: "2px solid #545267",
    color: Theme.NAV_LINK_COLOR
  },
  bottomContainer: {
    marginBottom: width<500?'10%':'0',
    width:width<500?'unset':'100%',
    // position:width<500?'initial':'absolute',
    // top:width<500?'0':'75%',
  },
  seperator:{
    height:'1px',
    width:'100%',
    backgroundColor:'#f3f3f3',
    display:width<500?'none':'block'
  },
  title: {
    fontWeight: 'normal',
    fontSize: width<500?'5vw': '1.3rem',
    paddingRight: width<500 ? 0 : '2vw',
  }
};
