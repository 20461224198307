import FriendsListTopBarView from './friends-list-top-bar.rn';
import { connect } from 'react-redux';
import { setSearchTerm, toggleRequests } from '../friends.actions';

const mapStateToProps = ({ friends }) => ({
  activeState: friends.activeState,
  searchTerm: friends.searchTerm,
  showHiddenStudents: friends.showHiddenStudents
});

const mapDispatchToProps = dispatch => ({
  toggleRequests: newState => dispatch(toggleRequests(newState)),
  setSearchTerm: search => dispatch(setSearchTerm(search))
});

const withState = connect(mapStateToProps, mapDispatchToProps);

export const FriendsListTopBar = withState(FriendsListTopBarView);
