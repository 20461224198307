import { compose, graphql } from 'react-apollo-temp';
import { allManualsQuery } from '../graphql/all-manuals-query';
import { ManualsView } from './manuals.component';
import { connect } from 'react-redux';
import { updateManualsFilter, editEntity } from '../manuals.actions';
import { toggleComponent } from '../../router/router.actions';

const mapStateToProps = ({ manuals, navigation, auth }) => ({
  skip: manuals.skip,
  limit: manuals.limit,
  search: manuals.search,
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  currentUser: auth.currentUser
});

const mapDispatch = dispatch => ({
  updateFilter: filterChanges => dispatch(updateManualsFilter(filterChanges)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const withState = connect(mapStateToProps, mapDispatch);

const withData = graphql(allManualsQuery, {
  name: 'manuals',
  options: props => {
    let extraVars = {
      perferedIds: []
    };

    if (
      props.search === '' &&
      props.checkedItems &&
      props.checkedItems.length > 0
    ) {
      extraVars = {
        perferedIds: props.checkedItems
          .map(group =>
            group.startsWith('manual_') ? group.replace('manual_', '') : null
          )
          .filter(item => item)
      };
    }

    return {
      fetchPolicy: 'network-only',
      variables: {
        skip: props.skip,
        limit: props.limit,
        search: props.search,
        ...extraVars
      }
    };
  }
});

export const Manuals = compose(withState, withData)(ManualsView);
