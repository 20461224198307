import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const chatAddedSubscription = gql`
    subscription  {
        chatAdded {
            id
            title
            unreadMessagesCount
            enabled
            createdAt
            lastMessage {
                createdAt
                content
                messageType
            }
            lastUpdate
            users {
                ...ClassmateItem
            }
        }
    }

    ${classmateItemFragment}
`