import { graphql, compose } from 'react-apollo-temp';
import { FeedEventItemView } from './feed-event-item.rn';
import { toggleReminderMutation } from '../../../graphql/toggle-reminder.mutation';
import { popupReminderModal } from '../../../news-feed.actions';
import { store } from '../../../../store';
import { createUpdateCommentMutation } from '../../../graphql/create-update-comment.mutation';
import { withEditEntity } from '../../../graphql/with-edit-feed-comment.container';
import { updatePostLikes } from '../../../graphql/update-event-likes';
import { connect } from 'react-redux';
import { deleteCommentMutation } from '../../../graphql/delete-comment.mutation';
import { updateCommentsFilter } from '../../../comments.actions';
import {
  openGalleryModal,
  toggleComponent
} from '../../../../router/router.actions';
import { setAlert } from '../../../../alert/alert.actions';

import gql from 'graphql-tag';

export const withRemindersMutation = graphql(toggleReminderMutation, {
  withRef: true,
  props: ({ mutate }) => ({
    popupReminderModal: reminderHasSet => {
      store.dispatch(popupReminderModal(reminderHasSet));
    },
    toggleReminder: (userId, event) =>
      mutate({
        variables: { userId, eventId: event.id },
        optimisticResponse: {
          __typename: 'Mutation',
          toggleReminder: {
            ...event,
            userReminder: !event.userReminder
          }
        }
      }).catch(() => null)
  })
});

const mapStateToProp = ({ auth, comments }) => ({
  currentUser: auth.currentUser,
  skip: comments.skip,
  limit: comments.limit
});

const mapDispatch = dispatch => ({
  updateFilter: filterChanges => dispatch(updateCommentsFilter(filterChanges)),
  openGalleryModal: images => dispatch(openGalleryModal(images)),
  toggleComponent: component => dispatch(toggleComponent(component)),
  setAlert: message => dispatch(setAlert(message, 'success'))
});

const withState = connect(mapStateToProp, mapDispatch);

const withMutation = graphql(createUpdateCommentMutation, {
  props: ({ ownProps, mutate }) => ({
    createUpdateComment: (userId, commentId, comment) =>
      mutate({
        variables: {
          userId, 
          commentId,
          comment
        }
      })
  }),
  // options: {
  //   refetchQueries: ['Feed']
  // }
});

const withUpdateLikesMutation = graphql(updatePostLikes, {
  props: ({ ownProps, mutate }) => ({
    updatePostLikes: (itemId, likes) => mutate({ variables: { itemId, likes } })
  }),
  options: {
    refetchQueries: ['Feed']
  }
});

export const withDeleteComment = graphql(deleteCommentMutation, {
  props: ({ mutate }) => ({
    deleteComment: commentId => mutate({ variables: { commentId } })
  }),
  options: {
    refetchQueries: ['Feed']
  }
});

export const FeedEventItem = compose(
  withState,
  withUpdateLikesMutation,
  withDeleteComment,
  withMutation,
  withRemindersMutation,
  withEditEntity
)(FeedEventItemView);
