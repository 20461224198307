import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CategoryImage from '../../categories-grid/category-image.rn';
import { PollOptionView } from './poll-option.rn';
import { ConfirmationModal } from '../../../../common/components/confirmation-modal.rn';
import { analytics } from '../../../../analytics/report';
import Comments from '../feed-event-item/comments.rn';
import * as commentActions from '../../../comments.actions';
import { LangData } from '../../../../assets/locales/localization';
import { Theme } from '../../../../common/providers/colors';
const width=window.innerWidth

export class FeedPollItemView extends Component {
  static propTypes = {
    poll: PropTypes.any.isRequired,
    answerPoll: PropTypes.func.isRequired,
    setReadIndicationForItem: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      answerIndex: null,
      renderAnswers: true,
      scrollPosition: 0,
      comments: [],
      loadingComments: true,
      lastCommentUpdate: null,
      maxIndex: 0,
      minIndex: 0
    };
  }

  answer(answerIndex) {
    // this.props.setReadIndicationForItem({ [this.props.poll.id]: true });
    console.log(this.props.poll);
    if (answerIndex === this.props.poll.userAnswered) {
      return;
    }

    if (
      this.props.poll.userAnswered !== null ||
      this.props.poll.userAnswered === 0
    ) {
      let position = document.documentElement.scrollTop;
      document.body.style.position = 'fixed';
      console.log(answerIndex);
      this.setState({
        modalOpen: true,
        answerIndex,
        scrollPosition: position
      });

      return;
    }

    this.doVote(answerIndex);
  }

  doVote(answerIndex) {
    document.body.style.position = '';
    window.scrollTo(0, this.state.scrollPosition);

    this.setState({ modalOpen: false, renderAnswers: false });
    analytics.report('Feed', 'AnswerPoll', this.props.poll.id);
    this.props.answerPoll(this.props.currentUser.id, this.props.poll, answerIndex).catch(() => null);
    this.setState({ renderAnswers: true });
  }

  renderAnswer(possibleAnswer) {
    const { index } = possibleAnswer;

    return (
      <PollOptionView
        optionText={possibleAnswer.answer}
        bold={this.props.poll.userAnswered === index}
        answerPercentage={possibleAnswer.value}
        disabled={this.props.poll.userAnswered === index}
        showAnswer={this.props.poll.userAnswered !== null}
        isCurrentAnswer={this.props.poll.userAnswered === index}
        onClick={() => this.answer(index)}
        key={index}
      />
    );
  }

  canceled() {
    this.setState({
      modalOpen: false
    });
    document.body.style.position = '';
    window.scrollTo(0, this.state.scrollPosition);
  }

  confirmed() {
    this.doVote(this.state.answerIndex);
  }

  copyComment(comment) {
    let copyComment = {};
    copyComment.id = comment.id;
    copyComment.body = comment.body;
    copyComment.email = comment.email;
    copyComment.parentId = comment.parentId;
    copyComment.userId = comment.userId;
    copyComment.userName = comment.userName;
    copyComment.createdAt = comment.createdAt;
    copyComment.postId = comment.postId;
    copyComment.userProfileImg = comment.userProfileImg;
    copyComment.likes = JSON.parse(comment.likes);
    return copyComment;
  }

  updateEventLikes(type) {
    let eventLikes = [];

    let eventItemLikes = JSON.parse(this.props.poll.likes);
    if (eventItemLikes && eventItemLikes.length > 0)
      eventLikes = eventItemLikes.slice();

    let exist = eventLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) {
      const index = eventLikes.findIndex(like => {
        return like.givingUser === this.props.currentUser.id;
      });
      eventLikes.splice(index, 1);
    } else {
      //add new like
      let newLike = {};
      newLike.givedAt = moment().format('YYYY-MM-DD H:mm:ss');
      newLike.userName = this.props.currentUser.name;
      newLike.givingUser = this.props.currentUser.id.toString();
      newLike.userProfileImg = this.props.currentUser.profilePictureUrl;
      newLike.type = type;

      eventLikes.push(newLike);
    }
    let savedLikes = JSON.stringify(eventLikes);

    // send request
    this.props
      .updatePostLikes(this.props.poll.id, savedLikes)
      .then(({ data }) => {
        console.log(data);
      })
      .catch(e => console.log(e));
  }

  updateComments(field, comment, text) {
    switch (field) {
      case 'likes':
        let copyComment = this.copyComment(comment);
        let commentLikes = [];
        if (copyComment.likes.length === 0) commentLikes = [];
        else commentLikes = copyComment.likes.slice();
        let exist = commentLikes.filter(
          like => like.givingUser === this.props.currentUser.id
        );
        if (exist.length > 0) {
          const index = commentLikes.findIndex(like => {
            return like.givingUser === this.props.currentUser.id;
          });
          commentLikes.splice(index, 1);
          let savedLikes = JSON.stringify(commentLikes);
          this.props
            .createUpdateComment(this.props.currentUser.id, comment.id, {
              ...copyComment,
              likes: savedLikes
            })
            .then(({ data }) => {
              console.log('got data from createUpdateComment', data);
              let copyComment = this.copyComment(comment);
              const objIndex = this.state.comments.findIndex(
                comment => comment.id === copyComment.id
              );
              let currentLikes = [];
              if (data.createUpdateComment.likes.length > 0)
                currentLikes = [data.createUpdateComment.likes];
              const updatedObj = {
                ...this.state.comments[objIndex],
                likes: currentLikes,
                liked: false
              };
              let existComments = [...this.state.comments];
              existComments[objIndex] = updatedObj;
              this.setState({ comments: existComments }, () =>
                console.log('comments', this.state.comments)
              );
            })
            .catch(e => {
              console.warn(e);
            });
        } else {
          //add new like
          let newLike = {};
          newLike.givedAt = moment().format('YYYY-MM-DD H:mm:ss');
          newLike.givingUser = this.props.currentUser.id.toString();
          commentLikes.push(newLike);
          let savedLikes = JSON.stringify(commentLikes);
          this.props
            .createUpdateComment(this.props.currentUser.id, comment.id, {
              ...copyComment,
              likes: savedLikes
            })
            .then(({ data }) => {
              console.log('got data from createUpdateComment', data);
              let copyComment = this.copyComment(data.createUpdateComment);
              console.log('copyComment1', copyComment);
              const objIndex = this.state.comments.findIndex(
                comment => comment.id === copyComment.id
              );
              console.log('objIndex', objIndex);
              let currentLikes = copyComment.likes.slice();
              let modifiedComments = this.state.comments.slice();
              console.log('currentLikes', currentLikes);
              const updatedObj = {
                ...this.state.comments[objIndex],
                likes: JSON.stringify(currentLikes),
                liked: true
              };
              modifiedComments[objIndex] = updatedObj;
              console.log('modified comments', modifiedComments);
              this.setState({ comments: modifiedComments }, () =>
                console.log('comments', this.state.comments)
              );
            })
            .catch(e => {
              console.warn(e);
            });
        }
        break;
      case 'edit':
        let cpy = this.copyComment(comment);
        this.props
          .createUpdateComment(this.props.currentUser.id, comment.id, {
            ...cpy,
            body: text
          })
          .then(({ data }) => {
            console.log('got data from createUpdateComment', data);
            let copyComment = this.copyComment(comment);
            const objIndex = this.state.comments.findIndex(
              comment => comment.id === copyComment.id
            );
            const updatedObj = {
              ...this.state.comments[objIndex],
              body: data.createUpdateComment.body
            };
            let existComments = [...this.state.comments];
            existComments[objIndex] = updatedObj;
            this.setState({ comments: existComments }, () =>
              console.log('comments', this.state.comments)
            );
          })
          .catch(e => {
            console.warn(e);
          });
        break;
      case 'reported':
        let copy = this.copyComment(comment);
        console.log('comment before', copy);
        this.props
          .createUpdateComment(this.props.currentUser.id, comment.id, {
            ...copy,
            reported: !copy.reported
          })
          .then(({ data }) => {
            console.log('got data from createUpdateComment', data);
            let copyComment = this.copyComment(comment);
            const objIndex = this.state.comments.findIndex(
              comment => comment.id === copyComment.id
            );
            const updatedObj = {
              ...this.state.comments[objIndex],
              reported: data.createUpdateComment.reported
            };
            let existComments = [...this.state.comments];
            existComments[objIndex] = updatedObj;
            this.setState({ comments: existComments }, () =>
              console.log('comments', this.state.comments)
            );
          })
          .catch(e => {
            console.warn(e);
          });
        break;
      default: 
      break
    }
  }

  deleteComment(comment) {
    let cpy = this.copyComment(comment);
    let existComments = this.state.comments.slice();
    const index = this.state.comments.findIndex(
      comment => comment.id === cpy.id
    );
    existComments.splice(index, 1);
    this.setState({ comments: existComments }, () =>
      console.log('after delete', this.state.comments)
    );
    this.props
      .deleteComment(comment.id)
      .then(res => console.log('got result from deleteComment', res))
      .catch(err => console.log('got error', err));
  }

  saveComment(text) {
    this.props
      .createUpdateComment(this.props.currentUser.id, this.props.editEntityId, {
        parentId: '',
        userId: this.props.currentUser.id,
        userName: this.props.currentUser.name,
        userProfileImg: this.props.currentUser.profilePictureUrl,
        createdAt: moment(new Date()).toDate(),
        body: text,
        email: this.props.currentUser.msjEmail,
        likes: '[]',
        postId: this.props.poll.id
      })
      .then(({ data }) => {
        console.log('got data from createUpdateComment', data);
        let tempComments = this.state.comments.slice();
        let newComment = data.createUpdateComment;
        tempComments.unshift(newComment);
        tempComments.pop();
        this.setState({ comments: tempComments }, () =>
          console.log('comments', this.state.comments)
        );
      })
      .catch(e => {
        console.warn(e);
      });
  }

  saveReplay(parentId, text) {
    this.props
      .createUpdateComment(this.props.currentUser.id, this.props.editEntityId, {
        parentId: parentId,
        userId: this.props.currentUser.id,
        userName: this.props.currentUser.username,
        userProfileImg: this.props.currentUser.profilePictureUrl,
        createdAt: Date.now().toString(),
        body: text,
        email: this.props.currentUser.msjEmail,
        postId: this.props.poll.id,
        likes: '[]'
      })
      .then(({ data }) => {
        console.log('got data from createUpdateComment', data);
      })
      .catch(e => {
        console.warn(e);
      });
  }

  extractUsername(c) {
    try {
      return c.userName !== '' ? c.userName : null;
    } catch (e) {
      console.log(e);
    }
  }

  extractBody(c) {
    try {
      return c.body && c.body !== '' ? c.body : null;
    } catch (e) {
      console.log(e);
    }
  }

  extractImage(c) {
    try {
      return c.image_id && c.user.image_id !== '' ? c.user.image_id : '';
    } catch (e) {
      console.log(e);
    }
  }

  extractChildrenCount(c) {
    try {
      return c.childrenCount || 0;
    } catch (e) {
      console.log(e);
    }
  }

  extractEditTime(item) {
    try {
      return item.updated_at;
    } catch (e) {
      console.log(e);
    }
  }

  extractCreatedTime(item) {
    try {
      return item.created_at;
    } catch (e) {
      console.log(e);
    }
  }

  likeExtractor(item) {
    if (!item.likes) return false;
    if (item.likes.length === 0) return false;
    let existLikes = [];
    if (typeof item.likes[0] === 'string')
      existLikes = [...JSON.parse(item.likes)];
    else existLikes = item.likes.slice();

    // console.log('this.props.currentUser.id',this.props.currentUser.id)
    let exist = existLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) return true;
    return false;
  }

  likeEventExtractor() {
    let eventLikes = JSON.parse(this.props.poll.likes);
    if (!eventLikes) return false;
    if (eventLikes.length === 0) return false;
    let existLikes = [];
    if (typeof eventLikes[0] === 'string')
      existLikes = [...JSON.parse(eventLikes)];
    else existLikes = eventLikes.slice();

    // console.log('this.props.currentUser.id',this.props.currentUser.id)
    let exist = existLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) return true;
    return false;
  }

  reportedExtractor(item) {
    return item.reported;
  }

  async likesExtractor(item) {
    let itemLikes = JSON.parse(item.likes);
    let users = [];
    if (itemLikes && itemLikes.length > 0) {
      itemLikes.map(like => {
        users.push({
          profilePictureUrl: like.userProfileImg,
          name: like.userName,
          likeType: like.type
        });
      });
    }
    return users;
  }

  isCommentChild(item) {
    console.log('item', item);
    console.log('isCommentChild', item.parentId !== '');
    return item.parentId !== null;
  }

  render() {
    const {
      question,
      mainCategory,
      possibleAnswers,
      displayCreationDate,
      isNew
    } = this.props.poll;
    const answers = possibleAnswers.map((item, index) => ({ ...item, index }));

    return (
      <div style={styles.container}>
        {this.state.modalOpen && (
          <ConfirmationModal
            title={LangData.feedPollItem.sure}
            question={
              this.state.modalOpen
                ? LangData.feedPollItem.vote +
                  ' ' +
                  possibleAnswers[this.state.answerIndex].answer
                : ''
            }
            onClose={() => this.canceled()}
            open={this.state.modalOpen}
            options={[
              {
                name: LangData.feedPollItem.yes,
                onClick: () => this.confirmed(),
                color: Theme.SECONDARY_TEXT_COLOR
              },
              {
                name: LangData.feedPollItem.no,
                onClick: () => this.canceled(),
                color: Theme.SECONDARY_TEXT_COLOR
              }
            ]}
          />
        )}
        <div style={styles.row}>
          <CategoryImage category={mainCategory} />
          <div style={{ flex: 1 }}>
            <p style={styles.questionText}>{question}</p>
            <p style={styles.postDateText}>
              {' '}
              {LangData.feedPollItem.posted + ' ' + displayCreationDate}
            </p>
          </div>
        </div>

        {answers.map(item => this.renderAnswer(item))}

        <Comments
          data={this.props.poll.comments}
          postId={this.props.poll.id}
          eventLikes={this.props.poll.likes}
          // usersWhoLikedTheEvent={this.getUsers(this.props.eventItem.likes)}
          viewingUserName={this.props.currentUser}
          childPropName={'children'}
          isChild={item => this.isCommentChild(item)}
          //Extract the key indicating comments parent
          parentIdExtractor={item => item.parentId}
          //what prop holds the comment owners username
          usernameExtractor={item => this.extractUsername(item)}
          //when was the comment last time edited
          editTimeExtractor={item => this.extractEditTime(item)}
          //When was the comment created
          createdTimeExtractor={item => this.extractCreatedTime(item)}
          //where is the body
          bodyExtractor={item => this.extractBody(item)}
          //where is the user image
          imageExtractor={item => this.extractImage(item)}
          //Where to look to see if user liked comment
          likeExtractor={item => this.likeExtractor(item)}
          //Where to look to see if user reported comment
          reportedExtractor={item => this.reportedExtractor(item)}
          //Where to find array with user likes
          likesExtractor={item => this.likesExtractor(item)}
          likesEventsExtractUsers={() => this.likesExtractor(this.props.poll)}
          likeEventExtractor={() => this.likeEventExtractor()}
          //what to do when user clicks reply. Usually its header height + position (b/c scroll list heights are relative)
          replyAction={offset => {
            this.refs.scrollView.scrollTo({
              x: null,
              y: this.scrollIndex + offset - 300,
              animated: true
            });
          }}
          //what to do when user clicks submits edited comment
          saveAction={(text, parentCommentId) => {
            let date = moment().format('YYYY-MM-DD H:mm:ss');
            console.log('comments before save', this.state.comments);

            if (!parentCommentId) {
              console.log('save');
              this.saveComment(text);

              // this.refs.scrollView.scrollToEnd();
            } else {
              console.log('parentId = ', parentCommentId);
              this.saveReplay(parentCommentId, text);
            }
          }}
          //what to do when user clicks submits edited comment
          editAction={(text, comment) => {
            this.updateComments('edit', comment, text);
          }}
          //what to do when user clicks report submit
          reportAction={comment => {
            let comments = this.actions.report(this.state.comments, comment);
            this.setState({
              comments: comments
            });
          }}
          //what to do when user clicks like
          likeAction={comment => {
            console.log('comment', comment);
            this.updateComments('likes', comment, '');
            this.actions.like(this.state.comments, comment);
          }}
          // like event
          likeEventPost={type => {
            this.updateEventLikes(type);
          }}
          //what to do when user clicks like
          deleteAction={comment => {
            this.deleteComment(comment);
          }}
          //Must return promise
          paginateAction={(from_comment_id, direction, parent_comment_id) => {
            //Must return array of new comments after pagination
            console.log('direction', direction);
            this.updatePagination(direction);
            console.log('comments:', this.props.comments.allComments.items);
          }}
        />
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex'
  },
  questionText: {
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    marginLeft: 10,
    fontSize: 13
  },

  postDateText: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontSize: 15,
    marginLeft: 10
  },
  backgroundWaitApprove: {
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'fixed',
    opacity: '0.97',
    zIndex: '9999999',
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0
  },
  modal: {
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: window.innerWidth / 15 + 'px',
    marginBottom: window.innerWidth / 18.75 + 'px',
    color: Theme.TEXT_COLOR,
    width: '85%',
    padding: '3%'
  }
};
