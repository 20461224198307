import React, { Fragment } from 'react';
import { Theme } from '../providers/colors';

const EmptyNotice = props => {
  let propsContainerStyle = props.containerStyle ? props.containerStyle : {};

  return (
    <div style={Object.assign(propsContainerStyle, styles.container)}>
      <p
        style={Object.assign(
          props.titleColor
            ? { color: props.titleColor }
            : { color: Theme.TEXT_COLOR },
          styles.titleText
        )}
      >
        {(props.title || '').toUpperCase()}
      </p>
      <p
        style={Object.assign(
          props.textColor
            ? { color: props.textColor }
            : { color: Theme.TEXT_COLOR },
          styles.subText
        )}
      >
        {' '}
        {props.text || ''}
      </p>
      {props.subTitle && (
        <p style={styles.subTextSmall}>{props.subTitle || ''}</p>
      )}
    </div>
  );
};

export default EmptyNotice;

const styles = {
  emojiText: {
    fontSize: 60,
    textAlign: 'center',
    paddingBottom: 3
  },
  titleText: {
    fontSize: 20,
    paddingTop: '2%',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  subText: {
    textAlign: 'center',
    fontSize: 18
  },
  subTextSmall: {
    textAlign: 'center',
    fontSize: 14,
    marginTop: 10
  },
  container: {
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20
  }
};
