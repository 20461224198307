import { SubscriptionClient, addGraphQLSubscriptions } from 'subscriptions-transport-ws2';
import ApolloClient, { createNetworkInterface } from 'apollo-client2';
import config from './core/config/config';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { AuthorizationMiddleware } from  './core/network/authorization-middleware';
import { TimezoneMiddleware } from './core/network/timezone-middleware';
import { AppVersionMiddleware } from './core/network/app-version-middleware';
import { toIdValue } from 'apollo-utilities';
import introspectionQueryResultData from './core/network/fragmentTypes.json';

const port = config.server.port > 0 ? `:${config.server.port}` : '';

const networkInterface = createNetworkInterface({
  uri: `${config.server.protocol}://${config.server.host}${port}${config.server.path}`,
});



networkInterface.use([
  {
    applyMiddleware(req, next) {
      setTimeout(next, 500);
      if (!req.options.headers) {
        req.options.headers = {
          ...AuthorizationMiddleware.getHeader(),
        ...TimezoneMiddleware.getHeader(),
        ...AppVersionMiddleware.getHeader()
        };

      }
    },
  },
]);




const newUrl = 'Be.YOCAS.life:4000/'//'Be.YOCAS.life:4000/'//'localhost:4000/' 
const endPoint = newUrl === 'localhost:4000/' ? `ws://${newUrl}subscriptions` : `wss://${newUrl}subscriptions`;


const wsClient = new SubscriptionClient(endPoint, {
  reconnect: true,
  connectionParams: {
    ...AuthorizationMiddleware.getHeader(),
    ...TimezoneMiddleware.getHeader(),
    ...AppVersionMiddleware.getHeader()
  }
});



const networkInterfaceWithSubscriptions = addGraphQLSubscriptions(networkInterface, wsClient);

const cache = new InMemoryCache({
  addTypename: false,
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  }),
  cacheRedirects: {
    Query: {
      user: (_, args) =>
        toIdValue(
          cache.config.dataIdFromObject({ __typename: 'User', id: args.id })
        )
    }
  }
})


export const apolloClient = new ApolloClient({
    networkInterface: networkInterfaceWithSubscriptions,
    cache
  });



