import { breadcrumb } from '../bugsnag';

export const EInstagramActions = {
  START_AUTH: 'START_AUTH'
};

export const startInstagramAuth = () => {
  breadcrumb('instagram', 'auth started');

  return {
    type: EInstagramActions.START_AUTH
  };
};
