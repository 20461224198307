import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import SecondStageModal from '../secondStage';
import { CategoriesPicker } from '../categories-picker.container';
import { LangData } from '../../../assets/locales/localization';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './poll.scss';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../common/providers/colors';

export class PollsView extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    notifications: PropTypes.array,
    loadOlder: PropTypes.func,
    reload: PropTypes.func,
    close: PropTypes.func,
    notifyReadStatus: PropTypes.func,
    client: PropTypes.any,
    navigateSecondStage: PropTypes.func.isRequired,
    createUpdatePoll: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      userModal: null,
      profileModalOpen: false,
      eventModal: null,
      mainCategory: '5ee08f9514d8e012c0c05f31',
      categories: ['5ee08f9514d8e012c0c05f31'],
      limitStart: false,
      limitEnd: false,
      pollQuestionText: '',
      answer1: '',
      answer2: '',
      answer3: '',
      answer4: '',
      answer5: '',
      isDateTimePickerVisible: false,
      startDate: null,
      startTime: null,
      startDateTime: null,
      endDate: null,
      endTime: null,
      endDateTime: null,
      isUpdating: false,
      currentPoll: null,
      secondStageOpen: false,
      selectedCategoriesIds: [],
      selectedMainCategoryId: '',
      isFinished: false,
      targetGroups: ['all'],
      done: false,
      isAdvancedOpen: false
    };
    console.log('props=', this.props, this.props.editEntityId);
    // this.notifyReadStatus = debounce(this.notifyReadStatus.bind(this), 100);
  }

  navigateSecondStage(poll) {
    requestAnimationFrame(() => {
      this.setState({ secondStageOpen: true });
      this.props.navigateSecondStage(poll);
      window.scrollTo(0, 0);
    });
  }

  handlePressCheckedBox = checked => {
    this.setState({
      isChecked: checked
    });
  };

  componentDidMount() {
    //  console.log("inside componentDidUpdate")
    const { editEntity } = this.props;
    console.log('inside componentDidMount', editEntity);
    this.setEditEntity(editEntity);
  }

  componentWillReceiveProps(nextProps) {
    //  console.log("inside componentWillReceiveProps")
    const { editEntity } = nextProps;
    console.log('inside componentWillReceiveProps', editEntity);
    this.setEditEntity(editEntity);
  }

  setEditEntity(editEntity) {
    if (editEntity) {
      const answers = editEntity.possibleAnswers.map(item => item.answer);
      const startMoment = editEntity.startTime
        ? moment(editEntity.startTime)
        : null;
      const endMoment = editEntity.endTime ? moment(editEntity.endTime) : null;
      this.setState({
        pollQuestionText: editEntity.question,
        categories: editEntity.categories.map(item => item.id),
        mainCategory: editEntity.mainCategory.id,
        answer1: answers[0] || '',
        answer2: answers[1] || '',
        answer3: answers[2] || '',
        answer4: answers[3] || '',
        answer5: answers[4] || '',
        startTime: editEntity.startTime ? startMoment.format('hh:mm A') : '',
        startDate: editEntity.startTime
          ? startMoment.format('DD-MM-YYYY')
          : null,
        endTime: editEntity.endTime ? endMoment.format('hh:mm A') : '',
        endDate: editEntity.endTime ? endMoment.format('DD-MM-YYYY') : null,
        limitStart: !!startMoment,
        limitEnd: !!endMoment
      });
    }
  }

  handleStartTimeChange(e, type) {
    let currStartTime = this.state.startDateTime;
    if (type === 'Date') {
      currStartTime.setFullYear(e.getFullYear());
      currStartTime.setMonth(e.getMonth());
      currStartTime.setDate(e.getDate());
    } else if (type === 'Time') {
      currStartTime.setHours(e.getHours());
      currStartTime.setMinutes(e.getMinutes());
    }

    this.setState({
      startDateTime: moment(currStartTime, 'DD-MM-YYYY HH:mm:ss').toDate()
    });
  }

  handleEndTimeChange(e, type) {
    let currEndTime = this.state.endDateTime;
    if (type === 'Date') {
      currEndTime.setFullYear(e.getFullYear());
      currEndTime.setMonth(e.getMonth());
      currEndTime.setDate(e.getDate());
    } else if (type === 'Time') {
      currEndTime.setHours(e.getHours());
      currEndTime.setMinutes(e.getMinutes());
    }

    this.setState({
      endDateTime: moment(currEndTime, 'DD-MM-YYYY HH:mm:ss').toDate()
    });
  }

  onRefresh() {
    this.setState({
      refreshing: true
    });

    this.props
      .reload()
      .catch(() => null)
      .then(() => {
        this.setState({
          refreshing: false
        });
      });
  }

  getTransformedOptions() {
    return [
      this.state.answer1,
      this.state.answer2,
      this.state.answer3,
      this.state.answer4,
      this.state.answer5
    ].filter(item => item != '');
  }

  isEmpty(field) {
    return !field || field === '';
  }

  isSubmitDisabled() {
    return (
      this.getTransformedOptions().length < 2 ||
      this.isEmpty(this.state.pollQuestionText) ||
      this.state.mainCategory === null ||
      this.state.categories.length === 0
    );
  }

  _setCloseSecond = (value, stateKey) => {
    this.setState({ [stateKey]: value }, () => {
      if (this.state.isFinished) {
        this.props._setClosePoll(true, 'closePool');
      }
    });
  };

  savePoll() {
    this.setState({ isUpdating: true });

    this.props
      .createUpdatePoll(this.props.editEntityId, {
        question: this.state.pollQuestionText,
        categories: this.state.categories,
        mainCategory: this.state.mainCategory,
        possibleAnswers: this.getTransformedOptions(),
        startTime: this.state.startDateTime ? this.state.startDateTime : null,
        endTime: this.state.endDateTime ? this.state.endDateTime : null
      })
      .then(({ data }) => {
        this.setState({ currentPoll: data });
        this.navigateSecondStage(data);

        this.props.updateTargets(
          data.createUpdatePoll.id,
          this.state.targetGroups
        );
        this.props.publish(data.createUpdatePoll.id, 'NEWS_FEED');

        this.setState({ done: true });
        this.setState({ isUpdating: false });
      })
      .catch(e => {
        console.log('error: ', e);
        this.setState({ isUpdating: false, error: e });
      });
  }

  onCategoriesChange = categories => {
    this.setState({ categories });
  };

  getNamesFromIndexs(indexs) {
    let names = [];
    console.log('categoriesData', this.props.categoriesData);
    let categories = this.props.categoriesData.categories;

    indexs.forEach(index => {
      names.push(categories[index]);
    });

    return names;
  }

  onMainCategoryChange = value => {
    this.setState({ mainCategory: value });
  };

  setTargetGroup = data => {
    this.setState({ targetGroups: data });
  };

  render() {
    if (this.props.loading || this.state.isUpdating) {
      return <LoadingSpinner />;
    }

    if (this.state.done) {
      setTimeout(() => this.props._setClosePoll(true, 'closePool'), 1900);
      return (
        <div style={styles.successContainer}>
          <span style={{ color: 'white' }}>
            {LangData.publicationPlatformPickerForm.success}
          </span>

          <button
            onClick={() => this.props._setClosePoll(true, 'closePool')}
            style={styles.continueBtn}
          >
            {LangData.publicationPlatformPickerForm.return}
          </button>
        </div>
      );
    }

    return (
      <div style={styles.pollsView} className='pollsView'>
        <h2 style={{ textAlign: 'center', fontSize: 20 }}>
          {LangData.polls.details}
        </h2>
        <TextInput
          type='text'
          value={this.state.pollQuestionText}
          onChange={e => this.setState({ pollQuestionText: e.target.value })}
          placeholder={LangData.polls.question}
        />

        <h2
          style={{
            textAlign: 'center',
            fontSize: 16
          }}
        >
          {LangData.polls.answers}
        </h2>

        <TextInput
          type='text'
          value={this.state.answer1}
          onChange={e => this.setState({ answer1: e.target.value })}
          placeholder={LangData.polls.a}
        />

        <TextInput
          type='text'
          value={this.state.answer2}
          onChange={e => this.setState({ answer2: e.target.value })}
          placeholder={LangData.polls.b}
        />

        <TextInput
          type='text'
          value={this.state.answer3}
          onChange={e => this.setState({ answer3: e.target.value })}
          placeholder={LangData.polls.c}
        />

        <TextInput
          type='text'
          value={this.state.answer4}
          onChange={e => this.setState({ answer4: e.target.value })}
          placeholder={LangData.polls.d}
        />

        <TextInput
          type='text'
          value={this.state.answer5}
          onChange={e => this.setState({ answer5: e.target.value })}
          placeholder={LangData.polls.e}
        />

        {/* Advanced Settings */}
        <h2 style={{ textAlign: 'center', fontSize: 20, marginBottom: '-2%' }}>
          Advanced{' '}
          <FontAwesomeIcon
            icon={this.state.isAdvancedOpen ? faChevronUp : faChevronDown}
            onClick={() =>
              this.setState({ isAdvancedOpen: !this.state.isAdvancedOpen })
            }
          />
        </h2>
        <div
          style={
            this.state.isAdvancedOpen
              ? styles.advancedContainerOpen
              : styles.advancedContainerClose
          }
        >
          <h2
            style={{
              textAlign: 'center',
              fontSize: 16
            }}
          >
            Choose poll categories
          </h2>

          <CategoriesPicker
            categories={this.state.categories}
            mainCategory={this.state.mainCategory}
            onMainCategoryChange={this.onMainCategoryChange}
            onCategoriesChange={this.onCategoriesChange}
          />

          {/* limitations view */}
          <h2
            style={{
              textAlign: 'center',
              fontSize: 16
            }}
          >
            Choose time limitation
          </h2>
          <div style={styles.checkboxContainer}>
            <StyledCheckbox
              value='limitStart'
              checked={this.state.limitStart}
              color='primary'
              onChange={e => {
                this.setState({
                  limitStart: !this.state.limitStart,
                  ...(this.state.limitStart === false
                    ? {
                        startDateTime: new Date(Date.now())
                      }
                    : {})
                });
              }}
            />
            <span>{LangData.polls.limitStart}</span>
          </div>

          {this.state.limitStart && (
            <div style={styles.timePickerContainer}>
              <span style={{ color: 'white', textAlign: 'left', fontSize: 11 }}>
                {LangData.polls.time}
              </span>{' '}
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    label='Start Date'
                    value={this.state.startDate}
                    autoOk={true}
                    onChange={e => {
                      this.setState({ startDate: e });
                      this.handleStartTimeChange(e, 'Date');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <KeyboardTimePicker
                    margin='normal'
                    variant='inline'
                    id='time-picker'
                    label='Start Time'
                    value={this.state.startTime}
                    onChange={e => {
                      this.setState({ startTime: e });
                      this.handleStartTimeChange(e, 'Time');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </div>
          )}

          <div style={styles.checkboxContainer}>
            <StyledCheckbox
              value='limitEnd'
              checked={this.state.limitEnd}
              color='primary'
              onChange={e => {
                this.setState({
                  limitEnd: !this.state.limitEnd,
                  ...(this.state.limitEnd === false
                    ? {
                        endDateTime: new Date(Date.now())
                      }
                    : {})
                });
              }}
            />
            <span>{LangData.polls.limitEnd}</span>
          </div>

          {this.state.limitEnd && (
            <div style={styles.timePickerContainer}>
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    label='End Date'
                    value={this.state.endDate}
                    onChange={e => {
                      this.setState({ endDate: e });
                      this.handleEndTimeChange(e, 'Date');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <KeyboardTimePicker
                    margin='normal'
                    label='End Time'
                    id='time-picker'
                    value={this.state.endTime}
                    onChange={e => {
                      this.setState({ endTime: e });
                      this.handleEndTimeChange(e, 'Time');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </div>
          )}

          <SecondStageModal
            poll={this.state.currentPoll}
            type='poll'
            onClose={() => this.setState({ secondStageOpen: false })}
            isOpen={this.state.secondStageOpen && !this.state.isFinish}
            _setCloseSecond={this._setCloseSecond}
            setGroups={this.setTargetGroup}
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={() => this.savePoll()}
          disabled={this.isSubmitDisabled()}
          style={styles.continueBtn}
        >
          Post
        </button>
      </div>
    );
  }
}

const styles = {
  checkbox: {
    color: 'white'
  },
  pollsDetails: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  pickerContainer: {
    flex: 1,
    justifyContent: 'center',
    margin: 30
  },
  container: {
    flexGrow: 1
  },
  list: {
    flexGrow: 1
  },
  titleStyle: {
    fontWeight: 'bold',
    fontSize: 14,
    marginLeft: 20,
    color: '#00356b'
  },
  pollQuestionInput: {
    color: 'white',
    fontSize: 16,
    paddingVertical: 0,
    paddingHorizontal: 0,
    width: '100%',
    marginTop: 10,
    textAlign: 'right'
  },
  pollsView: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: '#00356b',
    overflow: 'hidden',
    display: 'flex',
    color: Theme.TEXT_COLOR
  },
  textInput: {
    fontFamily: 'inherit',
    border: 'none',
    fontSize: '15px',
    padding: '9px',
    width: '80%',
    borderRadius: '5px',
    margin: '5px 0'
  },
  continueBtn: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    marginTop: '5%',
    fontSize: '17px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    border: 'none',
    padding: '3%',
    borderRadius: '50px',
    width: '35%',
    marginBottom: '3%'
  },
  checkboxContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '17px'
  },
  timePickerContainer: {
    width: '85%',
    marginLeft: '10%'
  },
  successContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px'
  },
  advancedContainerOpen: {
    visibility: 'visible',
    height: 'fit-content'
  },
  advancedContainerClose: {
    visibility: 'hidden',
    height: 0
  }
};

const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: Theme.SECONDARY_TEXT_COLOR,
      light: Theme.SECONDARY_TEXT_COLOR,
      dark: Theme.SECONDARY_TEXT_COLOR
    },
    primary: {
      main: Theme.SECONDARY_TEXT_COLOR,
      light: Theme.SECONDARY_TEXT_COLOR,
      dark: Theme.SECONDARY_TEXT_COLOR
    },
    textColor: {
      main: Theme.SECONDARY_TEXT_COLOR
    },
    datePicker: {
      selectColor: Theme.SECONDARY_TEXT_COLOR
    }
  }
});

const TextInput = styled.input`
  font-family: inherit;
  border: none;
  font-size: 15px;
  padding: 9px;
  width: 80%;
  border-radius: 5px;
  margin: 5px 0;

  &:focus {
    outline: none;
    border: none;
  }
`;
