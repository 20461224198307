import { graphql, compose } from 'react-apollo-temp';
import { friendsSuggestionsQuery } from '../graphql/friends-suggestions.query';
import FriendsSuggestionsView from './friends-suggestions.rn';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';

export const withSuggestions = graphql(friendsSuggestionsQuery, {
  name: 'suggestions',
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: localStorage.getItem('userId'),
      amount: 100
    }
  })
});

const withError = withErrorHandler({
  apollo: ['suggestions'],
  toNull: true
});

export const FriendsSuggestions = compose(
  withSuggestions,
  withError
)(FriendsSuggestionsView);
