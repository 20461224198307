import { graphql, compose } from 'react-apollo-temp';
import { allStudentsQuery } from '../graphql/allStudents.query';
import { AllStudentsView } from './allStudents.component';
import { deleteStudentMutation } from '../graphql/deleteStudent.mutation';
import { updateStudentsFilter, editEntity } from './students/students.actions';
import { connect } from 'react-redux';
import { withStatistics } from './with-statistics.container';

const mapStateToProps = ({ students }) => ({
  skip: students.skip,
  limit: students.limit,
  search: students.search,
  filterType: students.filterType,
  editModalOpen: students.isEditMode,
  editModeEntity: students.editEntity
});

const mapDispatchToProps = dispatch => ({
  updateFilter: filterChanges => dispatch(updateStudentsFilter(filterChanges)),
  toggleEditMode: entity => dispatch(editEntity(entity))
});

const withState = connect(mapStateToProps, mapDispatchToProps);

const withData = graphql(allStudentsQuery, {
  name: 'students',
  options: props => {
    let extraVars = {
      perferedIds: []
    };

    if (
      props.search === '' &&
      props.checkedItems &&
      props.checkedItems.length > 0
    ) {
      extraVars = {
        perferedIds: props.checkedItems
          .map(group =>
            group.startsWith('user_') ? group.replace('user_', '') : null
          )
          .filter(item => item)
      };
    }

    return {
      fetchPolicy: 'network-only',
      variables: {
        skip: props.skip,
        limit: props.limit,
        search: props.search,
        filterType: props.filterType,
        ...extraVars
      }
    };
  }
});

export const withStudentsList = compose(withState, withData);

export const withDeleteStudent = graphql(deleteStudentMutation, {
  props: ({ mutate }) => ({
    deleteStudent: studentId => mutate({ variables: { studentId } })
  }),
  options: {
    refetchQueries: ['AllStudents', 'statistics']
  }
});

export const AllStudentsPicker = withDeleteStudent(
  withStatistics(withStudentsList(AllStudentsView))
);
