import FriendsListView from './friends-list.rn';
import { withApollo, compose, graphql } from 'react-apollo-temp';
import { EFriendsListActiveState, toggleRequests } from '../friends.actions';
import { connect } from 'react-redux';
import { friendRequestsStatusQuery } from '../graphql/friend-requests-status.query';
import { totalFriendsQuery } from '../graphql/total-connections.query';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';
import { toggleComponent } from '../../router/router.actions';


export const withFriendsRequestsStatus = graphql(friendRequestsStatusQuery, {
  options: (props) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        userId: props.currentUser ? props.currentUser.id : null
      }
    }
  },
  props: ({ data }) =>
    data.friendRequestsStatus || {
      ignoredCount: 0,
      pendingCount: 0,
      sentCount: 0
    }
});

const withTotalConnections = graphql(totalFriendsQuery, {
  options: (props) => {
    // console.log('props inside total connections', props)
    return {    
      variables: {
        userId: props.currentUser ? props.currentUser.id : null
      },
      fetchPolicy: 'network-only'
    }
  },
  props: ({data}) => data.totalFriends ? { totalFriends: data.totalFriends} :  { totalFriends: 0}
});
 

const mapStateToProps = ({ friends, navigation, auth }) => ({
  isRequests: friends.activeState === EFriendsListActiveState.REQUESTS,
  isGlobalSearch: friends.activeState === EFriendsListActiveState.GLOBAL_SEARCH,
  isFriendsList: friends.activeState === EFriendsListActiveState.FRIENDS_LIST,
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleRequests: newState => dispatch(toggleRequests(newState)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const withError = withErrorHandler({
  apollo: ['searchResults', 'data', 'connections']
});

export const withState = connect(mapStateToProps, mapDispatchToProps);

export const FriendsList = compose(
  withApollo,
  withState,
  withFriendsRequestsStatus,
  withTotalConnections,
  withError
)(FriendsListView);
