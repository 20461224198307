import gql from 'graphql-tag';
import { FeedEventItemFragment } from './feed-event-item.fragment';
import { FeedSportsEventItemFragment } from './feed-sports-event-item.fragment';

export const toggleReminderMutation = gql`
    mutation toggleReminder($userId: ID, $eventId: String!) {
        toggleReminder(userId: $userId, eventId: $eventId) {
            ...FeedEventItem
            ...FeedSportsEventItem
        }
    }
  
    ${FeedEventItemFragment}
    ${FeedSportsEventItemFragment}
`;
