import FeedEventModalView from './feed-event-modal.rn';
import { withRemindersMutation } from '../feed-list/feed-event-item/feed-event-item.container';
import { rsvpMutation } from '../../graphql/rsvp.mutation';
import { compose, graphql } from 'react-apollo-temp';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { openImgModal, toggleComponent } from '../../../router/router.actions';

const withRsvpMutation = graphql(rsvpMutation, {
  withRef: true,
  props: ({ ownProps, mutate }) => {
    const execute = (event, answer) =>
      mutate({
        variables: {
          rsvp: {
            eventId: event.id,
            answer
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          rsvp: {
            ...event,
            userRSVP: answer,
            userReminder: answer === 'GOING' || event.userReminder
          }
        },
        updateQueries: {
          eventGoingList: (
            prev,
            {
              mutationResult: {
                data: { rsvp }
              }
            }
          ) => {
            const { me } = ownProps;
            let newState = (prev.eventGoingList || {}).users || [];
            let newTotal = (prev.eventGoingList || {}).total || 0;

            if (
              rsvp.userRSVP === 'MAYBE' &&
              newState.find(user => user.id === me.id)
            ) {
              newState = newState.filter(user => user.id !== me.id);
              newTotal -= 1;
            } else if (
              rsvp.userRSVP === 'GOING' &&
              !newState.find(user => user.id === me.id)
            ) {
              newState = [
                {
                  ...me,
                  friendRequestStatus: null,
                  friendsSince: null,
                  profilePictureThumbUrl: me.profilePictureUrl,
                  appSupportChat: true,
                  canRemoveConnection: false
                },
                ...newState
              ];
              newTotal += 1;
            }

            return update(prev, {
              eventGoingList: {
                users: {
                  $set: newState
                },
                total: {
                  $set: newTotal
                }
              }
            });
          },
          Feed: (
            prev,
            {
              mutationResult: {
                data: { rsvp }
              }
            }
          ) => {
            const itemIndex = prev.feedList.items.findIndex(
              item => item.id === event.id
            );

            if (itemIndex < 0) {
              return prev;
            }

            return update(prev, {
              feedList: {
                items: {
                  [itemIndex]: {
                    $set: {
                      ...prev.feedList.items[itemIndex],
                      userRSVP: rsvp.userRSVP,
                      userReminder: rsvp.userReminder
                    }
                  }
                }
              }
            });
          }
        }
      }).catch(() => null);

    return {
      rsvp: (event, response) => execute(event, response)
    };
  }
});

const mapStateToProp = ({ auth }) => ({
  me: auth.currentUser
});

const mapDispatch = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  openImgModal: img => dispatch(openImgModal(img))
});

const withState = connect(mapStateToProp, mapDispatch);

export const FeedEventModal = compose(
  withState,
  withRsvpMutation,
  withRemindersMutation
)(FeedEventModalView);
