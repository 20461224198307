import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../assets/locales/localization';
import {
  faSearch,
  faTimes,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import ReactPlayer from 'react-player';
import { Redirect } from 'react-router';
import { Theme } from '../../common/providers/colors';
import { ConfirmationModal } from '../../common/components/confirmation-modal.rn';
import yocasPdfLogo from '../../assets/images/yocasPdfLogo.png';
import { createVideoLogMutation } from '../graphql/createVideoLog';
import { updateVideoFinishedMutation } from '../graphql/updateVideoFinished';
import { updateWasUsefulMutation } from '../graphql/updateVideoUseful.mutation';
import { apolloClient } from '../../apollo-subs';
const width = window.innerWidth
const dummyPdfUrl = 'https://docs.google.com/viewer?url=https://fortshavendiag.blob.core.windows.net/pdf-manuals/Integrative%20Cancer%20Therapies72c570ac-e3b3-464e-920f-52913c5f2e34.pdf&embedded=true'

export class VideosView extends Component {
  static propTypes = {
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    search: PropTypes.string.isRequired,
    updateFilter: PropTypes.func.isRequired,
    videos: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allVideos: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  constructor() {
    super();

    this.state = {
      currentVideo: null,
      isScrollTop: true,
      isContentOpen: false,
      currentLabelDisplayed: 'General',
      isSurveyModalOpen: false,
      videoItemStarted: false,
      videoItemPasued: {},
      showSecondSurveyPart: false,
      currentSrc: '',
      title:'',
      videoLogId: null
    };
  }

  componentWillMount = () => {
    console.log("props in sess =>", this.props)
    if(this.props.location.state){
      this.setState({ title: this.props.location.state.yocasType})
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.currentVideo === null &&
      nextProps.videos &&
      nextProps.videos.allVideos &&
      nextProps.videos.allVideos.items
    ) {
      if (
        localStorage.getItem('videoToDisplay') &&
        localStorage.getItem('videoToDisplay') !== 'none'
      ) {
        let videoFromUrl = nextProps.videos.allVideos.items.filter(
          item => item.id === localStorage.getItem('videoToDisplay')
        );
        this.setState({
          currentVideo: null
        });
        localStorage.setItem('videoToDisplay', 'none');
      } else {
        this.setState({
          currentVideo: null
        });
      }
    }
  }

  updatePagination(nextData) {
    if (nextData) {
      this.props.updateFilter({ skip: this.props.skip + this.props.limit });
    } else {
      let newSkip = this.props.skip - this.props.limit;

      if (newSkip < 0) {
        newSkip = 0;
      }

      this.props.updateFilter({ skip: newSkip });
    }
  }

  resetFilter() {
    this.props.updateFilter({ skip: 0, search: '' });
  }

  updateSearch(newValue) {
    this.props.updateFilter({ skip: 0, search: newValue });
  }

  renderVideosItems(items) {
    if (items && items.length === 0) {
      return;
    }

    if (this.props.search !== '') {
      return items.map((item, index) => {
        return (
          <div
            style={styles.videoContainer}
            onClick={() => {
              this.setState({ currentVideo: item })
            }}
            key={index}
          >
            <img alt="" src={item.previewImage} style={styles.previewStyle} />
            {/* <FontAwesomeIcon icon={faPlayCircle} style={styles.playIcon} /> */}
            <span>
              <b>{item.name}</b>
            </span>
            <span>
              <b>Duration: </b> {this.getVideoTime(parseInt(item.duration))}
            </span>
            {
              item.pdfUrl &&
              <img alt="" onClick={() => {
                this.setState({ currentSrc: item.pdfUrl })
              }} style={styles.pdfExportLogoInCarusel} src={yocasPdfLogo} />
            }
          </div>
        );
      });
    }


    return items.map((item, index) => {
      if (this.state.currentLabelDisplayed === 'General' || item.label === this.state.currentLabelDisplayed) {
        return (
          <div
            style={styles.videoContainer}
            onClick={() => {
              console.log("video clicked!", item)
              this.setState({ currentVideo: item })}}
            key={index}
          >
            <img alt="" src={item.previewImage} style={styles.previewStyle} />
            {/* <FontAwesomeIcon icon={faPlayCircle} style={styles.playIcon} /> */}
            <span>
              <b>{item.name}</b>
            </span>
            <span>
              <b>Duration: </b> {this.getVideoTime(parseInt(item.duration))}
            </span>
            {
              item.pdfUrl &&
              <img alt="" onClick={() => {
                this.setState({ currentSrc: item.pdfUrl })
              }} style={styles.pdfExportLogoInCarusel} src={yocasPdfLogo} />
            }
          </div>
        );
      }
    });
  }

  renderVideosContent(items) {
    if (items && items.length === 0) {
      return;
    }
    const allLabels = []
    let currentLabel = '';
    let showLabel = false;
    

    let convertedItems = []
    let short = items.filter(elem => elem.label.toLowerCase().includes("short"))
    let med = items.filter(elem => elem.label.toLowerCase().includes("medium"))
    let long = items.filter(elem => elem.label.toLowerCase().includes("long"))
    convertedItems = [...short, ...med, ...long]
    
    return convertedItems.map((item, index) => {
      if (!allLabels.find(label => label === item.label)) {
        allLabels.push(item.label)
        currentLabel = item.label;
        showLabel = true;
      } else {
        showLabel = false;
      }


      return (
        <Fragment key={index}>
          {showLabel && (
            <p
              style={styles.labelContent}
              onClick={() => {
                if(item.label !== this.state.currentLabelDisplayed) {
                  if(this.state.videoItemStarted) {
                    this.setState({ videoItemStarted: false, isSurveyModalOpen: true, currentVideo: null, })
                  }
                }
                this.setState({ currentLabelDisplayed: item.label });
                document.getElementById('videoContainerScroll').scrollLeft = 0;
              }}
            >
              {currentLabel}
            </p>
          )}
        </Fragment>
      );
    });
  }

  pauseVideo(event, videoId) {
    // this.setState({ isSurveyModalOpen: true });
    let pauseMinute = event.target.currentTime.toFixed(2);
    let videoDuration = this.state.currentVideo.duration;
    let isCompleted = videoDuration === pauseMinute;
    let videoName = this.state.currentVideo.name;
    this.setState({
      videoItemPasued: { videoId, pauseMinute, isCompleted, videoName }
    });
  }

  getVideoTime(duration) {
    let minutes = Math.floor(duration / 60);
    let seconds = duration - minutes * 60;
    let res = '' + minutes + ':' + (seconds < 10 ? '0' : '');
    res += '' + seconds;
    return res;
  }

  toggleContentTable = () => {
    this.setState({ isContentOpen: !this.state.isContentOpen });
  };

  videoStarted = (e) => {
    console.log('videoItemStarted =>', this.state.videoItemStarted)
    if(this.state.videoItemStarted) {
      this.setState({ isSurveyModalOpen: true });
    } else {
      this.setState({ videoItemStarted: true })
    }
  }

  videoEnded = (e) => {
    console.log('video ended!')
    apolloClient.mutate({
      mutation: updateVideoFinishedMutation,
      variables: {
        videoLogId: this.state.videoLogId
      }
    })
    this.setState({ videoItemStarted: false, isSurveyModalOpen: true})
  }

  updateUseful = (wasUseful) => {
    apolloClient.mutate({
      mutation: updateWasUsefulMutation,
      variables: {
        videoLogId: this.state.videoLogId,
        wasUseful
      }
    })
  }


  videoClicked = async () => {
    console.log("video clicked =>", this.state.currentVideo)
    let result = await apolloClient.mutate({
      mutation: createVideoLogMutation,
      variables: {
        userId: localStorage.getItem('userId'),
        videoId: this.state.currentVideo.id,
        startedAt: new Date(),
        isFinished: false,
        category: this.props.location.state.yocasType,
        type: this.state.currentVideo.label,
        videoData: this.state.currentVideo.data,
        videoLabel: this.state.currentVideo.label,
        videoName: this.state.currentVideo.name
      }
    })

    let videoLogId = result.data.createVideoLog._id
    console.log("videoLogId =>", videoLogId)
    this.setState({ videoLogId })
  }

  render() {
    const items = this.props.videos.allVideos
      ? this.props.videos.allVideos.items
      : [];

    if (!this.props.currentUser) {
      return <Redirect to='/login' />;
    }

    return (
      <div style={styles.mainContainer} onScroll={this.handleScroll}>
        {/* Top Options Row */}
        <div style={styles.optionsRow}>
          <div
            onClick={() => {
              if(this.state.videoItemStarted) {
                this.setState({ videoItemStarted: false, isSurveyModalOpen: true, currentVideo: null, })
              }
              this.props.toggleComponent(this.props.prevComponent);
              this.props.history.push('/');
              // this.props.history.go(-1);
            }}
          >
            <span style={styles.goBack}>{'<'}</span>
            {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
          </div>
          <div>
            <h1 style={{ color: '#4D4B62', textTransform: 'capitalize' }}>{this.state.title}s</h1>
          </div>
          <div></div>
        </div>

        {/* Search Container */}
        <div style={styles.searchContainer}>
          <TextInput
            type='text'
            placeholder={`Search ${this.state.title}s`}
            onChange={e => this.updateSearch(e.target.value)}
            value={this.props.search}
            style={width < 500 ? styles.inputOnMobile : styles.inputOnDesktop}
          />
          <FontAwesomeIcon icon={faSearch} style={styles.searchIcon} />
          <div style={styles.clearIcon}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => this.resetFilter()}
            />
          </div>
        </div>

        {this.props.videos.loading && <LoadingSpinner />}

        {/* Table Of Content */}
        <div style={styles.contentContainer}>
          {/* <div
            style={styles.scrollContentRow}
            onClick={() => this.toggleContentTable()}
          >
            <p>
              {this.state.isContentOpen
                ? 'Click to hide content'
                : 'Click to see content'}
            </p>
            <FontAwesomeIcon
              icon={this.state.isContentOpen ? faChevronUp : faChevronDown}
              style={styles.serachIcon}
            />
          </div>
          <div
            style={
              this.state.isContentOpen
                ? styles.toggleContentConainerOpen
                : styles.toggleContentConainerClose
            }
          >
            {this.renderVideosContent(items)}
          </div> */}
          <div style={styles.toggleContentConainerOpen}>
            {this.renderVideosContent(items)}
          </div>
        </div>
        {
          this.state.currentSrc &&
          <div style={styles.iframeContainer}>
            <div style={styles.optionsRowPdfModal}>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => this.setState({ currentSrc: null })}
                style={styles.closeIframe}
              />
              <a
                target='_blank'
                href={this.state.currentSrc}
                download
                style={styles.href}
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </div>
            <iframe
              title="test"
              src={
                `https://docs.google.com/viewer?url=${this.state.currentSrc}&embedded=true`
              }
              style={styles.iframe}
            />
          </div>
        }
        {/* Video Player Component */}
        {this.state.currentVideo && (
          <div style={{ width: window.innerWidth, height: width < 500 ? '200px' : '500px' }}>
            <p style={styles.videoNameCurrent}>
              <span>{this.state.currentVideo.name}</span>
              {
                this.state.currentVideo.pdfUrl && <img onClick={() => {
                  this.setState({ currentSrc: this.state.currentVideo.pdfUrl })
                }} style={styles.pdfExportLogo} src={yocasPdfLogo}  alt=""/>
              }
            </p>
            <ReactPlayer
              onClickPreview={() => this.videoClicked()}
              ref={this.state.currentVideo.id}
              url={this.state.currentVideo.data}
              controls
              playsinline
              playing={true}
              onEnded={e => this.videoEnded(e)}
              onStart={e => this.videoStarted(e)}
              width={width < 500 ? '100%' : '60%'}
              height={width < 500 ? '100%' : '60%'}
              style={styles.videoPlayer}
              onPause={e => this.pauseVideo(e, this.state.currentVideo.id)}
              light={this.state.currentVideo.previewImage}
              // onReady={e => console.log('on ready called!')}
            />
          </div>
        )}

        {/* All Videos Preview */}
        {this.props.search === '' && this.state.currentLabelDisplayed && (
          <div style={styles.videosContainer}>
            {/* <p style={styles.label}>{this.state.currentLabelDisplayed}</p> */}
            <div style={styles.videoContainerScroll} id='videoContainerScroll'>
              {this.renderVideosItems(items)}
            </div>
          </div>
        )}

        {this.props.search !== '' && (
          <div style={styles.videosContainer}>
            <div style={styles.videoContainerScroll} id='videoContainerScroll'>
              {this.renderVideosItems(items)}
            </div>
          </div>
        )}

        {this.state.isSurveyModalOpen && (
          <Fragment>
            <div style={styles.modalContainer}>
              <div style={styles.modalInnerContainer}>
                {!this.state.showSecondSurveyPart ? (
                  <Fragment>
                    <p>Do you find this video helpful? </p>
                    <div style={styles.buttonsRow}>
                      <button
                        style={styles.optionButton}
                        onClick={() => {
                          let videoObj = this.state.videoItemPasued;
                          this.updateUseful(true)
                          this.props.videoView(
                            this.props.currentUser.id,
                            videoObj.videoId,
                            videoObj.pauseMinute,
                            videoObj.isCompleted,
                            videoObj.videoName,
                            'Was Helpful'
                          );
                          this.setState({
                            isSurveyModalOpen: false
                          });
                        }}
                      >
                        Yes
                      </button>
                      <button
                        style={styles.optionButton}
                        onClick={() => {
                          this.updateUseful(false)
                          this.setState({ showSecondSurveyPart: true })
                        }
                        }
                      >
                        No
                      </button>
                    </div>
                  </Fragment>
                ) : (
                    <Fragment>
                      <p>Why? </p>
                      <div style={styles.buttonsRowAnswers}>
                        {surveyButtons.map(button => {
                          return (
                            <button
                              style={styles.optionButtonAnswers}
                              onClick={() => {
                                let videoObj = this.state.videoItemPasued;
                                this.props.videoView(
                                  this.props.currentUser.id,
                                  videoObj.videoId,
                                  videoObj.pauseMinute,
                                  videoObj.isCompleted,
                                  videoObj.videoName,
                                  button
                                );
                                this.setState({
                                  showSecondSurveyPart: false,
                                  isSurveyModalOpen: false
                                });
                              }}
                            >
                              {button}
                            </button>
                          );
                        })}
                      </div>
                    </Fragment>
                  )}
              </div>
            </div>
            <div style={styles.modalBackground}></div>
          </Fragment>
        )}
      </div>
    );
  }
}

const styles = {
  mainContainer: {
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: window.innerHeight,
    backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.95), rgb(237,204,181,0.95))',
    color: Theme.TEXT_COLOR
  },
  searchIcon: {
    // paddingLeft: width < 500 ? '18px' : '25px',
    left: width < 500 ? '14%' : '7%',
    // paddingTop: width < 500 ? '7px' : 'unset',
    top: '31%',
    fontSize: '15px',
    position: 'absolute',
    color: '#4D4B62'
  },
  clearIcon: {
    // paddingLeft: window.innerWidth / 1.0869,
    // paddingTop: '5px',
    width: '20px',
    height: '20px',
    top: '29%',
    left: width < 500 ? '81%' : '93%',
    fontSize: '12px',
    position: 'absolute',
    color: '#4D4B62',
    border: '3px solid #4D4B62',
    borderRadius: '50%',
    textAlign: 'center',
  },
  searchContainer: {
    width: width < 500 ? '100%' : '70%',
    borderBottomColor: Theme.BACKGROUND_COLOR,
    borderBottomWidth: 2,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    position: 'relative'
  },
  videosContainer: {
    width: window.innerWidth,
    marginTop: width < 500 ? '15%' : '0%'
  },
  label: {
    color: Theme.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '20px',
    letterSpacing: '0.9px',
    marginBottom: 0
  },
  videoContainer: {
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '1%',
    margin: '2%',
    color: Theme.TEXT_COLOR,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    WebkitBoxShadow: '0px 0px 7px 1px rgba(94,93,94,1)',
    MozBoxShadow: '0px 0px 7px 1px rgba(94,93,94,1)',
    boxShadow: '0px 0px 7px 1px rgba(94,93,94,1)',
    fontSize: 14,
    width: width < 500 ? window.innerWidth / 2 : window.innerWidth / 4,
    flexShrink: 0,
    justifyContent: 'space-between',
    paddingBottom: '4%'
  },
  iframeContainer: {
    width: '100%',
    zIndex: '9',
    position: 'absolute',
    top: 0,
    height: '100%',
    backgroundColor: Theme.BACKGROUND_COLOR,
    opacity: '0.99',
    display: 'flex',
    flexDirection: 'column'
  },
  closeIframe: {
    margin: '3%',
    color: Theme.SECONDARY_TEXT_COLOR,
    zIndex: '99999'
  },
  videoNameIframe: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '20px',
    marginBottom: '-15%',
    color: Theme.SECONDARY_TEXT_COLOR,
    marginTop: '-1%'
  },
  previewStyle: {
    borderRadius: '15px',
    width: '100%',
    marginBottom: '1%',
    maxHeight: "59%"
  },
  playIcon: {
    position: 'absolute',
    width: window.innerWidth / 7.2,
    height: window.innerWidth / 4.5,
    color: 'white',
    opacity: '0.95',
    marginLeft: '38%',
    marginTop: '5%'
  },
  videoName: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '2%'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
    color: 'white',
    fontSize: '19px'
  },
  scrollContentRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    color: Theme.TEXT_COLOR
  },
  toggleContentConainerOpen: {
    maxHeight: '3500px',
    transition: 'max-height 0.5s ease-in',
    marginTop: '3%'
  },
  toggleContentConainerClose: {
    maxHeight: 0,
    transition: 'max-height 0.2s ease-out',
    overflow: 'hidden'
  },
  labelContent: {
    fontWeight: 'bold',
    fontSize: width < 500 ? '4vw' : "1rem",
    letterSpacing: '0.9px',
    backgroundColor: '#3a3d62',
    color: '#edccb5f2',
    padding: width < 500 ? '4%' : '1%',
    minWidth: width< 500 ? '75vw' : '20vw',
    width: width < 500 ? '78vw': '20vw',
    borderRadius: '20px'
  },
  videoContainerScroll: {
    width: window.innerWidth,
    height: width < 500 ? window.innerWidth / 1.44 : '55vh',
    display: width < 500 ? 'flex' : 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch'
  },
  videoContainerScrollInner: {},
  videoNameCurrent: {
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '1%',
    marginLeft: '3%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pdfExportLogo: {
    height: '30px',
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    width: '90%'
  },
  modalBackground: {
    position: 'fixed',
    top: '0',
    zIndex: '999',
    backgroundColor: 'black',
    width: '100vw',
    height: '100vh',
    opacity: '0.5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContainer: {
    zIndex: '9999',
    backgroundColor: 'white',
    position: 'fixed',
    top: '30%',
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1)',
    padding: '3%',
    fontWeight: 'bold'
  },
  buttonsRow: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  optionButton: {
    border: 'none',
    backgroundColor: Theme.BACKGROUND_COLOR,
    color: Theme.TEXT_COLOR,
    padding: '1%',
    borderRadius: '10px',
    width: '40px',
    fontFamily: 'inherit',
    fontWeight: 'bold'
  },
  buttonsRowAnswers: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column'
  },
  optionButtonAnswers: {
    border: 'none',
    backgroundColor: Theme.BACKGROUND_COLOR,
    color: Theme.TEXT_COLOR,
    padding: '1%',
    borderRadius: '10px',
    width: '100%',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    margin: '1%'
  },
  videoPlayer: {
    margin: 'auto'
  },
  inputOnDesktop: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  iframe: {
    height: '80%',
    border: 'none',
    borderRadius: '6px',
    margin: '0 1%'
  },
  iframeContainer: {
    width: '100%',
    zIndex: '9',
    position: 'fixed',
    top: 0,
    height: '100vh',
    backgroundColor: Theme.BACKGROUND_COLOR,
    opacity: '0.97',
    display: 'flex',
    flexDirection: 'column'
  },
  optionsRowPdfModal: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pdfExportLogoInCarusel: {
    width: '25%',
    margin: 'auto'
  },
  inputOnMobile: {
    width: '60%',
    margin: 'auto',
    border: '4px solid #4D4B62',
    fontSize: "4vw"
  },
  goBack:{
    borderRadius:'50%',
    width:'25px',
    height:'25px',
    position:'absolute',
    textAlign:'center',
    backgroundColor:'#4D4B62',
    color:'floralwhite'
  }
};

const TextInput = styled.input`
  padding-right: 30px;
  height: 25px;
  align-self: stretch;
  font-size: 15px;
  font-family: inherit;
  color: ${Theme.TEXT_COLOR};
  outline: none;
  border: none;
  padding-left: 7%;
  font-weight: bold;
  margin-left: 3%;
  margin-right: 3%;
  position: relative;
  background-color: white;
  border-radius: 15px;
  width: 100%;

  &:focus {
    outline: none;
    border: none;
  }
`;

const surveyButtons = [
  'The content is not what I am looking for',
  'The video is too long',
  'The instruction is hard to follow',
  'The video/audio is not working'
];
