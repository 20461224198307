import gql from 'graphql-tag';


export const createFeatureLogMutation = gql`
mutation($userId: ID, $feature: String, $clickedAt: String) {
    createFeatureLog(userId: $userId, feature: $feature, clickedAt:$clickedAt) {
       _id
    }
}

`