import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CategoryImage from '../../categories-grid/category-image.rn';
import ReminderBell from './reminder-bell.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkedAlt,
  faCalendar,
  faQuestion,
  faCalendarCheck,
  faCalendarPlus
} from '@fortawesome/free-solid-svg-icons';
import { faCaretSquareUp } from '@fortawesome/free-regular-svg-icons';
import { CalendarExportHandler } from '../../../../calendar-export/component/calendar-export.rn';
//import { AUTHORIZED } from '../../../../calendar-export/utils/calendar-exporter';
import { analytics } from '../../../../analytics/report';
import { ScoreBox } from './score-box.rn';
import Comments from './comments.rn';
import * as commentActions from '../../../comments.actions';
import LoadingSpinner from '../../../../common/components/loading-spinner.rn';
import { LangData } from '../../../../assets/locales/localization';
import { Theme } from '../../../../common/providers/colors';
import ProfileImage from '../../../../common/components/profile-image.rn';

export class FeedEventItemView extends Component {
  static propTypes = {
    eventItem: PropTypes.any,
    toggleReminder: PropTypes.func.isRequired,
    popupReminderModal: PropTypes.func.isRequired,
    onPosterClicked: PropTypes.func,
    onLastImagesClicked: PropTypes.func,
    extended: PropTypes.bool,
    createUpdateComment: PropTypes.func.isRequired,
    skip: PropTypes.number,
    limit: PropTypes.number,
    updateFilter: PropTypes.func.isRequired,
    comments: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allComments: PropTypes.shape({
        total: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      isInCalendar: true,
      comments: [],
      loadingComments: true,
      lastCommentUpdate: null,
      maxIndex: 0,
      minIndex: 0
    };

    this.actions = commentActions;
    this.scrollIndex = 0;
    this.props = props;
  }

  componentWillReceiveProps(newProps) {
    if (this.state.maxIndex !== newProps.skip + newProps.limit) {
      this.setState({ maxIndex: newProps.skip + newProps.limit }, () => {});
    }
    if (this.state.minIndex !== newProps.skip) {
      this.setState({ minIndex: newProps.skip }, () => {});
    }

    if (
      newProps.comments &&
      newProps.comments.allComments &&
      newProps.comments.allComments.items
    ) {
      if (this.state.comments !== newProps.comments.allComments.items) {
        this.setState({ comments: newProps.comments.allComments.items });
      }
    }
  }

  copyComment(comment) {
    let copyComment = {};
    copyComment.id = comment.id;
    copyComment.body = comment.body;
    copyComment.email = comment.email;
    copyComment.parentId = comment.parentId;
    copyComment.userId = comment.userId;
    copyComment.userName = comment.userName;
    copyComment.createdAt = comment.createdAt;
    copyComment.postId = comment.postId;
    copyComment.userProfileImg = comment.userProfileImg;
    copyComment.likes = JSON.parse(comment.likes);
    return copyComment;
  }

  updateEventLikes(type) {
    let eventLikes = [];

    let eventItemLikes = JSON.parse(this.props.eventItem.likes);
    if (eventItemLikes && eventItemLikes.length > 0)
      eventLikes = eventItemLikes.slice();

    let exist = eventLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) {
      const index = eventLikes.findIndex(like => {
        return like.givingUser === this.props.currentUser.id;
      });
      eventLikes.splice(index, 1);
    } else {
      //add new like
      let newLike = {};
      newLike.givedAt = moment().format('YYYY-MM-DD H:mm:ss');
      newLike.userName = this.props.currentUser.name;
      newLike.givingUser = this.props.currentUser.id.toString();
      newLike.userProfileImg = this.props.currentUser.profilePictureUrl;
      newLike.type = type;
      eventLikes.push(newLike);
    }
    let savedLikes = JSON.stringify(eventLikes);

    // send request
    this.props
      .updatePostLikes(this.props.eventItem.id, savedLikes)
      .then(({ data }) => {
        console.log(data);
      })
      .catch(e => console.log(e));
  }

  updateComments(field, comment, text) {
    switch (field) {
      case 'likes':
        let copyComment = this.copyComment(comment);
        let commentLikes = [];
        if (copyComment.likes.length === 0) commentLikes = [];
        else commentLikes = copyComment.likes.slice();
        let exist = commentLikes.filter(
          like => like.givingUser === this.props.currentUser.id
        );
        if (exist.length > 0) {
          const index = commentLikes.findIndex(like => {
            return like.givingUser === this.props.currentUser.id;
          });
          commentLikes.splice(index, 1);
          let savedLikes = JSON.stringify(commentLikes);
          this.props
            .createUpdateComment(this.props.currentUser.id, comment.id, {
              ...copyComment,
              likes: savedLikes
            })
            .then(({ data }) => {
              let copyComment = this.copyComment(comment);
              const objIndex = this.state.comments.findIndex(
                comment => comment.id === copyComment.id
              );
              let currentLikes = [];
              if (data.createUpdateComment.likes.length > 0)
                currentLikes = [data.createUpdateComment.likes];
              const updatedObj = {
                ...this.state.comments[objIndex],
                likes: currentLikes,
                liked: false
              };
              let existComments = [...this.state.comments];
              existComments[objIndex] = updatedObj;
              this.setState({ comments: existComments }, () =>
                console.log('comments', this.state.comments)
              );
            })
            .catch(e => {
              console.warn(e);
            });
        } else {
          //add new like
          let newLike = {};
          newLike.givedAt = moment().format('YYYY-MM-DD H:mm:ss');
          newLike.givingUser = this.props.currentUser.id.toString();
          commentLikes.push(newLike);
          let savedLikes = JSON.stringify(commentLikes);
          this.props
            .createUpdateComment(this.props.currentUser.id, comment.id, {
              ...copyComment,
              likes: savedLikes
            })
            .then(({ data }) => {
              console.log('got data from createUpdateComment', data);
              let copyComment = this.copyComment(data.createUpdateComment);
              console.log('copyComment1', copyComment);
              const objIndex = this.state.comments.findIndex(
                comment => comment.id === copyComment.id
              );
              console.log('objIndex', objIndex);
              let currentLikes = copyComment.likes.slice();
              let modifiedComments = this.state.comments.slice();
              console.log('currentLikes', currentLikes);
              const updatedObj = {
                ...this.state.comments[objIndex],
                likes: JSON.stringify(currentLikes),
                liked: true
              };
              modifiedComments[objIndex] = updatedObj;
              console.log('modified comments', modifiedComments);
              this.setState({ comments: modifiedComments }, () =>
                console.log('comments', this.state.comments)
              );
            })
            .catch(e => {
              console.warn(e);
            });
        }
        break;
      case 'edit':
        let cpy = this.copyComment(comment);
        this.props
          .createUpdateComment(this.props.currentUser.id, comment.id, {
            ...cpy,
            body: text
          })
          .then(({ data }) => {
            console.log('got data from createUpdateComment', data);
            let copyComment = this.copyComment(comment);
            const objIndex = this.state.comments.findIndex(
              comment => comment.id === copyComment.id
            );
            const updatedObj = {
              ...this.state.comments[objIndex],
              body: data.createUpdateComment.body
            };
            let existComments = [...this.state.comments];
            existComments[objIndex] = updatedObj;
            this.setState({ comments: existComments }, () =>
              console.log('comments', this.state.comments)
            );
          })
          .catch(e => {
            console.warn(e);
          });
        break;
      case 'reported':
        let copy = this.copyComment(comment);
        console.log('comment before', copy);
        this.props
          .createUpdateComment(this.props.currentUser.id,comment.id, {
            ...copy,
            reported: !copy.reported
          })
          .then(({ data }) => {
            console.log('got data from createUpdateComment', data);
            let copyComment = this.copyComment(comment);
            const objIndex = this.state.comments.findIndex(
              comment => comment.id === copyComment.id
            );
            const updatedObj = {
              ...this.state.comments[objIndex],
              reported: data.createUpdateComment.reported
            };
            let existComments = [...this.state.comments];
            existComments[objIndex] = updatedObj;
            this.setState({ comments: existComments }, () =>
              console.log('comments', this.state.comments)
            );
          })
          .catch(e => {
            console.warn(e);
          });
        break;
      default: 
      break
    }
  }

  deleteComment(comment) {
    let cpy = this.copyComment(comment);
    let existComments = this.state.comments.slice();
    const index = this.state.comments.findIndex(
      comment => comment.id === cpy.id
    );
    existComments.splice(index, 1);
    this.setState({ comments: existComments }, () =>
      console.log('after delete', this.state.comments)
    );
    this.props
      .deleteComment(comment.id)
      .then(res => console.log('got result from deleteComment', res))
      .catch(err => console.log('got error', err));
  }

  saveComment(text) {
    this.props
      .createUpdateComment(this.props.currentUser.id, this.props.editEntityId, {
        parentId: '',
        userId: this.props.currentUser.id,
        userName: this.props.currentUser.name,
        userProfileImg: this.props.currentUser.profilePictureUrl,
        createdAt: moment(new Date()).toDate(),
        body: text,
        email: this.props.currentUser.msjEmail,
        likes: '[]',
        postId: this.props.eventItem.id
      })
      .then(({ data }) => {
        console.log('got data from createUpdateComment', data);
        let tempComments = this.state.comments.slice();
        let newComment = data.createUpdateComment;
        tempComments.unshift(newComment);
        tempComments.pop();
        this.setState({ comments: tempComments }, () =>
          console.log('comments', this.state.comments)
        );
      })
      .catch(e => {
        console.warn(e);
      });
  }

  saveReplay(parentId, text) {
    this.props
      .createUpdateComment(this.props.currentUser.id, this.props.editEntityId, {
        parentId: parentId,
        userId: this.props.currentUser.id,
        userName: this.props.currentUser.username,
        userProfileImg: this.props.currentUser.profilePictureUrl,
        createdAt: Date.now().toString(),
        body: text,
        email: this.props.currentUser.msjEmail,
        postId: this.props.eventItem.id,
        likes: '[]'
      })
      .then(({ data }) => {
        console.log('got data from createUpdateComment', data);
      })
      .catch(e => {
        console.warn(e);
      });
  }

  extractUsername(c) {
    try {
      return c.userName !== '' ? c.userName : null;
    } catch (e) {
      console.log(e);
    }
  }

  extractBody(c) {
    try {
      return c.body && c.body !== '' ? c.body : null;
    } catch (e) {
      console.log(e);
    }
  }

  extractImage(c) {
    try {
      return c.image_id && c.user.image_id !== '' ? c.user.image_id : '';
    } catch (e) {
      console.log(e);
    }
  }

  extractChildrenCount(c) {
    try {
      return c.childrenCount || 0;
    } catch (e) {
      console.log(e);
    }
  }

  extractEditTime(item) {
    try {
      return item.updated_at;
    } catch (e) {
      console.log(e);
    }
  }

  extractCreatedTime(item) {
    try {
      return item.created_at;
    } catch (e) {
      console.log(e);
    }
  }

  likeExtractor(item) {
    if (!item.likes) return false;
    if (item.likes.length === 0) return false;
    let existLikes = [];
    if (typeof item.likes[0] === 'string')
      existLikes = [...JSON.parse(item.likes)];
    else existLikes = item.likes.slice();

    // console.log('this.props.currentUser.id',this.props.currentUser.id)
    let exist = existLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) return true;
    return false;
  }

  likeEventExtractor() {
    let eventLikes = JSON.parse(this.props.eventItem.likes);
    if (!eventLikes) return false;
    if (eventLikes.length === 0) return false;
    let existLikes = [];
    if (typeof eventLikes[0] === 'string')
      existLikes = [...JSON.parse(eventLikes)];
    else existLikes = eventLikes.slice();

    // console.log('this.props.currentUser.id',this.props.currentUser.id)
    let exist = existLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) return true;
    return false;
  }

  reportedExtractor(item) {
    return item.reported;
  }

  async likesExtractor(item) {
    let itemLikes = JSON.parse(item.likes);
    let users = [];
    if (itemLikes && itemLikes.length > 0) {
      itemLikes.map(like => {
        users.push({
          profilePictureUrl: like.userProfileImg,
          name: like.userName,
          likeType: like.type
        });
      });
    }
    return users;
  }

  isCommentChild(item) {
    console.log('item', item);
    console.log('isCommentChild', item.parentId !== '');
    return item.parentId !== null;
  }

  _openLink(link) {
    // Linking.canOpenURL(link).then(supported => {
    //   if (supported) {
    //     Linking.openURL(link);
    //   }
    // }).catch(() => null);
  }

  handleUrlPress(url) {
    this._openLink(url);
  }

  handlePhonePress(phone) {
    this._openLink(`tel:${phone}`);
  }

  handleEmailPress(email) {
    this._openLink(`mailto:${email}`);
  }

  async checkCalendarState(props) {
    if (this._calendarHandler) {
      let hasPermissions = false;

      try {
        const status = await this._calendarHandler.getCurrentStatus();
        // hasPermissions = status === AUTHORIZED;
      } catch (e) {
        hasPermissions = false;
      }

      if (hasPermissions) {
        const existingItem = await this._calendarHandler.getExistingEvent(
          props.eventItem.title,
          props.eventItem.exportStartTime,
          props.eventItem.exportEndTime
        );

        this.setState({
          isInCalendar: !!existingItem
        });
      } else {
        this.setState({
          isInCalendar: false
        });
      }
    }
  }

  toggleReminder() {
    console.log('inside toggleReminder');

    analytics.report('Feed', 'ToggleReminder', this.props.eventItem.id);

    if (this.props.eventItem.cancelled === true) {
      return;
    }

    this.props.popupReminderModal(!this.props.eventItem.userReminder);
    this.props.toggleReminder(this.props.currentUser.id, this.props.eventItem);
  }

  componentDidMount() {
    if (this._descriptionScroll) {
      this._descriptionScroll.flashScrollIndicators();
    }

    if (this.props.eventItem && this.props.extended) {
      this.checkCalendarState(this.props);
    }
  }

  async exportEvent() {
    if (this._calendarHandler) {
      analytics.report('Feed', 'ExportEvent', this.props.eventItem.id);

      const result = await this._calendarHandler.exportEvent(
        `${this.props.eventItem.cancelled ? '[CANCELLED]' : ''}${
          this.props.eventItem.title
        }`,
        this.props.eventItem.exportStartTime,
        this.props.eventItem.exportEndTime,
        this.props.eventItem.locationName,
        this.props.eventItem.description,
        this.props.eventItem.allDay
      );

      if (result && result.eventId) {
        alert(LangData.feedEventItem.export);
        this.setState({ isInCalendar: true });
      }
    }
  }

  openUrl(url) {
    const newsUrl = (url || '').trim();

    if (newsUrl === '') {
      return;
    }

    // Linking.openURL(newsUrl).catch(() => null);
  }

  prepareGender(gender) {
    if (!gender) {
      return null;
    }

    return gender.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  prepareType(type) {
    if (!type) {
      return null;
    }

    return type
      .replace(/_/g, ' ')
      .replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      )
      .replace(' And ', ' & ');
  }

  updatePagination(nextData) {
    if (nextData === 'up') {
      let nextSkip = this.props.skip + this.props.limit;
      this.props.updateFilter({ skip: nextSkip });
    } else {
      let newSkip = this.props.skip - this.props.limit;
      if (newSkip < 0) {
        newSkip = 0;
      }
      this.props.updateFilter({ skip: newSkip });
    }
  }

  resetFilter() {
    this.props.updateFilter({ skip: 0 });
  }

  openGalleryPhoto = (photo, index) => {
    let imagesObj = {
      images: this.props.eventItem.previousEventImages,
      firstIndex: index
    };
    this.props.openGalleryModal(imagesObj);
    this.props.history.push('/imageGalleryViewerModal');
    // this.props.toggleComponent('ImageGalleryViewerModal');
  };

  render() {
    const { eventItem } = this.props;
    let showReminderBell = null;
    let isNew = null;
    let isSportsItem = null;
    if (eventItem) {
      showReminderBell = moment(
        eventItem.startTime,
        'ddd, MMM D | hh:mm A'
      ).isAfter(moment());

      isNew = eventItem.isNew;
      isSportsItem = eventItem.__typename === 'NewsFeedSportItem';
    }

    // const showList =
    //   this.props.comments &&
    //   this.props.comments.allComments &&
    //   this.props.comments.allComments.items;

    // if (!showList) {
    //   return (
    //     <div style={{ width: '100%' }}>
    //       <LoadingSpinner />
    //     </div>
    //   );
    // }

    return (
      <Fragment>
        <div style={styles.mainContainer}>
          <div style={styles.container}>
            <div
              style={
                eventItem.posterImageUrl
                  ? { flex: 1.65, display: 'flex', flexDirection: 'column' }
                  : { flexGrow: 1 }
              }
            >
              <div style={styles.titleContainer}>
                <ProfileImage
                  height={35}
                  width={35}
                  withFullSize={false}
                  imageUrl={eventItem.author.profilePictureUrl}
                />
                {/* {!eventItem.posterImageUrl &&
                  (isSportsItem && eventItem.type ? (
                    <span>icon event type</span>
                  ) : (
                    <CategoryImage category={eventItem.mainCategory} />
                  ))} */}
                <div style={styles.titleContainerNew}>
                  <span style={styles.titleText}>{eventItem.title}</span>
                  <span style={styles.dateText}>{eventItem.displayCreationDate}</span>
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'column',
                  flexGrow: 1,
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}
              >
                <div style={styles.descriptionContainer}>
                  {eventItem.description}
                </div>
                {eventItem.link && isSportsItem ? (
                  <span
                    style={styles.linkText}
                    onClick={() => this.openUrl(eventItem.link)}
                  >
                    {LangData.feedEventItem.info}
                  </span>
                ) : null}
                <div>
                  {eventItem.previousEventImages &&
                    eventItem.previousEventImages.length > 0 && (
                      <div style={styles.detailsRow}>
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          style={styles.iconMargin}
                        />
                        <span style={styles.detailsText}>
                          {eventItem.previousEventDate
                            ? LangData.feedEventItem.last
                            : ''}
                        </span>
                        <div style={styles.previousEventImagesSection}>
                          {(eventItem.previousEventImagesThumbs || [])
                            .slice(0, 3)
                            .map((image, i) => (
                              <div
                                key={image}
                                onClick={e => {
                                  e.stopPropagation();
                                  this.openGalleryPhoto(image, i);
                                  if (this.props.onLastImagesClicked) {
                                    this.props.onLastImagesClicked({
                                      title: eventItem.title,
                                      images: eventItem.previousEventImages,
                                      date: eventItem.previousEventDate,
                                      startIndex: i
                                    });
                                  }
                                }}
                              >
                                <img
                                  src={image}
                                  style={styles.previousEventImage}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  {eventItem.locationName ? (
                    <div style={styles.detailsRow}>
                      <FontAwesomeIcon
                        icon={faMapMarkedAlt}
                        style={styles.iconMargin}
                      />
                      <span style={styles.detailsText}>
                        {eventItem.locationName}
                      </span>
                    </div>
                  ) : null}
                  {eventItem.whoToContact && this.props.extended ? (
                    <div style={styles.detailsRow}>
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={styles.iconMargin}
                      />
                      <span style={styles.detailsText}>
                        {LangData.feedEventItem.contact1 +
                          eventItem.whoToContact +
                          LangData.feedEventItem.contact2}
                      </span>
                    </div>
                  ) : null}
                  <div style={styles.detailsRow}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={styles.iconMargin}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={styles.detailsBoldText}>
                        {LangData.feedEventItem.start}{' '}
                        <span style={styles.detailsText}>
                          {this.props.eventItem.startTime}
                        </span>
                      </span>
                      {eventItem.endTime ? (
                        <span style={styles.detailsBoldText}>
                          {LangData.feedEventItem.end}{' '}
                          <span style={styles.detailsText}>
                            {eventItem.endTime}
                          </span>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {this.props.extended && (
                    <div style={styles.detailsRow}>
                      <FontAwesomeIcon
                        icon={faCalendarPlus}
                        style={Object.assign(
                          { alignSelf: 'baseline' },
                          styles.iconMargin
                        )}
                      />
                      <div style={styles.detailsText}>
                        <CalendarExportHandler
                          event={{
                            title: this.props.eventItem.title,
                            description: this.props.eventItem.description,
                            location: this.props.eventItem.locationName,
                            startTime: this.props.eventItem.exportStartTime,
                            endTime: this.props.eventItem.exportEndTime
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: 'flex-start',
                flexShrink: 1,
               
              }}
            >
              {showReminderBell && (
                <ReminderBell
                  style={styles.bell}
                  colored={eventItem.userReminder}
                  onPress={() => this.toggleReminder()}
                  show={showReminderBell}
                />
              )}
              {this.props.extended && !this.state.isInCalendar ? (
                <div
                  onClick={() => this.exportEvent()}
                  style={{ marginTop: 10, alignItems: 'center' }}
                >
                  <FontAwesomeIcon
                    icon={faCaretSquareUp}
                    style={styles.iconMargin}
                  />
                </div>
              ) : (
                <div style={{ width: isSportsItem ? 0 : 15 }} />
              )}
            </div>
            {eventItem.cancelled ? (
              <div style={styles.cancelBackdrop}>
                <div style={styles.cancelContainer}>
                  <span style={styles.cancelText}>
                    {LangData.feedEventItem.cancel}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {eventItem.thumbUrl && this.props.showComments && (
          <div
            style={styles.eventPosterContainer}
            onClick={e => {
              e.stopPropagation();
              if (this.props.onPosterClicked) {
                this.props.onPosterClicked(eventItem.posterImageUrl);
              }
            }}
          >
            {/* <div style={styles.category}>
                  <CategoryImage category={eventItem.mainCategory} />
                </div> */}
            <img style={styles.eventImage} src={eventItem.thumbUrl} />
          </div>
        )}
        {this.props.showComments && (
          <Comments
            showShare={true}
            setAlert={this.props.setAlert}
            data={this.props.eventItem.comments}
            postId={this.props.eventItem.id}
            eventLikes={this.props.eventItem.likes}
            // usersWhoLikedTheEvent={this.getUsers(this.props.eventItem.likes)}
            viewingUserName={this.props.currentUser}
            childPropName={'children'}
            isChild={item => this.isCommentChild(item)}
            //Extract the key indicating comments parent
            parentIdExtractor={item => item.parentId}
            //what prop holds the comment owners username
            usernameExtractor={item => this.extractUsername(item)}
            //when was the comment last time edited
            editTimeExtractor={item => this.extractEditTime(item)}
            //When was the comment created
            createdTimeExtractor={item => this.extractCreatedTime(item)}
            //where is the body
            bodyExtractor={item => this.extractBody(item)}
            //where is the user image
            imageExtractor={item => this.extractImage(item)}
            //Where to look to see if user liked comment
            likeExtractor={item => this.likeExtractor(item)}
            //Where to look to see if user reported comment
            reportedExtractor={item => this.reportedExtractor(item)}
            //Where to find array with user likes
            likesExtractor={item => this.likesExtractor(item)}
            likesEventsExtractUsers={() =>
              this.likesExtractor(this.props.eventItem)
            }
            likeEventExtractor={() => this.likeEventExtractor()}
            //what to do when user clicks reply. Usually its header height + position (b/c scroll list heights are relative)
            replyAction={offset => {
              this.refs.scrollView.scrollTo({
                x: null,
                y: this.scrollIndex + offset - 300,
                animated: true
              });
            }}
            //what to do when user clicks submits edited comment
            saveAction={(text, parentCommentId) => {
              let date = moment().format('YYYY-MM-DD H:mm:ss');
              console.log('comments before save', this.state.comments);

              if (!parentCommentId) {
                this.saveComment(text);

                // this.refs.scrollView.scrollToEnd();
              } else {
                this.saveReplay(parentCommentId, text);
              }
            }}
            //what to do when user clicks submits edited comment
            editAction={(text, comment) => {
              this.updateComments('edit', comment, text);
            }}
            //what to do when user clicks report submit
            reportAction={comment => {
              let comments = this.actions.report(this.state.comments, comment);
              this.setState({
                comments: comments
              });
            }}
            //what to do when user clicks like
            likeAction={comment => {
              console.log('comment', comment);
              this.updateComments('likes', comment, '');
              this.actions.like(this.state.comments, comment);
            }}
            // like event
            likeEventPost={type => {
              this.updateEventLikes(type);
            }}
            //what to do when user clicks like
            deleteAction={comment => {
              this.deleteComment(comment);
            }}
            //Must return promise
            paginateAction={(from_comment_id, direction, parent_comment_id) => {
              //Must return array of new comments after pagination
              console.log('direction', direction);
              this.updatePagination(direction);
              console.log('comments:', this.props.comments.allComments.items);
            }}
          />
        )}
      </Fragment>
    );
  }
}

const styles = {
  mainContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    padding: '1%'
  },
  container: {
    display: 'flex',
    maxHeight: 250,
    minHeight: 140,
    flexDirection: 'row',
    alignSelf: 'stretch',
    width: '100%'
  },
  commentsContainer: {
    display: 'flex',
    // maxHeight: 500,
    // minHeight: 300,
    width: '100%',
    flexDirection: 'row',
    alignSelf: 'stretch'
  },
  posterContainer: {
    flex: 0.35
  },
  cancelText: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontSize: 35,
    fontWeight: 'bold'
  },
  cancelContainer: {
    borderRadius: 35,
    borderWidth: 3,
    borderColor: Theme.SECONDARY_TEXT_COLOR,
    backgroundColor: 'rgba(255,255,255,0.6)',
    paddingHorizontal: 15,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    transform: [
      {
        rotate: '-10deg'
      }
    ]
  },
  cancelBackdrop: {
    borderRadius: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.6)',
    zIndex: 100
  },
  eventPosterContainer: {
    flex: 0.55,
    paddingRight: 10,
    display: 'flex'
  },
  eventImage: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    borderRadius: 10
  },
  previousEventImage: {
    width: 30,
    height: 30,
    marginRight: 8,
    borderRadius: 5
  },
  previousEventImagesSection: {
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  titleText: {
    color: Theme.BLACK_COLOR,
    fontSize: 17,
    marginBottom: 0,
    marginTop: 0,
    letterSpacing: "0.2px",
    fontWeight:'bold'
  },
  sportsTitleText: {
    color: Theme.BLACK_COLOR,
    fontSize: 17,
    fontWeight: 'bold'
  },
  sportsTitleSubText: {
    color: Theme.BLACK_COLOR,
    fontSize: 12,
    fontFamily: 'Assistant'
  },
  bell: {
    alignSelf: 'flex-end',
    padding: 3
  },
  detailsRow: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5
  },
  detailsIcon: {
    marginRight: 5
  },
  detailsText: {
    fontSize: 15,
    color: Theme.BLACK_COLOR,
    fontWeight: '100',
    paddingRight: 5
  },
  detailsBoldText: {
    fontWeight: 'bold',
    fontSize: 15,
    color: Theme.BLACK_COLOR,
    paddingRight: 5
  },
  descriptionText: {
    fontSize: 15,
    color: Theme.BLACK_COLOR,
    fontWeight: 'bold'
  },
  descriptionContainer: {
    marginTop: 5,
    marginBottom: 7,
    fontSize: '15px',
    color: Theme.BLACK_COLOR,
    maxHeight: window.innerWidth / 8.181,
    overflowY: 'scroll'
  },
  category: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    backgroundColor: 'rgba(255,255,255,0.5)',
    borderRadius: 25
  },
  linkText: {
    marginLeft: 2,
    fontSize: 17,
    color: 'white',
    fontWeight: 'bold',
    marginTop: 5
  },
  iconMargin: {
    marginRight: '2%',
    color: Theme.BLACK_COLOR
  },
  titleContainerNew: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5%'
  },
  dateText:{
    fontSize: 12,
    color: Theme.BLACK_COLOR,
    marginTop: 0,
    letterSpacing: "0.2px"
  }
};
