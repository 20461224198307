import { Video } from './video.component';
import { connect } from 'react-redux';
import { setUser } from '../../auth/auth.actions';

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser
});

const mapDispatch = dispatch => ({
    setUser: user => dispatch(setUser(user)),
});

const withState = connect(mapStateToProps, mapDispatch);

export const VideoContainer = withState(Video);
