import gql from 'graphql-tag';

export const updateVideoFinishedMutation = gql`
  mutation updateVideoFinished(
    $videoLogId: ID
  ) {
    updateVideoFinished(
        videoLogId: $videoLogId,
    ) {
        _id
    }
  }
`;
