import gql from 'graphql-tag';

export const cloudinaryConfigQuery = gql`
  query cloudinaryConfig {
      cloudinaryConfig {
          cloudName
          uploadUrl
          presetName
      }
  }
`;
