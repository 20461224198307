import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo-temp';
import { InstagramAuthModal } from './instagram-auth-view.rn';
import { startInstagramAuth } from '../instagram.actions';
import { reloadUserProfile } from '../../auth/auth.actions';
import { instagramDetailsQuery } from '../graphql/instagram-details.query';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';

const mapStateToProps = ({ auth }) => ({
  accessToken: auth.accessToken
});

const mapDispatchToProps = dispatch => ({
  startAuth: () => dispatch(startInstagramAuth()),
  authDone: () => dispatch(reloadUserProfile('instagram'))
});

const withData = graphql(instagramDetailsQuery, {
  name: 'instagram',
  // withRef: true,
  props: ({ instagram }) => {
    if (instagram.loading || instagram.error) {
      return {};
    }

    const { clientId, returnUrl } = instagram.instagramAuth;

    return {
      clientId,
      returnUrl
    };
  }
});

const withError = withErrorHandler({
  apollo: ['instagram']
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const InstagramAuth = compose(
  withState,
  withData,
  withError
)(InstagramAuthModal);
