import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ProfileImage from '../../../../common/components/profile-image.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faThumbsUp,
  faPrayingHands
} from '@fortawesome/free-solid-svg-icons';
import { Animated } from 'react-animated-css';
import { Theme } from '../../../../common/providers/colors';

export class FeedEventLikesUsersListModal extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  renderUsersListItem(item, index) {
    return (
      <div style={styles.userItem} key={index}>
        <div style={styles.profileImage}>
          <ProfileImage
            showBorder={true}
            withFullSize={false}
            height={50}
            width={50}
            imageUrl={item.profilePictureUrl}
          />
          {
            item.likeType === 'namaste' && (
            <FontAwesomeIcon icon={faPrayingHands} style={styles.likesIcon} />
          )}
          {
            item.likeType === 'like' && (
            <FontAwesomeIcon icon={faThumbsUp} style={styles.likesIcon} />
          )}
          {
            item.likeType === 'love' && (
              <img 
                alt=""
                src={require('../../../../assets/images/Love.png')}
                style={styles.likesIcon}
              />         
          )}
          {
            item.likeType === 'happy' && (
              <img 
                alt=""
                src={require('../../../../assets/images/Happy.png')}
                style={styles.likesIcon}
              /> 
          )}
          {
            item.likeType === 'sad' && (
              <img 
                alt=""
                src={require('../../../../assets/images/Sad.png')}
                style={styles.likesIcon}
              /> 
            )}
        </div>

        <div style={styles.details}>
          <p style={styles.name}>{item.name}</p>
          {item.major && item.major !== '' ? (
            <p style={styles.major}>{item.major}</p>
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.props.isOpen ? (
          <div
            style={styles.backgroundWaitApprove}
            onClick={e => e.stopPropagation()}
          >
           <Animated animationIn='pulse' style={{ width: '85%', height: '94%' }}>
              <div
                disabled={true}
                onClose={() => this.props.onClose()}
                style={styles.modal}
              >
                <div
                  style={styles.closeStyle}
                  onClick={() => this.props.onClose()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>

                <div style={styles.likesUsersContainer}>
                {this.props.users.map((item, index) => {
                  return this.renderUsersListItem(item, index);
                })}
                </div>
              </div>
            </Animated>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const styles = {
  likesUsersContainer:  {
    height: '80%',
    overflowY: 'scroll'
  },
  userItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    display: 'flex'
  },
  details: {
    marginLeft: 10
  },
  list: {
    flexGrow: 1
  },
  name: {
    fontWeight: 'bold',
    color: Theme.TEXT_COLOR
  },
  major: {
    color: Theme.TEXT_COLOR,
    fontSize: 12
  },
  backgroundWaitApprove: {
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'fixed',
    opacity: '0.97',
    zIndex: '9999999',
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '-1%',
    top: 0
  },
  modal: {
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: window.innerWidth / 15 + 'px',
    marginBottom: window.innerWidth / 18.75 + 'px',
    color: Theme.TEXT_COLOR,
    width: '95%',
    height: '60%',
    padding: '3%'
  },
  likesIcon: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    color: 'white',
    padding: '16%',
    borderRadius: '50%',
    fontSize: '10px',
    marginRight: '1%',
    width: '15px',
    height: '15px',
    position: 'absolute',
    bottom: '0',
    left: window.innerWidth / 12
  },
  profileImage: {
    position: 'relative',
    marginRight: '3%'
  }
};
