import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo-temp';
import { TwitterAuthModal } from './twitter-auth-view.rn';
import { startTwitterAuth } from '../twitter.actions';
import { reloadUserProfile } from '../../auth/auth.actions';
import { twitterDetailsQuery } from '../graphql/twitter-details.query';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';

const mapStateToProps = ({ auth }) => ({
  accessToken: auth.accessToken
});

const mapDispatchToProps = dispatch => ({
  startAuth: () => dispatch(startTwitterAuth()),
  authDone: () => dispatch(reloadUserProfile('twitter'))
});

const withData = graphql(twitterDetailsQuery, {
  name: 'twitter',
  options: {
    fetchPolicy: 'network-only'
  },
  // withRef: true,
  props: ({ twitter }) => {
    if (twitter.loading || twitter.error) {
      return {};
    }

    return {
      redirectUrl: twitter.twitterAuth.redirectUrl,
      returnUrl: twitter.twitterAuth.returnUrl
    };
  }
});

const withError = withErrorHandler({
  apollo: ['twitter']
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const TwitterAuth = compose(
  withState,
  withData,
  withError
)(TwitterAuthModal);
