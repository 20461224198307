import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const removeConnectionMutation = gql`
  mutation removeConnection($currentUserId: ID, $userId: String!) {
    removeConnection(currentUserId:$currentUserId, userId: $userId) {
      ...ClassmateItem
    }
  }

  ${classmateItemFragment}
`;
