import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { Theme } from '../../common/providers/colors';

export class UsersPickerView extends Component {
  static propTypes = {
    updateFilter: PropTypes.func,
    onItemChange: PropTypes.func,
    checkedItems: PropTypes.array,
    search: PropTypes.string,
    skip: PropTypes.number,
    limit: PropTypes.number,
    students: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allStudents: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  constructor(props) {
    super(props);
    this.ifExist = false;
    this.checkboxesStatus = [];
  }

  buildCheckBoxObject() {
    console.log(
      ' this.props.students.allStudents.items',
      this.props.students.allStudents.items
    );
    let checkboxObject = [];
    let tempObj = {};
    this.props.students.allStudents.items.map((student, index) => {
      tempObj.student = student;
      tempObj.isChecked = false;
      tempObj.index = index;
      checkboxObject.push(tempObj);
      tempObj = {};
    });
    console.log('checkboxObject', checkboxObject);
    this.ifExist = true;
    this.checkboxesStatus = checkboxObject;
    return checkboxObject;
  }

  handleChange(studentId) {
    if (this.props.checkedItems.includes('users')) {
      let ind = this.checkboxesStatus.filter(
        element => element.student.id === studentId
      )[0].index;
      this.checkboxesStatus[ind].isChecked = !this.checkboxesStatus[ind]
        .isChecked;
      this.props.onItemChanged(
        `user_${studentId}`,
        this.checkboxesStatus[ind].isChecked
      );
    } else alert('Please choose users');
  }

  render() {
    const showList =
      this.props.students &&
      this.props.students.allStudents &&
      this.props.students.allStudents.items;

    if (!showList) {
      return <LoadingSpinner />;
    }

    if (showList && !this.ifExist) this.buildCheckBoxObject();

    return (
      <div style={styles.mainContainer}>
        <TextInput
          type='text'
          // style={styles.inputStyle}
          placeholder={'Find Participants...'}
          onChange={e =>
            this.props.updateFilter({ skip: 0, search: e.target.value })
          }
          value={this.props.search}
        />

        {showList
          ? this.props.students.allStudents.items.map((student, key) => (
              <div key={key} style={{ display: 'flex' }}>
                <StyledCheckbox
                  checked={this.props.checkedItems.includes(
                    `user_${student.id}`
                  )}
                  color='primary'
                  onChange={e =>
                    this.props.onItemChange(
                      `user_${student.id}`,
                      e.target.checked
                    )
                  }
                />

                <p style={styles.studentName}>{student.name}</p>
              </div>
            ))
          : null}
      </div>
    );
  }
}

const styles = {
  mainContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15%'
  },
  inputStyle: {
    height: '35px',
    width: '100%',
    border: 'none',
    paddingLeft: '10px',
    borderRadius: '10px',
    fontFamily: 'inherit'
  },
  studentName: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 14
  }
};
const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);

const TextInput = styled.input`
  height: 35px;
  width: 100%;
  border: none;
  padding-left: 10px;
  border-radius: 10px;
  font-family: inherit;

  &:focus {
    outline: none;
    border: none;
  }
`;
