import React, { Component, Fragment } from 'react';
import { LangData } from '../../../../assets/locales/localization';
import { Theme } from '../../../../common/providers/colors';

const FirstTimeReminderModal = props => {
  return (
    <Fragment>
      {props.isOpen ? (
        <div
          isOpen={props.isOpen}
          onRequestClose={() => props.onClose()}
          style={styles.backgroundContainer}
          id='FirstTimeReminderModal background'
        >
          <div style={styles.container}>
            <p style={styles.title}>
              {LangData.firstTimeReminderModal.congratulations}
            </p>
            <img
              style={{ width: 80, height: 80 }}
              src={require('../../../../assets/images/specialEvents.png')}
            />
            <p style={styles.text}>{LangData.firstTimeReminderModal.first}</p>
            <p style={styles.smallText}>
              {LangData.firstTimeReminderModal.push}
            </p>
            <div style={styles.row}>
              <button style={styles.btn} onClick={() => props.onClose()}>
                {LangData.firstTimeReminderModal.close}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default FirstTimeReminderModal;

const styles = {
  backgroundContainer: {
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'fixed',
    opacity: '0.97',
    zIndex: '9999999',
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
  },
  title: {
    maxWidth: 250,
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center'
  },
  text: {
    marginTop: 15,
    fontSize: 14,
    maxWidth: 250,
    textAlign: 'center'
  },
  smallText: {
    marginTop: 15,
    fontSize: 12,
    maxWidth: 250,
    textAlign: 'center'
  },
  row: {
    flexDirection: 'row',
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  btn: {
    color: 'white',
    fontFamily: 'inherit',
    padding: '10%',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    border: 'none',
    borderRadius: '10px',
    width: '100%'
  }
};
