import gql from 'graphql-tag';

export const createNewChatMutation = gql`
  mutation createNewChat(
    $userId: ID, 
    $title: String
    $users: [String!]!
    $createIfNotExists: Boolean
  ) {
    createNewChat(
      userId: $userId,
      title: $title
      users: $users
      createIfNotExists: $createIfNotExists
    ) {
      id
      title
      unreadMessagesCount
      createdAt
      lastMessage {
        createdAt
        content
      }
      users {
        name
        id
        profilePictureUrl
      }
    }
  }
`;
