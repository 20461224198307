import { connect } from 'react-redux';
import { doEnroll } from '../snapchat.actions';
import { EnrollmentFormView } from './enrollment-form-view.rn.js';

const mapDispatchToProps = dispatch => ({
  doEnroll: (userId, username) => dispatch(doEnroll(userId, username))
});

const mapProps = ({ auth }) => ({
  user: auth.currentUser
});

const withState = connect(mapProps, mapDispatchToProps, null);

export const EnrollmentForm = withState(EnrollmentFormView);
