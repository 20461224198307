import ProfilePictureMenuView from './profile-picture-menu.rn';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { cancelProfilePictureApproval } from '../../graphql/cancel-profile-pic-approval.mutation';
import { updateProfilePictureMutation } from '../../graphql/update-profile-picture.mutation';
import { reloadUserProfile } from '../../../auth/auth.actions';
import {
  changeProfilePictureStarted,
  setRejectionReason
} from '../../profile.actions';
import { graphql } from 'react-apollo-temp';
// import { togglePermissionsErrorModal } from '../../../main/main.actions';
// import { withPermissionsState } from '../../../notification/hoc/with-permissions-state';

const mapDispatchToProps = dispatch => ({
  // openPermissionsErrorModal: type => dispatch(togglePermissionsErrorModal(true, type)),
  reloadUserProfile: () => dispatch(reloadUserProfile('profile-picture')),
  setProfilePictureRejected: reason => dispatch(setRejectionReason(reason)),
  changeProfilePictureStarted: isCancel =>
    dispatch(changeProfilePictureStarted(isCancel))
});

const mapStateToProps = ({ profile, auth }) => ({
  isLoading: profile.profilePictureLoading,
  rejectionReason: profile.rejectionReason,
  currentUser: auth.currentUser
});

const withMutation = graphql(updateProfilePictureMutation, {
  props: ({ ownProps, mutate }) => ({
    uploadImage: (userId, imageId) => {
      ownProps.changeProfilePictureStarted();

      return mutate({ variables: { userId, imageId } })
        .catch(() => null)
        .then(() => ownProps.reloadUserProfile());
    }
  })
});

const withCancelMutation = graphql(cancelProfilePictureApproval, {
  props: ({ ownProps, mutate }) => ({
    cancel: (userId) => {
      ownProps.changeProfilePictureStarted();

      return mutate({ variables: { userId } })
        .catch(() => null)
        .then(() => ownProps.reloadUserProfile());
    }
  })
});

const withState = connect(mapStateToProps, mapDispatchToProps);

export const ProfilePictureMenu = compose(
  withState,
  withMutation,
  withCancelMutation
)(ProfilePictureMenuView);
