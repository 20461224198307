import React, { Component, Fragment } from 'react';
import { analytics } from '../../analytics/report';
import ErrorMessage from './error-message.rn';
import { accountsPassword } from '../../account/account.init';
import { LangData } from '../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faCheckCircle,
  faGripHorizontal,
  faLock,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import logoSrc from '../../assets/images/LogoDark.png';
import { Animated } from 'react-animated-css';
import { resetPassword, updateUserLogin } from '../../account/account.util';
import { isAndroid, isIOS } from 'react-device-detect';
import { Theme } from '../../common/providers/colors';
import styled from 'styled-components';
const width = window.innerWidth;

export class ForgotPasswordScreen extends Component {
  state = {
    phone: '',
    error: null,
    loading: false,
    showSuccessModal: false,
    showCode: false,
    code: ''
  };

  requestPasswordReset = async () => {
    analytics.report('Auth', 'Request Reset Password');
    let { phone } = this.state;

    this.setState({ loading: true });
    if (!phone.includes('+')) {
      phone = '+1' + phone;
    }
    let email = await getEmail({ phoneNumber: phone });
    if (email !== '') {
      try {
        await accountsPassword.requestPasswordReset(email);
        this.setState({ error: null, loading: false, showCode: true });
      } catch (err) {
        console.log(err);
        this.setState({ error: 'error', loading: false });
      }
    } else {
      this.setState({
        error: 'user was not found',
        loading: false
      });
    }
  };

  submitCode = async () => {
    this.setState({ loading: true });
    let phone = this.state.phone;
    if (!phone.includes('+')) {
      phone = '+1' + phone;
    }
    let res = await getToken({ phoneNumber: phone });
    if (res && res.loginCode) {
      if (this.state.code === res.loginCode) {
        try {
          analytics.report('Auth', 'Login');
          await localStorage.setItem('userId', res.userId)
          updateUserLogin()



          // await resetPassword(res.loginToken, this.state.code);
          this.setState({ error: null, loading: false });
          this.props.history.push('/');
        } catch (err) {
          console.log(err);
          this.setState({
            error: LangData.changePasswordScreen.wrong,
            loading: false
          });
        }
      }
      else {
        this.setState({
          error: 'Wrong verification code. Please try again',
          loading: false
        });
      }
    }
  };

  closeModal = () => this.setState({ showSuccessModal: false });

  render() {
    const { phone, error, loading, showSuccessModal, showCode } = this.state;

    return (
      <Fragment>
        {!window.matchMedia('(display-mode: standalone)').matches &&
          // isAndroid &&
          this.props.showInstallPage &&
          this.props.history.push('/installApp')}
        {this.props.user && this.props.history.push('/')}
        {error && (
          <ErrorMessage
            message={error}
            onPress={() => {
              this.setState({ error: null });
            }}
          />
        )}

        {(loading || this.props.loading) && <LoadingSpinner />}
        <div style={styles.mainContainer}>
          <div style={styles.formContainer}>
            <img src={logoSrc} alt='logo' style={styles.logoImg} />
            <h2 style={styles.signIn}>
              {LangData.forgotPasswordScreen.signIn}
            </h2>
            <div style={styles.hr} />
            <p style={styles.description}>
              {showCode
                ? LangData.forgotPasswordScreen.descriptionB
                : LangData.forgotPasswordScreen.descriptionA}
            </p>
            <div style={styles.inputDiv}>
              <label style={styles.inputDiv.label}>

                <TextInput
                  disabled={this.state.showCode}
                  type='text'
                  onChange={e => this.setState({ phone: e.target.value })}
                  value={phone}
                  placeholder={LangData.forgotPasswordScreen.phone}
                />
              </label>
            </div>
            {
              showCode ?
                <div style={styles.inputDiv}>
                  <label style={styles.inputDiv.label}>
                    <TextInput
                      type='text'
                      onChange={e => this.setState({ code: e.target.value })}
                      value={this.state.code}
                      placeholder={LangData.forgotPasswordScreen.code}
                      id='single-factor-code-text-field'
                      autocomplete='one-time-code'
                    />
                  </label>
                </div>
                : null
            }

            <button
              disabled={ loading || !phone}
              onClick={
                showCode
                  ? () => this.submitCode()
                  : () => this.requestPasswordReset()
              }
              style={
                error || loading || !phone
                  ? styles.submitBtnDis
                  : styles.submitBtn
              }
            >
              {showCode
                ? LangData.forgotPasswordScreen.submit
                : LangData.forgotPasswordScreen.change}
            </button>
            {
              showCode && <div style={styles.resendCode} onClick={this.requestPasswordReset}>Resend verification code</div>
            }
          </div>
          {/* <div style={styles.SignUpNow}>Don't have an account? <strong>Sign Up Now</strong></div> */}
        </div>

        {showSuccessModal && (
          <PopupMessage
            onPress={() => {
              this.setState({ showSuccessModal: false });
            }}
          />
        )}
      </Fragment>
    );
  }
}

const PopupMessage = props => {
  return (
    <div style={styles.fullPage}>
      <Animated
        animationIn='pulse'
        animationOut='fadeOut'
        isVisible={props.error}
      >
        <div style={styles.msgContainer}>
          <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
          <p>{LangData.forgotPasswordScreen.done}</p>
          <button style={styles.btn} onClick={props.onPress}>
            {LangData.forgotPasswordScreen.close}
          </button>
        </div>
      </Animated>
    </div>
  );
};

const styles = {
  mainContainer: {
    height: window.innerHeight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${Background})`,
    // backgroundImage: `linear-gradient(rgba(100,100,100,0.8), rgba(100,100,100,0.8)), url(${Background})`
    backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.95), rgb(237,204,181,0.95))',
    // backgroundImage: `url(${welcomeScreenBackgroundImage})`,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: Theme.TEXT_COLOR,
    width: width < 500 ? '100%' : '80%',
    margin: width < 500 ? '0' : '5% auto'
  },
  logoImg: {
    margin: width < 500 ? '20% 0' : '5% 0',
    width: width < 500 ? '50%' : '30%'
  },
  inputDiv: {
    borderRadius: window.innerWidth / 12 + 'px',
    padding: width < 500 ? '5%' : '2.5%',
    width: '75%',
    marginBottom: '2%',
    color: '#4d4b62',
    border: '3px solid #4d4b62',
    label: {
      display: 'flex',
      alignItems: 'center',
      width: '96%',
      border: 'none',
      color: '#4d4b62'
    },
    input: {
      border: 'none',
      width: '100%',
      backgroundColor: 'transparent',
      fontFamily: 'inherit',
      marginLeft: '3%',
      fontSize: width < 500 ? '15px' : '22px',
      color: '#4d4b62'
    }
  },
  submitBtn: {
    backgroundColor: '#4d4b62',
    color: Theme.WHITE_COLOR,
    textAlign: 'center',
    padding: width < 500?'5%':'3%',
    fontFamily: 'inherit',
    fontSize: width < 500 ? '15px' : '20px',
    border: 'none',
    width: width < 500 ?'85%':'60%',
    marginTop: '5%',
    borderRadius: '30px',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)'
  },
  submitBtnDis: {
    backgroundColor: '#4d4b62',
    color: Theme.WHITE_COLOR,
    textAlign: 'center',
    padding: width < 500 ? '5%' : '3%',
    fontFamily: 'inherit',
    border: 'none',
    fontSize: width < 500 ? '15px' : '20px',
    width: width < 500 ? '85%' : '60%',
    marginTop: '5%',
    borderRadius: '30px',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)'
  },
  fullPage: {
    minHeight: window.innerHeight,
    width: window.innerWidth,
    opacity: '0.9',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '999999',
    textAlign: 'center',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0'
  },
  msgContainer: {
    height: window.innerWidth / 2 + 'px',
    width: window.innerWidth / 2 + 'px',
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  btn: {
    color: Theme.TEXT_COLOR,
    backgroundColor: Theme.BACKGROUND_COLOR,
    border: 'none',
    padding: '7%',
    fontWeight: 'bold',
    fontSize: window.innerWidth / 24 + 'px',
    fontFamily: 'inherit',
    width: '100%'
  },
  icon: {
    color: Theme.BACKGROUND_COLOR,
    fontSize: window.innerWidth / 7.2 + 'px',
    marginTop: '5%'
  },
  description: {
    width: '80%',
    color: '#4d4b62',
    fontWeight: 'bold',
    fontSize: '17px',
    letterSpacing: '1.4px',
  },
  signIn: {
    alignSelf: 'end',
    marginLeft: '11%',
    color: '#4d4b62',
    fontWeight: 'bold',
    fontSize: '35px',
    letterSpacing: '1.05px',
    marginBottom: '0%',
    fontWeight: 'bold'
  },
  hr: {
    width: '10%',
    borderBottom: '5px solid #4d4b62',
    alignSelf: 'end',
    marginLeft: '11%',
    borderRadius: '10px'
  },
  SignUpNow: {
    margin: 'auto',
    color: '#4d4b62'
  },
  resendCode: {
    margin: 'auto',
    marginTop: '5%',
    color: '#4d4b62'
  },
};

export const getEmail = variables =>
  fetch('https://Be.YOCAS.life:4000/graphql', {
  // fetch('http://localhost:4000/graphql', {
    method: 'POST',
    body: JSON.stringify({
      query: `
      mutation emailByPhone($phoneNumber: String) {
        emailByPhone(phoneNumber: $phoneNumber)
      }
    `,
      variables
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(res => res.data.emailByPhone);

export const getToken = variables =>
  fetch('https://Be.YOCAS.life:4000/graphql', {
    // fetch('http://localhost:4000/graphql', {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation tokenByPhone($phoneNumber: String) {
          tokenByPhone(phoneNumber: $phoneNumber){
            loginToken
            loginCode
            userId
          }
        }
      `,
      variables,
      fetchPolicy: 'network-only'
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(res => res.data.tokenByPhone);

const TextInput = styled.input`
  color: #4d4b62;
  font-size: ${width < 500 ? '15px' : '20px'};
  width: 100%;
  font-family: inherit;
  border: none;
  background-color: transparent;
  margin-left: 3%;

  &:focus {
    outline: none;
    border: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #4d4b62;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #4d4b62;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #4d4b62;
  }
`;
