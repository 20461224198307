import { connect } from 'react-redux';
import { cancelEnroll, doVerify, setCode } from '../snapchat.actions';
import { VerifyCodeView } from './verify-code-view.rn';

const mapDispatchToProps = dispatch => ({
  doVerify: (userId, code) => dispatch(doVerify(userId, code)),
  cancelEnroll: () => dispatch(cancelEnroll()),
  setCode: codeArray => dispatch(setCode(codeArray))
});

const mapProps = ({ snapchat, auth }) => ({
  verificationError: snapchat.verificationError !== null,
  codeItems: snapchat.verificationCode, 
  currentUser: auth.currentUser,
});

const withState = connect(mapProps, mapDispatchToProps, null);

export const VerifyCode = withState(VerifyCodeView);
