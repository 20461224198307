import { combineReducers } from 'redux';
import { ESnapchatActions } from './snapchat.actions';
import { EAuthActions } from '../auth/auth.actions';

const enrollmentError = (state = null, action) => {
  switch (action.type) {
    case ESnapchatActions.ENROLL_START:
    case ESnapchatActions.ENROLL_START_DONE:
      return null;
    case ESnapchatActions.ENROLL_START_ERROR:
      return action.error;
    default: {
      return state;
    }
  }
};

const verificationError = (state = null, action) => {
  switch (action.type) {
    case ESnapchatActions.VERIFICATION_START:
    case ESnapchatActions.VERIFICATION_DONE:
    case ESnapchatActions.SET_VERIFICATION_CODE:
    case ESnapchatActions.CANCEL_START:
    case ESnapchatActions.DONE_AUTH:
    case ESnapchatActions.START_AUTH:
      return null;
    case ESnapchatActions.VERIFICATION_ERROR:
      return action.error;
    default: {
      return state;
    }
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case ESnapchatActions.ENROLL_START:
    case EAuthActions.RELOAD_USER:
    case ESnapchatActions.VERIFICATION_START:
    case ESnapchatActions.CANCEL_START:
      return true;
    case ESnapchatActions.ENROLL_START_DONE:
    case ESnapchatActions.ENROLL_START_ERROR:
    case ESnapchatActions.VERIFICATION_DONE:
    case ESnapchatActions.VERIFICATION_ERROR:
    case ESnapchatActions.CANCEL_DONE:
    case ESnapchatActions.CANCEL_ERROR:
    case EAuthActions.USER_LOADED:
      return false;
    default: {
      return state;
    }
  }
};

export const verificationCode = (state = new Array(4).fill(null), action) => {
  const emptyValue = new Array(4).fill(null);

  switch (action.type) {
    case ESnapchatActions.SET_VERIFICATION_CODE:
      return action.codeArray;
    case ESnapchatActions.VERIFICATION_DONE:
    case ESnapchatActions.CANCEL_START:
    case ESnapchatActions.DONE_AUTH:
    case ESnapchatActions.START_AUTH:
      return emptyValue;
    default:
      return state;
  }
};

const isDuringEnrollment = (state = false, action) => {
  if (action.type === ESnapchatActions.START_AUTH ||
    (action.type === EAuthActions.RELOAD_USER || action.reason === 'snapchat')) {
    return true;
  }
  if (action.type === EAuthActions.USER_LOADED || action.type === ESnapchatActions.DONE_AUTH) {
    return false;
  }

  return state;
};

export const snapchat = combineReducers({
  isDuringEnrollment,
  isLoading,
  enrollmentError,
  verificationError,
  verificationCode,
});
