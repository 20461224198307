import gql from 'graphql-tag';

export const getUserFcmToken = gql`
  mutation getUserFcmToken($userId: String!) {
    getUserFcmToken(userId: $userId) {
      endpoint
      expirationTime
      p256dh
      auth
    }
  }
`;
