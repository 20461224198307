import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const respondMutation = gql`
  mutation respond($currentId: ID, $userId: String!, $response: String!) {
    respond(currentId:$currentId, response: $response, userId: $userId) {
      classmate {
        ...ClassmateItem
      }

      friendRequestsStatus {
        ignoredCount
        pendingCount
        sentCount
      }
    }
  }

  ${classmateItemFragment}
`;
