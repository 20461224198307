export class AppVersionMiddleware {
  static version;

  static setVersion(version) {
    AppVersionMiddleware.version = version;
  }

  static getHeader() {
    return {
      AppVersion: AppVersionMiddleware.version || ''
    };
  }
}
