import { EMainActions } from '../main/main.actions';
import { EMyPostsActions } from './posts.actions'



export const pollsReducer = (state = '', action) => {
    switch (action.type) {
      case EMyPostsActions.NAVIGATE_TO_SECOND_STAGE:
      case EMainActions.NAVIGATE_PARENT:
        return '';
      default:
        return state;
    }
  }

