import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const myConnectionsQuery = gql`
  query myConnections(
    $userId: String,
    $limit: Int
    $skip: Int
    $pending: Boolean
    $ignored: Boolean
    $sent: Boolean
  ) {
    myConnections(
      userId: $userId,
      limit: $limit
      skip: $skip
      pending: $pending
      ignored: $ignored
      sent: $sent
    ) {
      ...ClassmateItem
    }
  }

  ${classmateItemFragment}
`;
