import React, { useState, useEffect } from 'react';
import { EFriendsListActiveState } from '../friends.actions';
import { LangData } from '../../assets/locales/localization';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Theme } from '../../common/providers/colors';
const width= window.innerWidth

const FriendsListTopBarView = props => {
  const [searchTermToDebounce, setSearchTermToDebounce] = useState('');
  const active = props.activeState === EFriendsListActiveState.REQUESTS;

  useEffect(() => {
    if (props.searchTerm !== searchTermToDebounce) {
      setSearchTermToDebounce(props.searchTerm);
    }
  }, [props]);

  const setSearchTerm = (event) => {
    let text = event.target.value
    setSearchTermToDebounce(text);
   
  }

  const handleSearchInputKeyPress = (event) => {
    console.log('event key', event.key)
    let text = event.target.value
    if (event.key === "Enter") {
      props.setSearchTerm(text);
    }
  }

  const clearSearchTerm = () => {
    let text = ''
    setSearchTermToDebounce(text);
    props.setSearchTerm(text);
  }




  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <TextInput
        type='text'
        autoComplete='false'
        placeholder={LangData.friendsListTopBar.search}
        onChange={e => setSearchTerm(e)}
        onKeyPress={e => handleSearchInputKeyPress(e)}
        value={searchTermToDebounce}
        style={styles.inputSearch}
        />
          <div style={styles.searchIconContainer}>
            <FontAwesomeIcon  icon={faSearch} style={styles.searchIcon}/>
          </div>
        <div onClick={clearSearchTerm} style={styles.clearIcon}>
          <FontAwesomeIcon icon={faTimes} style={styles.clearInput} />
        </div>
      </div>


      {props.activeState !== EFriendsListActiveState.REQUESTS ? (
        <RequestsBtn
          onClick={() =>
            props.toggleRequests(
              props.activeState !== EFriendsListActiveState.REQUESTS
            )
          }
        >
          {LangData.friendsGrid.requests}
        </RequestsBtn>
      ) : (
          <RequestsBtnSelected
            onClick={() =>
              props.toggleRequests(
                props.activeState !== EFriendsListActiveState.REQUESTS
              )
            }
          >
            {LangData.friendsGrid.requests}
          </RequestsBtnSelected>
        )}
    </div>
  );
};
export default FriendsListTopBarView;

const styles = {
  buttonTextStyle: {
    color: 'white',
    fontWeight: 'bold',
    alignSelf: 'center',
    height: 30,
    fontSize: 12,
    paddingTop: 5
  },
  searchIcon: {
    color:'#5f5b6d',
    top: '0.5%',
    left: '12%',
    // paddingTop: '5px',
    fontSize: '14px',
  },
  searchIconContainer: {
    top: '6%',
    left: width < 500 ? '12%' : '9%',
    fontSize: '14px',
    position: 'absolute'
  },
  clearIcon: {
    position: 'absolute',
    top: '0.5%',
    right: '12%',
    // paddingTop: '5px',
    fontSize: '14px',
  },
  container: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    width: '100%',
    borderBottomColor: Theme.BACKGROUND_COLOR,
    borderBottomWidth: 2,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  innerContainer: {
    width: '100%',
    display: 'flex'
  },
  inputSearch: {
    backgroundColor: '#f3decb',
    margin: '0 8%',
    padding: "10px 30px",
    width: '100%'
  },
  clearInput: {
    margin: '12% 2% 15% 5%',
    marginBottom: '20%',
    borderRadius: "50%",
    padding: "2px 2px",
    width: "13px",
    height: "13px",
    border: "2px solid #545267",
    color: Theme.NAV_LINK_COLOR,
    marginTop: '7px'
  },
};

const RequestsBtn = styled.button`
  background-color: ${Theme.DARK_BLUE_COLOR};
  justify-content: center;
  align-items: center;
  border: none;
  color: ${Theme.WHITE_COLOR};
  font-weight: bold;
  border-radius: 15px;
  padding:  ${width < 500 ? "1% 4%" : "0 4%"} ;
  margin-left: 8%;
  margin-top: 2%;
  height: 30px;
  width: ${width < 500 ? "46%" : "17%"};
  font-size: ${width < 500 ? "4.2vw" : "1rem"};

  &:focus {
    outline: none;
    border: none;
  }
`;

const RequestsBtnSelected = styled.button`
  background-color: ${Theme.DARK_BLUE_COLOR};
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  color: ${Theme.WHITE_COLOR};
  font-weight: bold;
  border-radius: 15px;
  padding:  ${width < 500 ? "1% 4%" : "0 4%"} ;
  margin-left: 8%;
  margin-top: 2%;
  height: 30px;
  width: 46%;

  &:focus {
    outline: none;
    border: none;
  }
`;

const TextInput = styled.input`
  padding-right: 30px;
  height: 25px;
  align-self: stretch;
  font-size: 13px;
  font-family: inherit;
  color: ${Theme.TEXT_COLOR};
  outline: none;
  border: none;
  padding-left: 7%;
  font-weight: bold;
  margin-left: 3%;
  position: relative;
  background-color: white;
  border-radius: 15px;
  width: 50%;

  &:focus {
    outline: none;
    border: none;
  }
`;
