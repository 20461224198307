import React, { Fragment, useState, useEffect } from 'react';
import { LangData } from '../../assets/locales/localization';
import { Theme } from '../../common/providers/colors';

const PostsNavigationScrollableTabView = props => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setIndex(props.index);
  }, [props]);

  return (
    <div style={styles.toggleContainer}>
      <label style={styles.switch}>
        <span
          style={styles.leftToggle}
          id='leftToggle'
          onClick={() => props.changeIndex(0)}
        >
          {LangData.postsNavigator.news}
        </span>
        <span
          style={styles.middleToggle}
          id='middleToggle'
          onClick={() => props.changeIndex(1)}
        >
          {LangData.postsNavigator.polls}
        </span>
        <span
          style={styles.rightToggle}
          id='rightToggle'
          onClick={() => props.changeIndex(2)}
        >
          {LangData.postsNavigator.events}
        </span>
        <span
          style={
            index === 0
              ? styles.sliderLeft
              : index === 1
              ? styles.sliderMiddle
              : styles.sliderRight
          }
          id='checked'
        ></span>
      </label>
    </div>
  );
};

export default PostsNavigationScrollableTabView;

const styles = {
  toggleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '5%',
    marginBottom: '2%'
  },
  leftToggle: {
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    position: 'absolute',
    zIndex: '1',
    left: window.innerWidth / 9 + 'px',
    fontSize: window.innerWidth / 30 + 'px',
    bottom: window.innerWidth / 45 + 'px'
  },
  middleToggle: {
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    position: 'absolute',
    zIndex: '1',
    left: window.innerWidth / 2.518 + 'px',
    fontSize: window.innerWidth / 30 + 'px',
    bottom: window.innerWidth / 45 + 'px'
  },
  rightToggle: {
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    position: 'absolute',
    zIndex: '1',
    left: window.innerWidth / 1.385 + 'px',
    fontSize: window.innerWidth / 30 + 'px',
    bottom: window.innerWidth / 45 + 'px'
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    color: 'white',
    fontWeight: 'bold'
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: window.innerWidth / 1.10299 + 'px',
    height: window.innerWidth / 12 + 'px',
    borderRadius: window.innerWidth / 18 + 'px',
    backgroundColor: Theme.BACKGROUND_COLOR,
    border: `1px solid ${Theme.SECONDARY_TEXT_COLOR}`
  },
  checkboxInput: {
    opacity: '0',
    width: '0',
    height: '0'
  },
  sliderRight: {
    position: 'absolute',
    content: '""',
    height: window.innerWidth / 13.9158 + 'px',
    top: window.innerWidth / 180 + 'px',
    width: window.innerWidth / 3.6 + 'px',
    left: window.innerWidth / 1.82741 + 'px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    transition: 'all 0.4s ease 0s',
    borderRadius: window.innerWidth / 7.5 + 'px',
    transform: 'translateX(26px)'
  },
  sliderLeft: {
    position: 'absolute',
    content: '""',
    height: window.innerWidth / 13.9158 + 'px',
    top: window.innerWidth / 180 + 'px',
    width: window.innerWidth / 3.6 + 'px',
    left: window.innerWidth / -16 + 'px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    transition: 'all 0.4s ease 0s',
    borderRadius: window.innerWidth / 10.048 + 'px',
    transform: 'translateX(26px)'
  },
  sliderMiddle: {
    position: 'absolute',
    content: '""',
    height: window.innerWidth / 13.9158 + 'px',
    top: window.innerWidth / 180 + 'px',
    width: window.innerWidth / 3.6 + 'px',
    left: window.innerWidth / 4.28571 + 'px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    transition: 'all 0.4s ease 0s',
    borderRadius: window.innerWidth / 7.5 + 'px',
    transform: 'translateX(26px)'
  }
};
