import gql from 'graphql-tag';

export const facebookDetailsQuery = gql`
    query {
        facebookAuth {
            appId
            returnUrl
            stateParam
        }
    }
`;
