import gql from 'graphql-tag';

export const notifyChatReadStatusMutation = gql`
    mutation notifyChatReadStatus($userId: ID, $chatId: String!, $messageId: String!){
        notifyChatReadStatus(userId:$userId, chatId: $chatId, messageId: $messageId){
            chat {
                id
                enabled
                unreadMessagesCount
                lastUpdate
            }
            unreadChatMessagesCount
        }
    }
`;
