import React, { Fragment } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { Theme } from '../providers/colors';

const LoadingSpinner = props => {
  return (
    <div
      style={Object.assign(
        props.height
          ? { height: props.height, width: props.width }
          : { minHeight: '100%', width: window.innerWidth },
        props.bgcolor
          ? { backgroundColor: props.bgcolor }
          : { backgroundColor: Theme.BACKGROUND_COLOR },
        props.top ? { top: props.top } : { top: 0 },
        props.left ? { left: props.left } : { left: 0 },
        styles.loaderSpinner
      )}
    >
      <div style={styles.sweetLoading}>
        <PulseLoader sizeUnit={'px'} size={20} color={'white'} />
      </div>
    </div>
  );
};

export default LoadingSpinner;

const styles = {
  loaderSpinner: {
    opacity: '0.85',
    zIndex: '999999',
    textAlign: 'center',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sweetLoading: {}
};
