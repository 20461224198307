import gql from 'graphql-tag';
import { newsFeedNewsFragment } from '../../posts/graphql/news-feed-news-item.fragment';

export const NewFeedItemUpdatedSubscription = gql`
    subscription itemUpdated {
        itemUpdated {
            ...NewsFields
        }
    }

    ${newsFeedNewsFragment}
`;
