import { graphql } from 'react-apollo-temp';
import { withEditEntity } from '../../posts/components/with-edit-feed-item.container';
import { connect } from 'react-redux';
import { createUpdateNewsMutation } from '../../posts/graphql/create-update-news.mutation';
import AddPost from './AddPost';
import { toggleMyPosts } from '../../posts/posts.actions';
import { navigateSecondStage } from '../../posts/posts.actions';
import { uploadToCloudinaryMutation } from '../../common/graphql/upload-to-cloudinary.mutation';
import { getDataFromFile } from '../../cloudinary/utils/upload';
import { compose } from 'redux';
import { updateNewsFeedItemTargetsQuery } from '../../posts/graphql/update-news-feed-item-targets.mutation';
import { publishMutation } from '../../posts/graphql/publish-feed-item.mutation';
import { DISMISS_ERROR } from '../../login/login.reducer';


const mapDispatch = dispatch => ({
  navigateSecondStage: event => dispatch(navigateSecondStage(event)),
  toggleModal: (isOpen, userId) => dispatch(toggleMyPosts(isOpen)),
  dismissError: () => dispatch(DISMISS_ERROR),
  close: () => dispatch(navigateSecondStage(null))
});

export const withImageUploadMutation = graphql(uploadToCloudinaryMutation, {
  props: ({ ownProps, mutate }) => ({
    uploadFile: (fsFile, tag, fileData, filePath, publicId, update) =>
      mutate({
        variables: {
          ...getDataFromFile(fsFile),
          filePath: filePath,
          publicId: publicId,
          tag
        },
        optimisticResponse: {
          __typename: 'Mutation',
          uploadToCloudinary: {
            id: `upload_temp_id_${Date.now().toString()}`,
            filePath: `data:image/jpeg;base64,${fileData}`,
            __typename: 'CloudinaryFileUpload'
          }
        },
        update
      })
  })
});

const withState = connect(null, mapDispatch);

const withMutation = graphql(createUpdateNewsMutation, {
  props: ({ ownProps, mutate }) => {    
    return {
    createUpdateNews: (userId, newsId, news) =>
      mutate({
        variables: {
          userId,
          newsId,
          news
        }
      })
  }}
 
});

const withTargetMutation = graphql(updateNewsFeedItemTargetsQuery, {
  props: ({ ownProps, mutate }) => ({
    updateTargets: (itemId, targets) =>
      mutate({ variables: { itemId, targets } })
  })
});

const withPublicationMutation = graphql(publishMutation, {
  props: ({ ownProps, mutate }) => ({
    publish: (itemId, platforms) => mutate({ variables: { itemId, platforms } })
  }),
  
 
});

export const CreateUpdateNewsForm = compose(
  withImageUploadMutation,
  withState,
  withMutation,
  withEditEntity,
  withTargetMutation,
  withPublicationMutation
)(AddPost);
