import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';
import { FeedEventItemFragment } from '../../news-feed/graphql/feed-event-item.fragment';

export const NotificationUpdatedSubscription = gql`
  subscription {
    notificationUpdated {
              __typename
              id
              content
              read
              time
              approved
              relatedUserId
              relatedFeedItemId
              userId
              originalPhotoUrl
              photoUrl
              title
              otherUser {
                ...ClassmateItem
              }
              event {
                ...FeedEventItem
              }
        }
    }

    ${classmateItemFragment}
    ${FeedEventItemFragment}


`;
