import moment from 'moment';
import { reportAnalyticsMutation } from './graphql/report-analytics.mutation';

class MsjAnalytics {
  constructor() {
    this._queue = [];
    this._user = null;
    this._handle = null;
    this._apolloClient = null;
  }

  start(interval, client) {
    this._handle = setInterval(() => this.send(), interval);
    this._apolloClient = client;
  }

  stop() {
    if (this._handle) {
      clearInterval(this._handle);
      this._handle = null;
    }
  }

  setUser(user) {
    this._user = user;
  }

  send() {
    console.log("inside send!", this._queue)
    if (this._queue && this._queue.length > 0) {
      const copy = [...this._queue];
      this._queue = [];

      this._apolloClient.mutate({
        mutation: reportAnalyticsMutation,
        variables: {
          items: copy,
        },
      }).catch(() => null);
    }
  }

  report(category, action, identifier = null, overrideUser = null) {
    const userId = overrideUser ? overrideUser.id : this._user ? this._user.id : null;

    this._queue.push({
      category,
      action,
      identifier,
      userId,
      time: moment().utc().format(),
    });
  }
}

export const analytics = new MsjAnalytics();
