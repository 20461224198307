import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../common/providers/colors';

export class ChatPickedChips extends Component {
  static propTypes = {
    picked: PropTypes.array.isRequired,
    onRemove: PropTypes.func
  };

  componentWillReceiveProps(nextProps) {
    // if (this.props.picked !== nextProps.picked) {
    //   LayoutAnimation.configureNext(
    //     LayoutAnimation.create(
    //       200,
    //       LayoutAnimation.Types.easeInEaseOut,
    //       LayoutAnimation.Properties.opacity
    //     )
    //   );
    // }
  }

  renderChip(user) {
    return (
      <div key={user.id} style={styles.chip}>
        <span style={styles.chipText}>{user.name}</span>
        <div onClick={() => this.props.onRemove(user)}>
          <FontAwesomeIcon icon={faTimes} style={styles.closeIcon} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        {this.props.picked.map(user => this.renderChip(user))}
      </div>
    );
  }
}

const styles = {
  chip: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    borderRadius: 15,
    paddingVertical: 5,
    paddingHorizontal: 7,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    marginRight: 5,
    minWidth: 'fit-content',
    padding: '0 10px'
  },
  chipText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 15,
    marginRight: '7px'
  },
  container: {
    flexDirection: 'row',
    display: 'flex',
    alignSelf: 'end',
    margin: '0px 10px 5px 10px',
    overflowX: 'scroll'
  },
  removeIcon: {
    marginTop: 2,
    marginLeft: 7
  },
  closeIcon: {
    color: 'black',
    fontSize: '13px'
  }
};
