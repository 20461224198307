import React, { Fragment, useEffect, useState } from 'react';
import { LangData } from '../../../assets/locales/localization';
import ProfileImage from '../../../common/components/profile-image.rn';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUserFriends,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../common/providers/colors';

const ClassmateItemView = props => {
  const [icon, setIcon] = useState(null);
  const [emphasisColor, setEmphasisColor] = useState(
    Theme.SECONDARY_TEXT_COLOR
  );

  useEffect(() => {
    if (props.classmate.friendRequestStatus === 'APPROVED') {
      setEmphasisColor(Theme.SECONDARY_BACKGROUND_COLOR);
      setIcon(faUserFriends);
    } else if (
      props.classmate.friendRequestStatus === 'PENDING' ||
      props.classmate.friendRequestStatus === 'SENT'
    ) {
      setEmphasisColor('#aaaaaa');
      setIcon(faUser);
    }
  }, [props]);

  return (
    <Fragment>
      <div
        disabled={props.disabled}
        onClick={() => {
          props.onItemClick(props.classmate);
        }}
        style={styles.container}
      >
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <div
            style={Object.assign(
              props.isRelative ? { position: 'relative' } : {},
              styles.imageContainer
            )}
          >
            <ProfileImage
              showBorder={false}
              style={styles.image}
              imageUrl={props.classmate.profilePictureUrl}
            />
          </div>

          <div style={styles.textContainer}>
            <p style={styles.title}>
              {props.me && props.me.id === props.classmate.id
                ? LangData.classmateItem.you
                : props.classmate.name}
            </p>
            {props.minimal ? null : (
              <p style={styles.subTitle} numberOfLines={2}>
                {props.classmate.major}
              </p>
            )}
          </div>
        </div>
        {props.checked ? (
          <FontAwesomeIcon
            icon={faCheck}
            style={{ color: Theme.SECONDARY_TEXT_COLOR }}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

const SCREEN_WIDTH = window.innerWidth;
const ITEM_WIDTH = 100;
const ITEM_HEIGHT = 120;
export const CLASSMATE_ITEM_MARGIN = (SCREEN_WIDTH - ITEM_WIDTH * 3) / 8;

const styles = {
  imageContainer: {
    borderRadius: '50%',
    width: 35,
    textAlign: 'center',
    // position: 'relative',
    height: 35,
    marginRight: '5%'
  },
  iconContainer: {
    width: 'fit-content',
    position: 'absolute',
    right: '-4px',
    bottom: '5px',
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '3px',
    border: '1px solid ',
    fontSize: '12px'
  },
  title: {
    color: Theme.SECONDARY_TEXT_COLOR,
    fontWeight: 'bold',
    margin: 0
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    marginBottom: '5%',
    justifyContent: 'space-between'
  }
};

export const ClassmateItem = connect(({ auth }) => ({ me: auth.currentUser }))(
  ClassmateItemView
);
