import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { hideChatMutation } from '../../graphql/hide-chat.mutation';
import { withApollo, compose, graphql } from 'react-apollo-temp';
import { myChatsQuery } from '../../graphql/my-chats.query';
import { leaveGroupChatMutation } from '../../graphql/leave-group-chat.mutation';
import { LangData } from '../../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSignOutAlt,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Theme } from '../../../common/providers/colors';

export class ChatListItemMenuView extends Component {
  static REMOVE_CHAT = 'REMOVE_CHAT';
  static LEAVE_CHAT = 'LEAVE_CHAT';
  static ADD_FRIENDS = 'ADD_FRIENDS';

  static propTypes = {
    chat: PropTypes.any,
    visible: PropTypes.bool,
    showAddFriendsButton: PropTypes.bool,
    onClose: PropTypes.func,
    hideChat: PropTypes.func,
    addUsersToChat: PropTypes.func,
    leaveGroupChat: PropTypes.func,
    onBeforeAction: PropTypes.func
  };

  async remove() {
    const chatId = this.props.chat.id;

    this.props.onDone();

    if (this.props.onBeforeAction) {
      await this.props.onBeforeAction(ChatListItemMenuView.REMOVE_CHAT);
    }

      await this.props.hideChat(this.props.currentUser.id, chatId);
      // alert(LangData.chatListItemMenu.delete);
  }

  async leaveGroupChat() {
    const chatId = this.props.chat.id;
    const { title } = this.props.chat;
    this.props.onDone();

    if (this.props.onBeforeAction) {
      await this.props.onBeforeAction(ChatListItemMenuView.LEAVE_CHAT);
    }

    setTimeout(async () => {
      await this.props.leaveGroupChat(this.props.currentUser.id, chatId);
      // alert(LangData.chatListItemMenu.part + ' ' + title);
    }, 50);
  }

  render() {
    const isGroup =
      this.props.chat && this.props.chat.users && this.props.chat.title;

    return (
      <Fragment>
        {this.props.visible ? (
          <div
            style={isGroup ? styles.menuContainerGroup : styles.menuContainer}
            id='menuContainer'
          >
            {this.props.showAddFriendsButton && isGroup && (
              <button
                style={styles.btn}
                onClick={async () => {
                  this.props.onClose();
                  if (this.props.onBeforeAction) {
                    await this.props.onBeforeAction(
                      ChatListItemMenuView.ADD_FRIENDS
                    );
                  }
                  this.props.addUsersToChat();
                }}
              >
                <FontAwesomeIcon icon={faUserPlus} style={styles.icon} />
                <span>{LangData.chatListItemMenu.add}</span>
              </button>
            )}

            {isGroup && (
              <button style={styles.btn} onClick={() => this.leaveGroupChat()}>
                <FontAwesomeIcon icon={faSignOutAlt} style={styles.icon} />
                <span> {LangData.chatListItemMenu.leave}</span>
              </button>
            )}

            <button style={styles.btn} onClick={() => this.remove()}>
              <FontAwesomeIcon icon={faTrashAlt} style={styles.icon} />
              <span> {LangData.chatListItemMenu.remove}</span>
            </button>

            <button style={styles.btn} onClick={() => this.props.onClose()}>
              <FontAwesomeIcon icon={faTimes} style={styles.icon} />
              <span>Close</span>
            </button>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const styles = {
  menuContainer: {
    position: 'fixed',
    backgroundColor: 'white',
    right: '0',
    marginTop: '16.8%',
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  },
  menuContainerGroup: {
    position: 'fixed',
    backgroundColor: 'white',
    right: '0',
    marginTop: '16.8%',
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  },
  btn: {
    display: 'flex',
    fontFamily: 'inherit',
    fontSize: '14px',
    margin: '5px',
    color: Theme.TEXT_COLOR,
    background: 'none',
    border: 'none'
  },
  icon: {
    marginRight: '10px'
  }
};

const withHideChatMutation = graphql(hideChatMutation, {
  props: ({ ownProps, mutate }) => ({
    hideChat: (userId, chatId) => {
      // const chatsStatus = ownProps.client.readQuery({ query: myChatsQuery });
      // const chats = (chatsStatus.chats || []).filter(
      //   chat => chat.id !== chatId
      // );

      return mutate({
        variables: {
          userId,
          chatId
        },
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   hideChat: chats
        // },
        refetchQueries: ['unreadChatMessagesCount'],
        // update: (store, { data: { hideChat } }) => {
        //   store.writeQuery({
        //     query: myChatsQuery,
        //     data: {
        //       chats: hideChat
        //     }
        //   });
        // }
      });
    }
  })
});

const withLeaveGroupChatMutation = graphql(leaveGroupChatMutation, {
  props: ({ ownProps, mutate }) => ({
    leaveGroupChat: (userId, chatId) => {
      // const chatsStatus = ownProps.client.readQuery({ query: myChatsQuery });
      // const chats = (chatsStatus.chats || []).filter(
      //   chat => chat.id !== chatId
      // );

      return mutate({
        variables: {
          userId, 
          chatId
        },
        refetchQueries: ['unreadChatMessagesCount'],
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   leaveGroupChat: chats
        // },
        update: (store, { data: { leaveGroupChat } }) => {
          // store.writeQuery({
          //   query: myChatsQuery,
          //   data: {
          //     chats: leaveGroupChat
          //   }
          // });
        }
      });
    }
  })
});

export const ChatListItemMenu = compose(
  withApollo,
  withHideChatMutation,
  withLeaveGroupChatMutation
)(ChatListItemMenuView);
