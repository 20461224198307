import { graphql } from 'react-apollo-temp';
import { commentByIdQuery } from '../graphql/comment-by-id.query';

export const withEditEntity = graphql(commentByIdQuery, {
  skip: ownProps => !ownProps.editEntityId || ownProps.editEntityId === '',
  options: ownProps => ({
    options: {
      fetchPolicy: 'network-only'
    },
    variables: {
      id: ownProps.editEntityId
    }
  }),
  props: ({ ownProps, data }) => {
    return {
      loading: data.loading,
      editEntity: data.loading || data.error ? null : data.comment
    };
  }
});
