import FriendsGridView from './friends-grid.rn';
import { withFriendsState } from './with-state.container';
import { graphql } from 'react-apollo-temp';
import { compose } from 'redux';
import {
  EFriendsListActiveState,
  EFriendsListFilterType
} from '../friends.actions';
import { myConnectionsQuery } from '../graphql/my-connections.query';
import { searchStudentsQuery } from '../graphql/search-students.query';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';
import { connect } from 'react-redux';
import { toggleComponent } from '../../router/router.actions';
import { toggleUserProfileModal } from '../../profile/profile.actions';
import update from 'immutability-helper';

const PER_PAGE = 40;

export const withMyConnections = graphql(myConnectionsQuery, {
  name: 'connections',
  options: ({ activeState, currentUser }) => ({
    variables: {
      userId: currentUser ? currentUser.id : null,
      pending: activeState === EFriendsListFilterType.PENDING,
      ignored: activeState === EFriendsListFilterType.IGNORED,
      sent: activeState === EFriendsListFilterType.SENT,
      skip: 0,
      limit: PER_PAGE
    },
    fetchPolicy: 'cache-and-network'
  }),
  props({ connections, activeState }) {
    return {
      connections,
      loadMoreConnectionsData: (skipCount) => {
        if (connections) {
          return connections
            .fetchMore({
              variables: {
                pending: activeState === EFriendsListFilterType.PENDING,
                ignored: activeState === EFriendsListFilterType.IGNORED,
                sent: activeState === EFriendsListFilterType.SENT,
                skip:
                  connections && connections.myConnections
                    ? skipCount
                    : 0,
                limit: PER_PAGE
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                const target =
                  prev && prev.myConnections
                    ? prev
                    : { myConnections: { hasMore: true, items: [] } };

                if (
                  fetchMoreResult &&
                  fetchMoreResult.myConnections &&
                  fetchMoreResult.myConnections.length > 0
                ) {

                  let newElements = [];
                  fetchMoreResult.myConnections.map(elem => {
                    let isExist = prev.myConnections.filter(con => con.id.toString() === elem.id.toString()).length > 0
                    if(!isExist){
                      newElements.push(elem)
                    }
                  })

                  return update(target, {
                    myConnections: {
                      $push: newElements
                    }
                    // hasMore: { $set: fetchMoreResult.feedList.hasMore }
                  });
                }
              }
            })
            .catch(() => null);
        }

        return Promise.resolve();
      }
    };
  }
});

const withSearchResults = graphql(searchStudentsQuery, {
  name: 'searchResults',
  skip: ({ activeState }) =>
    activeState !== EFriendsListActiveState.GLOBAL_SEARCH,
  options: ({ searchTerm, showHiddenStudents, currentUser }) => ({
    variables: {
      userId: currentUser ? currentUser.id : null,
      text: searchTerm,
      showHidden: showHiddenStudents
    },
    fetchPolicy: 'cache-and-network'
  })
});

const withError = withErrorHandler({
  apollo: ['searchResults', 'connections']
});

const mapStateToProps = ({ navigation }) => ({
  currentComponent: navigation.navigationComponents.component
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  onItemPress: user => {
    return dispatch(toggleUserProfileModal(!!user, user.id));
  }
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const FriendsGrid = compose(
  withFriendsState,
  withState,
  withMyConnections,
  withSearchResults,
  withError
)(FriendsGridView);
