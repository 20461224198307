import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../../../assets/locales/localization';
import { Theme } from '../../../../common/providers/colors';
export class ScoreBox extends Component {
  static propTypes = {
    score: PropTypes.string.isRequired,
    ended: PropTypes.bool.isRequired
  };

  getScoreElements() {
    const matches = /([A-Z]), ([0-9]{1,3})-([0-9]{1,3})/g.exec(
      this.props.score
    );

    if (!matches) {
      return null;
    }

    const resultLetter = matches[1];
    const score1 = parseInt(matches[2]);
    const score2 = parseInt(matches[3]);

    return {
      result: resultLetter,
      team1: { score: score1, leading: score1 > score2 },
      team2: { score: score2, leading: score2 > score1 }
    };
  }

  render() {
    const elements = this.getScoreElements();

    if (!elements) {
      return null;
    }

    return (
      <div style={styles.container}>
        <div style={styles.scoresContainer}>
          <p style={styles.teamScore}>{elements.team1.score}</p>
          <div style={styles.separator} />
          <p style={styles.teamScore}>{elements.team2.score}</p>
        </div>
        <div style={styles.statusContainer}>
          {this.props.ended ? (
            <p style={styles.endedText}>
              {' '}
              {LangData.scoreBox.result + ' ' + elements.result}
            </p>
          ) : (
            <Fragment>
              <div style={styles.liveCircle} />
              <p style={styles.liveText}>{LangData.scoreBox.live}</p>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    marginRight: 7,
    flex: 0.4,
    alignSelf: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    height: 45,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 6
  },
  separator: {
    backgroundColor: 'white',
    width: 1
  },
  scoresContainer: {
    width: '100%',
    flexDirection: 'row',
    borderBottomColor: 'white',
    borderBottomWidth: 1
  },
  teamScore: {
    fontFamily: 'Assistant',
    flex: 1,
    fontSize: 20,
    textAlign: 'center',
    color: 'white'
  },
  leadingScore: {
    fontFamily: 'Assistant-Bold'
  },
  statusContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  liveText: {
    fontFamily: 'Assistant-Bold',
    fontSize: 11,
    color: 'white'
  },
  endedText: {
    fontFamily: 'Assistant',
    fontSize: 11,
    color: 'white'
  },
  liveCircle: {
    marginRight: 4,
    width: 5,
    height: 5,
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    borderRadius: 2.5
  }
};
