export const ECoursesViewActions = {
  CHANGE_ACTIVE_ITEM: 'courses/CHANGE_ACTIVE_ITEM',
  TOGGLE_DRAWER: 'courses/TOGGLE_DRAWER',
  TOGGLE_DRAWER_HEIGHT: 'courses/TOGGLE_DRAWER_HEIGHT'
};

export const changeActiveCourse = course => ({
  type: ECoursesViewActions.CHANGE_ACTIVE_ITEM,
  payload: course
});

export const toggleDrawer = newState => ({
  type: ECoursesViewActions.TOGGLE_DRAWER,
  newState
});
