import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faChevronUp,
  faChevronDown,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { LangData } from '../../assets/locales/localization';
import { Classmates } from './classmate.container';
import styled from 'styled-components';
import { Theme } from '../../common/providers/colors';
const width = window.innerWidth

const ClassmateDrawerView = props => {
  const [isDrawerFullHeight, setIsDrawerFullHeight] = useState(false);
  const [isSearchDisplay, setIsSearchDisplay] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleDrawerHeight = () => {
    setIsDrawerFullHeight(!isDrawerFullHeight);
  };

  const toggleSearchDisplay = () => {
    setIsSearchDisplay(!isSearchDisplay);
  };

  return (
    <Fragment>
      <div
        style={
          props.currentComponent !== 'Courses' || props.activeCourse === null
            ? styles.classMateConatinerClose
            : isDrawerFullHeight
            ? styles.classMateConatinerOpen
            : styles.classMateConatinerHalfOpen
        }
      >
        <div style={styles.searchRow}>
          <div
            styles={styles.searchIconContainer}
            onClick={toggleSearchDisplay}
          >
            <FontAwesomeIcon
              icon={isSearchDisplay ? faTimes : faSearch}
              style={styles.serachIcon}
            />
          </div>
          <div style={styles.middleContainer}>
            {props.activeCourse && !isSearchDisplay && (
              <p style={styles.className}>
                {LangData.classmatesDrawer.classmates.toUpperCase() +
                  ' ' +
                  props.activeCourse.name}
              </p>
            )}

            {props.activeCourse && isSearchDisplay && (
              <TextInput
                type='text'
                placeholder={LangData.classmatesDrawer.find}
                // style={styles.input}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            )}
          </div>
          <div styles={styles.arrowIconConatiner} onClick={toggleDrawerHeight}>
            <FontAwesomeIcon
              icon={isDrawerFullHeight ? faChevronDown : faChevronUp}
              style={styles.serachIcon}
            />
          </div>
        </div>

        <Classmates
          onListInit={props.onListInit}
          scrollEnabled={props.isOpen}
          activeCourse={props.activeCourse ? props.activeCourse : null}
          minimal={true}
          filterTerm={searchTerm}
          forceTopOnClose={true}
          isOpen={props.isOpen}
          validateModalOpen={() => {
            if (!isDrawerFullHeight) {
              toggleDrawerHeight();
              return false;
            }

            return true;
          }}
          history={props.history}
        />
      </div>
    </Fragment>
  );
};

export default ClassmateDrawerView;

const styles = {
  classMateConatinerClose: {
    display: 'none'
  },
  classMateConatinerOpen: {
    backgroundColor: 'white',
    position: 'fixed',
    bottom: '0',
    zIndex: '88',
    height: window.innerHeight / 1.077 + 'px',
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'height 1s ease'
  },
  classMateConatinerHalfOpen: {
    backgroundColor: 'white',
    position: 'fixed',
    bottom: '0',
    zIndex: '88',
    height: window.innerWidth / 1.836 + 'px',
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    WebkitBoxShadow: '0px -3px 3px rgba(50, 50, 50, 0.75)',
    MozBoxShadow: '0px -3px 3px rgba(50, 50, 50, 0.75)',
    boxShadow: '0px -3px 3px rgba(50, 50, 50, 0.75)',
    transition: 'height 1s ease'
  },
  searchRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    color: Theme.BACKGROUND_COLOR,
    padding: '1%',
    fontSize: window.innerWidth / 23 + 'px',
    borderBottom: `2px solid ${Theme.BACKGROUND_COLOR}`,
    fontWeight: 'bold',
    minHeight: 20
  },
  middleContainer: {
    width: '90%'
  },
  searchIcon: {
    transition: 'display 1s ease'
  },
  className: {
    textAlign: 'center',
    margin: '0'
  },
  input: {
    width: '100%',
    fontFamily: 'inherit',
    color: Theme.SECONDARY_TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: '15px'
  },
  classMates: {
    marginTop: '3%',
    textAlign: 'center',
    display: 'grid',
    width: '100%',
    gridTemplateColumns:
      window.innerWidth / 3 +
      'px ' +
      window.innerWidth / 3 +
      'px ' +
      window.innerWidth / 3 +
      'px'
  }
};

const TextInput = styled.input`
  width: 100%;
  font-family: inherit;
  color: ${Theme.SECONDARY_TEXT_COLOR};
  font-weight: bold;
  font-size: 15px;

  &:focus {
    outline: none;
    border: none;
  }
`;
