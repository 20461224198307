import EventLiveFeedView from './event-live-feed.rn';
import { compose, graphql } from 'react-apollo-temp';
import { eventLiveFeedImagesQuery } from '../../../graphql/event-live-feed-images.query';
import { withErrorHandler } from '../../../../common/hoc/error-handler.rn';
import { connect } from 'react-redux';

const withEventImages = graphql(eventLiveFeedImagesQuery, {
  name: 'liveFeedImages',
  options: ({ eventId }) => ({
    pollInterval: 30 * 1000,
    fetchPolicy: 'network-only',
    variables: {
      id: eventId
    }
  })
});

const withError = withErrorHandler({
  apollo: ['liveFeedImages']
});

const mapStateToProp = ({ auth }) => ({
  currentUser: auth.currentUser
});

const withState = connect(mapStateToProp);

export const EventLiveFeed = compose(
  withEventImages,
  withState,
  withError
)(EventLiveFeedView);
