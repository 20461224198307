import React, { Fragment, useState, useEffect } from 'react';
import { LangData } from '../assets/locales/localization';
import { Theme } from '../common/providers/colors';
import firstBackground from '../assets/images/01_Splash.png';
import secondBackground from '../assets/images/02_Onboarding.png';
import thirdBackround from '../assets/images/03_Onboarding.png';
import fourBackground from '../assets/images/04_Onboarding.png';
import SwipeableViews from 'react-swipeable-views';
import { connect } from 'react-redux';

const width = window.innerWidth

const Intro = props => {
  const [showFirstScreen, setShowFirstScreen] = useState(true);
  const [currIndex, setCurrIndex] = useState(0);
  useEffect(() => {
    if (width < 500) {
      setTimeout(() => {
        setShowFirstScreen(false);
        if(window.matchMedia('(display-mode: standalone)').matches) {
          props.history.push('/login')
        } else {
          props.history.push('/installApp')
        }
       
      }, 4000);
    }
    else {
      setShowFirstScreen(false);
      props.history.push('/welcome')
    }
  }, []);

  const handleChangeIndex = async index => {
    setCurrIndex(index);
  };
  return (
    <Fragment>
      <div style={styles.firstContainer}></div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  loading: state.intro.isLoading,
  user: state.auth.currentUser,
  showInstallPage: state.navigation.showInstallAppPage.show
  //   appVersion: state.main.appVersion
});

export default connect(mapStateToProps)(Intro);

const styles = {
  firstContainer: {
    backgroundImage: `url(${firstBackground})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  secondContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundImage: `url(${secondBackground})`,
    backgroundPosition: 'center',
    color: '#4d4b62',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
  },
  thirdContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundImage: `url(${thirdBackround})`,
    backgroundPosition: 'center',
    color: '#4d4b62',
    textAlign: 'center',
    justifyContent: 'space-between',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  },
  fourContainer: {
    backgroundImage: `url(${fourBackground})`,
    display: 'flex',
    flexDirection: 'column',
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundPosition: 'center',
    color: '#4d4b62',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  },
  submitButton: {
    backgroundColor: Theme.NEW_BACKGROUND_COLOR,
    color: Theme.BLACK_COLOR,
    textAlign: 'center',
    padding: '5%',
    fontFamily: 'inherit',
    fontSize: '15px',
    border: 'none',
    width: '85%',
    borderRadius: '30px',
    alignSelf: 'center'
  },
  paginationContainer: {
    width: '74.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center'
  },
  notSelectedDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#615F62',
    transition: 'background-color ease 0.5s',
    transition: 'width ease 0.5s'
  },
  selectedDot: {
    width: '35px',
    height: '10px',
    borderRadius: '20px',
    backgroundColor: '#D0C6BD',
    transition: 'background-color ease 0.5s',
    transition: 'width ease 0.5s'
  },
  contentInfo: {
    width: '80%',
    margin: '0 auto',
    marginBottom: '5%'
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  skipStyle: {
    marginBottom: '10%'
  },
  contuinueBtn: {
    position: 'absolute',
    top: '90%',
    left: '50%',
    transform: 'translateX(-50%)'
  }
};

const Pagination = props => {
  return (
    <div style={styles.paginationContainer}>
      <div
        style={
          props.currIndex === 0 ? styles.selectedDot : styles.notSelectedDot
        }
      />
      <div
        style={
          props.currIndex === 1 ? styles.selectedDot : styles.notSelectedDot
        }
      />
      <div
        style={
          props.currIndex === 2 ? styles.selectedDot : styles.notSelectedDot
        }
      />
    </div>
  );
};
