const getColorsByLocalStorage = () => {
  if (localStorage.getItem('option-colors') === 'first') {
    return {
      TEXT_COLOR: '#000000',
      BACKGROUND_COLOR: '#f1e9e0',
      SECONDARY_TEXT_COLOR: '#3A3D62',
      SHAPE_AND_LINES: '#B0B1B1',
      SECONDARY_BACKGROUND_COLOR: '#928F97',
      WHITE_COLOR: '#ffffff',
      NEW_BACKGROUND_COLOR: '#D4CCC0',
      BLACK_COLOR: '#000000',
      GRAY_COLOR: '#928F97',
      DARK_BLUE_COLOR: '#3A3D62',
      LOWER_NAV_ICONS_COLOR:'#f4d2b8',
      NAV_LINK_COLOR:'#545267'
    };
  } else if (localStorage.getItem('option-colors') === 'second') {
    return {
      TEXT_COLOR: '#000000',
      BACKGROUND_COLOR: '#f1e9e0',
      SECONDARY_TEXT_COLOR: '#3A3D62',
      SHAPE_AND_LINES: '#B0B1B1',
      SECONDARY_BACKGROUND_COLOR: '#928F97',
      WHITE_COLOR: '#ffffff',
      NEW_BACKGROUND_COLOR: '#D4CCC0',
      BLACK_COLOR: '#000000',
      GRAY_COLOR: '#928F97',
      DARK_BLUE_COLOR: '#3A3D62',
      LOWER_NAV_ICONS_COLOR:'#f4d2b8',
      NAV_LINK_COLOR:'#545267'
    };
  } else {
    return {
      TEXT_COLOR: '#000000',
      BACKGROUND_COLOR: '#f1e9e0',
      SECONDARY_TEXT_COLOR: '#3A3D62',
      SHAPE_AND_LINES: '#B0B1B1',
      SECONDARY_BACKGROUND_COLOR: '#928F97',
      WHITE_COLOR: '#ffffff',
      NEW_BACKGROUND_COLOR: '#D4CCC0',
      BLACK_COLOR: '#000000',
      GRAY_COLOR: '#928F97',
      DARK_BLUE_COLOR: '#3A3D62',
      LOWER_NAV_ICONS_COLOR:'#f4d2b8',
      NAV_LINK_COLOR:'#545267'
    };
  }
};

export let Theme = getColorsByLocalStorage();
