import gql from 'graphql-tag';
import { classmateItemFragment } from '../../classmates/graphql/classmate-item.fragment';

export const getUserById = gql`
  mutation getUserById($userId: String!) {
    userById(userId: $userId) {
      ...ClassmateItem
    }
  }

  ${classmateItemFragment}
`;
