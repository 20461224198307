import { combineReducers } from 'redux';
import { ETwitterActions } from './twitter.actions';
import { EAuthActions } from '../auth/auth.actions';

const isDuringAuthentication = (state = false, action) => {
  if (action.type === ETwitterActions.START_AUTH ||
    (action.type === EAuthActions.RELOAD_USER && action.reason === 'twitter')) {
    return true;
  }
  if (action.type === EAuthActions.USER_LOADED) {
    return false;
  }

  return state;
};

export const twitter = combineReducers({
  isDuringAuthentication,
});
